import cn from "classnames";
import {
  isBookingView,
  isRegView,
} from "components/hall-scheme/redux/HallSchemaV2/hall-helpersFn";
import { OpenScheme } from "components/registration/sub-renders/OpenScheme";
import { placeSelector, placesOptionsSelector } from "features/AppContext";
import { useApplicationContextActions } from "features/AppContext/slice";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { hallModeSelector } from "features/HallSchema/selectors";
import { useTimelineActions } from "features/Timeline";
import { SingleOption } from "models/common";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectBasic } from "ui-kit";
import { preventDefault, stopPropagation } from "utils";

import defaultStyles from "./PlaceSelect.module.scss";

interface PlaceSelectProps {
  disabled?: boolean;
  isNewDesign?: boolean;
  managerialBookingTimeStamp?: number;
  styles?: Record<string, string>;
  placeId?: unknown;
  handleFormPrefillNavigation?: () => void;
}

export function PlaceSelect({
  disabled,
  isNewDesign,
  managerialBookingTimeStamp = 0,
  styles,
  handleFormPrefillNavigation,
}: PlaceSelectProps) {
  const { setPlace } = useApplicationContextActions();
  const { setActiveTables } = useHallSchemaActions();
  const { setTimestamp } = useTimelineActions();
  const { pathname } = useLocation();
  const isReservationFromDialog = pathname.includes("/dialogs");

  const placesOptions = useSelector(placesOptionsSelector);
  const place = useSelector(placeSelector);

  const handleChange = useCallback(
    (opt: SingleOption | null) => {
      setActiveTables({ activeTables: [] });
      setPlace(opt ? opt.value : null);
    },
    [setPlace],
  );

  const value = useMemo(
    () =>
      placesOptions.find((el) => {
        return el.value === place;
      }),
    [place],
  );

  const hallMode = useSelector(hallModeSelector);
  const { switchMode } = useHallSchemaActions();

  const showSchema = (event: any) => {
    preventDefault(event);
    stopPropagation(event);

    if (isReservationFromDialog) {
      handleFormPrefillNavigation?.();
    }

    if (managerialBookingTimeStamp) {
      setTimestamp(managerialBookingTimeStamp);
    }

    if (isRegView(hallMode)) {
      switchMode(HallMode.REGISTRATION_HALL);
      return;
    }
    if (hallMode === HallMode.MANAGERIAL_CREATE_BOOKING) {
      switchMode(HallMode.MANAGERIAL_HALL_BOOKING);
      return;
    }

    if (hallMode === HallMode.MANAGERIAL_TABLET) {
      switchMode(HallMode.MANAGERIAL_HALL);
      return;
    }
    if (isBookingView(hallMode)) {
      switchMode(HallMode.BOOKING_HALL);
      return;
    }
    switchMode(HallMode.EDIT_HALL);
  };

  return (
    <div
      className={cn((styles || defaultStyles).placeSelect, {
        [defaultStyles.showButton]: isReservationFromDialog,
      })}
    >
      <SelectBasic
        className={disabled ? "input" : ""}
        isDisabled={disabled}
        isSearchable={false}
        options={placesOptions}
        value={value}
        onChange={handleChange as any}
      />
      {isNewDesign ? (
        <button
          className={(styles || defaultStyles).openScheme}
          disabled={disabled}
        >
          <OpenScheme onClick={showSchema} />
        </button>
      ) : null}
    </div>
  );
}
