import { STATE_FILTERS_TITLES, STATUS_FILTERS_TITLES } from "constants/";
import type { IntlShape } from "react-intl";
import { Link } from "react-router-dom";
import type { Locales } from "types/commons";
import { ETranslations } from "types/translates";
import { Button, Input, SelectCheckbox } from "ui-kit";

import type { BookingModeType, BookingsData } from "./types";
import { useBookingFilters } from "./useBookingFilters";

export const BookingFilters = ({
  styles,
  bookingMode,
  locale,
  formatMessage,
  isManageableTableSelected,
  setBookingsData,
}: {
  styles: { [key: string]: string };
  bookingMode: BookingModeType;
  locale: Locales;
  formatMessage: IntlShape["formatMessage"];
  isManageableTableSelected: boolean | undefined;
  setBookingsData: React.Dispatch<React.SetStateAction<BookingsData>>;
}) => {
  const {
    isLoading,
    handleStatusFilterSelect,
    handleExtraStatusFilterSelect,
    statusFilter,
    extraStatusFilter,
    statusOptions,
    extraStatusOptions,
    searchQuery,
    setSearchQuery,
  } = useBookingFilters(
    bookingMode,
    locale,
    setBookingsData,
    isManageableTableSelected,
  );

  return (
    <div className={styles.filters}>
      <div className={styles.search}>
        <Input.Search
          className={styles.searchInput}
          isLoading={isLoading}
          onSearch={setSearchQuery}
        />
        <Link
          state={{
            searchQuery,
          }}
          to={"/search"}
        >
          <Button className={styles.searchButton} type="button" variant="thin">
            {formatMessage({ id: ETranslations.GLOBAL_SEARCH })}
          </Button>
        </Link>
      </div>
      <div className={styles.selectFilter}>
        <SelectCheckbox
          isClearable={false}
          options={extraStatusOptions}
          placeholder={formatMessage({
            id: ETranslations.STATES,
          })}
          styleObject={{
            menuList: { width: 290 },
            menuPortal: { width: 290 },
            valueContainer: { display: "inline", paddingRight: 0, width: 139 },
          }}
          titles={STATE_FILTERS_TITLES}
          value={extraStatusFilter}
          maxContentWidth
          onChange={handleExtraStatusFilterSelect}
        />
        <SelectCheckbox
          isClearable={false}
          options={statusOptions}
          placeholder={formatMessage({
            id: ETranslations.STATUSES,
          })}
          styleObject={{
            valueContainer: { display: "inline", paddingRight: 0 },
          }}
          titles={STATUS_FILTERS_TITLES}
          value={statusFilter}
          maxContentWidth
          onChange={handleStatusFilterSelect}
        />
      </div>
    </div>
  );
};
