import styles from "./Form.module.scss";
import DataField from "./Steps/sub-renders/DataField";
import InfinityField from "./Steps/sub-renders/InfinityField";
import { InputField } from "./Steps/sub-renders/InputField";
import SchemaField from "./Steps/sub-renders/SchemaField";
import ShiftsField from "./Steps/sub-renders/ShiftsField";
import TimeField from "./Steps/sub-renders/TimeField";
import { WeekDaysField } from "components/ManagerialTables/form/fields";
import { required } from "components/shifts/utils";
import React from "react";
import { Field } from "react-final-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Labeled } from "ui-kit";

const RuleForm = () => {
  const intl = useIntl();
  return (
    <div className={styles.currentRuleForm}>
      <Labeled
        as="div"
        className={styles.bookingLabel}
        label={intl.formatMessage({ id: ETranslations.NAME_GROUP_OF_RULES })}
        required
      >
        <Field
          component={InputField}
          name="groupName"
          placeholder="Бойцовский клуб"
          validate={required}
          disabled
        />
      </Labeled>
      <Labeled
        as="div"
        className={styles.bookingLabel}
        label={intl.formatMessage({ id: ETranslations.NAME_RULE })}
        required
      >
        <Field
          component={InputField}
          name="rule_name"
          placeholder="Бойцовский клуб"
          validate={required}
        />
      </Labeled>
      <div className={styles.dateInfo}>
        <Labeled
          as="div"
          className={styles.bookingLabel}
          label={intl.formatMessage({ id: ETranslations.START_DATE })}
          required
        >
          <Field
            component={DataField}
            name="start_date"
            placeholder="Бойцовский клуб"
            validate={required}
          />
        </Labeled>
        <Labeled
          as="div"
          className={styles.bookingLabel}
          label={intl.formatMessage({ id: ETranslations.END_DATE })}
          required
        >
          <Field
            component={DataField}
            name="end_date"
            placeholder="Бойцовский клуб"
            validate={(fieldValue, formValues) => {
              //@ts-ignore
              if (formValues?.infininte) {
                return undefined;
              } else {
                return required(fieldValue);
              }
            }}
          />
        </Labeled>
        <Field component={InfinityField} name={`infininte`} />
      </div>

      <Labeled
        as="div"
        className={styles.bookingLabel}
        label={intl.formatMessage({
          id: ETranslations.DAYS_TO_CONTINUE_THIS_SHIFT,
        })}
        required
      >
        <Field
          component={WeekDaysField}
          name={`repeat_days`}
          placeholder="Бойцовский клуб"
          validate={required}
        />
      </Labeled>

      <div className={styles.hours}>
        <Labeled
          as="div"
          className={styles.bookingLabel}
          label={intl.formatMessage({ id: ETranslations.START_TIME })}
        >
          <Field
            component={TimeField}
            name="start_active_time"
            placeholder="Бойцовский клуб"
            validate={required}
          />
        </Labeled>
        <Labeled
          as="div"
          className={styles.bookingLabel}
          label={intl.formatMessage({ id: ETranslations.END_TIME })}
        >
          <Field
            component={TimeField}
            name="end_active_time"
            placeholder="Бойцовский клуб"
            validate={required}
          />
        </Labeled>
      </div>
      <Labeled
        as="div"
        className={styles.bookingLabel}
        label={intl.formatMessage({ id: ETranslations.SHIFT })}
        required
      >
        <Field
          component={ShiftsField}
          name="shift_id"
          placeholder="Бойцовский клуб"
          validate={required}
          disabled
        />
      </Labeled>
      <div className={styles.schemaInfo}>
        <Labeled
          as="div"
          className={styles.bookingLabel}
          label={intl.formatMessage({ id: ETranslations.AVAILABLE_FOR_HALL })}
        >
          <Field
            component={SchemaField}
            name="places"
            placeholder="Бойцовский клуб"
            validate={required}
            disabled
          />
        </Labeled>
      </div>
    </div>
  );
};

export default RuleForm;
