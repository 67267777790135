import classNames from "classnames";
import { Check } from "ui-kit/ICONS/icons";

import styles from "./RadioTag.module.scss";

type Props<T = undefined> = {
  className?: string;
  name: T;
  color?: string;
  children: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (name: T, checked: boolean) => void;
};

export function RadioTag<T = undefined>({
  className,
  name,
  color,
  checked,
  children,
  disabled,
  onChange,
}: Props<T>) {
  return (
    <div
      className={classNames(styles.tag, className, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })}
      style={{ backgroundColor: color }}
      onClick={() => onChange?.(name, !checked)}
    >
      <div className={styles.label} title={children}>
        {children}
      </div>
      <div className={styles.radio}>
        {!checked && <span className={styles.circle}></span>}
        {checked && (
          <span className={styles.check}>
            <Check width={10} />
          </span>
        )}
      </div>
    </div>
  );
}
