import { State, accessRulesSliceName, initialState } from "./slice";
import { createSelector } from "@reduxjs/toolkit";

const selectorDomain = (state: any) =>
  state[accessRulesSliceName] || initialState;

export const appSelectedAccessRule = createSelector(
  selectorDomain,
  ({ selectedAccessRule }: State) => selectedAccessRule,
);
