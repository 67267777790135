import { useActiveShift } from "features/api/shifts";
import moment from "moment";
import { useFormState } from "react-final-form";

export function useFormActiveShift() {
  const {
    values: { time },
  } = useFormState({ subscription: { values: true } });
  return useActiveShift(moment(time?.value));
}
