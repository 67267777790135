import { createSelector } from "@reduxjs/toolkit";

import { State, clientListSliceName, initialState } from "./index";

const selectDomain = (state: any): State =>
  state[clientListSliceName] || initialState;

export const clientListFilter = createSelector(
  selectDomain,
  ({ filter }) => filter,
);
export const clientData = createSelector(
  selectDomain,
  ({ loadedClients }) => loadedClients,
);
export const isClientAttachMode = createSelector(
  selectDomain,
  ({ attachMode }) => attachMode !== "none",
);
export const selectedTags = createSelector(
  selectDomain,
  ({ filter }) => filter.tags,
);
export const selectedClient = createSelector(
  selectDomain,
  (state) => state?.selectedClient,
);
