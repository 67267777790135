import { config } from "config";
import { useTap } from "hooks/useTap";
import type { Slot } from "models/booking.model";
import moment from "moment";
import { MouseEvent, useCallback, useMemo } from "react";
import { useDrag } from "react-dnd";
import { formatPhone, isBookingOrder, isManagerialTable } from "utils";

import { EDraggableEntity } from "../../../constants";
import { useVisitContext } from "../VisitContext";

const useBookingCard = ({
  slot,
  onClick,
  canDrag,
}: {
  slot: Slot;
  onClick: any;
  canDrag: boolean;
}) => {
  const handleClick = useCallback(() => onClick?.(slot), [onClick, slot]);

  const { isExpired, isLate, statusClassName, statusClassNameManagement } =
    useVisitContext();

  const label = useMemo(
    () => `${isBookingOrder(slot) ? "order" : "booking"} ${slot.slot_id}`,
    [slot],
  );
  const phone = useMemo(
    () => formatPhone(slot.slot_type === "BOOKING" ? slot.client?.phone : null),
    [slot],
  );

  const isManagerialTableBoolean = useMemo(
    () => isManagerialTable(slot),
    [slot],
  );

  const { handleTouchStart, handleTouchEnd, longTap } = useTap(100);

  const handleContextMenuCapture = (e: MouseEvent) => {
    // Отменять контекстное меню необходимо для планшетов (и других touch устройств), чтобы long tap не отменял drag
    e.preventDefault();
    e.stopPropagation();
  };

  const [isDragging, drag, dragPreview] = useDrag(
    () => ({
      type: EDraggableEntity.BOOKING_CARD,
      item: slot,
      canDrag: config.dragAndDrop && canDrag && longTap,
      collect: (monitor) => monitor.isDragging(),
    }),
    [slot, longTap],
  );

  const isEndingSoon =
    moment(`${slot.date} ${slot.time}`, "YYYY-MM-DD HH:mm")
      .add(slot.visit_duration, "minutes")
      .diff(moment(), "minutes") <= 20;

  return {
    handleClick,
    isExpired,
    isLate,
    label,
    statusClassName,
    statusClassNameManagement,
    phone,
    isManagerialTableBoolean,
    handleTouchStart,
    handleTouchEnd,
    handleContextMenuCapture,
    isDragging,
    drag,
    dragPreview,
    isEndingSoon,
  };
};

export default useBookingCard;
