import { config } from "config";
import { SpecialStatusColors } from "constants/colors";
import { useAllStatuses } from "features/api/dictionaries-api";
import type { Slot } from "models/booking.model";
import type { Status } from "types/status";

import { getStatusColor } from "./utils";

export const useStatusColor = (
  slot: Slot | undefined,
  isActualTime: boolean,
  tableColor: string,
) => {
  const { statuses } = useAllStatuses();
  const baseStatusColors = statuses.reduce(
    (result, status) => (
      !status.is_extra && (result[status.system_name] = status.color), result
    ),
    SpecialStatusColors as Record<Status["system_name"], string> &
      typeof SpecialStatusColors,
  );
  config.grayConfirmedStatusColor &&
    (baseStatusColors.CONFIRMED = baseStatusColors.NEW);

  // FIXME: вопросы а манагер ли?
  return slot?.slot_type === "MANAGER"
    ? baseStatusColors.MANAGEMENT
    : slot?.slot_type === "BOOKING"
      ? getStatusColor(slot, baseStatusColors, isActualTime, tableColor)
      : baseStatusColors.EMPTY;
};
