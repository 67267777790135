import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import { useField } from "react-final-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";
import Chips from "ui-kit/Chips/Chips";

const TagsContainer = () => {
  const {
    input: { onChange: changeTags, value: tags },
  } = useField<string[]>("tags", { allowNull: true });

  const {
    input: { value: color },
  } = useField<string>("color", { allowNull: true });
  const intl = useIntl();
  const [hasError, setHasError] = useState<boolean>(false);
  const onToggleError = () => setHasError((state) => !state);

  return (
    <>
      {tags.map((each: string, index: number) => {
        const handleDeleteEachChips = (
          id: number,
          e: React.MouseEvent<SVGSVGElement>,
        ) => {
          e.preventDefault();
          e.stopPropagation();
          if (tags.length > 1) {
            const filteredTags = tags.filter((_, idx) => idx !== id);
            changeTags([...filteredTags]);
          } else {
            onToggleError();
          }
        };
        return (
          <Chips
            background={color}
            deleteCallBack={handleDeleteEachChips}
            index={index}
            key={uniqueId()}
            textColor="#313131"
            type="DELETABLE"
            value={each}
          />
        );
      })}

      {hasError && (
        <ModalPopup
          buttons={
            <>
              <Button type="submit" variant="secondary" onClick={onToggleError}>
                {intl.formatMessage({ id: ETranslations.BASE_CLOSE })}
              </Button>
            </>
          }
          editBooking={true}
          isOpen={hasError}
          needIcon={true}
          text={intl.formatMessage({ id: ETranslations.WARNING_TAG })}
          title={intl.formatMessage({ id: ETranslations.WARNING })}
          onClose={onToggleError}
        />
      )}
    </>
  );
};

export default TagsContainer;
