import styles from "./Input.module.scss";
import cn from "classnames";
import type { ReactNode } from "react";

interface InputAddonProps {
  children: ReactNode;
  className?: string;
}

export function InputAddon({ children, className }: InputAddonProps) {
  if (!children) return null;
  return <span className={cn(styles.addon, className)}>{children}</span>;
}
