import cn from "classnames";
import { transformCategoryName } from "common/helpers";
import { ConfirmBusyTable } from "components/ConfirmBusyTable";
import { Menu } from "components/Menu/Menu";
import { Popup } from "components/Popup";
import {
  useApplyExtraStatusMutation,
  useCleanExtraStatusMutation,
} from "features/api/bookings-api";
import type { SlotId } from "models/booking.model";
import type { ClientId } from "models/client.model";
import type { ReactNode } from "react";
import type { BaseStatus, ExtraStatus } from "types/status";
import { Button, ICONS, Tag } from "ui-kit";

import styles from "./BaseStatusTag.module.scss";
import { useStatusActions } from "./hooks/useStatusActions";

export const BaseStatusTag = ({
  statusName,
  statusColor,
  className,
  children,
}: {
  statusName?: string;
  statusColor?: string;
  className: string;
  children?: ReactNode;
}) => {
  return (
    <Tag
      className={className}
      style={{ backgroundColor: statusColor || "gray" }}
    >
      {statusName}
      {children}
    </Tag>
  );
};

export const StatusActions = ({
  statusClassName,
  bookingId,
  status,
  extraStatus,
  clientId,
}: {
  bookingId: SlotId;
  status: BaseStatus;
  extraStatus: ExtraStatus | undefined;
  statusClassName: string;
  clientId?: ClientId | null;
}) => {
  const {
    hide,
    popup,
    isOpen,
    setIsOpen,
    force,
    goNextStatus,
    resetForce,
    extraStatuses,
  } = useStatusActions(bookingId, status, clientId);
  const [applyExtraStatus, { isLoading: isApplying }] =
    useApplyExtraStatusMutation();
  const [cleanExtraStatus, { isLoading: isCleaning }] =
    useCleanExtraStatusMutation();
  const isLoading = isApplying || isCleaning;

  return status.category === "TERMINAL" ? (
    <BaseStatusTag
      statusName={status.name}
      statusColor={status.color}
      className={cn(statusClassName, { [styles.isOpen]: isOpen })}
    />
  ) : (
    <>
      <Popup
        placement="auto-start"
        ref={popup}
        onClose={hide}
        onOpen={setIsOpen}
      >
        <Popup.Trigger>
          <Button
            variant="phantom"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            className={styles.trigger}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
            }}
            onTouchMove={(e) => {
              e.stopPropagation();
            }}
            onTouchStartCapture={(e) => {
              e.stopPropagation();
            }}
          >
            <BaseStatusTag
              statusName={status.name}
              statusColor={status.color}
              className={cn(statusClassName, { [styles.isOpen]: isOpen })}
            >
              <ICONS.ArrowDown
                width={16}
                height={16}
                className={cn(styles.arrow, {
                  [styles.isOpen]: isOpen,
                })}
              />
            </BaseStatusTag>
          </Button>
        </Popup.Trigger>
        <Popup.Content noPadding>
          <Menu
            className={styles.menu}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            disableDnD
          >
            <h2 className={styles.category}>
              {transformCategoryName(status.category)}
            </h2>
            {extraStatuses?.map((s) => (
              <Menu.Item
                className={cn(styles.item, {
                  [styles.active]: extraStatus?.id === s.id,
                  [styles.disabled]: isLoading,
                })}
                key={s.id}
                onClick={() =>
                  (extraStatus?.id === s.id
                    ? cleanExtraStatus(bookingId)
                    : applyExtraStatus({
                        bookingId,
                        statusId: s.id,
                      })
                  ).then(popup.current?.close)
                }
              >
                <div
                  className={styles.circle}
                  style={{ backgroundColor: s.color }}
                />
                <span className={styles.statusName}>{s.name}</span>
              </Menu.Item>
            ))}
          </Menu>
        </Popup.Content>
      </Popup>
      <ConfirmBusyTable
        isOpen={force}
        confirm={goNextStatus}
        decline={resetForce}
      />
    </>
  );
};
