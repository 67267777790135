import cn from "classnames";
import { Children, Fragment, ReactElement, ReactNode, useMemo } from "react";

import { TabAddon, TabAddonProps } from "./TabAddon";
import { TabItem, TabItemProps } from "./TabItem";
import { TabTitle } from "./TabTitle";
import styles from "./Tabs.module.scss";

export interface TabsProps<T extends string> {
  activeTab: T;
  onChange(tab: T): void;
  children: ReactNode;
  className?: string;
}

function TabsContent<T extends string>({
  onChange,
  activeTab,
  children,
  className,
}: TabsProps<T>) {
  const tabs = useMemo(
    () =>
      (Children.toArray(children) as ReactElement<TabItemProps<T>>[]).reduce<
        ReactElement<TabItemProps<T>>[]
      >(
        (result, el) => (
          el.type === TabItem
            ? result.push(el)
            : el.type === Fragment &&
              Array.isArray(el.props.children) &&
              el.props.children.forEach(
                (child) => child.type === TabItem && result.push(child),
              ),
          result
        ),
        [],
      ),
    [children],
  );

  const activeTabContent = useMemo(
    () => tabs.find((t) => t.props.tabName === activeTab),
    [activeTab, tabs],
  );

  const addons = useMemo(
    () =>
      (Children.toArray(children) as ReactElement<TabAddonProps>[]).filter(
        (el) => el.type === TabAddon,
      ),
    [children],
  );

  return (
    <>
      <div className={cn(styles.tabs, className)}>
        <nav className={styles.tabsList}>
          <ul className={styles.tabsContainer}>
            {tabs.map(({props: {disabled, icon, tabName, title}}) => (
              <TabTitle
                disabled={disabled}
                icon={icon}
                isActive={activeTab === tabName}
                key={tabName}
                tabName={tabName}
                title={title}
                onClick={onChange}
              />
            ))}
          </ul>
          {addons}
        </nav>
      </div>
      {activeTabContent && <>{activeTabContent}</>}
    </>
  );
}

export const Tabs = Object.assign(TabsContent, {
  Item: TabItem,
  Addon: TabAddon,
});
