import styles from "../../Form.module.scss";
import Checkbox from "common/components/checkbox";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { FieldRenderProps, useField } from "react-final-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";

const InfinityField: React.FC<FieldRenderProps<string, any>> = ({
  input,
  meta,
  currentFieldName,
}) => {
  const intl = useIntl();
  const { value, onChange } = input;

  const {
    input: { value: endDateValue },
  } = useField<Array<string | Date>>(
    currentFieldName ? `${currentFieldName}end_date` : "end_date",
  );

  useEffect(() => {
    // @ts-ignore
    if (dayjs(endDateValue).valueOf() <= 2208974400000) {
      onChange(false);
    }
  }, [endDateValue]);

  return (
    <div style={{ marginTop: "18px", display: "flex", alignItems: "center" }}>
      <Checkbox
        className={styles.checkbox}
        indent="deposit"
        text={intl.formatMessage({ id: ETranslations.INFINITY })}
        value={!!value}
        onChange={onChange}
        {...meta}
      />
    </div>
  );
};

export default InfinityField;
