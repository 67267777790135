import { ETranslations } from "../../../types/translates";
import cn from "classnames";
import { ICONS } from "common/helpers";
import { RestaurantWithHostess } from "models/restaurant.model";
import { AppointUser } from "models/user.model";
import React from "react";
import { useIntl } from "react-intl";
import { Spinner } from "ui-kit";

type AppointHostessProps = {
  isLoading: boolean;
  restaurantList?: RestaurantWithHostess[];
  selectedSectionId?: number;
  onCallModalHostess: (hostess?: AppointUser, restId?: number) => void;
  onCallModalConfirm: (id: number, mode: string) => void;
  setRestaurant: (restId: number) => void;
};

export default function AppointHostess(props: AppointHostessProps) {
  const intl = useIntl();
  const {
    restaurantList,
    setRestaurant,
    isLoading,
    selectedSectionId,
    onCallModalConfirm,
    onCallModalHostess,
  } = props;

  const isActiveRest = (id: number) =>
    selectedSectionId === id || (restaurantList && restaurantList.length === 1);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          className={`appointHostess sections include-table ${
            restaurantList?.length === 1 ? "one-tab" : ""
          }`}
        >
          {restaurantList && restaurantList.length ? (
            restaurantList.map((rest) => (
              <div
                className={`rest count-${rest.hostessList.length} ${
                  isActiveRest(rest.id) ? "active" : ""
                }`}
                key={rest.id}
              >
                <div
                  className="rest-info blocks"
                  onClick={() => setRestaurant(rest.id)}
                >
                  <span>{rest.name}</span>
                  <div>
                    <div
                      className="clickable"
                      onClick={(e) => {
                        e.stopPropagation();
                        onCallModalHostess(undefined, rest.id);
                      }}
                    >
                      <p>
                        {intl.formatMessage({ id: ETranslations.HOSTESS_ADD })}
                      </p>
                    </div>
                    {restaurantList?.length !== 1 && (
                      <figure
                        className={cn("arrow", {
                          active: isActiveRest(rest.id),
                        })}
                      >
                        <ICONS.ArrowDown />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="table rows custom-scroll">
                  {rest.hostessList.length ? (
                    rest.hostessList.map((hostess) => (
                      <div className="table-row blocks" key={hostess.id}>
                        <span>{hostess.name}</span>
                        <div>
                          <div
                            className="edit"
                            onClick={(e) => {
                              e.stopPropagation();
                              onCallModalHostess(hostess, rest.id);
                            }}
                          />
                          <img
                            alt=""
                            src={ICONS.trashBin}
                            onClick={(e) => {
                              e.stopPropagation();
                              hostess.id &&
                                onCallModalConfirm(hostess.id, "HOSTESS");
                            }}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="message">
                      {intl.formatMessage({
                        id: ETranslations.HOSTESS_INFO_MISSING,
                      })}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="empty-list">
              {intl.formatMessage({ id: ETranslations.NOT_FOUND })}
            </div>
          )}
        </div>
      )}
    </>
  );
}
