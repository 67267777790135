import { BaseShiftsModal } from "../BaseShiftsModal";
import styles from "../style.module.scss";
import { Modal } from "components/modal";
import CurrentRuleForm from "components/shifts/Form/AccessRuleForm/CurrentRuleForm";
import React from "react";

interface AccessRulesModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  currentRule: any;
}

export const CurrentRuleModal = ({
  isOpen,
  onClose,
  title,
  currentRule,
}: AccessRulesModalProps) => {
  return (
    <BaseShiftsModal isOpen={isOpen} title={title} onClose={onClose}>
      <Modal.Content className={styles.modalContentAccess}>
        <CurrentRuleForm currentRule={currentRule} onClose={onClose} />
      </Modal.Content>
    </BaseShiftsModal>
  );
};
