import Modal from "components/LegacyModal/modal";
import { useAllStatuses } from "features/api/dictionaries-api";
import { useFetchAllRestaurants } from "features/api/restaurants";
import {
  useGetAllGroupsQuery,
  useGetPermissionsQuery,
} from "features/api/user-api";
import { ChangesParams, PositionModal } from "models/common";
import { Restaurant } from "models/restaurant.model";
import {
  SettingsHostess,
  SettingsStatus,
  SettingsUser,
} from "models/settings.model";
import React from "react";
import { RootState, TypeModal } from "types/rootState";
import { Spinner } from "ui-kit";

type ApplicationContextProps = {
  restaurant: Restaurant;
};

export const ModalContext = React.createContext<ModalProvider>(null!);

export class ModalProvider extends React.PureComponent<
  ApplicationContextProps,
  RootState
> {
  settingsParamsChanges: ChangesParams[] = [];
  settingsHostessModal: SettingsHostess = {
    hostess: undefined,
    restId: undefined,
  };

  settingsUserModal: SettingsUser = { user: undefined, role: undefined };
  confirmDialog: { data: any; mode: string } = { data: undefined, mode: "" };
  // todo в этом атрибуте хранится информация для модалки. Это не лучшее решение.
  paramsInfo: { text: string; icon: string } | undefined;
  settingsEditStatusModal: SettingsStatus | undefined = undefined;

  state: RootState = {
    settingsHostessModal: this.settingsHostessModal,
    settingsEditStatusModal: this.settingsEditStatusModal,
  };

  constructor(props: ApplicationContextProps) {
    super(props);
    this.setState = this.setState.bind(this);
  }

  setSettingsHostessModal = (settings: SettingsHostess) => {
    this.setState({ settingsHostessModal: settings });
  };

  setSettingsEditStatusModal = (settings: SettingsStatus) => {
    this.setState({ settingsEditStatusModal: settings });
  };

  setConfirmDialogData = (data: { data: any; mode: string }) => {
    this.confirmDialog = data;
  };

  // todo: что за условие не сильно понятно, но влияет на отображение модалок.
  // Чтобы ничего не сломать пришлось добавить новый тип модалки для ошибок
  isActiveModalNoPhone = () => {
    const { activeModal } = this.state;
    return (
      activeModal &&
      activeModal.type !== TypeModal.infoPhones &&
      activeModal.type !== TypeModal.infoCall
    );
  };

  activateModal = (type: TypeModal, position: PositionModal) => {
    this.setState((state) => ({
      ...state,
      activeModal:
        state.activeModal?.type === type ? undefined : { position, type },
    }));
  };

  closeModals = () => {
    const { activeModal } = this.state;

    this.settingsHostessModal = { hostess: undefined, restId: undefined };
    this.confirmDialog = { data: undefined, mode: "" };
    if (!activeModal) {
      return;
    }
    this.setState({ activeModal: undefined });
  };

  render() {
    return (
      <ModalContext.Provider value={this}>
        {this.props.children}
        {this.isActiveModalNoPhone() && (
          <React.Suspense fallback={<Spinner />}>
            <Modal
              active={this.isActiveModalNoPhone()}
              activeTypeModal={this.state.activeModal?.type}
              closeModals={this.closeModals}
              confirmDialog={this.confirmDialog}
              settingsEditStatusModal={this.state.settingsEditStatusModal}
              settingsHostessModal={this.state.settingsHostessModal}
              settingsParamsChanges={this.settingsParamsChanges}
              settingsUserModal={this.settingsUserModal}
            />
          </React.Suspense>
        )}
      </ModalContext.Provider>
    );
  }
}

const ApplicationContextWrapper: React.FC<
  Omit<ApplicationContextProps, "restaurant"> & any
> = ({ children, ...rest }) => {
  const { isSuccess: restFulfilled, restaurant } = useFetchAllRestaurants();
  const { isSuccess: statusesFulfilled } = useAllStatuses();
  const { isSuccess: groupsFulfilled } = useGetAllGroupsQuery();
  const { isSuccess: permissionsFulfilled } = useGetPermissionsQuery();

  const allQuery: boolean[] = [
    restFulfilled,
    statusesFulfilled,
    permissionsFulfilled,
  ];
  const isAllFetched = allQuery.every(Boolean);

  return isAllFetched ? (
    <ModalProvider {...rest} restaurant={restaurant}>
      {children}
    </ModalProvider>
  ) : (
    <Spinner />
  );
};

export default ApplicationContextWrapper;
