export const groupFilterColors = {
  multiValue: (s: any, { data }: any) => ({
    ...s,
    borderRadius: "20px",
    padding: "0 10px",
    height: "20px",
    alignItems: "center",
    backgroundColor: data.color,
  }),
  multiValueLabel: () => ({
    padding: "0",
    font: "var(--font-12-b)",
    color: "var(--gl_text_constant_primary2)",
  }),
  multiValueRemove: (s: any) => ({
    ...s,
    cursor: "pointer",
    color: "var(--gl_text_constant_primary2)",
    ":hover": {
      color: "var(--gl_text_content_primary)",
    },
  }),
};
