import { ETranslations } from "../../../../types/translates";
import { SearchContext, SearchType } from "types/globalSearch";

export type SearchTypeOption = { label: string; value: SearchType };

export const options: Array<SearchTypeOption> = [
  {
    label: ETranslations.SEARCH_HEADER_OPTION_NAME_PHONE,
    value: SearchContext.CLIENT,
  },
  {
    label: ETranslations.SEARCH_HEADER_OPTION_GUEST_TAG,
    value: SearchContext.CLIENT_TAG,
  },
  {
    label: ETranslations.SEARCH_HEADER_OPTION_GUEST_EMAIL,
    value: SearchContext.CLIENT_EMAIL,
  },
  {
    label: ETranslations.SEARCH_HEADER_OPTION_BOOKING_COMMENT,
    value: SearchContext.COMMENT,
  },
  {
    label: ETranslations.SEARCH_HEADER_OPTION_BOOKING_TAG,
    value: SearchContext.TAGS,
  },
  {
    label: ETranslations.SEARCH_HEADER_OPTION_BOOKING_SOURCE,
    value: SearchContext.SOURCE,
  },
  {
    label: ETranslations.BOOKED_BY,
    value: SearchContext.USER,
  },
];
