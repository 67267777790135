import { userApi } from "features/api/user-api";
import type { Response as TResponse } from "models/common";
import { AppointUser, type GrantedUser, User } from "models/user.model";
import { store } from "storage";

import { apiINT } from "./api-data";
import { Notification } from "./notification";
import { RequestWrapper } from "./request-wrapper";

export class UsersService {
  static async getHostess(idRest?: number): Promise<TResponse<GrantedUser[]>> {
    return RequestWrapper.fetch(
      `${apiINT}/users?type=HOSTESS${
        idRest ? `&restaurant_ids=${idRest}&auth=false` : ""
      }`,
    ).then((response) => response.json());
  }

  static async getById(id: number): Promise<TResponse<GrantedUser>> {
    return RequestWrapper.fetch(`${apiINT}/users/${id}`).then((response) =>
      response.json(),
    );
  }

  static async getAllRests(restIds: string): Promise<TResponse<AppointUser[]>> {
    return RequestWrapper.fetch(
      `${apiINT}/users?type=HOSTESS&restaurant_ids=${restIds}`,
    ).then((response) => response.json());
  }

  static async change(user: AppointUser): Promise<TResponse<any>> {
    return RequestWrapper.fetch(`${apiINT}/user`, {
      method: "PUT",
      body: JSON.stringify(user),
    }).then((response) => {
      store.dispatch(userApi.util.invalidateTags(["Users"]));
      return response.json();
    });
  }

  /**
   * @deprecated нужно переписать на RTK-Query
   */
  static async create(user: AppointUser): Promise<TResponse<any>> {
    return RequestWrapper.fetch(`${apiINT}/user`, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((response) => {
        store.dispatch(userApi.util.invalidateTags(["Users"]));
        return response.json();
      })
      .catch((e: unknown) => {
        if (e instanceof Response) {
          e.json().then((error) =>
            Notification.error({
              title: error.errorMessage,
            }),
          );
        }
      });
  }
}
