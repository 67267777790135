import styles from "./Modal.module.scss";
import cn from "classnames";
import { ReactNode } from "react";

interface ModalFooterProps {
  children: ReactNode;
  className?: string;
}

export function ModalFooter({ children, className }: ModalFooterProps) {
  return <div className={cn(styles.modalFooter, className)}>{children}</div>;
}
