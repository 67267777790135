import { ReactNode } from "react";

import { ColumnBar } from "..";
import styles from "./Header.module.scss";

export function Header({ children }: { children: ReactNode }) {
  return (
    <header className={styles.header}>
      <ColumnBar className={styles.headerContent}>{children}</ColumnBar>
    </header>
  );
}
