import cn from "classnames";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useLoaderData, useRouteError } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, Collapses, ICONS, IconWithCaption } from "ui-kit";

import { loader } from "../api/loader";
import { RoleModal, type UserModalProps } from "./RoleModal";
import styles from "./Roles.module.scss";
import { RolesHeader } from "./RolesHeader";

const RoleCard = ({
  userName,
  restaurantNames,
  phone,
  onEdit,
}: {
  userName: string;
  restaurantNames: string;
  phone?: string;
  onEdit: () => void;
}) => {
  const [isRestaurantsExpanded, setIsRestaurantsExpanded] = useState(false);
  return (
    <article
      className={cn(
        styles.roleCard,
        isRestaurantsExpanded && styles.expandedRestaurants,
      )}
    >
      <p className={styles.userName}>{userName}</p>
      <button
        className={styles.restaurantsButton}
        onClick={() => setIsRestaurantsExpanded(true)}
      >
        <IconWithCaption
          caption={restaurantNames}
          title={isRestaurantsExpanded ? undefined : restaurantNames}
          captionClassName={cn(
            styles.restaurants,
            isRestaurantsExpanded && styles.expandedRestaurants,
          )}
        >
          <ICONS.Restaurant />
        </IconWithCaption>
      </button>
      <IconWithCaption caption={phone}>
        <ICONS.USER_PHONE />
      </IconWithCaption>
      <aside>
        <Button variant="phantom" className={styles.edit} onClick={onEdit}>
          <ICONS.Edit />
        </Button>
        <Button variant="phantom" className={styles.delete}>
          <ICONS.TrashBin />
        </Button>
      </aside>
    </article>
  );
};

const NoRoles = ({ message }: { message?: string }) => {
  const { formatMessage } = useIntl();
  return (
    <p className={styles.noRoles}>
      <strong>
        {message || formatMessage({ id: ETranslations.NOT_FOUND })}
      </strong>
    </p>
  );
};

export const Roles = () => {
  const options = useLoaderData<typeof loader>();
  const [userModalProps, setUserModalProps] = useState<UserModalProps | null>(
    null,
  );
  return (
    <>
      <RolesHeader
        onAddUser={(fullName) =>
          setUserModalProps({ intent: "CREATE", fullName })
        }
      />
      {options.length ? (
        <Collapses.Root isExpandedAll={Boolean(false)} options={options}>
          {(user) => (
            <Collapses.Item key={user.userId}>
              <RoleCard
                userName={user.userName}
                restaurantNames={user.restaurantNames}
                phone={user.phone}
                onEdit={() =>
                  setUserModalProps({ intent: "EDIT", userId: user.userId })
                }
              />
            </Collapses.Item>
          )}
        </Collapses.Root>
      ) : (
        <NoRoles />
      )}
      {userModalProps && (
        <RoleModal close={() => setUserModalProps(null)} {...userModalProps} />
      )}
    </>
  );
};

export const RolesErrorBoundary = () => {
  const routeError = useRouteError();

  const errorMessage =
    routeError instanceof Error
      ? routeError.message
      : JSON.stringify(routeError);
  const { formatMessage } = useIntl();
  return (
    <>
      <RolesHeader />
      <NoRoles
        message={formatMessage(
          { id: ETranslations.ERROR_ENTITY },
          {
            entity: formatMessage({
              id: ETranslations.USERS_ALT,
            }).toLowerCase(),
            error:
              errorMessage in ETranslations
                ? formatMessage({ id: errorMessage })
                : errorMessage,
          },
        )}
      />
    </>
  );
};
