import { Option } from "../../../../../../models/common";
import dayjs from "dayjs";
import { timelineSelectors } from "features/Timeline";
import { useReturnAllSlots } from "features/api/shifts";
import React, { useEffect } from "react";
import { FieldRenderProps, useField } from "react-final-form";
import { useSelector } from "react-redux";
import { SelectBasic } from "ui-kit";

const TimeField: React.FC<FieldRenderProps<string, any>> = ({
  meta,
  input,
  ...rest
}) => {
  const { options } = useReturnAllSlots();
  const timestamp = useSelector(timelineSelectors.getTimestamp);
  const {
    input: { value },
  } = useField<Option<string> | null>(input.name, { allowNull: true });

  useEffect(() => {
    if (!value?.value) {
      const nextValue = options.find((each) =>
        dayjs(each.value).isSameOrAfter(timestamp),
      );
      input.onChange(nextValue || null);
    } else {
      return;
    }
  }, [options]);

  return (
    <SelectBasic
      options={options}
      value={value}
      changedMaxSize
      onChange={input.onChange}
      {...rest}
      {...meta}
    />
  );
};

export default TimeField;
