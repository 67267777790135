import { FormBooking } from "features/BookingFormProxy/types";
import { useCreateBookingMutation } from "features/api/bookings-api";
import {
  useCreateClientMutation,
  useLazyFetchClientQuery,
} from "features/api/client-api";
import { invalidateHallSlots } from "features/api/hallschema-api";
import { useCreateManagerialBookingMutation } from "features/api/managerialTables";
import { useBooleanState } from "hooks/useBooleanState";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Notification } from "services/notification";

import { useIntlUtils } from "../../../../../hooks/useIntlUtils";
import { ETranslations } from "../../../../../types/translates";

export function useCreateWithOverbooking() {
  const { getIntlActionFailed, getIntlJoinedParts } = useIntlUtils();
  const [createBooking] = useCreateBookingMutation();
  const [createManagerialBooking] = useCreateManagerialBookingMutation();
  const [hasManagerialError, setHasManagerialError] = useState<boolean>(false);
  const [createGuest] = useCreateClientMutation();
  const [overbookingData, setOverbookingData] = useState<
    FormBooking | undefined
  >();
  const dispatch = useDispatch();
  const [getClient] = useLazyFetchClientQuery();
  const [isCreating, start, stop] = useBooleanState();

  const closeModal = () => setHasManagerialError(false);
  const create = useCallback(
    async (formData) => {
      start();
      const data = formData;

      try {
        if (
          !data?.client?.client_id &&
          data?.client?.contact_type !== "CONTACT"
        ) {
          await createGuest({ ...data.client, phone: data.phone }).then(
            async (res) => {
              //@ts-ignore
              await getClient(res?.data?.data.client_id).then((response) => {
                //@ts-ignore
                data.client_id = res?.data?.data.client_id;
                data.client = response.data;
              });
            },
          );
        }
        return await (
          data.seatType === "MANAGER_BOOK"
            ? createManagerialBooking({ data })
            : createBooking({ data })
        ).unwrap();
      } catch (e) {
        // @ts-ignore
        if (e?.data?.errorCode === 10400) {
          setHasManagerialError(true);
        }
        // @ts-ignore
        if (e?.data?.errorCode === 10100) {
          setOverbookingData(data);
        }
        throw e;
      } finally {
        stop();
      }
    },
    [createBooking, setOverbookingData],
  );

  const clearData = useCallback(
    () => setOverbookingData(undefined),
    [setOverbookingData],
  );

  const createWithOverbooking = useCallback(async () => {
    if (!overbookingData) return undefined;
    start();
    try {
      const result = await createBooking({
        data: overbookingData,
        isOverbooking: true,
      }).unwrap();
      setOverbookingData(undefined);
      dispatch(invalidateHallSlots());
      return result;
    } catch (e) {
      Notification.error({
        title: getIntlActionFailed(
          getIntlJoinedParts([
            ETranslations.BASE_CREATE,
            ETranslations.PLURAL_BOOKING,
          ]).toLowerCase(),
        ),
      });
      throw e;
    } finally {
      stop();
    }
  }, [overbookingData, createBooking]);

  return {
    create,
    createWithOverbooking,
    isConfirmationRequired: !!overbookingData,
    clearData,
    isCreating,
    hasManagerialError,
    closeModal,
  };
}
