import WeekDays from "../../../shifts/Form/WeekDays/WeekDays";
import { FC } from "react";
import { FieldRenderProps } from "react-final-form";
import { EDaysOfWeek } from "types/commons";

export const WeekDaysField: FC<FieldRenderProps<EDaysOfWeek[]>> = ({
  input,
  meta,
  ...rest
}) => {
  const { value, onChange, ...restInputProps } = input;

  return (
    <WeekDays
      weekDays={value}
      onChange={onChange}
      {...restInputProps}
      {...meta}
      {...rest}
    />
  );
};
