import { TableColor, TableTimeWord } from "components/hall-scheme/hall-helpers";

export enum DepositStatus {
  none = "none",
  made_not_paid = "made_not_paid",
  made_paid = "made_paid",
}

export enum HallTableStatus {
  booked_not_conf = "booked_not_conf",
  booked = "booked",
  in_hall = "in_hall",
  delay_start = "delay_start",
  delay_end = "delay_end",
  busy = "busy",
  managerial = "managerial",
}

export class HallTableModel {
  constructor(
    public tableId: number,
    public tableNumber: number,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public shape: "round" | "square" = "square",
    public type: number,
    public isVIP: boolean,
    public hasComment: boolean,
    public depositStatus: DepositStatus,
  ) {}
}

export class HallTableBookingInfo {
  constructor(
    public beforeStart: number,
    public beforeEnd: number,
    public status: HallTableStatus,
    public dayStartBeforeStart: number,
    public id?: number,
    public bookingStartTime?: string,
  ) {}
}

export class HallTableView {
  constructor(
    public tableColor: TableColor,
    public timeWord: TableTimeWord,
    public timeString: string,
    public stripPercent: number,
    public hasComment?: boolean,
    public isVIP?: boolean,
  ) {}
}

export interface SelectedTable {
  tableNumber: number;
  tableId: number;
  persons: number;
}
