import cn from "classnames";
import { InfiniteList } from "components/InfiniteList";
import {
  useFetchOrganizationPageQuery,
  useLazyFetchOrganizationPageQuery,
} from "features/api/source-api";
import { OrganizationId } from "models/source.model";
import { type RefObject, useState } from "react";
import { useIntl } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
import { ETranslations } from "types/translates";
import { Input, Spinner } from "ui-kit";
import { ArrowDown } from "ui-kit/ICONS/icons";

import { DataListItem } from "./DataList";
import styles from "./SourceInput.module.scss";

export const OrganizationInput = ({
  label,
  organizationName,
  organizationId,
  error,
  onOrganizationNameChange,
  onOrganizationIdChange,
}: {
  label?: string;
  organizationName?: string;
  organizationId?: OrganizationId;
  error?: string | string[];
  onOrganizationNameChange?: (name: string | undefined) => void;
  onOrganizationIdChange?: (id: OrganizationId | undefined) => void;
}) => {
  const { formatMessage } = useIntl();
  const defaultState = {
    searchName: organizationName || "",
    showOptions: false,
  };
  const [datalistState, setDatalistState] = useState(defaultState);
  const [fetch] = useLazyFetchOrganizationPageQuery();
  const { data, isLoading, fulfilledTimeStamp } = useFetchOrganizationPageQuery(
    {
      searchName: datalistState.searchName,
    },
  );

  const handleCloseDetails = () => {
    setDatalistState((prev) => ({
      ...prev,
      showOptions: false,
    }));
    if (organizationName !== datalistState.searchName) {
      onOrganizationIdChange?.(undefined);
      onOrganizationNameChange?.(datalistState.searchName || undefined);
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        datalistState.showOptions && handleCloseDetails();
      }}
    >
      <Input
        label={label}
        placeholder={formatMessage({ id: ETranslations.WITHOUT_ORGANIZATION })}
        autoComplete="off"
        required
        className={styles.datalist}
        value={datalistState.searchName}
        onClick={() =>
          setDatalistState((prev) => ({
            ...prev,
            showOptions: !prev.showOptions,
          }))
        }
        onChange={({ currentTarget: { value } }) => {
          fetch({ searchName: value });
          setDatalistState((prev) => ({
            ...prev,
            searchName: value,
            showOptions: true,
          }));
        }}
        onKeyDown={(e) => {
          e.key === "Enter" && handleCloseDetails();
          e.key === "Escape" && setDatalistState(defaultState);
        }}
        suffix={
          <ArrowDown
            className={cn(
              styles.arrow,
              datalistState.showOptions && styles.open,
            )}
          />
        }
        datalist={
          isLoading ? (
            <Spinner />
          ) : (
            Boolean(data?.content.length) &&
            datalistState.showOptions && (
              <datalist
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const element = e.target;

                  if (element instanceof HTMLOptionElement) {
                    setDatalistState({
                      searchName: element.label,
                      showOptions: false,
                    });
                    onOrganizationIdChange?.(
                      Number(element.value) as OrganizationId,
                    );
                    onOrganizationNameChange?.(element.label);
                  }
                }}
              >
                <InfiniteList
                  key={fulfilledTimeStamp}
                  initialItems={data!.content}
                  fetchNextPage={(page) =>
                    fetch({ searchName: datalistState.searchName, page })
                  }
                >
                  {(organization, ref) => (
                    <DataListItem
                      value={organization.id}
                      label={organization.name}
                      selected={organization.id === organizationId}
                      ref={ref as RefObject<HTMLOptionElement> | undefined}
                    />
                  )}
                </InfiniteList>
              </datalist>
            )
          )
        }
        error={error}
      />
    </OutsideClickHandler>
  );
};
