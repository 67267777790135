export const DIALOG_FILTERS_LOCALSTORAGE_KEY = "dialog_filters";

export type DialogTab = "CHAT" | "FEEDS";
export type ReservationTab = "EDIT" | "HISTORY";

export type Tab = DialogTab | ReservationTab;

type Tabs<T extends string> = {
  [key in Uppercase<T> | (string & {})]: key extends T ? key : unknown;
};

export const TAB_KEY = "tab";

export const DIALOG_TABS: Tabs<DialogTab> = {
  CHAT: "CHAT",
  FEEDS: "FEEDS",
};

export const RESERVATION_DETAILS_TABS: Tabs<ReservationTab> = {
  EDIT: "EDIT",
  HISTORY: "HISTORY",
};

export const ALL_TABS: Tabs<DialogTab | ReservationTab> = {
  CHAT: "CHAT",
  FEEDS: "FEEDS",
  EDIT: "EDIT",
  HISTORY: "HISTORY",
};

export const isDialogTab = (currentTab: Tab) =>
  Boolean(DIALOG_TABS[currentTab]);

export const getActiveTab = (currentTab: string | null) =>
  (ALL_TABS[currentTab || String(currentTab)] || "CHAT") as Tab;

export const getActiveReservationTab = (currentTab: string | null) =>
  (RESERVATION_DETAILS_TABS[currentTab || String(currentTab)] ||
    "EDIT") as ReservationTab;
