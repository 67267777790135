import type { CSSObject } from "@emotion/react";
import React, { FC } from "react";
import { StylesConfig } from "react-select";

import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import { SelectBasic, SelectBasicProps } from "./SelectBasic";

type OptionTagType = {
  color: string;
  label: string;
  value: number;
};

const customStyles: StylesConfig<OptionTagType, true> = {
  multiValue: (base, { data }) =>
    ({
      ...base,
      borderRadius: "20px",
      height: "20px",
      color: "var(--gl_text_content_primary)",
      alignItems: "center",
      marginBottom: "3px",
      backgroundColor: data.color || "var(--tags_constant_background_-5)",
      paddingLeft: 10,
      paddingRight: 4,
      font: "var(--font-12-r)",
    }) as CSSObject,
  multiValueLabel: () => ({
    padding: "0",
    color: "var(--gl_text_content_primary)",
  }),
  multiValueRemove: (base) =>
    ({
      ...base,
      ":hover": { backgroundColor: "none", cursor: "pointer" },
    }) as CSSObject,
};

export const SelectTag: FC<SelectBasicProps<OptionTagType>> = (props) => {
  const { getIntlChooseEntity } = useIntlUtils();
  const handleExample = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <SelectBasic
      closeMenuOnSelect={false}
      isSearchable={false}
      placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
      //@ts-ignore
      styles={customStyles}
      isClearable
      isMulti
      onKeyDown={handleExample}
      {...props}
    />
  );
};
