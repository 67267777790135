import cn from "classnames";
import { useColorMode } from "hooks/useColorMode";

import { Button, ICONS } from "..";
import styles from "./ModeSwitch.module.scss";

export function ModeSwitch() {
  const { setLight, setDark, mode } = useColorMode();

  return (
    <Button
      variant="phantom"
      className={cn(styles.button, mode === "dark" && styles.dark)}
      onClick={mode === "light" ? setDark : setLight}
    >
      <ICONS.ThemeSwitcher />
    </Button>
  );
}
