import { config } from "config";
import type { Client } from "models/client.model";
import { useMemo } from "react";
import { Notification } from "services/notification";
import type {
  BaseStatus,
  EditStatusRequest,
  Status,
  StatusId,
} from "types/status";

import { IResponse } from "../../models/common";
import { type ErrorResponse, Response } from "../../types/commons";
import { ETranslations } from "../../types/translates";
import { api, relativePathApi } from "./api";

type Statuses = { statuses: Status[]; statusById: Record<StatusId, Status> };

export const dictionariesApi = api
  .enhanceEndpoints({ addTagTypes: ["Dicts"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAllStatus: build.query<Statuses, void>({
        query: () => ({
          url: "v2/status",
        }),
        providesTags: ["Dicts", "Statuses"],
        transformResponse: (response: Response<Status[]>) =>
          response.data.reduce<Statuses>(
            (result, status) => {
              result.statusById[status.id] = status;
              return result;
            },
            {
              statuses: response.data,
              statusById: {},
            },
          ),
        onQueryStarted(args, { queryFulfilled }) {
          queryFulfilled.catch(() => []);
        },
      }),
      editStatus: build.mutation<Response<Status>, EditStatusRequest>({
        query: (status) => ({
          url: `v2/status/${status.status_id}`,
          method: "POST",
          body: status,
        }),
        invalidatesTags: ["Statuses", "Bookings"],
        onQueryStarted(args, { queryFulfilled }) {
          queryFulfilled.catch((e) => {
            const error = (e as unknown as ErrorResponse)?.error;
            return error?.data?.errorCode === 10000
              ? Notification.error({
                  title: error?.data?.errorMessage,
                })
              : Notification.error({
                  title: ETranslations.ERROR_UNABLE_TO_EDIT_STATUS,
                  message: error?.message,
                });
          });
        },
      }),
      //FIXME: убрать, из конфига тоже?
      fetchDefaultUser: build.query<Client, any>({
        query: () => ({
          url: `v2/client/${config.autoGuestId}`,
        }),
        providesTags: ["Dicts"],
        transformResponse: (response: IResponse<Client>) => response.data,
      }),
    }),
  });

export const localResourcesApi = relativePathApi.injectEndpoints({
  endpoints: (build) => ({
    fetchTranslates: build.query<Record<ETranslations, string>, string>({
      query: (locale) => ({
        url: `/lang/${locale}.json`,
      }),
    }),
  }),
});

export const {
  useFetchDefaultUserQuery,
  useFetchAllStatusQuery,
  useEditStatusMutation,
} = dictionariesApi;

export const { useFetchTranslatesQuery } = localResourcesApi;

const EMPTY: Status[] = [];

export function useAllStatuses() {
  const { data, ...rest } = useFetchAllStatusQuery();
  return {
    ...rest,
    statuses: data?.statuses || EMPTY,
    statusById: data?.statusById || undefined,
  };
}

export function useBaseStatuses() {
  const { data, ...rest } = useFetchAllStatusQuery();
  const baseStatuses = (
    data ? data.statuses.filter((status) => !status.is_extra) : EMPTY
  ) as BaseStatus[];
  return { ...rest, data: baseStatuses };
}

export function useActiveStatuses() {
  const { data: allStatuses, ...rest } = useFetchAllStatusQuery();
  const active = useMemo(
    () => allStatuses?.statuses.filter((s) => !s.is_terminal) ?? EMPTY,
    [allStatuses],
  );
  return { ...rest, data: active };
}

export function useCompleteStatuses() {
  const { data: allStatuses, ...rest } = useFetchAllStatusQuery();
  const complete = useMemo(
    () => allStatuses?.statuses.filter((s) => s.is_terminal) ?? EMPTY,
    [allStatuses],
  );
  return { ...rest, data: complete };
}
export const getActiveStatuses = (statuses: Status[] | undefined) =>
  statuses?.filter((status) => !status.is_terminal) || [];

export const getCompletedStatuses = (statuses: Status[] | undefined) =>
  statuses?.filter((status) => status.is_terminal) || [];
