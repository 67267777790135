/* eslint-disable react/jsx-sort-props */
import {
  CHAT_TRANSLATIONS_EN,
  CHAT_TRANSLATIONS_RU,
  MessageList,
  MessageSender,
  useChatController,
} from "@frontend/uikit";
import cn from "classnames";
import {
  DialogChannels,
  DialogMessageType,
  DialogsMessage,
  SendMessagePayload,
  useCustomerAllowedMessengers,
  useDialog,
  useDialogAllowedMessengers,
  useDialogMessages,
  useMarkMessagesAsRead,
  useMarkTaskMessagesAsRead,
  useSendMessage,
  useSendTaskMessage,
  useTaskMessages,
} from "features/Dialogs/SDK";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";
import { Spinner } from "ui-kit";

import styles from "./Messages.module.scss";

// TODO: указать реальный как только понадобится поддержка preview
const PREVIEW_MODAL_DOM_ID = "modal";

type DialogProps = {
  dialogId: string;
};

export function DialogMessages({ dialogId }: DialogProps) {
  const {
    dialog,
    loading: loadingDialog,
    error: dialogError,
  } = useDialog(dialogId);

  const {
    messages,
    loading: messagesLoading,
    error: messagesError,
  } = useDialogMessages(dialogId);
  const {
    allowedMessengers: allowed,
    loading: allowedLoading,
    error: allowedError,
  } = useDialogAllowedMessengers(dialogId);
  const { markAsRead } = useMarkMessagesAsRead(dialogId);
  const { send } = useSendMessage(dialogId);
  const { formatMessage } = useIntl();

  const loading = loadingDialog || messagesLoading || allowedLoading;
  const error = dialogError || messagesError || allowedError;

  if (loading) return <Spinner />;
  if (error)
    return (
      <p className={styles.error}>
        {formatMessage({ id: ETranslations.ERROR_DIALOG_MESSAGES }, { error })}
      </p>
    );

  return (
    <Chat
      key={dialogId}
      chatId={dialogId}
      readonly={!dialog || Boolean(dialog.closed_at)}
      messengers={allowed || []}
      messages={messages || []}
      onMarkSeen={markAsRead}
      onSend={send}
    />
  );
}

type TaskProps = { taskId: string; customerId: string; className?: string };

export function TaskMessages({ taskId, customerId, className }: TaskProps) {
  const {
    messages,
    loading: messagesLoading,
    error: messagesError,
  } = useTaskMessages(taskId);
  const {
    allowedMessengers: allowed,
    loading: allowedLoading,
    error: allowedError,
  } = useCustomerAllowedMessengers(customerId);
  const { markAsRead } = useMarkTaskMessagesAsRead(taskId);
  const { send } = useSendTaskMessage(taskId);

  const { formatMessage } = useIntl();

  const loading = messagesLoading || allowedLoading;
  const error = messagesError || allowedError;

  if (loading) return <Spinner />;
  if (error || !messages)
    return (
      <p className={styles.error}>
        {formatMessage(
          { id: ETranslations.ERROR_DIALOG_MESSAGES },
          { error: error || "" },
        )}
      </p>
    );

  if (!messages.length)
    return (
      <p className={styles.error}>
        {formatMessage({ id: ETranslations.EMPTY_CHAT })}
      </p>
    );

  return (
    <Chat
      className={className}
      key={taskId}
      chatId={taskId}
      messengers={allowed || []}
      messages={messages || []}
      onMarkSeen={markAsRead}
      onSend={send}
      // см. задачу HOS-1819
      readonly={true /* messages && messages.length ? false : true */}
    />
  );
}

type ChatProps = {
  className?: string;
  chatId: string;
  messages: DialogsMessage[];
  messengers: DialogChannels[];
  readonly?: boolean;
  onMarkSeen: (ids: string[]) => Promise<void>;
  onSend: (payload: SendMessagePayload) => Promise<void>;
};

function Chat({
  className,
  chatId,
  messages,
  messengers,
  readonly,
  onMarkSeen,
  onSend,
}: ChatProps) {
  // --- Translations

  const { formatMessage } = useIntl();

  const { locale } = useIntl();
  const translations =
    locale === "ru" ? CHAT_TRANSLATIONS_RU : CHAT_TRANSLATIONS_EN;

  // --- Chat Controller

  const {
    // --- Message
    message,
    handleMessage,
    // --- Subject
    subject,
    handleSubject,
    // --- Channel
    channel,
    /*
      handleChannel,
    */
    // --- Phones
    /*
      phones,
      handlePhones,
    */
    // --- Email
    email,
    handleEmail,
    // --- Reply and Quote
    handleReply,
    quote,
    handleQuoteRemove,
    // --- Uploads
    /*
      uploads,
      handleUploads,
    */
    // --- Templates
    /*
      templateId,
      handleTemplate,
    */
    // --- Submit
    submitting,
    handleSubmit,
  } = useChatController({
    chatId,
    messages,
    useSubjectFromQuote: true,
    draftStorageKey: "dialogs/draft",
    onSend: useCallback((payload) => {
      const data: SendMessagePayload = {
        text: payload.message,
        reply_to: payload.quote?.guid,
        type:
          payload.messenger.channel === DialogChannels.EMAIL
            ? DialogMessageType.EMAIL
            : DialogMessageType.TEXT,
        subject:
          payload.messenger.channel === DialogChannels.EMAIL
            ? payload.messenger.subject
            : undefined,
        to_email:
          payload.messenger.channel === DialogChannels.EMAIL
            ? payload.messenger.email
            : undefined,
        resend_to_phones:
          payload.messenger.channel === DialogChannels.SMS_MSG
            ? payload.messenger.phones
            : undefined,
        resend_to:
          payload.messenger.channel !== DialogChannels.CHAT &&
          payload.messenger.channel !== DialogChannels.EMAIL
            ? [payload.messenger.channel as DialogChannels]
            : undefined,
      };

      const promise = onSend(data);

      promise.catch(() => {
        Notification.error(
          formatMessage({
            id: ETranslations.ERROR_DIALOGS_SEND,
          }),
        );
      });

      return promise;
    }, []),
  });

  // --- Render

  return (
    <section className={cn(styles.root, styles.theme, className)}>
      <MessageList
        hideSubjectInReply
        //-
        messages={messages}
        //-
        enableReadTracking
        isMessagesLoading={false}
        hideCustomerName
        //-
        onMarkAsSeen={onMarkSeen}
        onReply={handleReply}
        onTaskLinkClick={undefined}
        onUrlSign={undefined}
        //-
        translations={translations}
        previewModalId={PREVIEW_MODAL_DOM_ID}
      />
      {!readonly && (
        <MessageSender
          chatId={chatId}
          //-
          message={message}
          onMessageChange={handleMessage}
          //-
          subject={subject}
          onSubjectChange={handleSubject}
          hideSubjectInReply
          //-
          channel={channel}
          channelsList={messengers}
          onChannelChange={undefined}
          //-
          phones={undefined}
          onPhonesChange={undefined}
          phonesList={undefined}
          // -
          email={email}
          onEmailChange={handleEmail}
          //-
          quote={quote}
          onQuoteRemove={handleQuoteRemove}
          //-
          uploads={undefined}
          onUploadsChange={undefined}
          //-
          templatesOnly={false}
          templateId={undefined}
          templates={undefined}
          onTemplate={undefined}
          moreTemplatesView={undefined}
          //-
          onSubmit={handleSubmit}
          submitting={submitting}
          //-
          disabled={false}
          //-
          translations={translations}
          previewModalId={PREVIEW_MODAL_DOM_ID}
        />
      )}
    </section>
  );
}
