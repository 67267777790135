import cn from "classnames";
import { getFullName } from "common/helpers";
import { InfiniteList } from "components/InfiniteList";
import {
  useFetchSourcesPageQuery,
  useLazyFetchSourcesPageQuery,
} from "features/api/source-api";
import type { SourceId } from "models/source.model";
import { type RefObject, useState } from "react";
import { useIntl } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
import { ETranslations } from "types/translates";
import { Input, Spinner } from "ui-kit";
import { ArrowDown } from "ui-kit/ICONS/icons";

import { DataList, DataListGroup, DataListItem } from "./DataList";
import styles from "./SourceInput.module.scss";

export const SourceInput = ({
  label,
  placeholder,
  sourceName,
  sourceId,
  error,
  disabled,
  onChange,
}: {
  label?: string;
  placeholder?: string;
  sourceName?: string;
  sourceId?: SourceId;
  error?: string | string[];
  disabled?: boolean;
  onChange: (id: SourceId | undefined, name: string | undefined) => void;
}) => {
  const { formatMessage } = useIntl();
  const defaultState = {
    searchName: sourceName || "",
    showOptions: false,
  };
  const [datalistState, setDatalistState] = useState(defaultState);
  const [fetch] = useLazyFetchSourcesPageQuery();
  const { data, isLoading, fulfilledTimeStamp } = useFetchSourcesPageQuery({
    searchString: datalistState.searchName,
  });

  const handleCloseDetails = () => {
    setDatalistState((prev) => ({
      ...prev,
      showOptions: false,
    }));
    if (sourceName !== datalistState.searchName) {
      onChange(undefined, datalistState.searchName || undefined);
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        datalistState.showOptions && handleCloseDetails();
      }}
    >
      <Input
        label={label}
        placeholder={placeholder}
        autoComplete="off"
        className={styles.datalist}
        value={datalistState.searchName}
        disabled={disabled}
        onClick={() =>
          setDatalistState((prev) => ({
            ...prev,
            showOptions: !prev.showOptions,
          }))
        }
        onChange={({ currentTarget: { value } }) => {
          fetch({ searchString: value });
          setDatalistState((prev) => ({
            ...prev,
            searchName: value,
            showOptions: true,
          }));
        }}
        onKeyDown={(e) => {
          e.key === "Enter" && handleCloseDetails();
          e.key === "Escape" && setDatalistState(defaultState);
        }}
        suffix={
          <ArrowDown
            className={cn(
              styles.arrow,
              datalistState.showOptions && styles.open,
            )}
          />
        }
        datalist={
          isLoading ? (
            <Spinner />
          ) : (
            Boolean(data?.content.length) &&
            datalistState.showOptions && (
              <DataList
              className={styles.datalistTop}
                onSelect={(selectedSourceId, selectedSourceName) => {
                  setDatalistState({
                    searchName: selectedSourceName,
                    showOptions: false,
                  });
                  onChange(
                    Number(selectedSourceId) as SourceId,
                    selectedSourceName,
                  );
                }}
              >
                <InfiniteList
                  key={fulfilledTimeStamp}
                  initialItems={data!.content}
                  fetchNextPage={(page) =>
                    fetch({ searchString: datalistState.searchName, page })
                  }
                  isLastPage={data?.last}
                >
                  {(organization, ref) => (
                    <DataListGroup
                      key={organization.organization_id}
                      ref={ref as RefObject<HTMLOptGroupElement> | undefined}
                      label={
                        organization.organization_name ||
                        formatMessage({
                          id: ETranslations.WITHOUT_ORGANIZATION,
                        })
                      }
                    >
                      {organization.sources.map((source) => (
                        <DataListItem
                          key={source.source_id}
                          value={source.source_id}
                          label={
                            getFullName(
                              source.source_contact?.name,
                              source.source_contact?.middle_name,
                              source.source_contact?.surname,
                            ) || "N/A"
                          }
                          selected={source.source_id === sourceId}
                        />
                      ))}
                    </DataListGroup>
                  )}
                </InfiniteList>
              </DataList>
            )
          )
        }
        error={error}
      />
    </OutsideClickHandler>
  );
};
