import cn from "classnames";
import ManagerialModalError from "components/ManagerialTables/form/ManagerialModalError";
import { ConfirmOverbookingModal } from "components/modals/ConfirmOverbookingModal";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import {
  moveBookingSelectors,
  useMoveBookingActions,
} from "features/MoveBooking";
import { useTableBookingListActions } from "features/TableBooking/slice";
import {
  SwapRequest,
  useMoveMutation,
  useSwapMutation,
} from "features/api/hallschema-api";
import { useBooleanState } from "hooks/useBooleanState";
import type { BookingSlot } from "models/booking.model";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useBoolean } from "react-use";
import type { ErrorResponse, EventFor } from "types/commons";
import { Button } from "ui-kit";

import { useTimelineActions } from "../../../../features/Timeline";
import { ETranslations } from "../../../../types/translates";
import styles from "../../MoveStatus.module.scss";
import { useTablesBookings } from "../TableBookingList/useTableBookings";

const Container = (props: any) => (
  <div className={styles.moveStatus} {...props} />
);

const MoveControls: React.FC<any> = () => {
  const { formatMessage } = useIntl();
  const bookingSelected = useSelector(moveBookingSelectors.isSourceSelected);
  const [hasManagerialError, setHasManagerialError] = useState<boolean>(false);
  const { clearSelection } = useMoveBookingActions();
  const { switchMode } = useHallSchemaActions();
  const moveOrSwapData = useSelector(moveBookingSelectors.moveData);
  const sourceTableNumbers = useSelector(
    moveBookingSelectors.sourceTableNumber,
  );
  const targetTablesNumbers = useSelector(
    moveBookingSelectors.targetTablesNumbers,
  );
  const [move] = useMoveMutation();
  const [swap] = useSwapMutation();
  const { reset } = useTableBookingListActions();
  const [isForce, setForce] = useState<true | undefined>();
  const [isProcessing, start, stop] = useBooleanState();
  const tableId = moveOrSwapData?.destination.tables_id[0];
  const { resetTimeShift } = useTimelineActions();

  const { data } = useTablesBookings(tableId, !tableId, "", true);
  const isHasMultipleBookings = useMemo(
    () => (data?.slots?.length ?? 0) > 1,
    [data],
  );
  const [pickBooking, setPickTrue, setPickFalse] = useBooleanState();
  const [isSwapping, setIsSwapping] = useBoolean(false);
  const canSwap = Boolean(
    (moveOrSwapData?.destination.booking_id || data?.slots?.length) &&
      targetTablesNumbers.length,
  );

  const swapPayload = useRef<SwapRequest | null>(null);

  const resetForce = useCallback(() => {
    setForce(undefined);
    setIsSwapping(false);
  }, [setForce]);

  const closeErrorModal = () => {
    setHasManagerialError(() => false);
  };

  const cancel = useCallback(() => {
    resetTimeShift();
    clearSelection();
    reset();
    switchMode(HallMode.TABLE_BOOKINGS_LIST);
  }, []);

  const processSwap = useCallback(
    async (payload: SwapRequest) => {
      swapPayload.current = payload;
      start();
      setIsSwapping(true);
      try {
        await swap(payload).unwrap();
        reset();
        cancel();
        resetForce();
      } catch (e) {
        const errorData = (e as ErrorResponse["error"])?.data;
        return isForce
          ? errorData?.errorCode === 10_000 && (resetForce(), clearSelection())
          : errorData?.errorCode === 10100 && setForce(true);
      } finally {
        reset();
        setPickFalse();
        stop();
      }
    },
    [isForce, resetForce, swap],
  );

  const retrySwap = useCallback(() => {
    const { current: payload } = swapPayload;
    if (!payload) return undefined;
    return processSwap({ ...payload, force: true });
  }, []);

  const process = useCallback(async () => {
    start();
    setIsSwapping(false);
    try {
      if (!moveOrSwapData) return;
      await move({ ...moveOrSwapData, force: isForce }).unwrap();
      cancel();
      resetForce();
    } catch (e) {
      const errorData = (e as ErrorResponse["error"])?.data;
      errorData?.errorCode === 10400 && setHasManagerialError(true);
      return isForce
        ? errorData?.errorCode === 10_000 && (resetForce(), clearSelection())
        : errorData?.errorCode === 10100 && setForce(true);
    } finally {
      stop();
      reset();
    }
  }, [move, moveOrSwapData, isForce, resetForce]);

  const pickExactBooking = useCallback(
    async (booking: BookingSlot) => {
      if (moveOrSwapData) {
        const payload = {
          origin: {
            booking_id: moveOrSwapData.origin.booking_id,
          },
          destination: {
            booking_id: booking.slot_id,
          },
          force: isForce,
        };
        await processSwap(payload);
      }
    },
    [moveOrSwapData, isForce],
  );

  const handlePick = useCallback(
    (
      e: EventFor<"button", "onClick">,
      bookingId: number | undefined | null,
    ) => {
      if (!moveOrSwapData || !bookingId) return;
      if (isHasMultipleBookings) {
        e.stopPropagation();
        setPickTrue();
        return;
      }
      const payload = {
        origin: {
          booking_id: moveOrSwapData.origin.booking_id,
        },
        destination: {
          booking_id: bookingId,
        },
        force: isForce,
      };

      processSwap(payload);
    },
    [isHasMultipleBookings, isForce, moveOrSwapData],
  );

  const tablesNumbers = useMemo(() => {
    if (!sourceTableNumbers.length || !targetTablesNumbers.length) return "";
    const source = sourceTableNumbers.join(", ");
    const target = targetTablesNumbers.join(", ");

    return ` ${source} → ${target}`;
  }, [sourceTableNumbers, targetTablesNumbers]);

  return (
    <>
      <Container style={{ display: bookingSelected ? "flex" : "none" }}>
        {canSwap && (
          <Button
            disabled={isProcessing}
            type="button"
            variant="primary"
            onClick={(e) =>
              handlePick(
                e,
                moveOrSwapData?.destination.booking_id ||
                  data?.slots?.[0]?.slot_id,
              )
            }
          >
            {formatMessage({ id: ETranslations.SEATS_SWAP })}
            {tablesNumbers}
          </Button>
        )}
        <Button
          disabled={!moveOrSwapData || isProcessing}
          type="button"
          variant="primary"
          onClick={process}
        >
          {formatMessage({ id: ETranslations.DOUBLE_BOOKING })}
          {tablesNumbers}
        </Button>
        {bookingSelected && (
          <button
            className={cn("secondary", styles.cancelButton)}
            type="button"
            onClick={cancel}
          >
            {formatMessage({ id: ETranslations.BASE_CANCEL })}
          </button>
        )}
      </Container>
      <ConfirmOverbookingModal
        disabled={isProcessing}
        isOpen={isForce}
        onConfirm={isSwapping ? retrySwap : process}
        onDecline={resetForce}
      />

      {hasManagerialError && (
        <ManagerialModalError
          isOpen={hasManagerialError}
          onClose={closeErrorModal}
        />
      )}
    </>
  );
};

export const HallMoveStatusControls = memo(MoveControls);
