import cn from "classnames";
import { Outlet, useLocation } from "react-router-dom";

import styles from "./LayoutWebView.module.scss";

export function LayoutWebView({
  getPageName,
}: {
  getPageName: (path: string) => string;
}): JSX.Element {
  const pageName = getPageName(useLocation().pathname);

  return (
    <main className={cn(styles.main, styles[pageName])} id={pageName}>
      <Outlet />
    </main>
  );
}
