import { useLocalStorage } from "react-use";
import { ELocales } from "types/commons";
import { validateLocale } from "utils";

import { DEFAULT_LOCALE } from "../constants";

export function useUserLocale() {
  const [userLocale, setUserLocale] = useLocalStorage<ELocales>(
    "locale",
    DEFAULT_LOCALE,
  );

  return {
    userLocale: validateLocale(userLocale),
    setUserLocale,
  };
}
