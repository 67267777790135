import type { RequiredBy } from "types/commons";
import { ETranslations } from "types/translates";
import { z } from "zod";



import { PageableScheme } from "./common";


export const OrganizationId = z.number().int().brand("OrganizationId");
export type OrganizationId = z.infer<typeof OrganizationId>;

export const OrganizationName = z.string().brand("OrganizationName");
export type OrganizationName = z.infer<typeof OrganizationName>;

export const SourceId = z.number().int().brand("SourceId");
export type SourceId = z.infer<typeof SourceId>;

export const SourceContactId = z.number().int().brand("SourceContactId");
export type SourceContactId = z.infer<typeof SourceContactId>;

export const SourceType = z.enum(
  [
    "CONCIERGE",
    "PERSONAL_ASSISTANT",
    "LIFESTYLE_MANAGER",
    "PRIME_CONCIERGE",
    "OPEN_SOURCE",
  ],
  { message: ETranslations.FIELD_INVALID },
);

export type SourceType = z.infer<typeof SourceType>;

const SourceContact = z.object({
  id: SourceContactId,
  tenant_id: z.number(),
  label: SourceType,
  phone: z.string().nullish(),
  extension_phone: z.string().nullish(),
  email: z.string().nullish(),
  surname: z.string().nullish(),
  middle_name: z.string().nullish(),
  name: z.string().nullish(),
});
export type SourceContact = z.infer<typeof SourceContact>;

const SourceContactWithOldSources = SourceContact.extend({
  id: SourceContactId.nullable(),
  tenant_id: z.number().nullable(),
  label: SourceType.nullable(),
}).nullable();

export const Source = z.object({
  source_id: SourceId,
  source_contact: SourceContactWithOldSources,
  organization: z
    .object({
      id: OrganizationId.nullable(),
      name: OrganizationName.nullable(),
      phone: z.string().nullish(),
    })
    .nullable(),
});
export type Source = z.infer<typeof Source>;

export const Organization = z.object({
  id: OrganizationId,
  name: OrganizationName,
  type: z.string().nullable(),
  address: z.string().nullable(),
  phone: z.string().nullable(),
  organization_uuid: z.string().uuid().nullable(),
  tenant_id: z.number().nullable().optional(),
});
export type Organization = z.infer<typeof Organization>;

const SourceWithOrganization = z.object({
  organization_id: OrganizationId.nullable(),
  organization_name: OrganizationName.nullable(),
  sources: Source.array(),
});

export const SourceSearchDTO = PageableScheme(SourceWithOrganization);
export type SourceSearchDTO = z.infer<typeof SourceSearchDTO>;

export type SourceSearchParams = Partial<{
  searchString: string;
  contactLabels: SourceType[];
}>;

export const OrganizationSearchDTO = PageableScheme(
  z.object({
    id: OrganizationId,
    name: OrganizationName,
    type: z.string().nullable(),
    address: z.string().nullable(),
    phone: z.string().nullable(),
    organization_uuid: z.string().uuid().nullable(),
    tenant_id: z.number().nullable(),
  }),
);
export type OrganizationSearchDTO = z.infer<typeof OrganizationSearchDTO>;

export type SourceAggregateRequest = {
  source_contact: RequiredBy<SourceContact, "name" | "phone">;
  organization?: RequiredBy<Organization, "name">;
};