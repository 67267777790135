import cn from "classnames";
import type { MouseEventHandler, ReactNode } from "react";

import styles from "./DataSection.module.scss";

const DataSectionRoot = ({
  title,
  children,
  className,
  titleClassName,
  dataListClassName,
}: {
  title?: string;
  children: ReactNode;
  className?: string;
  titleClassName?: string;
  dataListClassName?: string;
}) => (
  <section className={cn(styles.dataSection, className)}>
    {title && <h2 className={cn(styles.title, titleClassName)}>{title}</h2>}
    <dl className={cn(styles.dataList, dataListClassName)}>{children}</dl>
  </section>
);

const DataSectionItem = ({
  label,
  children,
  onClick,
  className,
  labelClassName,
  valueClassName,
}: {
  label: ReactNode;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
}) => (
  <div className={cn(styles.item, className)}>
    <dt className={labelClassName}>{label}</dt>
    <dd className={valueClassName}>
      {onClick ? (
        <button type="button" onClick={onClick}>
          {children}
        </button>
      ) : (
        children
      )}
    </dd>
  </div>
);

export const DataSection = { Root: DataSectionRoot, Item: DataSectionItem };
