import type { ActionFunction } from "react-router-dom";
import { formDataToObject } from "utils";

import { UserRoleFormSchema } from "../model/schema";

export const action = (async ({ params, request }) => {
  const formDataObj = await request.formData().then(formDataToObject);
  const parsedData = UserRoleFormSchema.safeParse(formDataObj);
  const errors = parsedData.error?.flatten();
  return errors;
}) satisfies ActionFunction;
