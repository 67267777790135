import {
  useFetchNotificationsQuery,
  useUpdateNotificationsMutation,
} from "features/api/notification-api";
import { useGetPermissionsQuery } from "features/api/user-api";
import { useCheckPermission } from "hooks/useCheckPermission";
import { NotificationType, notificationTypes } from "models/notification.model";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import type { IfEquals } from "types/commons";
import { ETranslations } from "types/translates";
import {
  AutoResizeTextarea,
  Button,
  Card,
  FieldError,
  ICONS,
  Spinner,
} from "ui-kit";
import { DetailsCollapse } from "ui-kit/Collapse/Collapse";
import { DataSection } from "ui-kit/DataSection/DataSection";
import { UncontrolledToggleSwitch } from "ui-kit/ToggleSwitch";
import { type ZodType, z } from "zod";

import styles from "./MessagesForm.module.scss";

const TEXTAREA_MAX_LENGTH = 300;

type MessageFormSchemaInternalType = {
  [K in NotificationType as `${K}-enabled`]: z.ZodEffects<
    z.ZodUnion<[z.ZodLiteral<"on">, z.ZodUndefined]>,
    boolean,
    "on" | undefined
  >;
} & {
  [K in NotificationType as `${K}-message`]: z.ZodString;
};

const MessagesFormSchema = z.object(
  notificationTypes.reduce((acc, type) => {
    acc[`${type}-enabled`] = z
      .union([z.literal("on"), z.undefined()])
      .transform(Boolean);
    acc[`${type}-message`] = z
      .string()
      .max(TEXTAREA_MAX_LENGTH, ETranslations.FIELD_TOO_LONG);
    return acc;
  }, {} as MessageFormSchemaInternalType),
);
type MessagesFormSchema = z.infer<typeof MessagesFormSchema>;

const formatZodErrors = () =>
  notificationTypes.reduce(
    (acc, type) => {
      return acc;
    },
    {} as Record<NotificationType, string[]>,
  );

const MessageField = ({
  type,
  label,
  isEnabled,
  text,
}: {
  type: NotificationType;
  label: string;
  isEnabled: boolean;
  text?: string;
}) => {
  return (
    <fieldset className={styles.messageField}>
      <legend className={styles.messageFieldHeader}>
        <h3 className={styles.label}>{label}</h3>
        <UncontrolledToggleSwitch
          name={`${type}-enabled`}
          isEnabled={isEnabled}
        />
      </legend>
      <AutoResizeTextarea
        name={`${type}-message`}
        defaultValue={text}
        maxLength={TEXTAREA_MAX_LENGTH}
      />
    </fieldset>
  );
};

const MessagesForm = ({ shiftId }: { shiftId: number }) => {
  const { formatMessage } = useIntl();
  const [update, { isLoading }] = useUpdateNotificationsMutation();
  const { data } = useFetchNotificationsQuery(shiftId);
  const [keySuffix, setKeySuffix] = useState("");
  const { hasPermissionFor } = useCheckPermission();
  const [isFormDirty, setIsFormDirty] = useState(false);

  return data ? (
    <>
      <Card.Content className={styles.content} noPadding as="section">
        <DetailsCollapse
          className={styles.legend}
          contentClassName={styles.legendContent}
          title={formatMessage({ id: ETranslations.LEGEND })}
          titleClassName={styles.legendTitle}
          icon={<ICONS.Info width={24} height={24} />}
        >
          <DataSection.Root className={styles.variables}>
            {data.variables &&
              Object.entries(data.variables).map((variable) => (
                <DataSection.Item
                  className={styles.variable}
                  label={`{${variable[0]}}`}
                  key={variable[0]}
                >
                  {variable[1]}
                </DataSection.Item>
              ))}
          </DataSection.Root>
        </DetailsCollapse>
        <form
          id="messages-form"
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault();
            // Получаем данные формы по полям
            const formData = new FormData(e.currentTarget);

            // переводим FormData в объект
            const result = Object.fromEntries(formData) as {
              [K in NotificationType as `${K}-enabled`]: "on" | undefined;
            } & {
              [K in NotificationType as `${K}-message`]: string;
            };

            // Проходимся по массиву текущих настроек сообщений
            //на его основе создаем новые настройки с новым текстом и статусом enabled
            const updatedSettings = data.settings.map((setting) => ({
              type: setting.type,
              message: result[`${setting.type}-message`],
              subject: setting.subject,
              enabled: Boolean(result[`${setting.type}-enabled`]),
            }));

            // отправляем запрос на обновление настроек сообщений
            update({
              settings: updatedSettings,
              shiftId,
            }).then((response) => {
              if (response && "data" in response) {
                setIsFormDirty(false);
                Notification.success(ETranslations.MESSAGES_UPDATE_SUCCESS);
              } else {
                Notification.error({
                  title: ETranslations.MESSAGES_UPDATE_ERROR,
                  message: JSON.stringify(response.error),
                });
              }
            });
          }}
          // При первом редактировании любого поля, отмечаем, форму "грязной"
          onChange={() => isFormDirty || setIsFormDirty(true)}
          // При резете формы меняем key на всех MessageField, чтобы сбросить их и отмечаем форму чистой
          onReset={(e) => {
            setKeySuffix(String(e.timeStamp));
            setIsFormDirty(false);
          }}
        >
          {data?.settings.map((setting) => (
            <MessageField
              key={setting.type + keySuffix}
              type={setting.type}
              label={setting.subject}
              isEnabled={setting.enabled}
              text={setting.message}
            />
          ))}
        </form>
      </Card.Content>
      <footer className={styles.footer}>
        <Button variant="secondary" form="messages-form" type="reset">
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
        <Button
          variant="primary"
          form="messages-form"
          type="submit"
          disabled={
            !isFormDirty ||
            isLoading ||
            !data ||
            !hasPermissionFor("ACCESS_MANAGEMENT_SHIFT_UPDATE")
          }
        >
          {formatMessage({ id: ETranslations.BASE_SAVE })}
        </Button>
      </footer>
    </>
  ) : (
    <Spinner />
  );
};

MessagesForm.displayName = "MessagesForm";

export { MessagesForm };
