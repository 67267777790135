import { ICONS } from "common/helpers";
import { Menu } from "components/Menu/Menu";
import { Popup, PopupRef } from "components/Popup";
import { useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import type { BookingOrder } from "types/booking";
import { Button } from "ui-kit";



import { ETranslations } from "../../types/translates";
import styles from "./BookingActions.module.scss";


const RESET_PADDING = { top: true, bottom: true };

interface BookingActionsProps {
  className?: string;
  order: BookingOrder;
  onEdit?: (booking: BookingOrder) => void;
}

export function OrderActions({
  className,
  order,
  onEdit,
}: BookingActionsProps) {
  const intl = useIntl();
  const popup = useRef<PopupRef>(null);

  const handleEdit = useCallback(() => {
    onEdit?.(order);
    popup.current?.close();
  }, [order, onEdit]);

  return (
    <Popup placement="auto-start" ref={popup}>
      <Popup.Trigger>
        <Button className={className} variant="phantom">
          <ICONS.ActionsMenu />
        </Button>
      </Popup.Trigger>
      <Popup.Content noPadding={RESET_PADDING}>
        <Menu className={styles.menu}>
          <Menu.Item icon={ICONS.Edit} onClick={handleEdit}>
            {intl.formatMessage({ id: ETranslations.BASE_EDIT })}
          </Menu.Item>
        </Menu>
      </Popup.Content>
    </Popup>
  );
}