import dayjs from "dayjs";
import { useShiftTimes } from "features/api/shifts";
import { useMemo } from "react";
import { useFormState } from "react-final-form";

const DEFAULT_OPTIONS: number[] = [0];

export function useFormVisitTimes() {
  const {
    values: { time, date },
  } = useFormState({ subscription: { values: true } });
  const { visitTimes: times } = useShiftTimes();

  const timestamp = useMemo(() => {
    if (typeof time?.value === "string") {
      const currentDate = dayjs(date);
      const currentTime = dayjs(time.value, "HH:mm");
      const hour = currentTime.hour();
      const minute = currentTime.minute();
      return currentDate.set("hour", hour).set("minute", minute).valueOf();
    }
    return time?.value;
  }, [time]);

  const options = useMemo(() => {
    if (!timestamp) return [];
    const existTime = times[timestamp];
    if (existTime) return [...DEFAULT_OPTIONS, ...existTime];
    return [];
  }, [times, timestamp]);

  return {
    options,
  };
}
