import React from "react";
import { Form } from "react-final-form";
import { ETranslations } from "types/translates";
import { Button, Card } from "ui-kit";

import CreateManagerialModalError from "./CreateManagerialModalError";
import { ManagerialTablesForm } from "./ManagerialTablesForm";
import useCreateManagerialBooking from "./hooks/useCreateManagerialBooking";

export const CreateManagerialForm = ({ reset }: { reset: any }) => {
  const { initialValues, intl, onSubmit, close, onClose, managerialError } =
    useCreateManagerialBooking(reset);

  return (
    <Card onClose={close}>
      <Card.Header
        ManagerialButton={
          <Button
            style={{
              padding: 5,
              marginRight: 12,
              font: "var(--font-13-r)",
              fontSize: "400",
            }}
            variant="primary"
          >
            {intl.formatMessage({ id: ETranslations.MANAGEMENT_BOOKING })}
          </Button>
        }
        title={intl.formatMessage({ id: ETranslations.CREATE_BOOKING })}
      />
      <Card.Content noPadding>
        <Form
          initialValues={initialValues}
          render={() => {
            return <ManagerialTablesForm />;
          }}
          onSubmit={onSubmit}
        />
      </Card.Content>
      {!!managerialError.length && (
        <CreateManagerialModalError
          isOpen={!!managerialError.length}
          managerialError={managerialError}
          onClose={onClose}
        />
      )}
    </Card>
  );
};
