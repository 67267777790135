import { restaurantSelector } from "features/AppContext";
import { useFetchShiftsListQuery } from "features/api/shifts";
import React, { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { TNullable } from "types/commons";
import { Shift } from "types/shift";

import { InfoShiftsModal } from "../Modal/InfoModal/InfoShiftsModal";
import styles from "./ShiftsTable.module.scss";
import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";

export const ShiftsTable: FC = () => {
  const restaurant = useSelector(restaurantSelector);
  const { data: shifts } = useFetchShiftsListQuery(restaurant.restaurant_id);
  const [activeShift, setActiveShift] = useState<TNullable<Shift>>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const onClickHandler = useCallback((shift: Shift) => {
    setActiveShift(shift);
    setModalOpen(true);
  }, []);

  if (!shifts) return null;

  return (
    <div>
      <table className={styles.table}>
        <TableHead />
        <TableBody setShiftId={onClickHandler} shifts={shifts.data} />
      </table>
      <InfoShiftsModal
        isOpen={modalOpen && Boolean(activeShift)}
        shiftId={activeShift?.id}
        title={activeShift?.shift_name || ""}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};
