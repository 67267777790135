import { OrganizationInput } from "components/SourceInput";
import { type Control, useController } from "react-hook-form";

import { SourceFormSchema } from "../../model/schema";

export const SourceFormOrganizationInput = ({
  label,
  control,
}: {
  label?: string;
  control: Control<SourceFormSchema>;
}) => {
  const organizationId = useController({
    name: "organizationId",
    control,
    rules: { required: true },
  });
  const organizationName = useController({
    name: "organizationName",
    control,
  });
  return (
    <OrganizationInput
      label={label}
      organizationName={organizationName.field.value}
      organizationId={organizationId.field.value}
      error={
        organizationName.fieldState.error?.message ||
        organizationId.fieldState.error?.message
          ? ([
              organizationName.fieldState.error?.message,
              organizationId.fieldState.error?.message,
            ].filter(Boolean) as string[])
          : undefined
      }
      onOrganizationNameChange={organizationName.field.onChange}
      onOrganizationIdChange={organizationId.field.onChange}
    />
  );
};
