import { Menu } from "components/Menu/Menu";
import { Place } from "models/restaurant.model";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { ICONS } from "ui-kit";

interface HallMenuProps {
  hall: Place;
  onEditName: (hall: Place) => void;
  onEditScheme: (hall: Place) => void;
  onClick?: () => void;
}

export function HallMenu({
  hall,
  onEditName,
  onEditScheme,
  onClick,
}: HallMenuProps) {
  const handleEdit = useCallback(() => {
    onClick?.();
    onEditName(hall);
  }, [onClick, onEditName, hall]);

  const handleScheme = useCallback(() => {
    onClick?.();
    onEditScheme(hall);
  }, [onClick, onEditScheme, hall]);

  const { formatMessage } = useIntl();

  return (
    <Menu>
      <Menu.Item icon={ICONS.Edit} onClick={handleEdit}>
        {formatMessage({ id: ETranslations.EDIT_HALL_NAME_AND_NUMBER })}
      </Menu.Item>
      <Menu.Item icon={ICONS.HallIcon} onClick={handleScheme}>
        {formatMessage({ id: ETranslations.EDIT_HALL_SCHEME })}
      </Menu.Item>
    </Menu>
  );
}
