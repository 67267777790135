import cn from "classnames";
import { PlaceSelect } from "components/PlaceSelect";
import { Edit } from "components/registration/forms/FormControls/Edit";
import { DataChips } from "components/registration/sub-renders/data-chips";
import { ChoiceTimeInput } from "components/registration/sub-renders/date-time";
import { ChoiceTimeInputNew } from "components/registration/sub-renders/date-time-new";
import {
  ChoiceAutoTableInputRedux,
  ChoiceTablesInputRedux,
} from "components/registration/sub-renders/location";
import { required } from "components/shifts/utils";
import type { FormBooking } from "features/BookingFormProxy/types";
import React from "react";
import { Field } from "react-final-form";
import { ETranslations } from "types/translates";
import { BottomBar, Button, Labeled, LinkButton } from "ui-kit";

import {
  ChoiceVisitTimeButtonsInput,
  ChoiceVisitTimeControls,
} from "../../registration/sub-renders/visit-time";
import { CancelBooking } from "./CancelBooking";
import ManagerialModal from "./ManagerialModal";
import { WeekDaysField } from "./fields";
import { ChoiceDateInput } from "./fields/DateField";
import { EndDate } from "./fields/EndDateInput";
import useManagerialTablesForm from "./hooks/useManagerialTablesForm";
import styles from "./styles.module.scss";

export const ManagerialTablesForm = ({
  editBooking = false,
}: {
  editBooking?: boolean;
}) => {
  const {
    handleSubmit,
    isNewDesign,
    intl,
    disabeld,
    intlTitles,
    valid,
    openModal,
    dirty,
    handleReset,
    isEditMode,
    timePassed,
    modalIsOpen,
    closeModal,
    cancelBooking,
    managerialBookingTimeStamp,
    formBookingState,
    from,
  } = useManagerialTablesForm(editBooking);

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={isNewDesign ? styles.timePartFull : styles.timePart}>
          <Labeled
            label={intl.formatMessage({ id: ETranslations.START_DATE })}
            required
          >
            <Field
              component={ChoiceDateInput}
              disabled={editBooking}
              name="start_date"
              required
            />
          </Labeled>
          <div className={styles.showInSmall}>
            <Field
              component={DataChips}
              disabled={editBooking}
              isCreateBooking={true}
              name="start_date"
            />
          </div>
          <Labeled
            label={intl.formatMessage({ id: ETranslations.END_DATE })}
            required
          >
            <Field
              component={EndDate}
              disabled={editBooking}
              name="end_date"
              required
            />
          </Labeled>
          {!isNewDesign && (
            <Labeled
              label={intl.formatMessage({ id: ETranslations.BASE_TIME })}
              required
            >
              <ChoiceTimeInput
                allowValueFromOutsideRange={editBooking}
                disabled={disabeld}
                isFromManagement
              />
            </Labeled>
          )}

          {isNewDesign && (
            <div
              style={{
                gridTemplateColumns: "auto",
                display: "grid",
                gridColumn: "span 2",
                marginTop: "8px",
              }}
            >
              <ChoiceTimeInputNew
                allowValueFromOutsideRange={editBooking}
                disabled={disabeld}
                isEditMode={isEditMode}
              />
            </div>
          )}
        </div>

        <div className={styles.mainInfo}>
          <Labeled label={intl.formatMessage({ id: ETranslations.DURATION })}>
            <div className={styles.duration}>
              <Field
                component={ChoiceVisitTimeButtonsInput}
                disabled={disabeld}
                name="duration"
                validate={(value) => !value}
                isManagerialTable
                newDesign
              />
              <Field
                component={ChoiceVisitTimeControls}
                disabled={disabeld}
                name="duration"
                validate={(value) => !value}
                isFromManagement
              />
            </div>
          </Labeled>
          <div
            className={cn(
              editBooking
                ? `${styles.placeBox} ${styles.edit}`
                : styles.placeBox,
            )}
          >
            <Labeled
              className={styles.bookingLabel}
              label={intl.formatMessage({ id: ETranslations.SEATING_AREA })}
              required
            >
              <PlaceSelect
                disabled={disabeld}
                managerialBookingTimeStamp={managerialBookingTimeStamp}
                styles={styles}
                isNewDesign
              />
            </Labeled>
            <div
              className={cn(
                editBooking ? styles.tableBoxEdit : styles.tableBox,
              )}
            >
              <Labeled
                className={styles.bookingLabel}
                label={intlTitles.tableNumber}
                required
              >
                <div className={styles.openSchemeContainerNew}>
                  <Field
                    component={ChoiceTablesInputRedux}
                    disabled={disabeld}
                    name="tables"
                    validate={(value) => {
                      return !value.length;
                    }}
                  />
                </div>
              </Labeled>
              <ChoiceAutoTableInputRedux
                disabled={disabeld}
                isCreateBooking={true}
                isShowTableScheme={false}
              />
            </div>
          </div>

          <div className={styles.tableBox}>
            <Labeled
              className={styles.bookingLabel}
              label={intlTitles.tableNumber}
              required
            >
              <div className={styles.openSchemeContainerNew}>
                <Field
                  component={ChoiceTablesInputRedux}
                  disabled={disabeld}
                  name="tables"
                  validate={(value) => {
                    return !value.length;
                  }}
                />
              </div>
            </Labeled>
            <ChoiceAutoTableInputRedux
              disabled={disabeld}
              isCreateBooking={editBooking}
              isShowTableScheme={false}
            />
          </div>
        </div>
        {!editBooking && (
          <Labeled
            as="div"
            className={styles.daysOfRePeat}
            label={intl.formatMessage({
              id: ETranslations.DAYS_TO_REPEAT_A_BOOKING,
            })}
            required
          >
            <Field
              component={WeekDaysField}
              name="week_days"
              validate={required}
              required
            />
          </Labeled>
        )}
      </form>
      <BottomBar className={styles.controls}>
        {!editBooking && (
          <BottomBar.Part placement="left">
            <Button
              disabled={editBooking || !valid}
              type="button"
              variant="primary"
              onClick={openModal}
            >
              {intl.formatMessage({ id: ETranslations.BASE_CREATE })}
            </Button>
            <Button disabled={!dirty} variant="secondary" onClick={handleReset}>
              {intl.formatMessage({ id: ETranslations.CANCEL })}
            </Button>
          </BottomBar.Part>
        )}
        {editBooking && (
          <>
            {!isEditMode && (
              <BottomBar.Part placement="left">
                {!timePassed && (
                  <LinkButton
                    to={"/create-booking"}
                    state={{
                      isBookingFormData: true,
                      formData: formBookingState,
                      from,
                    }}
                    type="button"
                    variant="secondary"
                  >
                    {intl.formatMessage({ id: ETranslations.CREATE_BOOKING })}
                  </LinkButton>
                )}
              </BottomBar.Part>
            )}
            <BottomBar.Part placement="right">
              <CancelBooking openModal={openModal} />
              <Edit isFromManagement showSave />
            </BottomBar.Part>
          </>
        )}
      </BottomBar>
      <ManagerialModal
        buttons={
          <>
            {!editBooking ? (
              <>
                <Button type="submit" variant="primary" onClick={handleSubmit}>
                  {intl.formatMessage({ id: ETranslations.BASE_CREATE })}
                </Button>
                <Button variant="secondary" onClick={closeModal}>
                  {intl.formatMessage({ id: ETranslations.CANCEL })}
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="submit"
                  variant="dangerous"
                  onClick={cancelBooking}
                >
                  {intl.formatMessage({ id: ETranslations.BASE_DELETE })}
                </Button>
                <Button variant="secondary" onClick={closeModal}>
                  {intl.formatMessage({ id: ETranslations.CANCEL })}
                </Button>
              </>
            )}
          </>
        }
        editBooking={editBooking}
        isOpen={modalIsOpen}
        text={intl.formatMessage({
          id: ETranslations.CANCEL_MANAGAREL_RESERVATION,
        })}
        title={
          !editBooking
            ? intl.formatMessage({
                id: ETranslations.CREATING_MANAGERIAL_TABLE,
              })
            : intl.formatMessage({
                id: ETranslations.DELETE_MANAGERIAL_TABLE_TILE,
              })
        }
        onClose={closeModal}
      />
    </>
  );
};
