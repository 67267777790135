import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { ETranslations } from "../../../types/translates";
import { capitalizeString } from "../../../utils";
import { ICONS, translateParamsMapper, undefinedString } from "common/helpers";
import { TagServer } from "models/booking.model";
import { ChangesParams, Param } from "models/common";
import { useMemo } from "react";

export interface ChangesParamsModalProps {
  changesParams: ChangesParams[];
  closeModal: () => void;
}

export default function ChangesParamsModal(props: ChangesParamsModalProps) {
  const { intl, getIntlNumbersOf, getIntlParametersOf, getIntlJoinedParts } =
    useIntlUtils();
  const intlTitles = useMemo(
    () => ({
      tags: capitalizeString(
        intl.formatMessage({ id: ETranslations.PLURAL_TAGS_NOM }),
      ),
      numbersOfTables: getIntlNumbersOf(ETranslations.PLURAL_TABLES),
      comment: intl.formatMessage({ id: ETranslations.COMMENT }),
      source: intl.formatMessage({ id: ETranslations.SOURCE }),
    }),
    [],
  );
  const isItemNullable = (item: ChangesParams) =>
    item.old === null ||
    item.old === "" ||
    (Array.isArray(item.old) && !item.old.length) ||
    item.old === undefinedString;
  const getTranslatedIcon = (field: string): string =>
    // @ts-ignore
    ICONS[translateParamsMapper[field]];
  const { changesParams } = props;
  const isBookingNEW =
    changesParams.length > 2 &&
    changesParams.every((item) => isItemNullable(item));

  /* eslint-disable */
  const renderParams = (param: Param, paramName: string) => {
    if (Array.isArray(param) && paramName === intlTitles.tags) {
      // @ts-ignore
      let tagsBlock: JSX.Element[] | JSX.Element = (param as TagServer[]).map(
        (item) => {
          return (
            <div
              key={item.tag_id}
              style={{ backgroundColor: item.color }}
              className="tag"
            >
              {item.description}
            </div>
          );
        },
      );

      tagsBlock =
        param.length === 0 ? (
          <div style={{ height: "100%" }}>{undefinedString}</div>
        ) : (
          tagsBlock
        );
      return <div className="tags">{tagsBlock}</div>;
    } else if (
      typeof param === "string" &&
      paramName === intlTitles.numbersOfTables &&
      param.length > 25
    ) {
      return (
        <>
          {param.slice(0, 25) + "..."}
          {/* <div className="tooltip">{param}</div> */}
        </>
      );
    } else {
      return param === "" ? undefinedString : param;
    }
  };
  /* eslint-enable */

  return (
    <div
      className="changes-params"
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className="top-block-A">
        <h2>{getIntlParametersOf(ETranslations.PLURAL_CHANGES)}</h2>
        <img alt="" src={ICONS.crossGrey} onClick={() => props.closeModal()} />
      </div>
      <div className={`content ${isBookingNEW ? "one-column" : ""}`}>
        <div className="table-header">
          <div className="definition">
            {intl.formatMessage({ id: ETranslations.PLURAL_PARAMETER })}
          </div>
          <div className="new">
            {capitalizeString(
              getIntlJoinedParts([
                ETranslations.CURRENT,
                ETranslations.PLURAL_VALUES,
              ]),
            )}
          </div>
          {!isBookingNEW && (
            <>
              <div className="separ" />
              <div className="old">
                {capitalizeString(
                  getIntlJoinedParts([
                    ETranslations.PREVIOUS,
                    ETranslations.PLURAL_VALUES,
                  ]),
                )}
              </div>
            </>
          )}
        </div>
        <div className="table-body custom-scroll">
          {props.changesParams.map((change) => (
            <div
              className={`table-row
                ${change.field === intlTitles.tags ? "tags-only" : ""}
                ${change.field === intlTitles.numbersOfTables ? "numbers" : ""}
                ${change.field === intlTitles.comment ? "comment" : ""}
              `}
              key={change.field}
            >
              <div className="definition">
                <div
                  className="icon"
                  style={
                    change.field === intlTitles.source
                      ? { marginLeft: "-5px", marginRight: "5px" }
                      : {}
                  }
                >
                  {change.field === intlTitles.tags ? (
                    <div className="tags">
                      <div className="tag">Т</div>
                    </div>
                  ) : (
                    <img
                      alt={change.field}
                      src={getTranslatedIcon(change.field)}
                    />
                  )}
                </div>
                {change.field}
              </div>
              <div
                className={`new ${change.field === intlTitles.numbersOfTables ? "numbers" : ""}`}
              >
                {renderParams(change.new, change.field)}
              </div>
              {!isBookingNEW && (
                <>
                  <div className="separ" />
                  <div
                    className={`old ${change.field === intlTitles.numbersOfTables ? "numbers" : ""}`}
                  >
                    {renderParams(change.old, change.field)}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
