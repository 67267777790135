import { ClientInfiniteList } from "containers/Guests";
import { ClientList } from "containers/Guests/Clients/ClientList";
import dayjs from "dayjs";
import { useFetchClientsPageQuery } from "features/api/client-api";
import type { ClientId } from "models/client.model";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Button, Input, Spinner } from "ui-kit";

import { useCreateBookingFormContext } from "../../context/CreateBookingContext";
import { CreateBookingFormFooter } from "../CreateBookingFormLayout";
import { StepContainer } from "../FormComponents";
import styles from "./ClientStep.module.scss";

export const ClientStep = () => {
  const { formatMessage } = useIntl();
  const { formValues, updateValues, updateValidationState } =
    useCreateBookingFormContext();
  const [search, setSearch] = useState("");
  const filter = {
    offset: 0,
    count: 20,
    term: search,
  };
  const { data, isLoading, fulfilledTimeStamp } =
    useFetchClientsPageQuery(filter);

  return (
    <>
      <StepContainer>
        <ClientList
          className={styles.clientList}
          withoutTagsSelect
          addon={
            <Button className={styles.newClientButton} variant="thin">
              {formatMessage({ id: ETranslations.ADD_NEW_CLIENT })}
            </Button>
          }
          onSelectClient={(client) =>
            updateValues({
              client: {
                clientId: client.client_id as ClientId,
                phone: client.phone,
                surname: client.surname || "",
                name: client.name || "",
                middleName: client.middle_name || "",
              },
            })
          }
        />
      </StepContainer>
      {formValues.client && <CreateBookingFormFooter currentStep={3} />}
    </>
  );
};
