import styles from "./Table.module.scss";
import cn from "classnames";

export const OverbookedLabel = ({
  className,
  ...props
}: JSX.IntrinsicElements["div"]) => (
  <div className={cn(styles.overbooked, className)} {...props}>
    !
  </div>
);
