import { useIntl } from "react-intl";
import { Form, useSearchParams } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, ICONS, Input } from "ui-kit";

import styles from "./RolesHeader.module.scss";

export const RolesHeader = ({
  onAddUser,
}: {
  onAddUser?: (fullName?: string) => void;
}) => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get("q");
  const changeSearchParams = (q: string, isFirstSearch: boolean) =>
    setSearchParams((prev) => (prev.set("q", q), prev), {
      replace: !isFirstSearch,
    });

  return (
    <header className={styles.header}>
      <Form role="search" className={styles.searchForm}>
        <Input
          prefix={<ICONS.Search />}
          type="search"
          role="searchbox"
          defaultValue={searchValue || ""}
          onChange={(e) =>
            changeSearchParams(e.currentTarget.value, searchValue === null)
          }
        />
      </Form>
      <Button
        variant="primary"
        disabled={!onAddUser}
        onClick={(e) => {
          e.stopPropagation();
          onAddUser && onAddUser(searchValue || undefined);
        }}
      >
        {formatMessage(
          { id: ETranslations.BASE_ADD_ENTITY },
          {
            entity: formatMessage({ id: ETranslations.OF_USER }).toLowerCase(),
          },
        )}
      </Button>
    </header>
  );
};
