import styles from "./HallControlsPlugin.module.scss";
import { Plugin, TemplateConnector } from "@devexpress/dx-react-core";
import { Plugins } from "@technolab/hse";
import { ICONS } from "common/helpers";
import { memo } from "react";
import { Button } from "ui-kit";

export const HallControlsPlugin = memo(() => (
  <Plugin name="HallControlsPlugin">
    <TemplateConnector>
      {(_getters, actions) => (
        <div className={styles.root}>
          <Button
            className={styles.button}
            variant="phantom"
            onClick={actions[Plugins.Zoom.ACTIONS.zoomIn]}
          >
            <ICONS.ZoomPlus />
          </Button>
          <Button
            className={styles.button}
            variant="phantom"
            onClick={actions[Plugins.Zoom.ACTIONS.zoomOut]}
          >
            <ICONS.ZoomMinus />
          </Button>
          <Button
            className={styles.button}
            variant="phantom"
            onClick={actions[Plugins.Zoom.ACTIONS.reset]}
          >
            <ICONS.ZoomPlus className={styles.reset} />
          </Button>
        </div>
      )}
    </TemplateConnector>
  </Plugin>
));
