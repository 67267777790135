import type { SettingsHostess, SettingsStatus } from "../models/settings.model";
import { PositionModal } from "models/common";

export enum TypeModal {
  changesParams = "changesParams",
  infoCall = "infoCall",
  infoPhones = "infoPhones",
  changeHostess = "changeHostess",
  changeUser = "changeUser",
  confirm = "confirm",
  status = "status",
}

export interface RootState {
  activeModal?: { type: TypeModal; position: PositionModal };
  settingsHostessModal: SettingsHostess;
  settingsEditStatusModal?: SettingsStatus;
}
