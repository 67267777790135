import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import { capitalizeString } from "../../utils";
import styles from "./NewPasswordForm.module.scss";
import { FormData, useLocalizedScheme } from "./utils";
import { FormField } from "components/FormField";
import { useUpdatePasswordMutation } from "features/api/user-api";
import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { Notification } from "services/notification";
import type { ErrorResponse } from "types/commons";

interface NewPasswordFormProps {
  userId: number;
  onClose(): void;
}

export function NewPasswordForm({ userId, onClose }: NewPasswordFormProps) {
  const { validate } = useLocalizedScheme();
  const { intl, getIntlJoinedParts, getIntlCurrentUnit, getIntlNewtUnit } =
    useIntlUtils();
  const [updatePassword] = useUpdatePasswordMutation();
  const handleUpdate = useCallback(
    async (data: Required<FormData>) => {
      try {
        await updatePassword({ ...data, userId }).unwrap();
        onClose();
      } catch (e) {
        Notification.error({
          title: (e as ErrorResponse["error"])?.data?.errorMessage,
        });
        console.error(e);
      }
    },
    [userId, onClose],
  );
  return (
    <Form validate={validate} onSubmit={handleUpdate}>
      {({ handleSubmit }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          <FormField
            label={getIntlCurrentUnit(ETranslations.AUTH_PASSWORD)}
            name="old_password"
            type="password"
            required
          />
          <FormField
            autoComplete="new-password"
            label={getIntlNewtUnit(ETranslations.AUTH_PASSWORD)}
            name="new_password"
            type="password"
            required
          />
          <FormField
            autoComplete="new-password"
            label={capitalizeString(
              getIntlJoinedParts([
                ETranslations.BASE_REPEAT,
                ETranslations.AUTH_PASSWORD,
              ]).toLowerCase(),
            )}
            name="password_confirm"
            type="password"
            required
          />
          <div className={styles.actions}>
            <button className="primary" type="submit">
              {capitalizeString(
                getIntlJoinedParts([
                  ETranslations.BASE_CHANGE,
                  ETranslations.AUTH_PASSWORD,
                ]).toLowerCase(),
              )}
            </button>
            <button className={styles.cancel} type="button" onClick={onClose}>
              {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
            </button>
          </div>
        </form>
      )}
    </Form>
  );
}
