import { TelephonyAction } from "../models/telephony.model";
import { apiINT } from "./api-data";
import { AuthService } from "./auth.service";
import { RequestWrapper } from "./request-wrapper";

export class CallsService {
  static async getActiveCalls(
    restId: number,
  ): Promise<{ data: TelephonyAction[] }> {
    return RequestWrapper.fetch(`${apiINT}/calls?restId=${restId}`, {
      method: "GET",
    }).then((r) => r.json());
  }
}
