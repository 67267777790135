import { requiredField } from "components/registration/validators/validators";
import { dateSelector } from "features/AppContext";
import React, { MouseEvent } from "react";
import { Field, useField } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { ICONS, Labeled } from "ui-kit";

import styles from "../Form.module.scss";
import EachGroupFiled from "./sub-renders/EachGroupFiled";
import { InputField } from "./sub-renders/InputField";

const Settings = ({ isCreating }: { isCreating: boolean }) => {
  const {
    input: { value: rules, onChange },
  } = useField<any[]>("settings.rules");

  const startDate = useSelector(dateSelector);

  const handleAddNewRule = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onChange([
      ...rules,
      {
        rule_name: "",
        start_date: new Date(startDate.format("YYYY-MM-DD")),
        end_date: null,
        infininte: false,
        repeat_days: [],
        start_active_time: { value: 0, label: "0" },
        end_active_time: { value: 0, label: "0" },
        shiftId: "",
        places: [],
      },
    ]);
  };

  const intl = useIntl();

  return (
    <div className={styles["setting-step"]}>
      <p className={styles["title"]}>
        {intl.formatMessage({ id: ETranslations.EACH_ACCESS_DESC })}
      </p>
      <Labeled
        as="div"
        className={styles.bookingLabel}
        label={intl.formatMessage({ id: ETranslations.NAME_GROUP_OF_RULES })}
        required
      >
        <Field
          component={InputField}
          name="settings.nameOfGroup"
          placeholder={intl.formatMessage({ id: ETranslations.ENTER_NAME })}
          validate={(value) =>
            requiredField(
              value,
              intl.formatMessage({
                id: ETranslations.ERROR_DATE_NOT_SPECIFIED,
              }),
            )
          }
        />
      </Labeled>

      <div className={styles.eachGroup}>
        {rules.map((eachRule, index) => {
          return (
            <EachGroupFiled
              eachRule={{ ...eachRule, index }}
              isCreating={isCreating}
              key={index}
            />
          );
        })}

        <button className={styles.createNew} onClick={handleAddNewRule}>
          <ICONS.Plus />
          {intl.formatMessage({ id: ETranslations.CREATE_RULE })}
        </button>
      </div>
    </div>
  );
};

export default Settings;
