import styles from "./EachAccessRule.module.scss";
import EachRule from "./EachRule";
import { AccessRulesModal } from "components/shifts/Modal/AccessRulesModal/AccessRulesModal";
import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { AccessRuleData } from "types/accessRules";
import { ETranslations } from "types/translates";
import { Collapse } from "ui-kit";

const EachAccessRule = ({ name, access_rules, id }: AccessRuleData) => {
  //@ts-ignore
  const isActive = useMemo(
    () => access_rules.filter((each) => !!each.active).length > 0,
    [access_rules],
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const intl = useIntl();

  const modalOnClose = useCallback(() => {
    setModalOpen(false);
    setIsEditMode(false);
  }, []);
  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setModalOpen(() => true);
  };

  return (
    <Collapse
      expendInHeader={true}
      header={
        <div className={styles.header}>
          <span>{name} </span>
          <div className={styles.restInfo}>
            <button className={styles[isActive ? "active" : "inactive"]}>
              {intl.formatMessage({
                id: isActive
                  ? ETranslations.SHIFT_STATUS_ACTIVE
                  : ETranslations.SHIFT_STATUS_INACTIVE,
              })}
            </button>
            <span className={styles.open} onClick={handleOpen}>
              {intl.formatMessage({ id: ETranslations.BASE_INFO })}
            </span>
          </div>
        </div>
      }
      headerClassName={styles.accessRuleHeader}
      iconClassName={styles.icon}
    >
      <table className={styles.rules}>
        <tbody>
          {/* @ts-ignore */}
          {access_rules.map(
            (
              {
                rule_name,
                start_date,
                start_active_time,
                end_active_time,
                end_date,
                repeat_days,
                shift_id,
                places,
              },
              index,
            ) => {
              return (
                <EachRule
                  access_rule={{ name, access_rules, id }}
                  currentRule={{
                    rule_name,
                    index,
                    groupName: name,
                    start_active_time,
                    end_active_time,
                    repeat_days,
                    start_date,
                    shift_id,
                    places,
                    end_date,
                  }}
                  key={index}
                />
              );
            },
          )}
        </tbody>
      </table>
      <AccessRulesModal
        access_rule={{ name, access_rules, id }}
        isEditMode={isEditMode}
        isOpen={modalOpen}
        setIsEditMode={setIsEditMode}
        title={name}
        onClose={modalOnClose}
      />
    </Collapse>
  );
};

export default EachAccessRule;
