import type { FunctionComponent, ReactNode } from "react";

export interface TabItemProps<T> {
  title: ReactNode;
  tabName: T;
  children?: ReactNode;
  disabled?: boolean;
  icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export function TabItem<T>({ children }: TabItemProps<T>) {
  return <>{children}</>;
}
