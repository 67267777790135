import styles from "./Chips.module.scss";
import React from "react";
import { ICONS } from "ui-kit/ICONS";

interface IChips {
  value: string;
  background: string;
  type: "SELECTABLE" | "INITIAL" | "DELETABLE";
  textColor?: string;
  index: number;
  deleteCallBack?: (index: number, e: React.MouseEvent<SVGSVGElement>) => void;
}

const Chips = ({
  value,
  background,
  type,
  textColor,
  index,
  deleteCallBack,
}: IChips) => {
  const handleDeleteClick = (e: React.MouseEvent<SVGSVGElement>) => {
    if (typeof deleteCallBack === "function") {
      deleteCallBack(index, e);
    }
  };

  return (
    <button
      className={styles[type]}
      style={{ background, color: textColor ?? "#000" }}
    >
      {value}
      {type === "DELETABLE" && <ICONS.Cross onClick={handleDeleteClick} />}
    </button>
  );
};

export default Chips;
