import { AuthService } from "./auth.service";
import _ from "lodash";

export class RequestWrapper {
  static async fetch(input: RequestInfo, init?: RequestInit): Promise<any> {
    const req = new Request(input, init);
    req.headers.set("Content-Type", "application/json");

    const resp = await AuthService.fetchWithAuthentication(req);

    if (resp.status === 500) throw resp;

    return resp;
  }
}
