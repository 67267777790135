import dayjs from "dayjs";
import { usAccessRulesActions } from "features/AccessRules/slice";
import { restaurantSelector } from "features/AppContext";
import {
  useCreateAccessRuleMutation,
  useEditAccessRuleMutation,
  useRemoveAccessRuleMutation,
} from "features/api/access_rules";
import useClosestInterval from "hooks/useClosestInterval";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";

type DateObject = Record<string, string | Date | TimeLabel>;

interface TimeLabel {
  label: string;
  value: number;
}

export function convertDatesToObjects<T extends DateObject>(
  data: T[],
): Array<DateObject> {
  return data.map((item) => {
    const newItem: DateObject = {};
    Object.entries(item).forEach(([key, value]) => {
      if (key === "start_date") {
        newItem[key] = new Date(value as string);
      } else if (key === "end_date") {
        const isInfinite = dayjs(value as string).valueOf() >= 2208974400000;
        if (isInfinite) {
          newItem[key] = "";
          //@ts-ignore
          newItem.infininte = true;
        } else {
          newItem[key] = new Date(value as string);
        }
      } else if (key === "start_active_time" || key === "end_active_time") {
        const startDate = new Date(item["start_date"] as string);
        const timeValue = new Date(`1970-01-01T${value as string}`).getTime();
        const timestamp = startDate.getTime() + timeValue;
        const formattedTime = dayjs(timestamp).format("HH:mm");
        newItem[key] = { label: formattedTime, value: timestamp } as TimeLabel;
      } else {
        newItem[key] = value;
      }
    });
    return newItem;
  });
}

const useAccessRuleForm = (
  access_rule: any,
  setIsEditMode: any,
  onClose: any,
) => {
  const intl = useIntl();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteAR] = useRemoveAccessRuleMutation();
  const [create] = useCreateAccessRuleMutation();
  const [edit] = useEditAccessRuleMutation();
  const [responseError, setResponseError] = useState("");
  const { reset } = usAccessRulesActions();

  const handleRemoveResponseError = () => {
    setResponseError("");
  };

  const toggleOpenDeleteModal = () => setOpenDeleteModal((state) => !state);
  const toggleOpensSave = () => setOpenEditModal((state) => !state);
  const { id } = access_rule ?? {};
  const handleDeleteAR = () => {
    deleteAR(id);
    setIsEditMode(false);
    toggleOpenDeleteModal();
  };

  const tabs = useMemo(() => {
    return [
      {
        name: "settings",
        text: intl.formatMessage({ id: ETranslations.SETTINGS }),
        id: 1,
      },
      // {name: "capicacity", text: "Вместимость", id: 2},
      // {name: "widget", text: "Виджет", id: 3},
    ];
  }, []);

  const closestInterval = useClosestInterval();

  const initalValue = useMemo(() => {
    if (access_rule) {
      const rules = access_rule.access_rules;
      return {
        settings: {
          id: access_rule.id,
          nameOfGroup: access_rule.name,
          rules: convertDatesToObjects(rules),
        },
      };
    } else {
      return {
        settings: {
          nameOfGroup: "",
          rules: [],
        },

        capicacity: {
          min: 1,
          max: undefined,
          maxPerShift: undefined,
        },

        widget: {
          authType: {},
          bookType: {},
        },
      };
    }
  }, [closestInterval]);

  const restaurant = useSelector(restaurantSelector);

  const onSubmit = useCallback(async (formData) => {
    const { settings } = formData;
    const { restaurant_id } = restaurant;
    const { nameOfGroup, rules, id: access_rule_id } = settings;

    if (access_rule?.id) {
      edit({
        id: access_rule_id,
        body: {
          name: nameOfGroup,
          restaurant_id,
          access_rules: [
            ...rules.map((each: any) => {
              return {
                rule_name: each.rule_name,
                shift_id: each.shift_id.value,
                start_date: dayjs(each.start_date).format("YYYY-MM-DD"),
                end_date: each.infininte
                  ? "2040-01-01"
                  : dayjs(each.end_date).format("YYYY-MM-DD"),
                start_active_time: `${each.start_active_time.label}:00`,
                end_active_time: `${each.end_active_time.label}:00`,
                repeat_days: each.repeat_days,
                places_id: each.places,
              };
            }),
          ],
          use_max_covered: true,
          max_covered_size: 1,
          party_min_person: 1,
          party_max_person: 1,
        },
      }).then((res: any) => {
        if (res.data) {
          onClose();
          return;
        }

        if (res.error) {
          setResponseError(res.error.data.errorMessage);
        }
      });
      return;
    }
    create({
      name: nameOfGroup,
      restaurant_id,
      access_rules: [
        ...rules.map((each: any) => {
          return {
            rule_name: each.rule_name,
            shift_id: each.shift_id.value,
            start_date: dayjs(each.start_date).format("YYYY-MM-DD"),
            end_date: each.infininte
              ? "2040-01-01"
              : dayjs(each.end_date).format("YYYY-MM-DD"),
            start_active_time: `${each.start_active_time.label}:00`,
            end_active_time: `${each.end_active_time.label}:00`,
            repeat_days: each.repeat_days,
            places_id: each.places,
          };
        }),
      ],
      use_max_covered: true,
      max_covered_size: 1,
      party_min_person: 1,
      party_max_person: 1,
    }).then((res: any) => {
      if (res.data) {
        onClose();
        reset();
        return;
      }

      if (res.error) {
        setResponseError(res.error.data.errorMessage);
      }
    });
  }, []);

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const onToggleEdit = () => {
    setIsEditMode(true);
  };

  return {
    initalValue,
    onSubmit,
    activeTab,
    setActiveTab,
    intl,
    tabs,
    onToggleEdit,
    handleDeleteAR,
    openDeleteModal,
    toggleOpenDeleteModal,
    toggleOpensSave,
    openEditModal,
    responseError,
    handleRemoveResponseError,
  };
};

export default useAccessRuleForm;
