import ManagerialModalError from "components/ManagerialTables/form/ManagerialModalError";
import { ManagerialTablesForm } from "components/ManagerialTables/form/ManagerialTablesForm";
import { getTimeOption } from "components/registration/forms/utils";
import dayjs from "dayjs";
import { useFromProxyActions } from "features/BookingFormProxy";
import { useHallSchemaActions } from "features/HallSchema";
import { invalidateHallSlots } from "features/api/hallschema-api";
import { useEditManagerialTableMutation } from "features/api/managerialTables";
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { Card } from "ui-kit";

import type { ManagementDetailsContext } from "../DetailsHeader/ManagementDetailsHeader";

export const ManagementInfo = () => {
  const management = useOutletContext<ManagementDetailsContext>();
  const { reset: closeEdit, setEditMode } = useFromProxyActions();
  const [value, setValue] = useState({ start_date: undefined });
  const [managerialError, setManagerialError] = useState<string>("");
  const [editManagerlBooking] = useEditManagerialTableMutation();
  const dispatch = useDispatch();
  const { setActiveTables } = useHallSchemaActions();

  const onSubmit = useCallback(
    async (values) => {
      const { tables, time, end_date, bookingId, visit_time, duration } =
        values;
      const payload = {
        date: end_date,
        time: `${dayjs(time.value).format("HH:mm:ss")}`,
        table_ids: tables,
        duration,
        visit_time,
      };
      editManagerlBooking({ id: bookingId, payload }).then((res) => {
        // @ts-ignore
        if (res?.error) {
          // @ts-ignore
          if (res.error.data.errorCode === 10100) {
            //@ts-ignore
            setManagerialError(res.error.data.errorMessage);
            setActiveTables({
              activeTables: management.slot_place.map(
                (place) => place.table_id,
              ) as number[],
            });
          }
        } else {
          dispatch(invalidateHallSlots());
          setEditMode(false);
        }
      });
    },
    [management],
  );

  const closeErrorModal = () => {
    setManagerialError("");
  };

  useEffect(() => {
    setValue({
      //@ts-ignore
      start_date: management?.date,
      end_date: management?.date,
      time: getTimeOption(management?.time),
      duration: management?.visit_duration,
      tables: management?.slot_place.map((place) => place.table_id),
      placeId: management?.slot_place[0]?.place_id,
      date: new Date(`${management?.date} ${management?.time}`),
      persons: 1,
      bookingId: management?.slot_id,
    });
  }, [management]);

  return (
    <Card onClose={closeEdit}>
      {value?.start_date ? (
        <Form
          initialValues={value}
          render={() => {
            return <ManagerialTablesForm editBooking />;
          }}
          onSubmit={onSubmit}
        />
      ) : null}
      {!!managerialError && (
        <ManagerialModalError
          isOpen={!!managerialError}
          text={managerialError}
          onClose={closeErrorModal}
        />
      )}
    </Card>
  );
};
