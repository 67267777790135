import dayjs from "dayjs";
import { restaurantSelector } from "features/AppContext";
import { useFetchShiftsListQuery } from "features/api/shifts";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { EDaysOfWeek } from "types/commons";
import { ETranslations } from "types/translates";

import styles from "./AccessRuleInfo.module.scss";

/* const jsCodeString = `import requests

// URL API, к которому вы хотите подключиться
var api_url = "https://example.com/api/users";

try {
    // Выполняем GET-запрос к API
    var response = requests.get(api_url);

    // Проверяем, успешно ли выполнен запрос (код 200 обозначает успех)
    if (response.status_code === 200) {
        // Распарсим JSON-ответ, если API возвращает данные в формате JSON
        var data = response.json();

        // Теперь у вас есть доступ к данным, которые вернуло API
        console.log(data);
    } else {
        // Если запрос не успешен, выведите сообщение об ошибке
        console.log("Ошибка при выполнении запроса: " + response.status_code);
    }
} catch (e) {
    // Обработка исключений, связанных с сетью
    console.log("Произошла ошибка сети: " + e);
}`; */

const AccessRuleInfo = ({ access_rule }: any) => {
  const { access_rules } = access_rule;
  const intl = useIntl();
  /* 
  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = jsCodeString;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    Notification.success({
      title: "Данные скопированные в буфер обмена",
    });
  }; */
  const { places: restPLaces, restaurant_id } = useSelector(restaurantSelector);
  const { data: shifts } = useFetchShiftsListQuery(restaurant_id);
  return (
    <div className={styles.container}>
      {/* <span className={styles.title}>{intl.formatMessage({id: ETranslations.CAPACITY})}</span> */}
      {/* <div className={styles.infoBlock}>
        <span className={styles.key}>Макс. броней на смену</span>
        <span></span>
        <span className={styles.value}>30</span>
        <span className={styles.key}>min</span>
        <span></span>
        <span className={styles.value}>1</span>
        <span className={styles.key}>max</span>
        <span></span>
        <span className={styles.value}>2</span>
      </div>
      <span className={styles.title}>Виджет</span>
      <div className={styles.infoBlock}>
        <span className={styles.key}>Тип авторизации</span>
        <span></span>
        <span className={styles.value}>Телефон</span>
        <span className={styles.key}>Тип бронирования</span>
        <span></span>
        <span className={styles.value}>Заявка</span>
      </div>
      <span className={styles.title}>API</span>
      <div className={styles.APIBlock}>
        <button className={styles.copy} onClick={copyToClipboard}>
          <CopyIcon />
          Копировать
        </button>
        <Textarea required label="Какое то название" readOnly value={jsCodeString} />
      </div> */}
      {access_rules.map(
        (
          {
            rule_name,
            repeat_days,
            start_date,
            end_date,
            start_active_time,
            end_active_time,
            places,
            shift_id,
          }: any,
          index: number,
        ) => {
          // @ts-ignore
          const repeatDaysValue = repeat_days
            ?.map((each: EDaysOfWeek) =>
              dayjs().isoWeekday(Number(EDaysOfWeek[each])).format("dd"),
            )
            .join(", ");
          const endDate =
            dayjs(end_date).valueOf() >= 2208974400000
              ? intl.formatMessage({ id: ETranslations.INFINITY })
              : `${dayjs(end_date).locale("ru").format("D/MM/YYYY")}`;
          const currentShift = shifts?.data.find(
            (each) => each.id === shift_id,
          );
          return (
            <div key={index} style={{ marginTop: "12px" }}>
              <span className={styles.title}>{rule_name}</span>
              <div className={styles.infoBlock} key={index}>
                <div className={styles.line}>
                  <span className={styles.key}>
                    {intl.formatMessage({ id: ETranslations.DAYS })}
                  </span>
                  <span></span>
                  <span className={styles.value}>{repeatDaysValue}</span>
                </div>
                <div className={styles.line}>
                  <span className={styles.key}>
                    {intl.formatMessage({ id: ETranslations.START_DATE })}
                  </span>
                  <span></span>
                  <span className={styles.value}>
                    {dayjs(start_date).format("D/MM/YYYY")}
                  </span>
                </div>
                <div className={styles.line}>
                  <span className={styles.key}>
                    {intl.formatMessage({ id: ETranslations.END_DATE_ONE })}
                  </span>
                  <span></span>
                  <span className={styles.value}>{endDate}</span>
                </div>
                <div className={styles.line}>
                  <span className={styles.key}>
                    {intl.formatMessage({ id: ETranslations.PERIOD_TIME })}
                  </span>
                  <span></span>
                  <span className={styles.value}>
                    {`${start_active_time.slice(0, -3)} - ${end_active_time.slice(0, -3)}`}
                  </span>
                </div>
                <div className={styles.line}>
                  <span className={styles.key}>
                    {intl.formatMessage({ id: ETranslations.HALLS })}
                  </span>
                  <span></span>
                  <span className={styles.value}>
                    {restPLaces
                      .filter((place) => places.includes(place.id))
                      .map((each) => each.name)
                      .join(", ")}
                  </span>
                </div>
                <div className={styles.line}>
                  <span className={styles.key}>
                    {intl.formatMessage({
                      id: ETranslations.SETTINGS_TITLE_SHIFTS,
                    })}
                  </span>
                  <span></span>
                  <span className={styles.value}>
                    {currentShift?.shift_name}
                  </span>
                </div>
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};

export default AccessRuleInfo;
