import { useIntlUtils } from "hooks/useIntlUtils";
import { FunctionComponent } from "react";
import ReactModal, { Props as ReactModalProps } from "react-modal";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";
import { ErrorWarning } from "ui-kit/ICONS/icons";

import "./Modal.scss";

export interface ModalProps
  extends Omit<ReactModalProps, "onAfterClose" | "onRequestClose"> {
  onClose(): void;

  text?: string;
}

type Props = {} & Omit<ModalProps, "children">;

const parentSelector = () => document.body;

const ManagerialModalError: FunctionComponent<Props> = ({
  onClose,
  isOpen,
  text = "",
  ...props
}) => {
  const { intl } = useIntlUtils();

  return (
    <ReactModal
      className="modal"
      closeTimeoutMS={0}
      isOpen={isOpen}
      overlayClassName="modalOverlay"
      parentSelector={parentSelector}
      portalClassName="modalPortal"
      role="dialog"
      shouldFocusAfterRender={false}
      ariaHideApp
      preventScroll
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldReturnFocusAfterClose
      onRequestClose={onClose}
      {...props}
    >
      <ErrorWarning />
      <div className="textContent">
        <div className="title">
          {intl.formatMessage({ id: ETranslations.MANAGEMENT_ERROR_TITLE })}
        </div>
        <div className="text">
          {text ||
            intl.formatMessage({ id: ETranslations.MANAGEMENT_ERROR_TEXT })}
        </div>

        <div className="buttonBox">
          <Button type="submit" variant="primary" onClick={onClose}>
            OK
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ManagerialModalError;
