import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { ETranslations } from "../../../types/translates";
import { ModalControls } from "./ModalControls";
import { EditStatusForm } from "./edit-status-form/edit-status-form";
import { Palette } from "./edit-status-form/palette";
import { getColor } from "./utils";
import { ICONS } from "common/helpers";
import { extraStatusColors } from "constants/colors";
import { useEditStatusMutation } from "features/api/dictionaries-api";
import type { Status, StatusId } from "types/status";
import { Card } from "ui-kit";
import { transformLocale } from "utils";

export const EditStatus = ({
  closeModal,
  intlUtils,
  statuses,
  statusId,
  editStatus,
  isLoading,
}: {
  closeModal: (isForceUpdate?: boolean) => void;
  intlUtils: ReturnType<typeof useIntlUtils>;
  statuses: Status[];
  statusId: StatusId;
  editStatus: ReturnType<typeof useEditStatusMutation>[0];
  isLoading: boolean;
}) => {
  const currentStatus = statuses.find(
    (status) => status.id === statusId && status.is_extra,
  );

  if (!currentStatus) return null;

  const currentColor = getColor(currentStatus) || extraStatusColors[0];

  const {
    intl: { formatMessage, locale },
  } = intlUtils;

  const intlTitles = {
    statusLabel: intlUtils.getIntlStatusAlt(ETranslations.TITLE),
    statusEdit: intlUtils.getIntlStatusAlt(
      ETranslations[locale === "en" ? "BASE_EDIT" : "PLURAL_CHANGE"],
    ),
    statusColor: intlUtils.getIntlStatusAlt(ETranslations.COLOR),
  };

  const formName = "status-edit";
  return (
    <div
      className="changeStatus"
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Card.Header
        controls={
          <button onClick={() => closeModal()}>
            <img alt="" src={ICONS.crossGrey} />
          </button>
        }
        title={<span className="modalTitle">{intlTitles.statusEdit}</span>}
      />
      <EditStatusForm
        currentStatus={currentStatus}
        formatMessage={formatMessage}
        formName={formName}
        label={intlTitles.statusLabel}
        labelClassName={undefined}
        locale={transformLocale(locale)}
        statuses={statuses}
        onValidSubmit={(payload) =>
          editStatus(payload).then((data) => "data" in data && closeModal())
        }
      >
        <Palette
          currentColor={currentColor}
          formatMessage={formatMessage}
          title={intlTitles.statusColor}
        />
      </EditStatusForm>
      <ModalControls
        cancelButtonText={formatMessage({
          id: ETranslations.CANCEL,
        })}
        className="modalControls"
        disabled={isLoading}
        formName={formName}
        submitButtonText={formatMessage({
          id: ETranslations.SAVE,
        })}
        onCancel={() => closeModal()}
      />
    </div>
  );
};
