import styles from "./ShiftsTable.module.scss";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";

export const TableHead: FC = () => {
  const intl = useIntl();

  return (
    <thead className={styles.header}>
      <tr>
        <th>{intl.formatMessage({ id: ETranslations.SHIFTS_NAME })}</th>
        <th>{intl.formatMessage({ id: ETranslations.SHIFT_TIME })}</th>
        <th>{intl.formatMessage({ id: ETranslations.SHIFT_DATES })}</th>
        <th>{intl.formatMessage({ id: ETranslations.DAYS })}</th>
        <th>{intl.formatMessage({ id: ETranslations.PLURAL_STATUS })}</th>
      </tr>
    </thead>
  );
};
