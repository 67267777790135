import cn from "classnames";
import type { ElementType } from "react";

import styles from "./Card.module.scss";
import { CloseCardContext } from "./useCloseCard";

export type CardProps = JSX.IntrinsicElements["div"] & {
  as?: ElementType;
  onClose?: () => void;
  layer?: 1 | 2 | 3;
  shadowed?: boolean;
};

export const Card = ({
  className,
  onClose,
  layer = 1,
  shadowed,
  as,
  ...props
}: CardProps) => {
  const Element = as || "div";
  return (
    <CloseCardContext.Provider value={onClose}>
      <Element
        {...props}
        className={cn(
          styles.card,
          styles[`layer${layer}` as keyof typeof styles],
          { [styles.shadowed]: shadowed },
          className,
        )}
      />
    </CloseCardContext.Provider>
  );
};
