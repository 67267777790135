import { ETranslations } from "../../../types/translates";
import { useFormVisitTimes } from "../hooks/useFormVisitTimes";
import useChoiceVisitTimeSelectors from "./hooks/useChoiceVisitTimeSelectors";
import styles from "./style.module.scss";
import cn from "classnames";
import _ from "lodash";
import React, { useMemo } from "react";
import { FieldRenderProps } from "react-final-form";
import { useIntl } from "react-intl";
import { ICONS, RadioButton, SelectBasic } from "ui-kit";

const ChoiceVisitTimeSelectors = ({
  value,
  onChange,
  onBlur,
  disabled,
  touched,
  newDesign = false,
  isFromManagement = false,
}: any) => {
  const {
    isCreateBooking,
    getHours,
    handleHour,
    getHourOptions,
    intl,
    getMinutes,
    handleMinute,
    getMinuteOptions,
    isTablet,
  } = useChoiceVisitTimeSelectors({
    value,
    onChange,
    touched,
    onBlur,
    disabled,
    isFromManagement,
  });

  return (
    <div
      className={cn("hours-mins", {
        [styles.hoursMins]: newDesign && isCreateBooking && !isFromManagement,
        [styles.hoursMinsEdit]:
          (newDesign && !isCreateBooking) || isFromManagement,
      })}
    >
      <SelectBasic
        isDisabled={disabled}
        menuPlacement={isTablet ? "top" : "bottom"}
        options={getHourOptions()}
        placeholder={intl.formatMessage({ id: ETranslations.PLURAL_HOURS_NOM })}
        value={getHours()}
        changedMaxSize
        onChange={handleHour}
      />
      <SelectBasic
        isDisabled={disabled}
        menuPlacement={isTablet ? "top" : "bottom"}
        options={getMinuteOptions()}
        placeholder={intl.formatMessage({
          id: ETranslations.PLURAL_MINUTES_NOM,
        })}
        value={getMinutes()}
        changedMaxSize
        onChange={handleMinute}
      />
    </div>
  );
};

const ChoiceVisitTimeButtons = ({
  value,
  onChange,
  disabled,
  newDesign = false,
}: any) => {
  const { options } = useFormVisitTimes();
  const intl = useIntl();
  const noLimit = useMemo(() => _.last(options), [options]);

  const defaultIsNotAvailable = useMemo(
    () => !options.includes(120),
    [options],
  );

  return (
    <RadioButton
      className={styles.radioButtons}
      disabled={disabled || defaultIsNotAvailable}
      value={value}
      onChange={onChange}
    >
      <RadioButton.Button className={styles.infinity} value={noLimit}>
        {newDesign ? (
          <ICONS.InfinityLogo fill="var(--gl_text_constant_primary2)" />
        ) : (
          intl.formatMessage({ id: ETranslations.NO_LIMIT })
        )}
      </RadioButton.Button>
    </RadioButton>
  );
};

export const ChoiceVisitTimeButtonsInput: React.FC<
  FieldRenderProps<string, any>
> = ({ input, meta, ...rest }) => (
  <ChoiceVisitTimeButtons {...input} {...meta} {...rest} />
);

export const ChoiceVisitTimeControls: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => <ChoiceVisitTimeSelectors {...input} {...meta} {...rest} />;
