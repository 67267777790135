import styles from "./style.module.scss";
import cn from "classnames";
import { CSSProperties, FC } from "react";
import { ICONS } from "ui-kit";

interface Props {
  alerts: {
    isOverbooking: boolean;
    isVip: boolean;
    isEndingSoon: boolean;
    isManagerial: boolean;
    isDeposit: boolean;
  };
  style?: CSSProperties;
}

export const BottomBlock: FC<Props> = ({ alerts, style }) => (
  <section className={styles.notifications} style={style} data-no-d3-zoom>
    {alerts.isManagerial && (
      <div className={cn(styles.iconWrapper, styles.lock)}>
        <ICONS.LockIcon height={18} width={18} />
      </div>
    )}
    {alerts.isDeposit && (
      <div className={cn(styles.iconWrapper, styles.deposit)}>$</div>
    )}
    {alerts.isOverbooking && (
      <div className={cn(styles.iconWrapper, styles.overbooking)}>!</div>
    )}
    {alerts.isVip && (
      <div className={styles.iconWrapper}>
        <ICONS.VipSign height={20} width={20} />
      </div>
    )}
    {alerts.isEndingSoon && <ICONS.ClockCircleScheme height={24} width={24} />}
  </section>
);
