import { restaurantSelector } from "features/AppContext";
import {
  fromProxySelectors,
  useFromProxyActions,
} from "features/BookingFormProxy";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { hallModeSelector } from "features/HallSchema/selectors";
import { tableBookingsTableSelector } from "features/TableBooking/selectors";
import { useTableBookingListActions } from "features/TableBooking/slice";
import { timelineSelectors, useTimelineActions } from "features/Timeline";
import { useIsTablet } from "hooks/useIsTablet";
import { type Client } from "models/client.model";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUnmount, useUpdateEffect } from "react-use";
import { isManagerialTable } from "utils";

import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import styles from "./Dashboard.module.scss";

export enum Tab {
  Edit = "Edit",
  History = "History",
  Chat = "Chat",
}

const useDashBoards = () => {
  const { getIntlEntityViewingAction, intl, getIntlHistoryOf } = useIntlUtils();
  const { pathname } = useLocation();
  const isRootDashboard =
    pathname === "/dashboard" || pathname === "/dashboard/";

  const emptyMessage = useMemo(
    () => (
      <div className={styles.emptyContainer}>
        <div className={styles.icon} />
        <p>
          {getIntlEntityViewingAction(
            ETranslations.OF_BOOKING,
            ETranslations.PLURAL_ALT_BOOKING,
          )}
        </p>
      </div>
    ),
    [],
  );

  const isTablet = useIsTablet();
  const {
    setOnlyBooking: setFormBooking,
    setClient,
    reset,
  } = useFromProxyActions();
  const { switchMode, reset: resetBooking } = useHallSchemaActions();
  const { resetTimeShift } = useTimelineActions();
  const timestamp = useSelector(timelineSelectors.getTimestamp);
  const booking = useSelector(fromProxySelectors.booking);
  const rest = useSelector(restaurantSelector);
  const hasBooking = useMemo(() => !!booking, [booking]);
  const mode = useSelector(hallModeSelector);
  const [tab, setTab] = useState<string>(Tab.Edit);
  const table = useSelector(tableBookingsTableSelector);
  const { reset: resetTable } = useTableBookingListActions();

  useEffect(() => {
    switchMode(HallMode.TABLES);
    if (table) {
      resetTable();
    }
  }, []);

  const close = useCallback(() => {
    resetTimeShift();
    setFormBooking(null);
  }, [setFormBooking]);

  // условия проверки откытости формы создания брони
  const isCreatingBooking = useMemo(
    () =>
      [
        HallMode.MANAGERIAL_CREATE_BOOKING,
        HallMode.MANAGERIAL_OPEN_GUESTS,
        HallMode.MANAGERIAL_HALL,
        HallMode.MANAGERIAL_HALL_BOOKING,
      ].includes(mode),
    [mode],
  );

  // изменения мода при закрытии схемы зала
  const closeHall = useCallback(() => {
    if (mode === HallMode.MANAGERIAL_HALL_BOOKING) {
      switchMode(HallMode.MANAGERIAL_CREATE_BOOKING);
    } else {
      switchMode(HallMode.TABLES);
    }
  }, [switchMode, mode]);

  // изменения мода при закрытии списка гостей
  const closeGuests = useCallback(() => {
    if (
      [
        HallMode.MANAGERIAL_OPEN_GUESTS,
        HallMode.MANAGERIAL_CREATE_BOOKING,
      ].includes(mode)
    ) {
      switchMode(HallMode.MANAGERIAL_CREATE_BOOKING);
      return;
    } else {
      switchMode(HallMode.TABLE_BOOKINGS_EDIT);
    }
  }, [switchMode, mode]);

  const handleSetClient = useCallback(
    (client: Client) => {
      setClient({ client });
      closeGuests();
    },
    [setClient, closeGuests],
  );

  useUnmount(() => {
    switchMode(HallMode.TABLES);
    resetTimeShift();
  });

  const isManagerialTableBooking = useMemo(() => {
    return isManagerialTable(booking as any);
  }, [booking]);

  const isGuestMode = mode.includes("GUEST");

  useUpdateEffect(() => {
    reset();
  }, [rest]);

  return {
    hasBooking,
    isManagerialTableBooking,
    booking,
    emptyMessage,
    timestamp,
    isTablet,
    isCreatingBooking,
    mode,
    resetBooking,
    closeHall,
    intl,
    closeGuests,
    handleSetClient,
    isGuestMode,
    tab,
    getIntlHistoryOf,
    setTab,
    close,
    isRootDashboard,
  };
};

export default useDashBoards;
