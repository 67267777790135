import type { ClientNoteDTO } from "models/notes.model";
import type { UserId } from "models/user.model";
import type { ReactNode } from "react";
import type { IntlShape } from "react-intl";
import { ICONS } from "ui-kit";

import { Actions } from "./Actions";
import styles from "./GuestNotes.module.scss";
import { NoteContent } from "./NoteContent";
import { formateDate } from "./utils";

const Note = ({
  photo,
  authorName,
  createdAt,
  restaurantName,
  noteText,
  id,
  children,
}: {
  photo: string;
  authorName: string;
  createdAt: string;
  restaurantName: string;
  noteText: string;
  id: number;
  children: ReactNode;
}) => (
  <article className={styles.note}>
    <img
      alt="User Avatar"
      className={styles.avatar}
      src={photo || ICONS.defaultUser}
    />
    <NoteContent
      authorName={authorName}
      className={styles.content}
      createdAt={createdAt}
      id={id}
      restaurantName={restaurantName}
      text={noteText}
    />
    {children}
  </article>
);

export const NoteList = ({
  notes,
  children,
  onEdit,
  userId,
  formatMessage,
}: {
  notes: ClientNoteDTO[];
  children: ReactNode;
  onEdit: (noteId: number, note: string) => void;
  formatMessage: IntlShape["formatMessage"];
  userId?: UserId;
}) => (
  <section className={styles.main}>
    <div className={styles.notes}>
      {notes.map(
        (note) =>
          note.notes !== "test—delete" && (
            <Note
              authorName={note.created_by.name}
              createdAt={formateDate(note.created_at, formatMessage)}
              id={note.id}
              key={`${note.id}-${note.notes.length}`}
              noteText={note.notes}
              photo={note.created_by.photo}
              restaurantName={note.restaurant_name}
            >
              {/* @ts-ignore */}
              {userId === note.created_by.id && (
                <Actions
                  className={styles.actions}
                  editNote={() => onEdit(note.id, note.notes)}
                />
              )}
            </Note>
          ),
      )}
    </div>
    {children}
  </section>
);
