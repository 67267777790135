import styles from "./GuestNotes.module.scss";
import { ICONS } from "ui-kit";

export const Actions = ({
  className,
  editNote,
}: {
  className: string;
  editNote: () => void;
}) => (
  <section className={className}>
    <button onClick={editNote}>
      <ICONS.Edit className={styles.action} />
    </button>
    {/*  <div className={styles.divider} />
    <button onClick={deleteNote}>
      <ICONS.TrashBin className={styles.action} />
    </button> */}
  </section>
);
