/*
    ____,-------------------------------,____
    \   |           Сущности            |   /
    /___|-------------------------------|___\
*/

export type Dialog = {
  id: string;
  created_at: string;
  closed_at?: string;
  status: DialogStatus;
  customer: DialogCustomer;
  partner?: DialogPartner;
  channels: string[];
  tasks?: DialogTask[];
  user?: DialogOperator;
  watchers: DialogOperator[];
  templates_only: boolean;
  unanswered_count: number;
  unseen_count: number;
  overdue: boolean;
  unseen_from: string;
  unanswered_from: string;
  attention: boolean;
  watch: boolean;
  meta?: { sender?: string };
  last_customer_message_at?: string;
};

export enum DialogStatus {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
}

export type DialogTask = {
  id: string;
  type: DialogTaskType;
  customer_id: string;
  title: string;
  status: string;
  close_state: string;
  created_at: string;
  deadline: string;
  deadline_info_delivery: string;
  description: string;
  responsible: DialogOperator;
  link_url: string;
};

export type DialogTaskType = {
  id: string;
  parent: string;
  name: string;
  //
  icon: string;
};

export type DialogCustomer = {
  id: string;
  name: string;
  //
  type: DialogCustomerType;
  project: DialogProject;
  link_url: string;
  level?: string;
  level_color?: string;
};

export type DialogOperator = {
  id: string;
  name: string;
};

export type DialogCustomerType = {
  id: string;
  name: string;
  //
  priority: boolean;
  project: DialogProject;
};

export type DialogProject = {
  id: string;
  name: string;
};

export type DialogPartner = {
  id: string;
  guid: string;
  name: string;
  address?: string;
};

export type DialogNotification = {
  type: string;
  message: string;
  description: string;
};

export type DialogsFilter = {
  projects: string[];
  partners: string[];
  customerTypes: string[];
  taskTypes: string[];
  status: DialogStatus;
  kind: DialogFilterKind;
};

export enum DialogFilterKind {
  MY = "MY",
  MY_CUSTOMERS = "MY_CUSTOMERS",
  ALL = "ALL",
}

export enum DialogMessageType {
  "TEXT" = "TEXT",
  "IMAGE" = "IMAGE",
  "VOICEMESSAGE" = "VOICEMESSAGE",
  "VIDEO" = "VIDEO",
  "TASK_LINK" = "TASK_LINK",
  "DOC" = "DOC",
  "IFRAME" = "IFRAME",
  "TEMPLATE" = "TEMPLATE",
  "POSTBACK" = "POSTBACK",
  "SMS" = "SMS",
  "EMAIL" = "EMAIL",
  "CONTACT" = "CONTACT",
  "LOCATION" = "LOCATION",
}

export enum DialogMessageStatus {
  "NEW" = "NEW",
  "SENT" = "SENT",
  "RESERVED" = "RESERVED",
  "SEEN" = "SEEN",
  "DELETED" = "DELETED",
}

export enum DialogMessageSource {
  TELEGRAM = "TELEGRAM",
  FACEBOOK = "FACEBOOK",
  VIBER = "VIBER",
  WHATSAPP = "WHATSAPP",
  TINKOFF = "TINKOFF",
  PRIMEPARK = "PRIMEPARK",
  FRONTLINE = "FRONTLINE",
  CHAT = "CHAT",
  SMS = "SMS",
  EMAIL = "EMAIL",
  CRM = "CRM",
}

export enum DialogChannels {
  CHAT = "CHAT",
  SMS_MSG = "SMS_MSG",
  WHATSAPP = "WHATSAPP",
  TELEGRAM = "TELEGRAM",
  TINKOFF = "TINKOFF",
  EMAIL = "EMAIL",
}

export type DialogsMessage = {
  guid: string; // идентификатор сообщения
  sender_id: string; // идентификатор отправителя: U{userID} - сотрудник, С{customerID} - клиент
  sender_name?: string; // имя отправителя
  channel_id: string; // идентификатор канала
  timestamp: number; // время отправки сообщения
  status: DialogMessageStatus; // статус сообщения
  source?: DialogMessageSource; // источник сообщения
  delivered_to?: DialogMessageSource; // канал доставки сообщения
  ttl?: number; // время жизни
  meta?: // доп. информация
  {
    updated_by: string;
    subject?: string;
    sender?: string;
    sentFrom?: string;
    sentTo?: string;
  };
  reply_to?: string; // идентификатор сообщения на которое ответили, если ответили
} & DialogsMessageByType;

export type DialogsMessageByType =
  | {
      type: DialogMessageType.TASK_LINK;
      content_object: {
        task: {
          completed: boolean;
          customerId: number;
          description: string;
          id: number;
          name: string;
          requestDate: string;
          reserved: boolean;
          responsibleId: number;
          startServiceDate: string;
          taskId: number;
          taskType: { id: string; name: string };
        };
        message?: {
          body: {
            chatId: string;
            clientId: string;
            content: string;
            messageId: string;
            messageType: number;
            status: number;
            timestamp: number;
            ttl: number;
          };
          guid: string;
          requestId: number;
          source: DialogMessageSource;
          type: number;
        };
      };
      content_text?: string;
    }
  | {
      type: DialogMessageType.DOC | DialogMessageType.IMAGE;
      content_object: {
        name: string;
        size: number;
      };
      content_text?: string;
    }
  | {
      type:
        | DialogMessageType.TEXT
        | DialogMessageType.VOICEMESSAGE
        | DialogMessageType.VIDEO
        | DialogMessageType.EMAIL;
      content_text: string;
    };

export type SendMessagePayload = {
  reply_to?: string;
  resend_to?: DialogChannels[];
  resend_to_phones?: string[];
  subject?: string;
  template_id?: string;
  text: string;
  to_email?: string;
  type: DialogMessageType.TEXT | DialogMessageType.EMAIL;
};
