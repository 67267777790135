import cn from "classnames";
import Checkbox from "common/components/checkbox";
import { BookingsList } from "components/BookingsList";
import { RestaurantBookingStats } from "components/RestaurantBookingStats";
import { SortedBookingsList } from "components/SortedBookingsList";
import { config } from "config";
import React, { CSSProperties } from "react";
import type { ShortLocales } from "types/commons";
import { ETranslations } from "types/translates";
import { Card, RadioButton } from "ui-kit";
import { transformLocale } from "utils";

import { BookingFilters } from "./BookingFilters";
import { BookingListCollapse } from "./BookingListCollapse";
import styles from "./DashboardBookings.module.scss";
import UseDashboardBookings from "./UseDashboardBookings";

interface BookingProps {
  style?: CSSProperties;
}

export const DashboardBookings: React.FC<BookingProps> = ({ style }) => {
  const {
    intl,
    bookings,
    statistics,
    isManageableTableSelected,
    setIsManageableTableSelected,
    bookingMode,
    setBookingMode,
    isLoading,
    extraStatusFilter,
    searchQuery,
    includedStatuses,
    waitList,
    setBookingsData,
  } = UseDashboardBookings();

  return (
    <Card id="booking" style={style}>
      <Card.Header
        controls={
          <div className={styles.checkboxContainer}>
            <Checkbox
              text={intl.formatMessage({
                id: ETranslations.MANAGEMENT_RESERVATION,
              })}
              value={isManageableTableSelected}
              onChange={setIsManageableTableSelected}
            />
            <RadioButton value={bookingMode} onChange={setBookingMode}>
              <RadioButton.Button value="active">
                {intl.formatMessage({ id: ETranslations.BASE_ACTIVE })}
              </RadioButton.Button>
              <RadioButton.Button value="complete">
                {intl.formatMessage({ id: ETranslations.BASE_HISTORY })}
              </RadioButton.Button>
            </RadioButton>
          </div>
        }
        title={
          <RestaurantBookingStats
            stats={statistics}
            title={intl.formatMessage({ id: ETranslations.BASE_BOOKINGS })}
          />
        }
      />
      <Card.Content noPadding>
        <BookingFilters
          bookingMode={bookingMode}
          formatMessage={intl.formatMessage}
          isManageableTableSelected={isManageableTableSelected}
          locale={transformLocale(intl.locale as ShortLocales)}
          setBookingsData={setBookingsData}
          styles={styles}
        />
        <BookingListCollapse
          bookingList={
            config.sortStatusInDashboard ? (
              <SortedBookingsList
                canDrag={false}
                className={styles.bookingsList}
                extraStatusFilter={extraStatusFilter}
                includedStatuses={includedStatuses}
                searchQuery={searchQuery}
                showAllNodes={false}
              />
            ) : (
              <BookingsList
                bookings={bookings}
                canDrag={false}
                className={cn("opened")}
                loading={isLoading}
              />
            )
          }
          bookingMode={bookingMode}
          bookings={statistics?.waitList.bookings || 0}
          guests={statistics?.waitList.guests || 0}
          title={intl.formatMessage({ id: ETranslations.WAITING_LIST })}
        >
          <BookingsList bookings={waitList} loading={isLoading} compact />
        </BookingListCollapse>
      </Card.Content>
    </Card>
  );
};
