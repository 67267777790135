import {
  CalendarOptions,
  EventDropArg,
  SlotLabelContentArg,
} from "@fullcalendar/core";
import interactionPlugin, {
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import cn from "classnames";
import { ClientList } from "containers/Guests/Clients/ClientList";
import dayjs, { Dayjs } from "dayjs";
import {
  dateSelector,
  placeSelector,
  restaurantSelector,
  useApplicationContextActions,
} from "features/AppContext";
import { longPollingInterval } from "features/api/constants";
import type {
  BookingSlot,
  HallId,
  ManagerSlot,
  SlotId,
  TableId,
} from "models/booking.model";
import type { Client } from "models/client.model";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, ICONS, Spinner } from "ui-kit";
import { v4 as uuid } from "uuid";

import { BookingActions } from "../../components/BookingActions";
import { HideWhen } from "../../components/HideWhen";
import { HallSchema } from "../../components/hall-scheme/redux/HallSchemaV2/hall-schema";
import { EditBooking } from "../../components/hall-scheme/redux/TableBookingList/table-booking-list";
import { Modal } from "../../components/modal";
import { ConfirmOverbookingModal } from "../../components/modals/ConfirmOverbookingModal";
import { config } from "../../config";
import {
  fromProxySelectors,
  useFromProxyActions,
} from "../../features/BookingFormProxy";
import { HallMode, useHallSchemaActions } from "../../features/HallSchema";
import { hallModeSelector } from "../../features/HallSchema/selectors";
import { useTimelineActions } from "../../features/Timeline";
import { useUpdateBookingHandler } from "../../features/api/bookings-api";
import { useSlots } from "../../features/api/hallschema-api";
import { useTagsOptions } from "../../features/api/tags";
import { useIntlUtils } from "../../hooks/useIntlUtils";
import { TBookingUpdateParams } from "../../types/IBookingDTO";
import { type ErrorResponse, TNullable } from "../../types/commons";
import { TagOption } from "../../types/tag";
import { ETranslations } from "../../types/translates";
import { OverviewBook } from "../../ui-kit/ICONS/icons";
import { getBookingStartTime, isManagerialTable } from "../../utils";
import {
  GridHeader,
  renderResourceLabelContent,
} from "./RequestGridComponents";
import "./gridCssVariables.css";
import styles from "./requestsGrid.module.scss";

type TBookingThreshold = Record<
  string,
  { bookings: number; persons: number; bookingIds: Set<SlotId> }
>;
type TThresholdKeys = "bookingsStartTime" | "bookingsEndTime";
type TNewParams = Partial<
  Pick<Omit<TBookingUpdateParams, "booking_time">, "visit_time" | "tables">
> &
  Pick<TBookingUpdateParams, "booking_time">;
type TOverBookingData = {
  booking: BookingSlot;
  newParams: TNewParams;
  info: EventDropArg;
};

let bookingCountInTimeSlot = 0;
let personsCountInTimeSlot = 0;

const labelFormat: CalendarOptions["slotLabelFormat"] = {
  hour: "2-digit",
  minute: "2-digit",
  omitZeroMinute: false,
  hourCycle: "h23",
};

export const RequestsGrid: FC = () => {
  const { intl, getIntlEntityEdition, isRussianLocale } = useIntlUtils();
  const mode = useSelector(hallModeSelector);
  const { switchMode } = useHallSchemaActions();
  const closeHall = useCallback(
    () => switchMode(HallMode.TABLES),
    [switchMode],
  );
  const closeGuests = useCallback(
    () => switchMode(HallMode.TABLE_BOOKINGS_EDIT),
    [switchMode],
  );
  const { setClient, setOnlyBooking, setBooking, setEditMode } =
    useFromProxyActions();
  const activeBooking = useSelector(fromProxySelectors.selectBooking);
  // for prevent polling when interact with bookings in timeline
  const [pollingInterval, setPollingInterval] =
    useState<TNullable<number>>(longPollingInterval);
  const schedulerRef = useRef<FullCalendar | null>(null);
  const [sortDirection, setSortDirection] = useState("");
  const date = useSelector(dateSelector);
  const bookingSlots = useSlots(pollingInterval);
  const { restaurant_id } = useSelector(restaurantSelector);
  const tags = useTagsOptions({
    type: "BOOKING",
    owner_id: restaurant_id,
    include_deleted: true,
  });
  const { updateBookingHandler } = useUpdateBookingHandler();
  const currentPlace = useSelector(placeSelector) as HallId;
  const { setDate } = useApplicationContextActions();
  const { setTime, resetTimeShift } = useTimelineActions();
  const [isBookingDetailsOpen, setIsBookingDetailsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setPlaceFromBooking } = useApplicationContextActions();
  const [overBookingData, setOverBookingData] =
    useState<TNullable<TOverBookingData>>(null);
  const [isEditable, setIsEditable] = useState(true);

  const startPolling = useCallback(() => setPollingInterval(null), []);
  const stopPolling = useCallback(() => setPollingInterval(0), []);
  const toggleSortDirection = useCallback(
    () => setSortDirection((prev) => (prev === "" ? "-" : "")),
    [],
  );
  const turnOnEditable = useCallback(() => setIsEditable(true), []);
  const turnOffEditable = useCallback(() => setIsEditable(false), []);
  const clearData = useCallback(() => {
    overBookingData?.info.revert();
    setOverBookingData(null);
    turnOnEditable();
  }, [setOverBookingData, overBookingData, turnOnEditable]);

  const resources: CalendarOptions["resources"] = useMemo(() => {
    const result: CalendarOptions["resources"] = [];
    bookingSlots.currentData?.forEach(({ slot_place }) => {
      const tableId = slot_place.table_id.toString();

      result.push({
        id: tableId,
        title: slot_place.table_name.toString(),
        extendedProps: {
          capacity: slot_place.maximum_capacity,
        },
      });
    });
    return result;
  }, [bookingSlots, sortDirection]);

  const updateBookingFn = async ({
    booking,
    newParams,
    force = false,
  }: {
    booking: BookingSlot;
    newParams: TNewParams;
    force?: boolean;
  }) => {
    const tables =
      newParams.tables ||
      booking.slot_place.map((placeItem) => ({
        place_id: placeItem.hall_id,
        table_ids: [placeItem.table_id],
      }));
    const booking_time = newParams.booking_time;
    const visit_time = newParams.visit_time || booking.visit_duration;

    const updateBody: TBookingUpdateParams = {
      slot_type: booking.slot_type,
      slot_id: booking.slot_id,
      client: booking.client,
      restaurant_id,
      booking_date: booking.date,
      tags: booking.tags.map(({ id, color, name }) => ({
        tag_id: id,
        color,
        description: name,
      })),
      persons: booking.visitors,
      tables,
      booking_time,
      visit_time,
      force,
    };
    return updateBookingHandler(updateBody).unwrap();
  };

  const handleOnDateClick: CalendarOptions["dateClick"] = useCallback(
    (event) => {
      const dateTime = dayjs(event.date);
      const tableId = Number(event.resource.id);
      const table = bookingSlots.currentData?.find(
        (tableItem) => tableItem.slot_place.table_id === tableId,
      )?.slot_place;
      if (table) {
        setBooking({
          booking: {
            bookingTime: dateTime.format("HH:mm"),
            places: [
              {
                table_id: table.table_id as TableId,
                table_name: table.table_name,
                hall_id: currentPlace as HallId,
                place_id: currentPlace as HallId,
              },
            ],
          },
          client: null,
        });
        navigate("/create-booking", {
          state: {
            from: location.pathname,
          },
        });
      }
    },
    [bookingSlots],
  );

  const handleOnBookingResize: CalendarOptions["eventResize"] = useCallback(
    async (info: EventResizeDoneArg) => {
      turnOffEditable();
      const booking = info.event.extendedProps.booking as BookingSlot;
      const visitTime = dayjs
        .duration(dayjs(info.event.end).diff(info.event.start))
        .asMinutes();
      try {
        await updateBookingFn({
          booking,
          newParams: {
            booking_time: dayjs(info.event.start).format("HH:mm:ss"),
            visit_time: visitTime,
          },
        });
      } catch (e) {
        info.revert();
      } finally {
        turnOnEditable();
      }
    },
    [currentPlace],
  );

  const commonCapacity = useMemo(
    () =>
      resources.reduce(
        (acc, resource) => acc + resource.extendedProps?.capacity,
        0,
      ),
    [resources],
  );

  const calendarData: { firstEventTime: TNullable<Dayjs> } & Pick<
    CalendarOptions,
    "resources" | "events"
  > &
    Record<TThresholdKeys, TBookingThreshold> = useMemo(() => {
    const events: CalendarOptions["events"] = [];
    const bookingsStartTime: TBookingThreshold = {};
    const bookingsEndTime: TBookingThreshold = {};
    let firstEventTime: TNullable<Dayjs> = null;

    bookingSlots.currentData?.forEach(({ slot_place, slots }) => {
      const tableId = slot_place.table_id.toString();

      slots.forEach((slot) => {
        const startDateTime = dayjs(`${slot.slot.date} ${slot.start_time}`);
        firstEventTime = startDateTime;
        const startTime = startDateTime.format("HH:mm");
        const endTime = startDateTime
          .add(slot.slot.visit_duration, "minute")
          .format("HH:mm");

        const persons = (slot.slot as BookingSlot).visitors;
        const {
          bookings: startTimeBookings,
          persons: startTimePersons,
          bookingIds: startTimeBookingIds,
        } = bookingsStartTime[startTime] || {
          bookings: 0,
          persons: 0,
          bookingIds: new Set(),
        };

        const {
          bookings: endTimeBookings,
          persons: endTimePersons,
          bookingIds: endTimeBookingIds,
        } = bookingsEndTime[endTime] || {
          bookings: 0,
          persons: 0,
          bookingIds: new Set(),
        };

        bookingsStartTime[startTime] = {
          bookings:
            startTimeBookings +
            (startTimeBookingIds.has(slot.slot.slot_id) ? 0 : 1),
          persons:
            startTimePersons +
            (startTimeBookingIds.has(slot.slot.slot_id) ? 0 : persons),
          bookingIds: startTimeBookingIds.add(slot.slot.slot_id),
        };
        bookingsEndTime[endTime] = {
          bookings:
            endTimeBookings +
            (endTimeBookingIds.has(slot.slot.slot_id) ? 0 : 1),
          persons:
            endTimePersons +
            (endTimeBookingIds.has(slot.slot.slot_id) ? 0 : persons),
          bookingIds: endTimeBookingIds.add(slot.slot.slot_id),
        };

        events.push({
          id: uuid(),
          resourceId: tableId,
          title: [
            (slot.slot as BookingSlot)?.client?.name,
            (slot.slot as BookingSlot)?.client?.surname,
          ].join(" "),
          start: startDateTime.format("YYYY-MM-DD HH:mm"),
          end: startDateTime
            .add(slot.slot.visit_duration, "minute")
            .format("YYYY-MM-DD HH:mm"),
          extendedProps: {
            booking: slot.slot,
            tags: tags.filter((tagItem) =>
              (slot.slot as BookingSlot)?.client?.tags?.includes(tagItem.value),
            ),
          },
        });
      });
    });

    return {
      events,
      bookingsStartTime,
      bookingsEndTime,
      firstEventTime,
    };
  }, [bookingSlots, sortDirection, resources]);

  const updateWithOverbooking = useCallback(async () => {
    if (!overBookingData) return;
    const { info, newParams, booking } = overBookingData;
    try {
      const updatedBooking = await updateBookingFn({
        booking,
        newParams,
        force: true,
      });
      info.event.setExtendedProp("booking", updatedBooking.data);
    } catch (e) {
      info.revert();
    } finally {
      setOverBookingData(null);
      startPolling();
      turnOnEditable();
    }
  }, [overBookingData]);

  const handleOnBookingDrop: CalendarOptions["eventDrop"] = useCallback(
    async (info: EventDropArg) => {
      turnOffEditable();
      const booking = info.event.extendedProps.booking as BookingSlot;
      const prevTableId = info.oldResource?.id;
      const nextTableId = info.newResource?.id;

      const table_ids: number[] = [];
      booking.slot_place.map((placeItem) => {
        if (!prevTableId || placeItem.table_id !== Number(prevTableId)) {
          table_ids.push(placeItem.table_id);
        }
      });
      if (nextTableId) {
        table_ids.push(Number(nextTableId));
      }

      const newParams = {
        booking_time: dayjs(info.event.start).format("HH:mm:ss"),
        tables: [{ place_id: currentPlace, table_ids }],
      };
      try {
        const updatedBooking = await updateBookingFn({
          booking,
          newParams,
        });
        startPolling();
        turnOnEditable();
        info.event.setExtendedProp("booking", {
          ...updatedBooking.data,
          isOverbooking: false,
        });
      } catch (e) {
        if ((e as ErrorResponse["error"])?.data?.errorCode === 10100) {
          setOverBookingData({ booking, info, newParams });
          return;
        }
        info.revert();
        startPolling();
        turnOnEditable();
      }
    },
    [currentPlace, calendarData.events],
  );

  const isCalendarResourcesReady = useMemo(() => {
    return resources instanceof Array && resources.length > 0;
  }, [resources, bookingSlots]);

  const isNoTables = useMemo(() => {
    return (
      bookingSlots.status !== "pending" &&
      resources instanceof Array &&
      resources.length === 0
    );
  }, [resources]);

  const clearBooking = useCallback(() => {
    setIsBookingDetailsOpen(false);
    setOnlyBooking(undefined);
    resetTimeShift();
    closeHall();
  }, []);

  const handleOnEventClick = useCallback((book: BookingSlot) => {
    setPlaceFromBooking(book);
    setDate(dayjs(book.date).toISOString());
    setTime(getBookingStartTime(book.date, book.time));
    setOnlyBooking(book);
    setIsBookingDetailsOpen(true);
  }, []);

  const handleSetClient = useCallback(
    (client: Client) => {
      setClient({ client });
      closeGuests();
    },
    [setClient, closeGuests],
  );

  const handleOnEdit = useCallback((booking: BookingSlot) => {
    setPlaceFromBooking(booking);
    setOnlyBooking(booking);
    setTimeout(setEditMode, 0, true);
  }, []);

  const renderEventContent: CalendarOptions["eventContent"] = useCallback(
    (eventInfo) => {
      const clientTags = eventInfo.event.extendedProps.tags as TagOption[];
      const booking = eventInfo.event.extendedProps.booking as
        | BookingSlot
        | ManagerSlot;

      const withTags = clientTags.length > 0;

      return (
        <div
          className={cn(styles.bookingItemInner, {
            [styles.isOverbooking]:
              booking.slot_type === "BOOKING" && booking.overbooking,
          })}
          onClick={
            booking.slot_type === "BOOKING"
              ? () => handleOnEventClick(booking)
              : undefined
          }
        >
          <div className={styles.bookingStatuses}>
            {booking.slot_type === "BOOKING" && booking.extra_status && (
              <div style={{ background: booking.extra_status.color || "" }} />
            )}
            <div
              style={{
                background:
                  booking.slot_type === "MANAGER"
                    ? "var(--status_constant_manager_background)"
                    : booking.status.color || "",
              }}
            />
          </div>
          <span>{booking.time}</span>
          <span className={styles.clientName} title={eventInfo.event.title}>
            {booking.slot_type === "MANAGER"
              ? intl.formatMessage({ id: ETranslations.MANAGEMENT_BOOKING })
              : eventInfo.event.title}
          </span>
          {booking.slot_type === "BOOKING" && (
            <>
              <span className={styles.vip}>
                {booking.client?.vip && (
                  <ICONS.VipSign width={12} height={12} />
                )}
              </span>
              <span className={styles.persons}>{booking.visitors}</span>
            </>
          )}
          {withTags && (
            <div className={styles.clientTags}>
              {clientTags.slice(0, 3).map((tag) => {
                return (
                  <span
                    key={tag.label}
                    style={{ background: tag.color }}
                    className={styles.clientTag}
                    title={clientTags
                      .map((tagItem) => tagItem.label)
                      .join(", ")}
                  />
                );
              })}
            </div>
          )}
          {!isManagerialTable(booking) && (
            <BookingActions
              placement="top-start"
              booking={booking}
              className={styles.contextMenuIcon}
              onEdit={() => handleOnEdit(booking)}
            />
          )}
        </div>
      );
    },
    [],
  );

  const renderSlotLabelContent: CalendarOptions["slotLabelContent"] =
    useCallback(
      (labelInfo: SlotLabelContentArg) => {
        if (calendarData.bookingsStartTime[labelInfo.text]) {
          bookingCountInTimeSlot +=
            calendarData.bookingsStartTime[labelInfo.text].bookings;
          personsCountInTimeSlot +=
            calendarData.bookingsStartTime[labelInfo.text].persons;
        }
        if (calendarData.bookingsEndTime[labelInfo.text]) {
          bookingCountInTimeSlot -=
            calendarData.bookingsEndTime[labelInfo.text].bookings;
          personsCountInTimeSlot -=
            calendarData.bookingsEndTime[labelInfo.text].persons;
        }

        return (
          <div className={styles.slotLabel}>
            <span className={styles.slotTime}>{labelInfo.text}</span>
            <div className={styles.booked}>
              <OverviewBook
                width={20}
                height={20}
                color="var(--gl_icon_primary_1)"
              />
              <span className={styles.bookedCount}>
                {bookingCountInTimeSlot}
              </span>
            </div>
            <span className={styles.capacity}>
              {personsCountInTimeSlot || 0}/{commonCapacity}
            </span>
          </div>
        );
      },
      [calendarData],
    );

  const scrollTime: CalendarOptions["scrollTime"] = useMemo(() => {
    const now = dayjs();
    const time = now.isSame(date.toDate(), "d")
      ? now
      : calendarData.firstEventTime;
    return time?.format("HH:mm");
  }, [date, calendarData.firstEventTime]);

  useEffect(() => {
    const schedulerApi = schedulerRef.current?.getApi();
    if (!schedulerApi) return;
    schedulerApi.gotoDate(date.toDate());
  }, [date, schedulerRef]);

  useEffect(() => {
    if (isCalendarResourcesReady) {
      const areaHeader = document.querySelector(".areaHeaderWrapper");
      areaHeader?.addEventListener("click", toggleSortDirection);
      return () => {
        areaHeader?.removeEventListener("click", toggleSortDirection);
      };
    }
  }, [isCalendarResourcesReady]);

  useEffect(() => {
    setPollingInterval(isBookingDetailsOpen ? 0 : null);
  }, [isBookingDetailsOpen]);

  return (
    <div className={styles.grid}>
      {!isCalendarResourcesReady && !isNoTables && (
        <Spinner className={styles.calendarLoader} />
      )}
      {isNoTables && (
        <div className={styles.noTablesOrShifts}>
          {intl.formatMessage({ id: ETranslations.REQUEST_GRID_NO_TABLES })}
        </div>
      )}
      {isCalendarResourcesReady && !isNoTables && (
        <FullCalendar
          ref={schedulerRef}
          plugins={[interactionPlugin, resourceTimelinePlugin]}
          headerToolbar={{
            left: "",
            center: "",
            right: "",
          }}
          initialView="resourceTimeline"
          resourceOrder={`${sortDirection}title`}
          initialDate={date.toDate()}
          resources={resources}
          events={calendarData.events}
          eventTimeFormat={labelFormat}
          slotLabelFormat={labelFormat}
          handleWindowResize
          eventContent={renderEventContent}
          slotLabelContent={renderSlotLabelContent}
          resourceLabelContent={renderResourceLabelContent}
          schedulerLicenseKey={config.fullCalendarKey}
          editable={isEditable}
          height={"100%"}
          nowIndicator
          slotDuration={{ minutes: 15 }}
          slotLabelInterval={{ minutes: 15 }}
          slotMinWidth={55}
          resourceAreaHeaderContent={
            <GridHeader sortDirection={sortDirection} />
          }
          resourceAreaHeaderClassNames="areaHeaderWrapper" // for exact dom query
          resourceAreaWidth={120}
          eventClassNames={styles.bookingItem}
          eventResizeStart={stopPolling}
          eventResizeStop={startPolling}
          eventResize={handleOnBookingResize}
          eventDragStart={stopPolling}
          eventDrop={handleOnBookingDrop}
          scrollTime={scrollTime}
          scrollTimeReset={false}
          dateClick={handleOnDateClick}
        />
      )}
      {isBookingDetailsOpen && activeBooking?.slot_id && (
        <Modal
          isOpen={Boolean(activeBooking)}
          onClose={clearBooking}
          title={getIntlEntityEdition(
            isRussianLocale
              ? ETranslations.PLURAL_BOOKINGS_NOM
              : ETranslations.PLURAL_BOOKING,
          )}
        >
          <Modal.Content noPadding className={styles.modalGrid}>
            <HideWhen
              condition={
                [
                  HallMode.BOOKING_GUEST,
                  HallMode.TABLE_BOOKINGS_EDIT_GUEST,
                ].includes(mode) || mode.includes("HALL")
              }
              noUnmount
            >
              <div>
                {activeBooking?.slot_id && (
                  <EditBooking
                    bookingId={activeBooking.slot_id as SlotId}
                    hideCard
                  />
                )}
              </div>
            </HideWhen>
            <HideWhen condition={!mode.includes("HALL")}>
              <Card onClose={closeHall}>
                <Card.Header
                  title={intl.formatMessage({ id: ETranslations.HALL_SCHEME })}
                />
                <Card.Content className={styles.scheme}>
                  <HallSchema />
                </Card.Content>
              </Card>
            </HideWhen>
            <HideWhen
              condition={
                ![
                  HallMode.BOOKING_GUEST,
                  HallMode.TABLE_BOOKINGS_EDIT_GUEST,
                ].includes(mode)
              }
            >
              <ClientList
                className={styles.guests}
                withHeader
                onSelectClient={handleSetClient}
                onClose={closeGuests}
              />
            </HideWhen>
          </Modal.Content>
        </Modal>
      )}
      <ConfirmOverbookingModal
        isOpen={!!overBookingData}
        onDecline={clearData}
        onConfirm={updateWithOverbooking}
      />
    </div>
  );
};
