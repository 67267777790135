import { ICONS } from "../../../common/helpers";
import "./phone-modal.scss";
import cn from "classnames";
import React from "react";

export const PhoneModal = (props: any) => {
  const { visibility, title, closeModal } = props;
  return (
    <div id="phone-modal" style={{ display: visibility ? "block" : "none" }}>
      <div className={cn("modal-header")}>
        <h2>{title}</h2>
        <ICONS.Cross onClick={closeModal} />
      </div>
      <div className={cn("content")}>{props.children}</div>
    </div>
  );
};
