import {
  Button as OriginalButton,
  ButtonProps as OriginalButtonProps,
} from "../Button";
import styles from "./RadioButton.module.scss";
import cn from "classnames";

export interface ButtonProps<T>
  extends Omit<OriginalButtonProps, "variant" | "ref" | "value"> {
  value: T;
}

export type InternalButtonProps<T> = ButtonProps<T> &
  Pick<OriginalButtonProps, "variant">;

export function Button<T>({ value, ...props }: ButtonProps<T>) {
  const { variant, disabled } = props as InternalButtonProps<T>;
  return (
    <OriginalButton
      disabled={disabled}
      {...props}
      className={cn(props.className, styles.button, {
        [styles.disabled]: disabled,
      })}
      type="button"
      variant={variant ?? "thin"}
    />
  );
}
