import { useGetPermissionsQuery } from "features/api/user-api";
import type { PermissionName } from "models/user.model";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";

export const useCheckPermission = () => {
  const { data = {} } = useGetPermissionsQuery();
  const { formatMessage } = useIntl();

  const hasPermissionFor = useCallback(
    (permissionName: PermissionName, toastOnFalse?: boolean) => {
      const hasPermission = !!data[permissionName];
      if (toastOnFalse && !hasPermission)
        Notification.error({
          title: formatMessage({ id: ETranslations.PERMISSION_DENIED }),
          message: formatMessage(
            { id: ETranslations.PERMISSION_CODE },
            { value: permissionName },
          ),
        });
      return hasPermission;
    },
    [data],
  );

  return {
    hasPermissionFor,
  };
};
