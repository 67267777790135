import cn from "classnames";
import { ICONS, Tabs, TabsProps } from "..";
import styles from "./Card.module.scss";
import { useCloseCard } from "./useCloseCard";

function HeaderTabsComponent<T extends string>({
  children,
  className,
  ...props
}: TabsProps<T>) {
  const close = useCloseCard();
  return (
    <Tabs {...props} className={cn(styles.tabs, className)}>
      {children}
      {close && (
        <Tabs.Addon>
          <ICONS.Cross className={styles.cross} onClick={close} />
        </Tabs.Addon>
      )}
    </Tabs>
  );
}

export const HeaderTabs = Object.assign(HeaderTabsComponent, Tabs);
