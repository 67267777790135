import { roundCeil } from "../../../common/helpers";
import _ from "lodash";
import moment from "moment";

export const timeFormat = "HH:mm:ss";
export const dateFormat = "YYYY-MM-DD";

export const roundTime = () => {
  const localTime = moment();
  const minutes = +localTime.format("mm");
  const roundMinutes = roundCeil(minutes, 15);
  return localTime.set({ minute: roundMinutes, seconds: 0 }).format(timeFormat);
};

export const roundMomentTime = _.memoize((momentDate) => {
  const minutes = +momentDate.format("mm");
  const roundMinutes = roundCeil(minutes, 15);
  return momentDate
    .set({ minute: roundMinutes, seconds: 0 })
    .format(timeFormat);
});
