import cn from "classnames";
import React from "react";

import "./phone-menu.scss";

export const PhoneMenu = (props: any) => {
  const { clickHandler } = props;
  return (
    <div className={cn("phoneMenu")} onClick={clickHandler}>
      <div className={cn("wrapper")}>
        <div className={cn("bar")} />
        <div className={cn("bar")} />
        <div className={cn("bar")} />
      </div>
    </div>
  );
};
