import { applicationContextActions } from "features/AppContext";
import { api } from "features/api";
import { userApi } from "features/api/user-api";
import type { GrantedUser } from "models/user.model";
import { type ActionFunction, redirect } from "react-router-dom";
import { type AuthErrorCause, AuthService } from "services/auth.service";
import { loadStore } from "storage";
import { ETranslations } from "types/translates";
import { commonFormErrorMap, transformLocale } from "utils";

import { AuthFormSchema } from "../model/schema";

export const action = (async ({ request }) => {
  const formData = await request.formData();
  const formDataObj = Object.fromEntries(formData);
  const parsedData = AuthFormSchema.safeParse(formDataObj, {
    errorMap: commonFormErrorMap,
  });
  const store = await loadStore();

  const validationErrors = parsedData.error?.flatten();

  const authError =
    parsedData.success &&
    (await AuthService.login({
      login: parsedData.data.login,
      password: parsedData.data.password,
      locale: transformLocale(parsedData.data.locale),
    })
      .then(
        (user) => (
          store.dispatch(
            applicationContextActions.setCurrentUser(
              user as unknown as GrantedUser,
            ),
          ),
          localStorage.setItem("user", JSON.stringify(user))
        ),
      )
      .catch((e: { cause: AuthErrorCause }) => e));

  const translatedAuthError =
    authError &&
    ("cause" in authError && authError.cause.code === 10000
      ? ETranslations.AUTH_INCORRECT_CREDENTIALS
      : ETranslations.ERROR_SOMETHING_WENT_WRONG);

  const errors = parsedData.success
    ? { formErrors: translatedAuthError, fieldErrors: undefined }
    : validationErrors!;

  const isLoginSuccess = parsedData.success && !authError;

  if (isLoginSuccess) {
    global?.channels?.auth?.cb && global.channels.auth.cb(true);
    store.dispatch(userApi.util.prefetch("getPermissions", undefined, {}));
    return redirect(parsedData.data.redirectTo);
  }

  return {
    shouldConfirmPassword: false,
    ...errors,
    hasFormErrors: Boolean(
      Array.isArray(errors.formErrors)
        ? errors.formErrors.length
        : errors.formErrors,
    ),
  };
}) satisfies ActionFunction;

export const logoutAction = (async ({ request }) => {
  const pathname = new URL(request.url).pathname;
  AuthService.logout();
  const store = await loadStore();
  store.dispatch(api.util.resetApiState());
  return redirect(`/login?redirectTo=${pathname}`);
}) satisfies ActionFunction;
