import type { FormBooking } from "features/BookingFormProxy/types";
import { castFormToCreateDTO } from "features/BookingFormProxy/utils";
import type { ManagerSlot } from "models/booking.model";
import type { IResponse } from "models/common";
import { Notification } from "services/notification";
import type { Booking } from "types/booking";
import type { ErrorResponse } from "types/commons";
import { createManagerialTablesPayload } from "types/managerialTables";
import { ETranslations } from "types/translates";

import { apiFrontOffice } from "./api";
import { coreApi } from "./bookings-api/core";
import { SLOT_TAGS } from "./hallschema-api";

export const managerialTablesApi = coreApi
  .enhanceEndpoints({ addTagTypes: ["Slots"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getManagerialTable: build.query<ManagerSlot, number>({
        query: (id: number) => ({
          url: `/management/${id}`,
          method: "GET",
        }),
        providesTags: (rest, err, args) => [
          { type: "ManagerialTables", id: args },
        ],
        transformResponse: (response: IResponse<ManagerSlot>) => response.data,
      }),

      createManagerialTable: build.mutation({
        query: (payload: createManagerialTablesPayload) => ({
          url: "/management/create/batch",
          method: "POST",
          body: payload,
        }),
      }),

      editManagerialTable: build.mutation({
        query: ({
          payload,
          id,
        }: {
          payload: createManagerialTablesPayload;
          id: number;
        }) => ({
          url: `/management/edit/${id}`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: (result, err, args) => [
          "Bookings",
          { type: "ManagerialTables", id: args.id },
          "TableOptions",
          "GlobalSearchBookings",
          ...SLOT_TAGS,
        ],
      }),

      cancelManagerialTable: build.mutation({
        query: ({ id }: { id: number }) => ({
          url: `management/delete/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, err, args) => [
          "Bookings",
          { type: "ManagerialTables", id: args.id },
          "TableOptions",
          "GlobalSearchBookings",
          ...SLOT_TAGS,
        ],
      }),
      createManagerialBooking: build.mutation<
        IResponse<Booking>,
        {
          data: FormBooking;
          isOverbooking?: boolean;
        }
      >({
        query: ({
          data,
          isOverbooking = false,
        }: {
          data: FormBooking;
          isOverbooking?: boolean;
        }) => ({
          url: "management/booking",
          method: "POST",
          params: { force: isOverbooking || false },
          body: castFormToCreateDTO(data),
        }),
        invalidatesTags: ["Bookings", "TableOptions", "GlobalSearchBookings"],
        async onQueryStarted(args, { queryFulfilled, dispatch }) {
          try {
            await queryFulfilled;
            dispatch(
              apiFrontOffice.util.invalidateTags([
                {
                  type: "Feeds",
                  id: String(
                    args?.data?.client_id || args?.data?.client?.client_id,
                  ),
                },
              ]),
            );
          } catch (err) {
            const errorData = (err as ErrorResponse)?.error?.data;
            errorData?.errorCode !== 10100 &&
              Notification.error(
                errorData?.errorCode === 10600
                  ? {
                      title: ETranslations.UNABLE_TO_CREATE_BOOKING_COVERAGE,
                    }
                  : {
                      title: ETranslations.UNABLE_TO_CREATE_BOOKING,
                      message: errorData?.errorMessage,
                    },
              );
            throw err;
          }
        },
      }),
    }),
  });

export const {
  useCreateManagerialTableMutation,
  useGetManagerialTableQuery,
  useEditManagerialTableMutation,
  useCancelManagerialTableMutation,
  useCreateManagerialBookingMutation,
} = managerialTablesApi;
