import { DialogsAuth } from "./auth";
import {
  Dialog,
  DialogChannels,
  DialogMessageStatus,
  DialogsFilter,
  DialogsMessage,
  SendMessagePayload,
} from "./entities";

/*
    ____,-------------------------------,____
    \   |         Интерфейс API         |   /
    /___|-------------------------------|___\
*/

export interface IDialogsApi {
  // Queries

  search(filter: DialogsFilter): Promise<Dialog[]>;
  byId(dialogId: string): Promise<Dialog | undefined>;
  messages(dialogId: string): Promise<DialogsMessage[]>;
  allowedMessengers(dialogId: string): Promise<DialogChannels[]>;
  taskMessages(taskId: string): Promise<DialogsMessage[]>;
  customerAllowedMessengers(taskId: string): Promise<DialogChannels[]>;

  // Commands

  assign(dialogId: string, operatorId: string): Promise<void>;
  markAttention(dialogId: string): Promise<void>;
  unmarkAttention(dialogId: string): Promise<void>;
  watch(dialogId: string): Promise<void>;
  unwatch(dialogId: string): Promise<void>;
  close(dialogId: string): Promise<void>;
  linkTask(dialogId: string, taskId: string): Promise<void>;
  unlinkTask(dialogId: string, taskId: string): Promise<void>;
  markMessagesSeen(dialogId: string, msgIds: string[]): Promise<void>;
  markTaskMessagesSeen(taskId: string, msgIds: string[]): Promise<void>;
  sendMessage(dialogId: string, payload: SendMessagePayload): Promise<void>;
  sendTaskMessage(taskId: string, payload: SendMessagePayload): Promise<void>;
}

const HTTP_UNAUTHORIZED = 401;

/*
    ____,-------------------------------,____
    \   |           API клиент          |   /
    /___|-------------------------------|___\
*/

export class DialogsApiClient implements IDialogsApi {
  constructor(private auth: DialogsAuth) {}

  // -- Реализация IDialogsApi

  async search(filter: DialogsFilter): Promise<Dialog[]> {
    return this.post("/dialogs/search", filter);
  }

  async byId(dialogId: string): Promise<Dialog | undefined> {
    return this.get(`/dialogs/${dialogId}`);
  }

  async messages(dialogId: string): Promise<DialogsMessage[]> {
    return this.get(`/dialogs/${dialogId}/messages`);
  }

  async allowedMessengers(dialogId: string): Promise<DialogChannels[]> {
    return this.get(`/dialogs/${dialogId}/allowedMessengers`);
  }

  async taskMessages(taskId: string): Promise<DialogsMessage[]> {
    return this.get(`/tasks/${taskId}/messages`);
  }

  customerAllowedMessengers(customerId: string): Promise<DialogChannels[]> {
    return this.get(`/customers/${customerId}/allowedMessengers`);
  }

  async assign(dialogId: string, operatorId: string): Promise<void> {
    return this.post(`/dialogs/${dialogId}/assign`, { user_id: operatorId });
  }

  async markAttention(dialogId: string): Promise<void> {
    return this.put(`/dialogs/${dialogId}/flags`, { attention: true });
  }

  async unmarkAttention(dialogId: string): Promise<void> {
    return this.put(`/dialogs/${dialogId}/flags`, { attention: false });
  }

  async watch(dialogId: string): Promise<void> {
    return this.put(`/dialogs/${dialogId}/flags`, { watch: true });
  }

  async unwatch(dialogId: string): Promise<void> {
    return this.put(`/dialogs/${dialogId}/flags`, { watch: false });
  }

  async close(dialogId: string): Promise<void> {
    return this.post(`/dialogs/${dialogId}/close`);
  }

  async linkTask(dialogId: string, taskId: string): Promise<void> {
    return this.post(`/dialogs/${dialogId}/link`, { task_id: taskId });
  }

  async unlinkTask(dialogId: string, taskId: string): Promise<void> {
    return this.post(`/dialogs/${dialogId}/unlink`, { task_id: taskId });
  }

  async markMessagesSeen(dialogId: string, msgIds: string[]): Promise<void> {
    return this.post(`/dialogs/${dialogId}/messages/status`, {
      messages: msgIds,
      status: DialogMessageStatus.SEEN,
    });
  }

  async markTaskMessagesSeen(taskId: string, msgIds: string[]): Promise<void> {
    return this.post(`/tasks/${taskId}/messages/status`, {
      messages: msgIds,
      status: DialogMessageStatus.SEEN,
    });
  }

  async sendMessage(
    dialogId: string,
    payload: SendMessagePayload,
  ): Promise<void> {
    return this.post(`/dialogs/${dialogId}/messages`, payload);
  }

  async sendTaskMessage(
    taskId: string,
    payload: SendMessagePayload,
  ): Promise<void> {
    return this.post(`/tasks/${taskId}/messages`, payload);
  }

  // -----

  private get<T>(path: string): Promise<T> {
    return this.request<T>(path, "GET");
  }

  private post<T>(path: string, data?: unknown): Promise<T> {
    return this.request<T>(path, "POST", data);
  }

  private put<T>(path: string, data?: unknown): Promise<T> {
    return this.request<T>(path, "PUT", data);
  }

  private async request<T>(
    path: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    data: unknown = undefined,
    isRetry: boolean = false,
  ): Promise<T> {
    const token = await this.auth.askToken(isRetry);

    const url = `${this.auth.baseUrl}${path}`;
    const response = await fetch(url, {
      method,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      body: data ? JSON.stringify(data, null, 2) : undefined,
    });

    if (!response.ok) {
      if (response.status === HTTP_UNAUTHORIZED) {
        if (isRetry || !this.auth.allowAuthRetry) {
          this.auth.publishError();
          throw new Error(`DialogsApi: Unauthorized request to ${path}`);
        }

        return this.request<T>(path, method, data, true);
      }

      if (response.headers.get("content-type") === "application/json") {
        const errJson = await response.json();
        throw new Error(
          `DialogsApi: (${response.status}) error making request to ${path}. Data: ${JSON.stringify(errJson)}`,
        );
      }

      throw new Error(
        `DialogsApi: (${response.status}) error making request to ${path}`,
      );
    }

    const json = await response.json();

    return json as T;
  }
}
