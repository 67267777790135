import { ColumnBar } from "..";
import styles from "./BottomBar.module.scss";
import cn from "classnames";
import { FC, HTMLAttributes } from "react";

export type BottomBarProps = HTMLAttributes<HTMLDivElement>;

export const BottomBar: FC<BottomBarProps> = (props) => {
  return (
    <ColumnBar {...props} className={cn(props.className, styles.bottomBar)} />
  );
};
