import cn from "classnames";
import { BookingsList } from "components/BookingsList";
import { HideWhen } from "components/HideWhen";
import { RestaurantBookingStats } from "components/RestaurantBookingStats";
import type { SelectCheckboxOption } from "components/booking/types";
import { filterBookingsListData } from "components/booking/utils";
import { TableActions } from "components/hall-scheme/redux/TableBookingList/TableActions";
import { config } from "config";
import dayjs from "dayjs";
import { selectedPlacesSelector } from "features/AppContext";
import { useFromProxyActions } from "features/BookingFormProxy";
import { tableBookingsTableSelector } from "features/TableBooking/selectors";
import { useTableBookingListActions } from "features/TableBooking/slice";
import { useCurrentRestBookings } from "features/api/bookings-api";
import { useAllStatuses } from "features/api/dictionaries-api";
import { usePlacesTable } from "features/api/tables-api";
import { groupBy, uniqBy } from "lodash";
import type { BookingSlot } from "models/booking.model";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Status } from "types/status";
import { Card, Collapse } from "ui-kit";
import { Input } from "ui-kit";

import { useTimelineActions } from "../../features/Timeline";
import { useSlots } from "../../features/api/hallschema-api";
import { ETranslations } from "../../types/translates";
import { getBookingStartTime } from "../../utils";
import styles from "./SortedBookingsList.module.scss";

interface SortedBookingsListProps {
  onHide?: () => void;
  className?: string;
  openedByTable?: boolean;
  showAllNodes?: boolean;
  canDrag?: boolean;
  searchQuery?: string;
  includedStatuses?: string[];
  isHallScheme?: true;
  extraStatusFilter?: SelectCheckboxOption[] | null;
}

const statusWeight: Partial<Record<Status["system_name"], number>> = {
  ARRIVED: 1,
  CONFIRMED: 2,
  IN_HALL: 3,
  NEW: 4,
  CANCELED: 5,
  NOT_COME: 6,
};

export function SortedBookingsList({
  onHide,
  className,
  openedByTable,
  showAllNodes = true,
  canDrag = true,
  searchQuery,
  includedStatuses,
  isHallScheme,
  extraStatusFilter,
}: SortedBookingsListProps) {
  const intl = useIntl();
  const { setOnlyBooking: setFormBooking, setEditMode } = useFromProxyActions();
  const { statuses } = useAllStatuses();
  const [search, setSearch] = useState<string | undefined>();
  const table = useSelector(tableBookingsTableSelector);
  const { table: tableData, isLoading: isTableLoading } = usePlacesTable(table);
  const isManageableTableSelected =
    localStorage.getItem("isManageableTableSelected") === "true";
  const selectedPlaces = useSelector(selectedPlacesSelector);

  const valideStatuses = useMemo(() => {
    if (includedStatuses) {
      return statuses.filter((status) =>
        includedStatuses.includes(status.system_name),
      );
    } else {
      return statuses;
    }
  }, [includedStatuses, statuses]);

  const { data } = useCurrentRestBookings(
    (showAllNodes ? search : searchQuery) || undefined,
    Boolean(!config.hasStatusFiltersInHallScheme && tableData?.table_id),
    includedStatuses && valideStatuses,
    isHallScheme,
    tableData?.table_id,
  );
  const { bookings, statistics } = filterBookingsListData(
    data,
    extraStatusFilter,
  );
  const { data: tableSlotsData } = useSlots();
  const { setTime, resetTimeShift } = useTimelineActions();
  const { reset: resetTable } = useTableBookingListActions();

  const bookingsTable = useMemo(() => {
    const bookingsData = tableSlotsData
      ?.find((el) => el.slot_place.table_id === table)
      ?.slots.map((el) => el.slot)
      .sort((a, b) => {
        const dateA = dayjs(`${a.date} ${a.time}`).valueOf();
        const dateB = dayjs(`${b.date} ${b.time}`).valueOf();
        return dateA - dateB;
      });
    return bookingsData || [];
  }, [table, tableSlotsData]);

  const bookingsByStatus = useMemo(() => {
    if (!bookings || isTableLoading) return {};
    const uniqBookings = uniqBy(bookings || [], (b) => b.slot_id);
    const filteredBookings = tableData
      ? uniqBookings.filter((b) =>
          b.slot_place.some((p) => p.table_id === tableData.table_id),
        )
      : uniqBookings;

    const groupByStatus = groupBy(
      filteredBookings,
      (b) => (b as BookingSlot)?.status?.system_name,
    );

    return groupByStatus;
  }, [bookings, tableData, isTableLoading]);

  const managerialTablesStatus = useMemo(() => {
    const managerialTables = tableSlotsData?.filter((eachTable) => {
      return eachTable?.slots.some(
        (slot) => slot?.slot?.slot_type === "MANAGER",
      );
    });

    const filterManagerialbookings = managerialTables
      ?.map((eachTable) =>
        eachTable.slots
          .filter((slot) => slot.slot?.slot_type === "MANAGER")
          .map((slot) => slot.slot),
      )
      .flat();

    const filteredBookings = tableData
      ? filterManagerialbookings?.filter((booking) =>
          booking.slot_place.some((p) => p.table_id === tableData?.table_id),
        )
      : filterManagerialbookings;

    //@ts-ignore
    return uniqBy(filteredBookings, (b) => b.notes.batch_id);
  }, [tableData?.table_id, tableSlotsData, isTableLoading, table]);

  const handleClose = useCallback(() => {
    onHide?.();
    resetTable();
    resetTimeShift();
  }, [onHide, resetTable]);

  const handleEdit = useCallback((booking: BookingSlot) => {
    setFormBooking(booking);
    setEditMode(true);
    setTime(getBookingStartTime(booking.date, booking.time));
  }, []);


  const WithOutStatusFilters = useMemo(() => {
    return (
      <BookingsList
        bookings={bookingsTable}
        canDrag={canDrag}
        className={styles.bookingsList}
        allowMove
        compact
        showPhone
        showStatus
      />
    );
  }, [bookingsTable, handleEdit, setFormBooking]);

  const WithStatusFilters = useMemo(() => {
    let allStatuses = valideStatuses;

    if (config.BRAND === "DUBAI") {
      allStatuses = [...valideStatuses]
        .filter((status) => statusWeight[status.system_name])
        .sort((a, b) => {
          const first = statusWeight[a.system_name];
          const second = statusWeight[b.system_name];
          if (first && second) return first - second;
          return 0;
        });
    }

    return allStatuses
      .map((status) => {
        const statusBookings = bookingsByStatus[status.system_name]?.filter(
          (booking) => {
            return booking?.slot_place?.some((place) => {
              return selectedPlaces?.includes(place?.hall_id);
            });
          },
        );

        if (!statusBookings?.length) return null;
        return (
          <Collapse
            header={
              <span>
                {status.name}&nbsp;
                <span className={styles.bookingsCount}>
                  {statusBookings.length}
                </span>
              </span>
            }
            headerClassName={styles.statusCollapse}
            key={status.id}
            initialOpen
          >
            <div>
              <BookingsList
                bookings={statusBookings}
                canDrag={canDrag}
                className={styles.bookingsList}
                allowMove
                compact
                showPhone
                showStatus
              />
            </div>
          </Collapse>
        );
      })
      .filter((el) => el);
  }, [
    valideStatuses,
    handleEdit,
    setFormBooking,
    bookingsByStatus,
    selectedPlaces.length,
  ]);

  const WithManagerialTablesStatus = useMemo(() => {
    const sortedManagerialTables = managerialTablesStatus?.filter((booking) => {
      return booking?.slot_place?.some((place) =>
        selectedPlaces?.includes(place?.hall_id),
      );
    });
    if (sortedManagerialTables?.length) {
      return (
        <Collapse
          header={
            <span>
              {intl.formatMessage({ id: ETranslations.MANAGERIAL_TABLES })}
              &nbsp;
              <span className={styles.bookingsCount}>
                {managerialTablesStatus.length}
              </span>
            </span>
          }
          headerClassName={styles.statusCollapse}
          initialOpen
        >
          <BookingsList
            bookings={sortedManagerialTables}
            canDrag={canDrag}
            className={styles.bookingsList}
            allowMove
            showPhone
            showStatus
          />
        </Collapse>
      );
    } else return null;
  }, [managerialTablesStatus, selectedPlaces.length]);

  return (
    <Card
      className={cn(className, styles.bookingsCard)}
      onClose={tableData && !openedByTable ? resetTable : handleClose}
    >
      <HideWhen condition={!showAllNodes}>
        <Card.Header
          title={
            tableData?.number ? (
              intl.formatMessage(
                { id: ETranslations.TABLE_BOOKINGS },
                { number: tableData.number },
              )
            ) : (
              <RestaurantBookingStats
                stats={statistics}
                title={intl.formatMessage({
                  id: ETranslations.PLURAL_BOOKINGS_NOM,
                })}
              />
            )
          }
        />
      </HideWhen>
      <Card.Content noPadding>
        <HideWhen condition={!showAllNodes}>
          <div className={styles.searchContainer}>
            <Input.Search onSearch={setSearch} />
          </div>
        </HideWhen>
        <div className={styles.list}>
          {config.hasStatusFiltersInHallScheme ? (
            <>
              {WithStatusFilters}
              {isManageableTableSelected && WithManagerialTablesStatus}
            </>
          ) : tableData?.number ? (
            WithOutStatusFilters
          ) : (
            <>
              {WithStatusFilters}
              {!isTableLoading &&
                isManageableTableSelected &&
                WithManagerialTablesStatus}
            </>
          )}
        </div>
        {tableData && (
          <TableActions className={styles.actions} table={tableData} />
        )}
      </Card.Content>
    </Card>
  );
}
