import { usePageId } from "contexts/LayoutContext";
import React from "react";
import { Spinner } from "ui-kit";

const ScheduleLandingComponent = React.lazy(
  () => import("../../components/schedule-landing/schedule-landing"),
);
export function ScheduleLanding() {
  usePageId("schedule-landing");

  return (
    <React.Suspense fallback={<Spinner />}>
      <ScheduleLandingComponent />
    </React.Suspense>
  );
}
