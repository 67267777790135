import dayjs from "dayjs";
import {
  dateSelector,
  useApplicationContextActions,
} from "features/AppContext";
import { useTimelineActions } from "features/Timeline";
import { Option } from "models/common";
import React, { useMemo } from "react";
import { FieldRenderProps, useField } from "react-final-form";
import { useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import { DatePicker } from "ui-kit";

type DateProps = FieldRenderProps<Date, any>;

const ChoiceDate = ({
  value,
  onChange,
  disabled,
  minDate = new Date(),
}: any) => {
  const date = useSelector(dateSelector);
  const {
    input: { onChange: changeTime, value: timeValue },
  } = useField<Option<string> | null>("time", { allowNull: true });
  const { setIsActualTime, setTimestamp } = useTimelineActions();
  const { setDate } = useApplicationContextActions();

  // Хук для синхронизации глобальной даты с датой формы
  useUpdateEffect(() => {
    if (disabled) return;
    const valueDate = dayjs(value).startOf("day");
    const selectedDate = dayjs(date.toDate());
    const diffDays = selectedDate.diff(valueDate, "day");

    // Устанавливаем актуальное время для следующего дня. Иначе установится первое доступное на новый день.
    if (typeof timeValue?.value === "number") {
      const time = dayjs.tz(timeValue.value);
      const timestamp = time.add(diffDays, "day").valueOf();
      changeTime({ ...timeValue, value: timestamp });
    }

    if (valueDate.isSame(selectedDate)) return;
    onChange(date.toDate());
  }, [date]);

  const datePickerHandler = (newDate: Date) => {
    const now = dayjs(value);
    const selectedDate = dayjs(newDate);
    const diffDays = selectedDate.diff(now, "days");

    const newTimestamp = dayjs(timeValue?.value).add(diffDays, "day");

    setDate(selectedDate.toDate().toISOString());
    setTimestamp(newTimestamp.valueOf());
    setIsActualTime(false);
  };

  const transformedValue = useMemo(() => dayjs(value).toDate(), [value]);

  return (
    <DatePicker
      disabled={disabled}
      minDate={minDate}
      selected={transformedValue}
      onChange={datePickerHandler}
    />
  );
};

export const ChoiceDateInput: React.FC<DateProps> = ({
  input,
  meta,
  ...rest
}) => <ChoiceDate {...input} {...meta} {...rest} />;
