import { Option } from "../../common/components/MultiSelect/multi-select";
import { config } from "../../config";
import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import { IPayloadClientHistory, TModeHistory } from "./BookingClientHistory";
import styles from "./styles.module.scss";
import cn from "classnames";
import moment from "moment";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { Status } from "types/status";
import { DatePicker, SelectBasic, SelectCheckbox } from "ui-kit";

interface Props {
  payload: IPayloadClientHistory;
  setPayload: Dispatch<SetStateAction<IPayloadClientHistory>>;
  mode: TModeHistory;
  setMode: Dispatch<SetStateAction<TModeHistory>>;
  statuses: Status[];
}

const elemCount = [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
];

export const SearchBlock: FC<Props> = ({
  payload,
  setPayload,
  mode,
  setMode,
  statuses,
}) => {
  const {
    getIntlEntityOnPage,
    intl,
    getIntlStatusOf,
    getIntlSelectEntity,
    getIntlJoinedParts,
    isRussianLocale,
  } = useIntlUtils();
  const { from, to } = payload;
  const [status, setStatus] = useState<Option<string>[]>([]);
  const isBooking = mode === "booking";

  const viewOptions: Array<{ value: TModeHistory; label: string }> = [
    {
      value: "booking",
      label: intl.formatMessage({ id: ETranslations.PLURAL_BOOKINGS_NOM }),
    },
  ];

  if (config.hasRequests) {
    viewOptions.push({
      value: "order",
      label: intl.formatMessage({ id: ETranslations.PLURAL_REQUESTS_NOM }),
    });
  }

  const options = statuses.map((element) => ({
    value: element.system_name,
    label: element.name,
  }));

  const bookingStatusTitle = useMemo(
    () =>
      getIntlStatusOf(
        isRussianLocale
          ? ETranslations.PLURAL_BOOKINGS_NOM
          : ETranslations.PLURAL_BOOKING,
      ).toLowerCase(),
    [],
  );
  const checkboxTitles = useMemo(() => {
    return [
      bookingStatusTitle,
      getIntlJoinedParts([
        ETranslations.PLURAL_STATUSES_ALT,
        ETranslations.PLURAL_BOOKINGS_NOM,
      ]),
      getIntlJoinedParts([
        ETranslations.PLURAL_STATUSES,
        ETranslations.PLURAL_BOOKINGS_NOM,
      ]),
      getIntlJoinedParts([
        ETranslations.PLURAL_STATUSES_NOM,
        ETranslations.PLURAL_BOOKINGS_NOM,
      ]),
    ];
  }, []);

  const changeHandler = (key: string, value: any) => {
    setPayload((oldState) => ({
      ...oldState,
      [key]: value,
    }));
  };

  const selectHandler = (e: Option<string>[]) => {
    setStatus(e);
    if (!e.length) {
      changeHandler("statuses", statuses);
      return;
    }
    const activeStatuses = e.map((it) => it.value);
    changeHandler("statuses", activeStatuses);
  };

  useEffect(() => {
    if (from > to) {
      setPayload((oldState) => ({
        ...oldState,
        from: to,
      }));
    }
  }, [payload]);

  return (
    <div
      className={cn(styles.searchBlock, {
        [styles.searchBlock__order]: !isBooking,
      })}
    >
      <SelectBasic
        // placeholder={getIntlEntityOnPage(ETranslations.PLURAL_ELEMENTS)}
        options={viewOptions}
        value={viewOptions.find((el) => el.value === mode)}
        // @ts-ignore
        onChange={(el) => setMode(el.value)}
      />
      <div />
      <SelectBasic
        options={elemCount}
        placeholder={getIntlEntityOnPage(ETranslations.PLURAL_ELEMENTS)}
        value={elemCount.find((el) => el.value === payload.count)}
        // @ts-ignore
        onChange={(el) => changeHandler("count", el.value)}
      />
      <DatePicker
        className="from"
        dateFormatCalendar="MMMM"
        maxDate={new Date(to)}
        placeholderText={intl.formatMessage({ id: ETranslations.BASE_DATE })}
        selected={new Date(from)}
        onChange={(e) => changeHandler("from", moment(e).format("YYYY-MM-DD"))}
      />
      <DatePicker
        className="to"
        dateFormatCalendar="MMMM"
        placeholderText={intl.formatMessage({ id: ETranslations.BASE_DATE })}
        selected={new Date(to)}
        onChange={(e) => changeHandler("to", moment(e).format("YYYY-MM-DD"))}
      />
      {isBooking && (
        <SelectCheckbox
          options={options}
          placeholder={getIntlSelectEntity(ETranslations.PLURAL_STATUS)}
          titles={checkboxTitles}
          value={status}
          // @ts-ignore
          onChange={selectHandler}
        />
      )}
    </div>
  );
};
