import { DeepPartial, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TManagerialForm } from "components/ManagerialTables/form/helpers/prepareData";
import { constant } from "lodash";
import type { OrderSlot } from "models/booking.model";
import { type Client, type Contact } from "models/client.model";
import type { BookingHistoryDTO } from "models/history.model";
import type { Booking, BookingWithRest } from "types/booking";

import { useDispatchActions } from "../../hooks/useDispatchActions";
import { TNullable } from "../../types/commons";

export interface State {
  selectedTimeValue: string;
  // option for edit exits booking
  editMode: boolean;
  client: Client | Contact | null;
  // TODO бронь здесь должна быть Booking без DeepPartial
  booking:
    | DeepPartial<Booking>
    | BookingWithRest
    | BookingHistoryDTO["booking"]
    | null;
  order: DeepPartial<OrderSlot> | null;
  editClient?: boolean;
  managerialTable: TNullable<TManagerialForm>;
}

export const initialState: State = {
  selectedTimeValue: "",
  client: null,
  booking: null,
  order: null,
  editMode: false,
  editClient: false,
  managerialTable: null,
};

const slice = createSlice({
  name: "bookingFormSlice",
  initialState,
  reducers: {
    setTime(state, action: PayloadAction<Pick<State, "selectedTimeValue">>) {
      const { selectedTimeValue } = action.payload;
      state.selectedTimeValue = selectedTimeValue;
    },
    setClient(state, action: PayloadAction<Pick<State, "client">>) {
      const { client } = action.payload;
      state.client = client;
    },
    resetBooking(state) {
      state.booking = null;
      state.client = null;
      state.editMode = false;
    },
    setOnlyBooking(
      state,
      action: PayloadAction<
        State["booking"] | BookingHistoryDTO["booking"] | undefined
      >,
    ) {
      state.client = null;
      state.booking = action.payload ?? null;
      state.editMode = false;
    },
    setOnlyOrder(state, action: PayloadAction<State["order"] | undefined>) {
      const orderValue = action.payload ?? null;
      state.order = orderValue;
      if (orderValue) {
        const client = orderValue.client ?? null;
        state.client = client as Client | Contact;
      }
    },
    setBooking(
      state,
      action: PayloadAction<Pick<State, "client" | "booking">>,
    ) {
      const { client, booking } = action.payload;
      state.booking = booking;
      state.client = client;
      state.editMode = false;
    },

    setEditMode(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
      if (!action.payload) {
        state.editClient = false;
      }
    },
    resetClient(state) {
      state.client = null;
    },
    setEditClient(state, { payload }: PayloadAction<boolean>) {
      state.editClient = payload;
    },
    reset: {
      prepare: constant({ payload: undefined }),
      reducer(state) {
        state.selectedTimeValue = "";
        state.client = null;
        state.booking = null;
        state.order = null;
        state.editMode = false;
      },
    },
  },
});

export const {
  name: formProxySliceName,
  reducer: formProxySliceReducer,
  actions: bookingFormSliceActions,
} = slice;
export const useFromProxyActions = () => useDispatchActions(slice.actions);
