export const PERMISSION_NAME_LIST = [
  "FEATURE_BOOKING_REPORT",
  "SECTION_BOOKINGS",
  "ACCESS_BOOKING_CREATE",
  "ACCESS_BOOKING_DELETE",
  "ACCESS_BOOKING_STATUS_OPERATIONS",
  "ACCESS_BOOKING_UPDATE",
  "FEATURE_BOOKING_PLACE_TRANSFER",
  "FEATURE_MANAGEMENT_TABLES",
  "ACCESS_MANAGER_TABLES_CREATE",
  "ACCESS_MANAGER_TABLES_DELETE",
  "ACCESS_MANAGER_TABLES_SEATING",
  "SECTION_HALL_SCHEMA",
  "FEATURE_DRAG_AND_DROP_ON_HALL_SCHEMA",
  "ACCESS_BOOKING_UPDATE_CONTACT",
  "SECTION_ORDER",
  "ACCESS_ORDER_UPDATE",
  "SECTION_GUESTS",
  "ACCESS_GUEST_CREATE",
  "ACCESS_GUEST_UPDATE",
  "FEATURE_GUEST_ACCOUNTING",
  "FEATURE_GUEST_BOOKING_CREATE",
  "SECTION_GUEST_NOTES",
  "ACCESS_GUEST_NOTES_LOCAL_CREATE",
  "ACCESS_GUEST_NOTES_GLOBAL_VIEW",
  "ACCESS_GUEST_NOTES_GLOBAL_DELETE",
  "ACCESS_GUEST_NOTES_UPDATE",
  "SECTION_LANDING_SCHEDULE",
  "SECTION_MANAGEMENT",
  "FEATURE_MANAGEMENT_ROLE_ASSIGNING",
  "FEATURE_MANAGEMENT_HOSTESS_ASSIGNING",
  "FEATURE_MANAGEMENT_BOOKING_HISTORY",
  "FEATURE_MANAGEMENT_SHIFT_VIEW",
  "ACCESS_MANAGEMENT_SHIFT_UPDATE",
  "ACCESS_MANAGEMENT_SHIFT_DELETE",
  "ACCESS_MANAGEMENT_SHIFT_CREATE",
  "FEATURE_MANAGEMENT_RESTAURANT",
  "ACCESS_MANAGEMENT_RESTAURANT_CREATE",
  "ACCESS_MANAGEMENT_RESTAURANT_UPDATE",
  "FEATURE_MANAGEMENT_STATUSES",
  "ACCESS_MANAGEMENT_STATUSES_UPDATE",
  "FEATURE_MANAGEMENT_TAG",
  "ACCESS_MANAGEMENT_TAG_CREATE",
  "ACCESS_MANAGEMENT_TAG_UPDATE",
  "ACCESS_MANAGEMENT_TAG_GLOBAL_LIST",
  "SECTION_DIALOGS",
  "FEATURE_DIALOGS_BOOKING_LINK",
  "FEATURE_DIALOGS_BOOKING_UNLINK",
  "ACCESS_DIALOGS_MESSAGE_HISTORY",
  "FEATURE_DIALOGS_MESSAGE_ATTACHMENT",
  "FEATURE_DIALOGS_BOOKING_CREATE",
  "FEATURE_DIALOGS_USING_TEMPLATES",
  "ACCESS_DIALOGS_TEMPLATES_CRUD",
  "ACCESS_DIALOGS_AVAILABLE_TEMPLATES_VIEW",
  "ACCESS_DIALOGS_AVAILABLE_TEMPLATES_UPDATE",
  "FEATURE_SOURCE",
  "ACCESS_ORGANIZATION_VIEW",
] as const;
