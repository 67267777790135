import { Button, ICONS } from "..";
import styles from "./DatePicker.module.scss";
import { DatePickerSelect, MyOptionSelectType } from "./DatePickerSelect";
import { monthsOptions, yearsOptions } from "./helpers";
import { getMonth, getYear } from "date-fns";
import React from "react";
import { ValueType } from "react-select";

type CustomHeaderPropsType = {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  locale?: "en_EN" | "ru_RU";
};

export const datePickerHeader = (
  {
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
    changeMonth,
    locale,
  }: CustomHeaderPropsType,
  minYear?: number,
): React.ReactNode => {
  const localizedMonthOptions = monthsOptions(locale || "en_EN");
  const changeMonthHandler = (e: ValueType<MyOptionSelectType, false>) => {
    if (!e) return;
    changeMonth(e.value);
  };

  const changeYearHandler = (e: ValueType<MyOptionSelectType, false>) => {
    if (!e) return;
    changeYear(+e.label);
  };

  const yearOptions = yearsOptions(minYear || 2020);
  const monthValue = localizedMonthOptions.find(
    (el) => el.value === getMonth(date),
  );
  const yearValue = yearOptions.find((el) => +el.label === getYear(date));

  return (
    <div className={styles.header}>
      <Button
        className={styles.headerButton}
        disabled={prevMonthButtonDisabled}
        variant="phantom"
        onClick={decreaseMonth}
      >
        <ICONS.LeftArrow />
      </Button>
      <DatePickerSelect
        menuPortalTarget={null}
        options={localizedMonthOptions}
        value={monthValue}
        onChange={changeMonthHandler}
      />
      <DatePickerSelect
        menuPortalTarget={null}
        options={yearOptions}
        value={yearValue}
        onChange={changeYearHandler}
      />
      <Button
        className={styles.headerButton}
        disabled={nextMonthButtonDisabled}
        variant="phantom"
        onClick={increaseMonth}
      >
        <ICONS.RightArrow />
      </Button>
    </div>
  );
};
