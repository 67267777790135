import TagsContainer from "components/TagsContainer/TagsContainer";
import { Modal } from "components/modal";
import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";
import { BaseShiftsModal } from "components/shifts/Modal/BaseShiftsModal";
import { restaurantSelector } from "features/AppContext";
import { useEditNewTagMutation } from "features/api/tags-api";
import React, { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import type { TagGroup } from "types/tag";
import { ETranslations } from "types/translates";
import { BottomBar, Button, Card, Input, Labeled, SelectBasic } from "ui-kit";

import styles from "./CreateGroupTagsModal.module.scss";
import { AddTagToGroupRedux } from "./sub-renders/AddTagToGroup";
import { ChooseColorRedux } from "./sub-renders/ChooseColorComponent";

interface EditGroupTagsModalProps {
  isOpen: boolean;
  onClose: any;
  groupInfo: TagGroup;
  title: string;
  selectedTag: string;
}

export const EditGroupTagsModal = ({
  isOpen,
  onClose,
  groupInfo,
  selectedTag,
  title,
}: EditGroupTagsModalProps) => {
  const intl = useIntl();

  const types = [
    {
      value: "GLOBAL",
      label: intl.formatMessage({ id: ETranslations.GLOBAL }),
    },
    { value: "LOCAL", label: intl.formatMessage({ id: ETranslations.LOCAL }) },
  ];
  const restaurant = useSelector(restaurantSelector);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>("");
  const toggleOpensSave = () => {
    setOpenEditModal((prev) => !prev);
  };
  const toggleServerError = () => {
    setHasError("");
  };
  const [edit] = useEditNewTagMutation();

  const onSubmit = (vals: TagGroup) => {
    // @ts-ignore
    const isGlobal = vals.group_type.value === "GLOBAL";
    edit({
      id: vals.id,
      name: vals.name,
      destination: selectedTag,
      color: vals.color,
      // @ts-ignore
      group_type: vals.group_type.value.toUpperCase(),
      owner_type: isGlobal ? undefined : "RESTAURANT",
      owner_id: isGlobal ? undefined : `${restaurant.restaurant_id}`,
      tags: vals.tags,
    }).then((res) => {
      // @ts-ignore
      if (res?.error?.data?.errorMessage) {
        // @ts-ignore
        setHasError(res?.error?.data?.errorMessage as string);
        setOpenEditModal(false);
        return;
      }
      onClose();
    });
  };

  const initalValue = useMemo(() => {
    return {
      ...groupInfo,
      group_type: {
        value: groupInfo.group_type,
        label: intl.formatMessage({
          id:
            groupInfo.group_type === "GLOBAL"
              ? ETranslations.GLOBAL
              : ETranslations.LOCAL,
        }),
      },
    };
  }, [groupInfo]);
  const handleCloseModal = () => onClose();

  return (
    <BaseShiftsModal isOpen={isOpen} title={title} onClose={handleCloseModal}>
      <Modal.Content className={styles.modalContentAccess}>
        <Card onClose={handleCloseModal}>
          <Form initialValues={initalValue} onSubmit={onSubmit}>
            {({ handleSubmit }) => {
              return (
                <form className={styles.form} onSubmit={handleSubmit}>
                  <div className={styles.content}>
                    <p className={styles.formTitle}>
                      {intl.formatMessage({ id: ETranslations.GROUP_PlURAL })}
                    </p>
                    <div>
                      <Labeled
                        label={intl.formatMessage({
                          id: ETranslations.GROUP_TYPE,
                        })}
                      >
                        <Field name="group_type">
                          {(props) => (
                            <SelectBasic
                              disabled={true}
                              options={types}
                              placeholder=""
                              value={props.input.value}
                              onChange={props.input.onChange}
                            />
                          )}
                        </Field>
                      </Labeled>

                      <Labeled
                        label={intl.formatMessage({
                          id: ETranslations.NAME_GROUP,
                        })}
                        required
                      >
                        <Field name="name">
                          {(props) => (
                            <Input
                              value={props.input.value}
                              onChange={props.input.onChange}
                            />
                          )}
                        </Field>
                      </Labeled>
                    </div>
                    <Labeled
                      label={intl.formatMessage({
                        id: ETranslations.GROUP_COLOR,
                      })}
                      required
                    >
                      <Field component={ChooseColorRedux} name="color" />
                    </Labeled>
                    <p className={styles.formTitle}>
                      {intl.formatMessage({ id: ETranslations.TAGS })}
                    </p>
                    <Labeled
                      label={intl.formatMessage({ id: ETranslations.ADD_TAG })}
                      required
                    >
                      <Field
                        component={AddTagToGroupRedux}
                        name="newTag"
                        styles={styles}
                      />
                    </Labeled>
                    <div className={styles.tagsContainer}>
                      <TagsContainer />
                    </div>
                  </div>

                  <BottomBar className={styles.controls}>
                    <BottomBar.Part placement="left">
                      <Button variant="primary" onClick={toggleOpensSave}>
                        {intl.formatMessage({ id: ETranslations.BASE_SAVE })}
                      </Button>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
                      </Button>
                    </BottomBar.Part>
                  </BottomBar>

                  {openEditModal && (
                    <ModalPopup
                      buttons={
                        <>
                          <Button
                            type="submit"
                            variant="primary"
                            onClick={handleSubmit}
                          >
                            {intl.formatMessage({
                              id: ETranslations.BASE_SAVE,
                            })}
                          </Button>
                          <Button variant="secondary" onClick={toggleOpensSave}>
                            {intl.formatMessage({ id: ETranslations.CANCEL })}
                          </Button>
                        </>
                      }
                      isOpen={openEditModal}
                      needIcon={true}
                      text={intl.formatMessage({
                        id: ETranslations.IMMEDIATELY_CHANGES,
                      })}
                      title={intl.formatMessage({
                        id: ETranslations.ASK_SAVE_CHANGES,
                      })}
                      onClose={toggleOpensSave}
                    />
                  )}

                  {hasError && (
                    <ModalPopup
                      buttons={
                        <Button variant="secondary" onClick={toggleServerError}>
                          {intl.formatMessage({ id: ETranslations.BASE_CLOSE })}
                        </Button>
                      }
                      isOpen={!!hasError}
                      needIcon={true}
                      text={hasError}
                      title={intl.formatMessage({
                        id: ETranslations.BASE_ERROR,
                      })}
                      editBooking
                      onClose={toggleServerError}
                    />
                  )}
                </form>
              );
            }}
          </Form>
        </Card>
      </Modal.Content>
    </BaseShiftsModal>
  );
};
