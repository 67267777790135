import dayjs from "dayjs";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import type { BaseStatus, Status, StatusSystemName } from "types/status";
import { ETranslations } from "types/translates";
import { getBookingStartTime } from "utils";

export function useExtendedStatuses({
  status,
  date,
  time,
  visitDuration,
}: {
  status: BaseStatus;
  date: string;
  time: string;
  visitDuration: number;
}) {
  const { formatMessage } = useIntl();
  const extendedStatuses = {
    late: {
      color: "#F7D694",
      name: formatMessage({ id: ETranslations.LATE }),
      system_name: "LATE" as StatusSystemName,
    },
    outstayed: {
      color: "#F7896F",
      name: formatMessage({ id: ETranslations.OUTSTAY }),
      system_name: "OUTSTAYED" as StatusSystemName,
    },
  } as const satisfies Record<
    string,
    Pick<Status, "color" | "name" | "system_name">
  >;
  const minutesPassedFromBookingStart = Math.max(
    dayjs().startOf("minute").diff(getBookingStartTime(date, time), "minute"),
    0,
  );

  const isInWaitList = status.system_name === "WAIT_LIST";

  const isInHall = status.system_name === "IN_HALL";

  const isLate =
    minutesPassedFromBookingStart > 0 && status.category === "PRE_SERVICE" && status.system_name !== "ARRIVED";

  const isOverstayed =
    minutesPassedFromBookingStart - visitDuration > 0 &&
    status.category === "IN_SERVICE";

  const extendedStatus = isLate
    ? extendedStatuses.late
    : isOverstayed
      ? extendedStatuses.outstayed
      : undefined;

  const newStatus =
    extendedStatus && !isInWaitList ? { ...status, ...extendedStatus } : status;

  const visitProgress = useMemo(() => {
    if (minutesPassedFromBookingStart <= 0) {
      return 0;
    }
    if (
      minutesPassedFromBookingStart > 0 &&
      minutesPassedFromBookingStart < visitDuration
    ) {
      return (minutesPassedFromBookingStart * 100) / visitDuration;
    }
    if (minutesPassedFromBookingStart >= visitDuration) {
      return 100;
    }
    return 0;
  }, [visitDuration, minutesPassedFromBookingStart]);

  const visitTime = useMemo(() => {
    if (isOverstayed) return -(minutesPassedFromBookingStart - visitDuration);
    if (isLate) return minutesPassedFromBookingStart;
    if (isInHall) return visitDuration - minutesPassedFromBookingStart;
    return visitDuration;
  }, [visitDuration, newStatus.system_name, minutesPassedFromBookingStart]);

  return { newStatus, visitProgress, visitTime };
}
