import { config } from "config";
import { useTap } from "hooks/useTap";
import moment from "moment";
import { MouseEvent, useCallback, useMemo } from "react";
import { useDrag } from "react-dnd";
import { Booking, BookingOrder } from "types/booking";
import { formatPhone, isBookingOrder, isManagerialTable } from "utils";

import { EDraggableEntity } from "../../../constants";
import { useVisitContext } from "../VisitContext";

const useBookingCardNew = ({
  booking,
  onClick,
  canDrag,
}: {
  booking: Booking | BookingOrder;
  onClick: any;
  canDrag: boolean;
}) => {
  const handleClick = useCallback(() => onClick?.(booking), [onClick, booking]);

  const { isExpired, isLate, statusClassName, statusClassNameManagement } =
    useVisitContext();

  const label = useMemo(
    () =>
      ("orderId" in booking
        ? ["order", booking.orderId]
        : ["booking", booking.bookingId]
      ).join(" "),
    [booking],
  );

  const isAnonym = booking?.client?.contact_type === "CONTACT";
  const phone = useMemo(() => formatPhone(booking?.client?.phone), [booking]);

  const isManagerialTableBoolean = useMemo(
    () => "seatType" in booking && booking.seatType === "MANAGEMENT",
    [booking],
  );

  const { handleTouchStart, handleTouchEnd, longTap } = useTap(100);

  const handleContextMenuCapture = (e: MouseEvent) => {
    // Отменять контекстное меню необходимо для планшетов (и других touch устройств), чтобы long tap не отменял drag
    e.preventDefault();
    e.stopPropagation();
  };

  const [isDragging, drag, dragPreview] = useDrag(
    () => ({
      type: EDraggableEntity.BOOKING_CARD,
      item: booking,
      canDrag: config.dragAndDrop && canDrag && longTap,
      collect: (monitor) => monitor.isDragging(),
    }),
    [booking, longTap],
  );

  const isEndingSoon =
    moment(`${booking.bookingDate} ${booking.bookingTime}`, "YYYY-MM-DD HH:mm")
      .add(booking.visitTime, "minutes")
      .diff(moment(), "minutes") <= 20;

  return {
    handleClick,
    isExpired,
    isLate,
    label,
    statusClassName,
    statusClassNameManagement,
    phone,
    isManagerialTableBoolean,
    handleTouchStart,
    handleTouchEnd,
    handleContextMenuCapture,
    isDragging,
    drag,
    dragPreview,
    isAnonym,
    isEndingSoon,
  };
};

export default useBookingCardNew;
