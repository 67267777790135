import { Modal } from "components/modal";
import { useGetUserByIdQuery } from "features/api/user-api";
import type { UserId } from "models/user.model";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";
import { Button, Spinner } from "ui-kit";

import { ROLE_INTENT } from "../constants";
import { AddUserForm } from "./AddUserForm";
import { EditUserForm } from "./EditUserForm";
import styles from "./Roles.module.scss";

export type UserModalProps =
  | {
      intent: typeof ROLE_INTENT.CREATE;
      fullName?: string;
      userId?: never;
    }
  | {
      intent: typeof ROLE_INTENT.EDIT;
      fullName?: never;
      userId: UserId;
    };

const CreateUserModal = ({
  fullName,
  close,
}: {
  fullName?: string;
  close: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      className={styles.userEditModal}
      isOpen
      title={formatMessage({ id: ETranslations.USER_CARD_UPDATING })}
      onClose={close}
    >
      <Modal.Content className={styles.modalBody}>
        <AddUserForm fullName={fullName} />
      </Modal.Content>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          name="intent"
          value={ROLE_INTENT.CREATE}
          form="edit-user"
          type="submit"
          variant="primary"
        >
          {formatMessage({ id: ETranslations.BASE_SAVE })}
        </Button>
        <Button type="button" variant="secondary" onClick={close}>
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditUserModal = ({
  userId,
  close,
}: {
  userId: UserId;
  close: () => void;
}) => {
  const { formatMessage } = useIntl();
  const { data, error } = useGetUserByIdQuery(userId);
  useEffect(() => {
    error &&
      (Notification.error({
        title: ETranslations.BASE_ERROR,
        message: formatMessage(
          { id: ETranslations.ERROR_ENTITY },
          {
            entity: formatMessage({ id: ETranslations.OF_USER }),
            error: JSON.stringify(error),
          },
        ),
      }),
      close());
  }, [error]);
  return (
    <Modal
      className={styles.userEditModal}
      isOpen
      title={formatMessage({ id: ETranslations.USER_CARD_UPDATING })}
      onClose={close}
    >
      <Modal.Content className={styles.modalBody}>
        {data ? (
          <EditUserForm
            fullName={data.username}
            phone={""}
            restaurants={data.active_in_restaurants}
            login={data.username}
          />
        ) : (
          <Spinner />
        )}
      </Modal.Content>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          name="intent"
          value={ROLE_INTENT.EDIT}
          form="edit-user"
          type="submit"
          variant="primary"
        >
          {formatMessage({ id: ETranslations.BASE_SAVE })}
        </Button>
        <Button type="button" variant="secondary" onClick={close}>
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const RoleModal = ({
  close,
  intent,
  fullName,
  userId,
}: UserModalProps & { close: () => void }) =>
  intent === "CREATE" ? (
    <CreateUserModal fullName={fullName} close={close} />
  ) : (
    <EditUserModal userId={userId} close={close} />
  );
