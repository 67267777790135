import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface LayoutContext {
  pageId: string | undefined;
  setPageId(id: string | undefined): void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const LayoutContext = createContext<LayoutContext>(null!);

interface LayoutContextProviderProps {
  children: ReactNode;
}

export function LayoutContextProvider({
  children,
}: LayoutContextProviderProps) {
  const [pageId, setPageId] = useState<string | undefined>();
  return (
    <LayoutContext.Provider
      value={{
        pageId,
        setPageId,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export function usePageId(id: string): void {
  const { setPageId } = useContext(LayoutContext);
  useEffect(() => {
    setPageId(id);
    return (): void => {
      setPageId(undefined);
    };
  }, [id, setPageId]);
}
