import { IPosition } from "@technolab/hse";
import cn from "classnames";
import { timelineSelectors } from "features/Timeline";
import { TTable, type HallSlotsDTO } from "features/api/hallschema-api";
import { useTableFigures } from "features/api/tables-api";
import { useSliderDatetime } from "hooks/useSliderDatetime";
import React, { FC } from "react";
import { useSelector } from "react-redux";

import {
  getSlotExtraOptions,
  selectSlotByShift,
  sumOfSquares,
} from "../../Table/utils";
import { useStatusColor } from "../BookingTable/useStatusColor";
import styles from "./style.module.scss";
import { calculatePath, getCenterCoords } from "./utils";

interface Props {
  slots: HallSlotsDTO['slots'];
  places: Record<number, TTable>;
}

export const TablePathSvg: FC<Props> = ({ slots, places }) => {
  const timeWithDate = useSliderDatetime();
  const slot = selectSlotByShift(slots, timeWithDate());
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const { data: figures, defaultFigure } = useTableFigures();
  const { tableColor } = getSlotExtraOptions(slot, timeWithDate());
  const statusColor = useStatusColor(slot?.slot, isActualTime, tableColor);

  if (!slot || !figures) return null;

  // Хрупкое место происходит мердж столов их схемы со столами из брони
  const bookingTables = slot.slot.slot_place
    .map((place) => places[place.table_id])
    .sort((a, b) => {
      const sumA = sumOfSquares(a.position!.x, a.position!.y);
      const sumB = sumOfSquares(b.position!.x, b.position!.y);
      return sumA - sumB;
    })
    .reduce<Record<"start" | "end", IPosition>[]>((acc, value, i, array) => {
      if (!value?.figure_id) return acc;
      const figureConfig =
        figures.find(
          (figure) => Number(figure.figure_id) === value.figure_id,
        ) || defaultFigure;
      const start = getCenterCoords(value.position, figureConfig);
      const end = getCenterCoords(array[i + 1]?.position, figureConfig);

      if (start && end) {
        acc.push({ start, end });
      }
      return acc;
    }, []);

  return (
    <g className={cn(styles.path, styles[tableColor])}>
      {bookingTables.map(({ start, end }) => (
        <path
          d={calculatePath(start, end)}
          key={`${start.x}-${start.y}`}
          stroke={statusColor}
        />
      ))}
    </g>
  );
};
