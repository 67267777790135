import cn from "classnames";
import { useTableBookingListActions } from "features/TableBooking/slice";
import { ReactNode } from "react";

import { Button, ICONS } from "..";
import { useIsTablet } from "../hooks";
import styles from "./Card.module.scss";
import { useCloseCard } from "./useCloseCard";

interface BlockHeaderProps {
  children?: ReactNode;
  title?: ReactNode;
  controls?: ReactNode;
  className?: string;
  ManagerialButton?: ReactNode;
  needToReset?: boolean;
}

export function Header({
  children,
  title,
  controls,
  className,
  ManagerialButton = null,
  needToReset = false,
}: BlockHeaderProps): JSX.Element {
  const close = useCloseCard();
  const isTablet = useIsTablet();
  const { reset: resetTable } = useTableBookingListActions();

  const handleClose = () => {
    close && close();
    needToReset && resetTable();
  };

  return (
    <div className={cn(styles.header, className)}>
      <div className={cn(styles.headerContent)}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {!isTablet && children && (
          <div className={styles.subHeader}>{children}</div>
        )}
        {controls && <div className={styles.controls}>{controls}</div>}
        {(close || ManagerialButton) && (
          <div className={styles.buttons}>
            {ManagerialButton}
            <Button
              className={styles.close}
              variant="phantom"
              square
              onClick={handleClose}
            >
              <ICONS.Cross />
            </Button>
          </div>
        )}
      </div>
      {isTablet && children && (
        <div className={cn(styles.subHeader)}>{children}</div>
      )}
    </div>
  );
}
