import { z } from "zod";

export interface TagsModel {
  description: string;
  id: number;
}

export const TagId = z.number().brand("TagId");
export type TagId = z.infer<typeof TagId>;

export const Tag = z.object({
  id: TagId,
  name: z.string(),
  color: z.string(),
  status: z.string().optional(),
  group: z.string(),
});
export type Tag = z.infer<typeof Tag>;

export const CRMTag = z.object({
  description: z.string(),
  color: z.string(),
  tag_id: TagId,
});
export type CRMTag = z.infer<typeof CRMTag>;
