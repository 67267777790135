import { DialogsApiClient, IDialogsApi } from "./api";
import { DialogsAuth, ITokenData } from "./auth";
import { DialogsSocket, IDialogsSocketApi } from "./socket";
import { DialogsStore } from "./store";
import React, { useEffect } from "react";
import { PropsWithChildren, useContext, useMemo } from "react";

/*
    ____,-------------------------------,____
    \   |          Конфигурация         |   /
    /___|-------------------------------|___\
*/

export type DialogsConfig = {
  // Интеграция
  baseUrl: string;

  // Авторизация
  authenticated: boolean;
  onAuth: (isRetry?: boolean) => Promise<ITokenData>;
  onAuthError: () => void;
  allowAuthRetry: boolean;

  // Моки для тестирования или разработки
  mockApi?: IDialogsApi;
};

/*
    ____,-------------------------------,____
    \   |           Провайдер           |   /
    /___|-------------------------------|___\

*/

export function DialogsProvider({
  authenticated,
  baseUrl,
  onAuth,
  onAuthError,
  allowAuthRetry,
  mockApi,
  children,
}: PropsWithChildren<DialogsConfig>) {
  // -- Auth

  const auth = useMemo(
    () => new DialogsAuth(baseUrl, onAuth, onAuthError, allowAuthRetry),
    [baseUrl, onAuth, onAuthError, allowAuthRetry],
  );

  useEffect(() => {
    if (!authenticated) auth.resetCache();
  }, [authenticated]);

  // -- Api

  const api = useMemo(
    () => mockApi ?? new DialogsApiClient(auth),
    [mockApi, auth],
  );

  // -- Socket

  const socket = useMemo(() => new DialogsSocket(auth), [auth]);

  // подчищаем старые сокеты при пересоздании объекта или unmount провайдера
  useEffect(() => {
    return () => {
      socket.cleanup();
    };
  }, [socket]);

  // подключаем socket
  useEffect(() => {
    if (authenticated) {
      socket.connect();
    } else {
      socket.disconnect();
    }
  }, [socket, authenticated]);

  // -- Store

  const store = useMemo(
    () => new DialogsStore(api, socket),
    [api, socket, authenticated /* очистка стора при разлогине */],
  );

  // -- Context

  const ctx = useMemo<IDialogsContext>(
    () => ({
      auth: auth,
      api,
      socket,
      store,
    }),
    [baseUrl, api, socket],
  );

  // -- Render

  return (
    <DialogsContext.Provider value={ctx}>{children}</DialogsContext.Provider>
  );
}

export function useDialogsContext(): IDialogsContext {
  const ctx = useContext(DialogsContext);

  if (!ctx) {
    throw new Error(
      "DialogsContext not initialized. Please, wrap your components in <DialogsProvider />",
    );
  }

  return ctx;
}

// ------------------------------------------

const DialogsContext = React.createContext<IDialogsContext | undefined>(
  undefined,
);

interface IDialogsContext {
  // Авторизация
  auth: DialogsAuth;

  // API
  api: IDialogsApi;
  socket: IDialogsSocketApi;

  // Store
  store: DialogsStore;
}
