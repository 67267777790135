import { minsToString } from "common/helpers";
import dayjs from "dayjs";
import React, {
  ReactNode,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useUpdateEffect } from "react-use";
import type { Status } from "types/status";
import { getBookingStartTime } from "utils";

// todo: внимательно изучить изменения
interface VisitContextValue {
  isExpired: boolean;
  diff: number;
  diffText: string;
  isLate: boolean;
  visitPercents: number;
  inHall: boolean;
  visitTime: number;
  statusClassName: string;
  statusClassNameManagement: string;
}

const VisitContext = React.createContext<VisitContextValue | null>(null);

interface VisitProviderProps {
  date: string;
  time: string;
  statusSystemName: Status["system_name"] | undefined;
  statusCategory: Status["category"] | undefined;
  visitDuration: number;
  children: ReactNode;
  isManagerialBooking: boolean;
}

export const VisitProvider = memo(
  ({
    children,
    date,
    time,
    statusSystemName,
    statusCategory,
    visitDuration,
    isManagerialBooking,
  }: VisitProviderProps) => {
    const getDiff = useCallback(
      () =>
        Math.max(
          dayjs()
            .startOf("minute")
            .diff(getBookingStartTime(date, time), "minute"),
          0,
        ),
      [date, time],
    );

    const [diff, setDiff] = useState(getDiff);

    useEffect(() => {
      const interval = setInterval(() => {
        setDiff(getDiff());
      }, 1e3);

      return () => clearInterval(interval);
    }, [getDiff]);

    useUpdateEffect(() => {
      setDiff(getDiff());
    }, [statusCategory, getDiff]);

    const inHall = useMemo(
      () => statusCategory === "IN_SERVICE",
      [statusCategory],
    );

    const isExpired = useMemo(() => {
      if (!inHall) return false;
      return diff - visitDuration >= 0;
    }, [visitDuration, diff, inHall]);

    const visitPercents = useMemo(() => {
      if (diff <= 0) {
        return 0;
      }
      if (diff > 0 && diff < visitDuration) {
        return (diff * 100) / visitDuration;
      }
      if (diff >= visitDuration) {
        return 100;
      }
      return 0;
    }, [visitDuration, diff]);

    const isLate = useMemo(() => {
      return statusCategory === "PRE_SERVICE" && diff > 0;
    }, [statusCategory, diff]);

    const statusClassName = useMemo(
      () => statusSystemName?.toLowerCase() || "",
      [statusSystemName],
    );

    const statusClassNameManagement = useMemo(() => {
      return isManagerialBooking ? "management" : "";
    }, [isManagerialBooking]);

    const diffText = useMemo(() => {
      if (isLate) {
        return minsToString(diff);
      }
      const newDiff = diff - visitDuration;
      return minsToString(Math.abs(newDiff));
    }, [diff, visitDuration, isLate]);

    const visitTime = useMemo(() => {
      if (isExpired) return (diff - visitDuration) * -1;
      if (isLate) return diff;
      if (inHall) return visitDuration - diff;
      return visitDuration;
    }, [visitDuration, isLate, isExpired, inHall, diff]);

    const value = useMemo(
      () => ({
        isExpired,
        diff,
        diffText,
        isLate,
        visitPercents,
        inHall,
        visitTime,
        statusClassName,
        statusClassNameManagement,
      }),
      [
        isExpired,
        diff,
        diffText,
        isLate,
        visitPercents,
        inHall,
        visitTime,
        statusClassName,
        statusClassNameManagement,
      ],
    );

    return (
      <VisitContext.Provider value={value}>{children}</VisitContext.Provider>
    );
  },
);

export function useVisitContext() {
  const value = useContext(VisitContext);
  if (!value) {
    throw new Error("Component is not wrapped in VisitProvider");
  }
  return value;
}
