import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { ICONS } from "ui-kit";

import styles from "./GuestNotes.module.scss";

export const NoteContent = ({
  createdAt,
  restaurantName,
  authorName,
  text,
  className,
  id,
}: {
  createdAt: string;
  restaurantName: string;
  authorName: string;
  text: string;
  className: string;
  id: number;
}) => {
  const [showMoreButton, setShowMoreButton] = useState(false);
  const ref = useRef<HTMLParagraphElement>(null);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollHeight !== ref.current.clientHeight &&
        setShowMoreButton(true);
    }
  }, []);

  return (
    <article className={className}>
      <header>
        <span className={styles.date}>
          {createdAt}
          {/* from am | pm to a.m. | p.m. */}
        </span>
        <span>{` ·\u00A0${restaurantName}\u00A0·\u00A0${authorName}`}</span>
      </header>
      <div>
        <input id={`expanded-${id}`} type="checkbox" />
        <p ref={ref}>{text}</p>
        {showMoreButton && (
          <label
            data-showless={formatMessage({ id: ETranslations.LESS })}
            data-showmore={formatMessage({ id: ETranslations.MORE })}
            htmlFor={`expanded-${id}`}
          >
            <ICONS.ArrowDown />
          </label>
        )}
      </div>
    </article>
  );
};
