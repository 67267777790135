import { IPosition } from "@technolab/hse";
import type { TableFigure } from "types/table";

export enum TEMPLATES {
  root = "TABLE_PATH:ROOT",
}

export enum GETTERS {
  getBookings = "TABLE_PATH:GET_BOOKINGS",
  getPlaces = "TABLE_PATH:GET_PLACES",
}

export const getCenterCoords = (
  place: IPosition | undefined,
  config: TableFigure,
) => {
  if (!place) return null;

  const centerX = place.x + (config.width + 40) / 2;
  const centerY = place.y + (config.height + 40) / 2;

  return { x: centerX, y: centerY };
};

export function calculatePath(start: IPosition, end: IPosition) {
  return `
    M ${start.x},${start.y} 
    L ${end.x},${end.y}
  `;
}
