import styles from "./EachTagGroup.module.scss";
import { EditGroupTagsModal } from "./Modals/EditGroupTagsModal";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import type { TagGroup } from "types/tag";
import { ETranslations } from "types/translates";
import { Collapse } from "ui-kit";
import Chips from "ui-kit/Chips/Chips";

interface IEachTagGroup {
  eachGroup: TagGroup;
  selectedTag: string;
  isHostess: boolean;
  isAdminOfRest: boolean;
}

const EachTagGroup = ({
  eachGroup,
  selectedTag,
  isHostess,
  isAdminOfRest,
}: IEachTagGroup) => {
  const { name, color, tags, group_type } = eachGroup;
  const intl = useIntl();
  const [handleOpenEditModal, setHandleOpenEditModal] =
    useState<boolean>(false);
  const onToggleOpenModal = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    setHandleOpenEditModal((prevState) => !prevState);
  };

  const CollapseHeader = useMemo(() => {
    return (
      <div className="collapseHeader">
        <p className={styles.tagGroupName}>
          {name}{" "}
          <span>
            •{" "}
            {intl.formatMessage({
              id:
                group_type === "GLOBAL"
                  ? ETranslations.GLOBAL
                  : ETranslations.LOCAL,
            })}
          </span>
        </p>
        <p
          style={{
            display:
              isHostess || (isAdminOfRest && group_type === "GLOBAL")
                ? "none"
                : "block",
          }}
          onClick={onToggleOpenModal}
        >
          {intl.formatMessage({ id: ETranslations.BASE_CHANGE })}
        </p>
      </div>
    );
  }, [color, name, tags]);

  return (
    <Collapse
      expendInHeader={true}
      header={CollapseHeader}
      headerClassName={styles.header}
      iconClassName={styles.icon}
    >
      <div className="groupsValue">
        {tags?.map((stringContect, index) => {
          return (
            <Chips
              background={color}
              index={index}
              key={`${index}-${stringContect}`}
              textColor="#313131"
              type="INITIAL"
              value={stringContect}
            />
          );
        })}
      </div>

      {handleOpenEditModal && (
        <EditGroupTagsModal
          groupInfo={eachGroup}
          isOpen={handleOpenEditModal}
          selectedTag={selectedTag}
          title={intl.formatMessage({ id: ETranslations.EDIT_GROUP })}
          onClose={setHandleOpenEditModal}
        />
      )}
    </Collapse>
  );
};

export default EachTagGroup;
