import dayjs from "dayjs";
import React, { FC, useCallback, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { TNullable } from "types/commons";
import { Shift, ShiftModalType } from "types/shift";
import { ETranslations } from "types/translates";
import { Button, Card, Tabs } from "ui-kit";

import { Modal } from "../../../modal";
import { getDurationTime } from "../../utils";
import { INDEFINITE_DATE } from "../EditFormContainer/EditFormContainer";
import styles from "../Form.module.scss";
import CapacityFields from "./CapacityFields";
import { MessagesForm } from "./MessagesForm";
import PropertiesFields from "./PropertiesFields";

const buildTimeOption = (time: string) => {
  const formatTime = dayjs.locale() === "ru" ? "HH:mm" : "h:mm A";

  const date = dayjs(time, "HH.mm.ss");
  const hour = date.get("hours");
  const minutes = date.get("minutes");
  date.set("minutes", hour * 60 + minutes);
  return {
    label: date.format(formatTime),
    value: hour * 60 + minutes,
  };
};

type TTabName = string;

//TODO: ODO: 2 iteration
// const timeIntervalOptions = [{label: '15 minutes', value: 15}, {label: '30 minutes', value: 30}]

export type TProps = {
  shift: TNullable<Partial<Shift>>;
  onCancel: (view: ShiftModalType) => void;
  onSubmitCallBack: (formData: any) => void;
  isLoadingSubmit: boolean;
};

const EditShiftForm: FC<TProps> = ({
  shift,
  onCancel,
  onSubmitCallBack,
  isLoadingSubmit,
}) => {
  if (!shift) return null;

  const durations =
    shift.capacity?.durations && getDurationTime(shift.capacity.durations);
  const intl = useIntl();
  const properties = intl.formatMessage({ id: ETranslations.PROPERTIES });
  const capacity = intl.formatMessage({ id: ETranslations.CAPACITY });
  const messages = intl.formatMessage({ id: ETranslations.MESSAGES });

  const TABS: TTabName[] = shift.id
    ? [properties, capacity, messages]
    : [properties, capacity];
  const [activeTab, setActiveTab] = useState<TTabName>(TABS[0]);

  const init = useMemo(() => {
    return {
      ...shift,
      shift_name: shift.shift_name,
      start_date: shift.start_date && new Date(shift.start_date),
      end_date:
        shift.end_date &&
        shift.end_date !== INDEFINITE_DATE &&
        new Date(shift.end_date),
      continue_indefinite: shift.end_date === INDEFINITE_DATE,
      repeatDays: shift.repeat_days,
      open_time: buildTimeOption(shift.start_active_time || ""),
      close_time: buildTimeOption(shift.end_active_time || ""),
      places: shift.places_id,
      durations: durations,
      use_max_covered: shift.capacity?.use_max_covered,
      max_covered_size: shift.capacity?.max_covered_size,
      booking_interval: {
        label: shift.booking_interval,
        value: shift.booking_interval,
      },
    };
  }, []);

  const onSubmit = useCallback(async (formData) => {
    onSubmitCallBack(formData);
  }, []);

  return (
    <Card onClose={close}>
      <Tabs
        activeTab={activeTab}
        className={styles.tabs}
        onChange={(e) => setActiveTab(e)}
      >
        {TABS.map((tab) => (
          <Tabs.Item key={tab} tabName={tab} title={tab} />
        ))}
      </Tabs>
      {activeTab !== messages && (
        <Card.Content noPadding>
          <Form initialValues={init} onSubmit={onSubmit}>
            {({ handleSubmit, submitting, invalid, form }) => (
              <form className={styles.content} onSubmit={handleSubmit}>
                {activeTab === properties && (
                  <PropertiesFields form={form} shift={shift} />
                )}
                {activeTab === capacity && <CapacityFields form={form} />}

                <Modal.Footer className={styles.modalFooter}>
                  <Button variant="secondary" onClick={() => onCancel("INFO")}>
                    {intl.formatMessage({ id: ETranslations.CANCEL })}
                  </Button>
                  <Button
                    disabled={isLoadingSubmit || submitting || invalid}
                    type="submit"
                    variant="primary"
                  >
                    {intl.formatMessage({ id: ETranslations.SAVE })}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          </Form>
        </Card.Content>
      )}
      {activeTab === messages && shift.id && (
        <MessagesForm shiftId={shift.id} />
      )}
    </Card>
  );
};

export default EditShiftForm;
