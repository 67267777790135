import cn from "classnames";
import { FunctionComponent } from "react";



import styles from "./Tag.module.scss";


interface Props {
  tag?: { name: string; color?: string; status?: string | undefined };
  className?: string;
  title?: string;
}

/* const tagsToShortView = (tags: Tag[], count: number) => ({
  visibleTags: tags.slice(0, count),
  invisibleTagsCount: tags.length - count,
}); */

export const Tag: FunctionComponent<Props> = ({ tag, className, title }) => (
  <em
    className={cn(styles.tag, tag?.status && styles.status, className)}
    style={{
      backgroundColor: tag?.status ? tag.status : tag?.color,
    }}
    title={title}
  >
    {tag?.name}
  </em>
);