import { config } from "config";
import { restaurantSelector } from "features/AppContext";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useInterval } from "react-use";

import { VerticalSeparator } from "../../common/components/VerticalSeparator";
import { TelephonyAction } from "../../models/telephony.model";
import { CallsService } from "../../services/calls.service";
import { ETranslations } from "../../types/translates";
import { HideWhen } from "../HideWhen";
import { PhoneLines } from "./lines/lines";
import { PhoneModal } from "./modal/phone-modal";
import { PhoneIndicator } from "./phone-indicator";
import { PhoneMenu } from "./phone-menu";
import styles from "./telephony.module.scss";

export const Telephony = () => {
  const intl = useIntl();
  const restaurant = useSelector(restaurantSelector);
  const isWork = config.telephonyEnabled;
  //FIXME: проверка на админа
  //&& !userIsAdmin();
  const [modalTrigger, setModalTrigger] = useState<boolean>(false);
  const [phoneLines, setPhoneLines] = useState<TelephonyAction[]>([]);

  const getActiveCalls = (restId: number) => {
    CallsService.getActiveCalls(restId).then((activeCalls) => {
      if (activeCalls.data) {
        setPhoneLines(activeCalls.data);
      }
    });
  };

  useEffect(() => {
    if (isWork && restaurant) {
      getActiveCalls(restaurant.restaurant_id);
    }
  }, [restaurant]);
  useInterval(() => {
    if (isWork && restaurant) {
      getActiveCalls(restaurant.restaurant_id);
    }
  }, 5000);
  const isActive = (): boolean => phoneLines.length > 0;
  const showModal = useCallback((): any => {
    setModalTrigger(!modalTrigger);
  }, [modalTrigger]);
  return (
    <>
      <HideWhen condition={!isWork}>
        <>
          <VerticalSeparator />
          <div className={styles.container}>
            <PhoneIndicator isActive={isActive()} />
            <PhoneMenu clickHandler={showModal} />
            <PhoneModal
              closeModal={showModal}
              title={intl.formatMessage({ id: ETranslations.ACTIVE_CALLS })}
              visibility={modalTrigger}
            >
              <PhoneLines data={phoneLines} onClose={() => showModal()} />
            </PhoneModal>
          </div>
        </>
      </HideWhen>
    </>
  );
};
