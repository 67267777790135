import type { FeedContent } from "types/communication";
import { ETranslations } from "types/translates";
import { ICONS } from "ui-kit";


export const separateFeeds = (
  feeds: FeedContent[],
  linkedFeedIds: string[] | undefined,
) =>
  linkedFeedIds?.length
    ? feeds.reduce(
        (result, feed) => (
          linkedFeedIds.includes(feed.id.feed_id)
            ? result.linkedFeeds.push(feed) &&
              result.missingFeedsIds.delete(feed.id.feed_id)
            : result.activeFeeds.push(feed),
          result
        ),
        {
          linkedFeeds: new Array<FeedContent>(),
          activeFeeds: new Array<FeedContent>(),
          missingFeedsIds: new Set(linkedFeedIds),
        },
      )
    : {
        linkedFeeds: [],
        activeFeeds: feeds,
        missingFeedsIds: new Set<string>(),
      };
export const separateFeeds2 = (
  feeds: FeedContent[],
  linkedFeedIds: string[],
) => {
  const separatedLists = feeds.reduce(
    (result, feed) => (
      linkedFeedIds.includes(feed.id.feed_id)
        ? result.linkedFeeds.push(feed)
        : result.activeFeeds.push(feed),
      result
    ),
    {
      linkedFeeds: new Array<FeedContent>(),
      activeFeeds: new Array<FeedContent>(),
    },
  );
  return {
    linkedFeeds: separatedLists.linkedFeeds,
    activeFeeds: separatedLists.activeFeeds,
    missingFeeds: linkedFeedIds.filter(
      (feedId) =>
        !separatedLists.linkedFeeds.some((feed) => feed.id.feed_id === feedId),
    ),
  };
};

const FEED_STATUS_INFO = {
  COMPLETED: {
    text: ETranslations.FEED_COMPLETED,
    icon: ICONS.AlarmEnd,
  },
  CANCELLED: {
    text: ETranslations.FEED_CANCELLED,
    icon: ICONS.AlarmCancel,
  },
  NOT_COMPLETED: {
    text: ETranslations.FEED_NOT_COMPLETED,
    icon: ICONS.AlarmNew,
  },
  UNKNOWN: {
    text: ETranslations.FEED_UNKNOWN,
    icon: undefined,
  },
};

export type StatusIcon =
  (typeof FEED_STATUS_INFO)[keyof typeof FEED_STATUS_INFO]["icon"];

export const getStatusIcon = (feedStatus: FeedContent["status_close_state"]) =>
  FEED_STATUS_INFO[feedStatus]?.icon;

export const getStatusText = (feedStatus: FeedContent["status_close_state"]) =>
  (FEED_STATUS_INFO[feedStatus] || FEED_STATUS_INFO.UNKNOWN).text;