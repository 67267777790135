import { useFormActiveShift } from "./useFormActiveShift";
import { useMemo } from "react";
import { useField } from "react-final-form";
import { GuestDuration } from "types/shifts";

export function useDefaultVisitTime(isFromManagement = false) {
  const {
    input: { value: persons },
  } = useField("persons");
  const shift = useFormActiveShift();

  return useMemo(() => {
    if (!shift) return undefined;
    if (!persons) return undefined;
    if (isFromManagement) return undefined;

    const key = (persons >= 10 ? 10 : persons) as keyof GuestDuration;
    return shift.guest_duration[key];
  }, [persons, shift]);
}
