import cn from "classnames";
import { type InputHTMLAttributes, forwardRef, useState } from "react";

import styles from "./ToggleSwitch.module.scss";

const ToggleSwitch = ({
  isEnabled,
  onToggle,
  disabled,
}: {
  isEnabled: boolean;
  onToggle: () => Promise<boolean | undefined>;
  disabled: boolean;
}) => {
  const [isActive, setIsActive] = useState(() => isEnabled);

  const handleChangeToggle = () => {
    setIsActive((prev) => !prev); //optimistic update
    onToggle().then((value) => setIsActive((prev) => value ?? !prev)); //real update
  };
  return (
    <label
      className={cn(styles.switchToggleContainer, {
        [styles.disabled]: disabled,
      })}
    >
      <input
        checked={isActive}
        className={styles.switchToggleInput}
        type="checkbox"
        onChange={handleChangeToggle}
      />
      <span className={styles.switchToggle} />
    </label>
  );
};

const UncontrolledToggleSwitch = forwardRef<
  HTMLInputElement,
  {
    isEnabled: boolean;
    onToggle?: () => void;
  } & InputHTMLAttributes<HTMLInputElement>
>(({ isEnabled, onToggle, ...props }, ref) => {
  return (
    <label
      className={cn(styles.switchToggleContainer, {
        [styles.disabled]: props.disabled,
      })}
    >
      <input
        ref={ref}
        defaultChecked={isEnabled}
        className={styles.switchToggleInput}
        type="checkbox"
        onChange={onToggle}
        {...props}
      />
      <span className={styles.switchToggle} />
    </label>
  );
});

ToggleSwitch.displayName = "ToggleSwitch";
UncontrolledToggleSwitch.displayName = "UncontrolledToggleSwitch";

export { ToggleSwitch, UncontrolledToggleSwitch };
