import { restaurantSelector } from "features/AppContext";
import { IResponse } from "models/common";
import type { Tag, TagId } from "models/tags.model";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { api } from "./api";

type Tags = { tags: Tag[]; tagsById: Record<Tag["id"], Tag> };

const tagsApi = api
  .enhanceEndpoints({ addTagTypes: ["Tag", "Tags"] })
  .injectEndpoints({
    endpoints: ($) => ({
      getTags: $.query<
        Tags,
        {
          owner_id: number | `${number}`;
          type: "BOOKING" | "CLIENT";
          include_deleted: boolean;
          ids?: number[] | `${number}`[];
        }
      >({
        query: ({ owner_id, type, include_deleted, ids }) => ({
          url: "/v2/tags/available",
          params: { destination: type, owner_id, include_deleted, ids },
        }),
        transformResponse: (
          response: IResponse<
            Array<{
              color: string;
              description: string;
              tag_id: number;
              group_name: string;
            }>
          >,
        ) =>
          response.data.reduce<Tags>(
            (result, t) => {
              const tag = {
                color: t.color,
                name: t.description,
                id: t.tag_id as TagId,
                group: t.group_name ?? t.color, // FIXME: убрать fallback на color
              };
              result.tagsById[t.tag_id as TagId] = tag;
              result.tags.push(tag);
              return result;
            },
            { tags: Array<Tag>(), tagsById: {} },
          ),
        providesTags: (_res, _err, args) => [
          { type: "Tags", id: `${args.type}-${args.owner_id}` },
        ],
      }),
    }),
  });

const { useGetTagsQuery } = tagsApi;

export { useGetTagsQuery };

export function useTags(ids: number[] | `${number}`[]) {
  const restaurant = useSelector(restaurantSelector).restaurant_id;
  // Загружаем все теги, возможные по данному ресторану, в том числе и удаленные
  const { data } = useGetTagsQuery({
    owner_id: restaurant,
    type: "CLIENT",
    include_deleted: true,
  });
  // Фильтруем на клиенте по id, которые нам нужны.
  //Можно было id отправить и на сервер, но тогда будет больше запросов. Поменять, если это будет необходимо
  return useMemo(
    () =>
      data &&
      ids.reduce<Tag[]>((result, id) => {
        const tag = data.tagsById[id as TagId];
        tag && result.push(tag);
        return result;
      }, []),
    [ids, data?.tags.length, restaurant],
  );
}

export function useTagsOptions({
  type,
  owner_id,
  include_deleted,
}: {
  type: "BOOKING" | "CLIENT";
  owner_id: number | `${number}`;
  include_deleted: boolean;
}) {
  const { data } = useGetTagsQuery({
    owner_id,
    type,
    include_deleted,
  });
  return useMemo(
    () => data?.tags.map((t) => ({ ...t, label: t.name, value: t.id })) ?? [],
    [data],
  );
}

export const useClientTagsOptions = (
  restaurantId: number | `${number}`,
  include_deleted?: boolean,
) =>
  useGetTagsQuery({
    owner_id: restaurantId,
    type: "CLIENT",
    include_deleted: Boolean(include_deleted),
  }).data?.tags.map((t) => ({ label: t.name, value: t.id })) || [];
