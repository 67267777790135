import styles from "./GuestNotes.module.scss";
import { MAX_NOTE_CHAR_COUNT } from "./utils";
import { Modal } from "components/modal";
import { useState } from "react";
import type { IntlFormatters } from "react-intl";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";
import { capitalizeString } from "utils";

export const NoteModal = ({
  title,
  isOpen,
  close,
  disabled,
  formatMessage,
  submit,
  defaultNoteText,
  submitButtonText,
}: {
  title: string;
  isOpen: boolean;
  close: () => void;
  disabled: boolean;
  formatMessage: IntlFormatters["formatMessage"];
  submit: (note: string) => Promise<unknown>;
  submitButtonText: string;
  defaultNoteText?: string;
}) => {
  const [textNote, setTextNote] = useState(defaultNoteText || "");

  const handleNoteFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    submit(textNote).then(close);
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      title={title}
      onClose={close}
    >
      <Modal.Content className={styles.modalBody}>
        <form
          className={styles.noteForm}
          id="create-note-form"
          onSubmit={handleNoteFormSubmit}
        >
          <label htmlFor="note">
            <h2>
              {capitalizeString(formatMessage({ id: ETranslations.NOTE }))}
            </h2>
            <span>{`${textNote.length}/${MAX_NOTE_CHAR_COUNT}`}</span>
          </label>
          <textarea
            maxLength={MAX_NOTE_CHAR_COUNT}
            name="note"
            rows={6}
            value={textNote}
            required
            onChange={(e) => setTextNote(e.currentTarget.value)}
          />
        </form>
      </Modal.Content>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          disabled={disabled}
          form="create-note-form"
          type="submit"
          variant="primary"
        >
          {submitButtonText}
        </Button>
        <Button type="button" variant="secondary" onClick={close}>
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
