import type { SeatType } from "models/booking.model";
import { ICONS } from "ui-kit";


export const SEAT_TYPE_ICONS: {
  [key in SeatType]?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  MANAGER_BOOK: ICONS.OverviewBook,
  BOOK: ICONS.OverviewBook,
  IN_WALK: ICONS.VisitorBook,
};