import { getFullName } from "common/helpers";
import type { BookingSlot } from "models/booking.model";
import { HTMLAttributes, forwardRef } from "react";

import styles from "./dndPreview.module.scss";

type TBookingCardDndLayoutProps = {
  booking: BookingSlot;
} & Pick<HTMLAttributes<HTMLSpanElement>, "style">;

export const BookingCardDndLayout = forwardRef<
  HTMLSpanElement,
  TBookingCardDndLayoutProps
>((data, ref) => {
  const { booking, style } = data;
  if (!booking?.client) return null;
  return (
    <span className={styles.bookingCardPreview} ref={ref} style={style}>
      {getFullName(
        booking.client.name,
        booking.client.middle_name,
        booking.client.surname,
      )}
    </span>
  );
});
