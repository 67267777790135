import { createSelector } from "@reduxjs/toolkit";
import { restaurantSelector } from "features/AppContext";
import { GlobalSearchParams } from "types/globalSearch";

import { globalSearchSliceName, initialState } from "./index";
import { Slice } from "./slice";

const selectDomain = (state: any): Slice =>
  state[globalSearchSliceName] || initialState;

export const params = createSelector(
  [selectDomain, restaurantSelector],
  (domain, restaurant): GlobalSearchParams => ({
    ...domain,
    restaurantId: restaurant.restaurant_id,
  }),
);
