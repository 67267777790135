import { zodResolver } from "@hookform/resolvers/zod";
import {
  useCreateSourceMutation,
  useUpdateSourceMutation,
} from "features/api/source-api";
import {
  type OrganizationId,
  type OrganizationName,
  Source,
  type SourceType,
} from "models/source.model";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { type Value as E164Number } from "react-phone-number-input";
import { useOutletContext } from "react-router-dom";
import { ETranslations } from "types/translates";
import {
  Button,
  FieldError,
  Input,
  Labeled,
  LinkButton,
  SelectBasic,
} from "ui-kit";
import { commonFormErrorMap } from "utils";

import { SourceFormSchema, getSourceFormSchema } from "../../model/schema";
import styles from "./SourceForm.module.scss";
import { SourceFormOrganizationInput } from "./SourceFormOrganizationInput";

const SourceForm = ({
  surname,
  name,
  middleName,
  phone,
  additionalPhone,
  email,
  type,
  organizationId,
  organizationName,
  disabled,
  error,
  onSubmit,
}: {
  surname?: string;
  name?: string;
  middleName?: string;
  phone?: string;
  additionalPhone?: string;
  email?: string;
  type?: SourceType;
  organizationId?: OrganizationId;
  organizationName?: string;
  disabled?: boolean;
  error?: unknown;
  onSubmit: (data: SourceFormSchema) => void;
}) => {
  const { formatMessage } = useIntl();
  const { register, handleSubmit, formState, control } =
    useForm<SourceFormSchema>({
      resolver: zodResolver(getSourceFormSchema(formatMessage), {
        errorMap: commonFormErrorMap,
      }),
      defaultValues: {
        surname,
        name,
        middleName,
        phone,
        additionalPhone,
        email,
        type,
        organizationId,
        organizationName,
      },
    });
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
        id="source-form"
      >
        <Input
          label={formatMessage({ id: ETranslations.LAST_NAME })}
          placeholder={formatMessage(
            { id: ETranslations.BASE_ENTER_VALUE },
            { value: formatMessage({ id: ETranslations.LAST_NAME_ALT }) },
          )}
          autoComplete="off"
          {...register("surname", { required: false })}
          error={formState.errors.surname?.message}
        />
        <Input
          label={formatMessage({ id: ETranslations.NAME })}
          placeholder={formatMessage(
            { id: ETranslations.BASE_ENTER_VALUE },
            { value: formatMessage({ id: ETranslations.NAME }).toLowerCase() },
          )}
          autoComplete="off"
          required
          {...register("name")}
          error={formState.errors.name?.message}
        />
        <Input
          label={formatMessage({ id: ETranslations.MIDDLE_NAME })}
          placeholder={formatMessage(
            { id: ETranslations.BASE_ENTER_VALUE },
            {
              value: formatMessage({
                id: ETranslations.MIDDLE_NAME,
              }).toLowerCase(),
            },
          )}
          autoComplete="off"
          {...register("middleName", { required: false })}
          error={formState.errors.middleName?.message}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, value } }) => (
            <Input.Phone
              label={formatMessage({ id: ETranslations.PHONE })}
              placeholder={formatMessage(
                { id: ETranslations.BASE_ENTER_VALUE },
                {
                  value: formatMessage({
                    id: ETranslations.PHONE,
                  }).toLowerCase(),
                },
              )}
              autoComplete="off"
              required
              value={value as E164Number}
              onChange={(v) => onChange(v || "")}
              error={formState.errors.phone?.message}
            />
          )}
        />
        <Input
          label={formatMessage({ id: ETranslations.ADDITIONAL_PHONE })}
          type="tel"
          placeholder={formatMessage(
            { id: ETranslations.BASE_ENTER_VALUE },
            {
              value: formatMessage({
                id: ETranslations.ADDITIONAL_PHONE,
              }).toLowerCase(),
            },
          )}
          autoComplete="off"
          {...register("additionalPhone", { required: false })}
          error={formState.errors.additionalPhone?.message}
        />
        <Input
          label={formatMessage({ id: ETranslations.EMAIL })}
          type="email"
          placeholder={formatMessage(
            { id: ETranslations.BASE_ENTER_VALUE },
            {
              value: formatMessage({
                id: ETranslations.EMAIL,
              }).toLowerCase(),
            },
          )}
          autoComplete="off"
          {...register("email", { required: false })}
          error={formState.errors.email?.message}
        />
        <Controller
          control={control}
          name="type"
          render={({ field: { onChange, value } }) => (
            <Labeled label={formatMessage({ id: ETranslations.TYPE })} required>
              <SelectBasic
                options={[
                  {
                    value: "CONCIERGE",
                    label: "CONCIERGE",
                  },
                  { value: "PERSONAL_ASSISTANT", label: "PERSONAL_ASSISTANT" },
                  { value: "LIFESTYLE_MANAGER", label: "LIFESTYLE_MANAGER" },
                  { value: "PRIME_CONCIERGE", label: "PRIME_CONCIERGE" },
                  { value: "OPEN_SOURCE", label: "OPEN_SOURCE" },
                ]}
                placeholder={formatMessage(
                  { id: ETranslations.BASE_SELECT_ENTITY },
                  {
                    entity: formatMessage({
                      id: ETranslations.TYPE,
                    }).toLowerCase(),
                  },
                )}
                value={
                  value
                    ? { value, label: formatMessage({ id: value }) }
                    : undefined
                }
                onChange={(option) =>
                  option &&
                  (Array.isArray(option)
                    ? onChange(option[0].value)
                    : onChange(
                        (option as { value: SourceType; label: SourceType })
                          .value,
                      ))
                }
                error={
                  formState.errors.type?.message &&
                  formatMessage(
                    { id: formState.errors.type.message },
                    {
                      field: formatMessage({
                        id: ETranslations.TYPE,
                      }).toLowerCase(),
                    },
                  )
                }
              />
            </Labeled>
          )}
        />
        <SourceFormOrganizationInput
          control={control}
          label={formatMessage({ id: ETranslations.ORGANIZATION })}
        />
        {(error || formState.errors.root) && (
          <section className={styles.formErrors}>
            <FieldError
              error={
                [JSON.stringify(error), formState.errors.root?.message].filter(
                  Boolean,
                ) as string[]
              }
            />
          </section>
        )}
      </form>
      <footer className={styles.formFooter}>
        <LinkButton to=".." variant="secondary">
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </LinkButton>
        <Button
          disabled={
            disabled ||
            formState.disabled ||
            !formState.isDirty ||
            !formState.isValid
          }
          type="submit"
          form="source-form"
          variant="primary"
        >
          {formatMessage({ id: ETranslations.BASE_SAVE })}
        </Button>
      </footer>
    </>
  );
};

export const EditSource = () => {
  const source = useOutletContext<Source>();
  const [update, { isLoading, error }] = useUpdateSourceMutation();
  return (
    <SourceForm
      surname={source.source_contact?.surname || undefined}
      name={source.source_contact?.name || undefined}
      middleName={source.source_contact?.middle_name || undefined}
      phone={source.source_contact?.phone || undefined}
      additionalPhone={source.source_contact?.extension_phone || undefined}
      email={source.source_contact?.email || undefined}
      type={source.source_contact?.label || undefined}
      organizationId={source.organization?.id || undefined}
      organizationName={source.organization?.name || undefined}
      //
      disabled={isLoading}
      error={error}
      //
      onSubmit={(updatedSource) =>
        update({
          id: source.source_id,
          organization:
            updatedSource.organizationName || updatedSource.organizationId
              ? {
                  id: updatedSource.organizationId as
                    | OrganizationId
                    | undefined,
                  name: updatedSource.organizationName as OrganizationName,
                }
              : undefined,
          source_contact: {
            id: source.source_contact?.id || undefined,
            label: updatedSource.type,
            phone: updatedSource.phone,
            extension_phone: updatedSource.additionalPhone || undefined,
            email: updatedSource.email || undefined,
            surname: updatedSource.surname || undefined,
            middle_name: updatedSource.middleName || undefined,
            name: updatedSource.name,
          },
        })
      }
    />
  );
};

export const CreateSource = () => {
  const [create, { isLoading, error }] = useCreateSourceMutation();
  return (
    <SourceForm
      //
      disabled={isLoading}
      error={error}
      //
      onSubmit={(updatedSource) =>
        create({
          organization:
            updatedSource.organizationName || updatedSource.organizationId
              ? {
                  id: updatedSource.organizationId as
                    | OrganizationId
                    | undefined,
                  name: updatedSource.organizationName as OrganizationName,
                }
              : undefined,
          source_contact: {
            label: updatedSource.type,
            phone: updatedSource.phone,
            extension_phone: updatedSource.additionalPhone || undefined,
            email: updatedSource.email || undefined,
            surname: updatedSource.surname || undefined,
            middle_name: updatedSource.middleName || undefined,
            name: updatedSource.name,
          },
        })
      }
    />
  );
};
