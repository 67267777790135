import {
  useCreateClientMutation,
  useLazyFetchClientDetailQuery,
  useLazyFetchClientQuery,
  useUpdateClientMutation,
} from "features/api/client-api";
import { useIntlUtils } from "hooks/useIntlUtils";
import { useCallback, useMemo } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";

import { useRegisterWithOverbooking } from "./useRegisterWithOverbooking";

const useRegisterVisit = ({
  reset,
  isFromManagement,
}: {
  reset: any;
  isFromManagement?: boolean;
}) => {
  const { getIntlRegistrationOf, isRussianLocale } = useIntlUtils();
  const registrationTitle = useMemo(() => {
    return getIntlRegistrationOf(
      isRussianLocale
        ? ETranslations.PLURAL_VISITS_ALT
        : ETranslations.PLURAL_VISIT,
    );
  }, []);
  const {
    create,
    createWithOverbooking,
    clearData,
    isConfirmationRequired,
    isCreating,
    closeModal,
    hasManagerialError,
  } = useRegisterWithOverbooking();
  const location = useLocation() as Location<{ from: string } | null>;
  const navigate = useNavigate();
  const [createGuest] = useCreateClientMutation();
  const [getUser] = useLazyFetchClientDetailQuery();
  const [updateGuest] = useUpdateClientMutation();
  const [getClient] = useLazyFetchClientQuery();

  const close = useCallback(() => {
    reset();
    const from = location.state?.from;
    from !== location.pathname && navigate(from || "/dashboard");
  }, [history, reset]);

  const registerVisit = useCallback(
    async (data) => {
      let { client } = data;
      const { client_id, phone, email } = data;
      if (client && data?.contact?.contact_type !== "CONTACT" && phone) {
        const {
          editClient = false,
          name,
          surname = "",
          middle_name = "",
        } = client;
        if (!client.client_id) {
          const {
            data: { client_id: newClientId },
          } = await createGuest({
            middle_name,
            name,
            phone,
            surname,
            email,
          }).unwrap();
          client = await getUser(newClientId).unwrap();
        }
        if (editClient && client_id) {
          getClient(client_id)
            .then(async (response) => {
              const newUser = {
                ...response.data,
                surname,
                name,
                middle_name,
                type_authorization: "AUTH",
              };
              return newUser;
            })
            .then(async (newUser) => {
              //@ts-ignore
              await updateGuest(newUser);
            });
        }
      }
      await create({
        ...data,
        seatType: isFromManagement ? "MANAGER_BOOK" : "IN_WALK",
        client,
      });
      close();
    },
    [create, close],
  );

  const registerOverbooking = useCallback(async () => {
    await createWithOverbooking();
    close();
  }, [createWithOverbooking, close]);

  return {
    registerVisit,
    registrationTitle,
    isConfirmationRequired,
    clearData,
    registerOverbooking,
    isCreating,
    close,
    hasManagerialError,
    closeModal,
  };
};

export default useRegisterVisit;
