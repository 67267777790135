import { useIntlUtils } from "../../../../hooks/useIntlUtils";
import React, { useEffect, useState } from "react";

const useChoiceNotesInput = (needResize: boolean) => {
  const { getIntlEnterEntity } = useIntlUtils();
  const [textareaHeight, setTextareaHeight] = useState("auto");

  const autoResize = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const textarea = event.currentTarget;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    needResize && setTextareaHeight(`${textarea.scrollHeight}px`);
  };

  useEffect(() => {
    // Set initial height when component mounts
    const textarea = document.getElementById("textarea-id");
    if (textarea && needResize) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
      setTextareaHeight(`${textarea.scrollHeight}px`);
    }
  }, []);
  return {
    autoResize,
    textareaHeight,
    getIntlEnterEntity,
  };
};

export default useChoiceNotesInput;
