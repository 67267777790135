import type { HallSlotsDTO } from "features/api/hallschema-api";
import { useMemo } from "react";

export type TTableSlots = Record<string, HallSlotsDTO["slots"]>;

export function useTablesSlots(hallSlots: HallSlotsDTO[]) {
  return useMemo(() => {
    return hallSlots.reduce<TTableSlots>((acc, { slots, slot_place }) => {
      acc[slot_place.table_id] = slots.filter(
        (slot) =>
          slot.slot.slot_type !== "BOOKING" ||
          slot.slot.status.category !== "TERMINAL",
      );
      return acc;
    }, {});
  }, [hallSlots]);
}
