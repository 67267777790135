import { config } from "config";
import { isClientEditing } from "features/BookingFormProxy/selectors";
import { throttle } from "lodash";
import type { Client } from "models/client.model";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useField, useForm } from "react-final-form";
import {
  type Value as E164Number,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useThrottle } from "react-use";

import { useFromProxyActions } from "../../../../features/BookingFormProxy";
import { useClientListActions } from "../../../../features/ClientList";
import { clientListFilter } from "../../../../features/ClientList/selectors";
import {
  HallMode,
  useHallSchemaActions,
} from "../../../../features/HallSchema";
import { hallModeSelector } from "../../../../features/HallSchema/selectors";
import { useFetchClientsPageQuery } from "../../../../features/api/client-api";
import { useIsTablet } from "../../../../hooks/useIsTablet";
import { isRegView } from "../../../hall-scheme/redux/HallSchemaV2/hall-helpersFn";
import { isRealClient } from "../../validators/validators";

const useChoicePhoneRedux = ({
  value,
  onChange,
  disabled,
  isCreateBooking,
  openGuestList,
  isFromDialog,
}: {
  value: string | undefined;
  onChange: any;
  disabled: boolean;
  isCreateBooking: boolean;
  openGuestList?: () => void;
  isFromDialog?: boolean;
}) => {
  const hallMode = useSelector(hallModeSelector);
  const filter = useSelector(clientListFilter);
  const { applyFilter, reset } = useClientListActions();
  const { setClient, resetClient, setEditClient } = useFromProxyActions();
  const isEditing = useSelector(isClientEditing);
  const {
    input: { value: client },
  } = useField<Client>("client");
  const [searchPhone, setSearchPhone] = useState(value);
  const { pathname } = useLocation();

  const isEmpty = useMemo(
    () =>
      Boolean(
        value?.trim() === config.defaultPhoneNumber || !value?.trim().length,
      ),
    [value],
  );

  useThrottle(() => {
    setSearchPhone(value);
  }, 1500);

  const filterWithPhone = useMemo(
    () => ({
      ...filter,
      phone:
        searchPhone === config.defaultPhoneNumber ? undefined : searchPhone,
    }),
    [filter, searchPhone],
  );
  // this hook is for cases when guest component is unmount
  const { data = [] } = useFetchClientsPageQuery(filterWithPhone);
  const isTablet = useIsTablet();
  const form = useForm();

  const throttledFilter = useMemo(
    () => throttle(applyFilter, 1e3),
    [applyFilter],
  );

  // why? todo: check if we can remove it
  useEffect(() => {
    if (disabled || !value) return;
    const [nextClient] = data;
    if (isValidPhoneNumber(value) || isTablet) {
      if (
        data?.length === 1 &&
        nextClient.phone?.replace(/\D/g, "") === value.replace(/\D/g, "")
      ) {
        setClient({ client: data[0] });
        form.batch(() => {
          form.change("client", data[0]);
          form.change(
            "phone",
            formatPhoneNumberIntl(data[0].phone as E164Number),
          );
          form.change("client_id", data[0].client_id);
        });
      }
    }
  }, [data, disabled, value]);

  useEffect(() => {
    if (!value || (!isValidPhoneNumber(value) && !client)) {
      resetClient();
    }
  }, [value, client]);

  useEffect(() => {
    //@ts-ignore
    if (isRealClient(client) && client?.phone && !disabled) {
      //@ts-ignore
      const clientPhone = formatPhoneNumberIntl(
        client.phone.replace(/^([^\D])/g, "+$1") as E164Number,
      );
      clientPhone && onChange(clientPhone);
    }
  }, [client]);

  const { switchMode } = useHallSchemaActions();

  const showClients = () => {
    openGuestList?.();
    if (isRegView(hallMode)) {
      switchMode(HallMode.REGISTRATION_GUESTS);
      return;
    }

    if (hallMode === HallMode.MANAGERIAL_CREATE_BOOKING) {
      switchMode(HallMode.MANAGERIAL_OPEN_GUESTS);
      return;
    }

    if (hallMode === HallMode.TABLE_BOOKINGS_EDIT) {
      switchMode(HallMode.TABLE_BOOKINGS_EDIT_GUEST);
      return;
    }
    switchMode(HallMode.BOOKING_GUEST);
  };

  const clearPhoneNumber = useCallback(() => {
    if (disabled) return;
    setEditClient(true);
    if (!isFromDialog) {
      reset();
      form.batch(() => {
        form.change("client", undefined);
        form.change("client_id", undefined);
      });
      resetClient();
    }
  }, [onChange, disabled]);

  useEffect(() => {
    if (value && value.length >= 3) {
      throttledFilter({ ...filter, phone: value });
    }
  }, [value]);

  const showPopUp =
    client?.client_id && (pathname.includes("search") || isCreateBooking);

  const isDisabled = Boolean(
    value &&
      isValidPhoneNumber(value) &&
      (disabled || client?.client_id) &&
      !(isFromDialog && isEditing),
  );

  return {
    clearPhoneNumber,
    showClients,
    client,
    isEmpty,
    showPopUp,
    isDisabled,
  };
};

export default useChoicePhoneRedux;
