import { Placement } from "@popperjs/core";
import { ClientDetailsPopup } from "components/BookingClientDetails";
import { Popup } from "components/Popup";
import type { ClientId } from "models/client.model";
import { ReactElement } from "react";
import { stopPropagation } from "utils";

interface BookingInfoPopupProps {
  clientId: ClientId;
  children: ReactElement;
  placement?: Placement;
}

export function ClientInfoPopup({
  children,
  clientId,
  placement = "auto",
}: BookingInfoPopupProps) {
  return (
    <Popup placement={placement}>
      <Popup.Trigger>{children}</Popup.Trigger>
      <Popup.Content noPadding>
        <ClientDetailsPopup clientId={clientId} onClick={stopPropagation} />
      </Popup.Content>
    </Popup>
  );
}
