import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  placeSelector,
  restaurantSelector,
  selectedPlacesSelector,
} from "features/AppContext";
import {
  useGetPlacesTablesQuery,
  useGetTablesInfoQuery,
} from "features/api/tables-api";
import moment from "moment";
import { ReactNode, useMemo } from "react";
import { useFormState } from "react-final-form";
import { useSelector } from "react-redux";

export interface TableOption {
  value: number;
  number: string | number;
  type: number;
  label: ReactNode;
}

export const useTablesInfo = () => {
  const rest = useSelector(restaurantSelector);
  const placeId = useSelector(placeSelector);
  const places = useSelector(selectedPlacesSelector);
  const { data } = useGetPlacesTablesQuery(places);
  const {
    values: { time, visit_time, duration },
  } = useFormState();
  const chosenDate = moment(time?.value);
  const isDateValid = chosenDate.isValid();
  const bookingTime = time?.label;

  const { data: tablesData } = useGetTablesInfoQuery(
    placeId && bookingTime && (visit_time || duration) && isDateValid
      ? {
          placeId,
          bookingDate: chosenDate.format("YYYY-MM-DD"),
          bookingTime,
          visitTime: visit_time || duration,
          restaurantId: rest.restaurant_id,
        }
      : skipToken,
  );

  const busyIds = tablesData?.busyTablesIds;

  const placeTables = data?.places[placeId];

  const tablesInfo = useMemo(
    () =>
      placeTables?.tables.map(
        ({ table_id, number, min_capacity, max_capacity }) => ({
          tableId: table_id,
          tableNumber: number,
          busy: busyIds?.includes(table_id),
          // capacity не может быть меньше 1
          // FIXME: убрать все это в валидацию, например zod
          minCapacity: Math.max(min_capacity, 1),
          maxCapacity:
            max_capacity >= Math.max(min_capacity, 1)
              ? max_capacity
              : Math.max(min_capacity, 1),
        }),
      ) || [],
    [placeId, data, tablesData],
  );
  return tablesInfo;
};

export type TableInfo = ReturnType<typeof useTablesInfo>[number];
