import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Input } from "ui-kit";

export const InputField: React.FC<FieldRenderProps<string, any>> = ({
  input,
  meta,
  ...rest
}) => {
  const { onChange, value } = input;
  return (
    <Input
      maxLength={50}
      value={value}
      onChange={onChange}
      {...rest}
      {...meta}
    />
  );
};
