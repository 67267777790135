import type { SlotPlace } from "models/booking.model";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Place } from "types/place";

import { ETranslations } from "../../../types/translates";
import { useVisitContext } from "../VisitContext";

export const useBookingVisitInfo = ({
  rawPlaces,
}: {
  rawPlaces: SlotPlace | SlotPlace[] | Place | Place[];
}) => {
  const intl = useIntl();
  const places = useMemo(
    () => (Array.isArray(rawPlaces) ? rawPlaces : [rawPlaces]),
    [rawPlaces],
  );
  const hallTitle = intl.formatMessage({ id: ETranslations.PLURAL_HALL });

  const hallName = useMemo(
    () =>
      Array.from(
        new Set(
          "id" in places[0]
            ? (places as Place[]).map(({ place: { name } }) =>
                name.toLowerCase() === hallTitle
                  ? name
                  : name.toLowerCase().replace(hallTitle, ""),
              )
            : (places as SlotPlace[]).map(({ hall_name }) =>
                hall_name.toLowerCase() === hallTitle
                  ? hall_name
                  : hall_name.toLowerCase().replace(hallTitle, ""),
              ),
        ),
      ).join(", "),
    [places],
  );

  const { isExpired, isLate, inHall, visitPercents, visitTime } =
    useVisitContext();

  const showProgress = isExpired || isLate || inHall;

  return {
    intl,
    hallName,
    visitPercents,
    visitTime,
    showProgress,
    places,
  };
};
