import _ from "lodash";
import type { Client } from "models/client.model";
import { Place, Restaurant, RestaurantExtended } from "models/restaurant.model";
import { GrantedUser, type UserId } from "models/user.model";

import { config } from "../config";
import { AuthService } from "../services/auth.service";
import { ClientsService } from "../services/clients.service";
import { RestaurantsService } from "../services/restaurants.service";
import { UsersService } from "../services/users.service";
import { Broadcast } from "./helpers";

export default class PreData {
  users: (GrantedUser & { value: UserId; label: string })[] = [];
  loaded: Broadcast<void> = new Broadcast();
  rests: RestaurantExtended[] = [];
  defaultClient: Client | undefined = undefined;

  usersMapper: { [id: string]: GrantedUser } = {};
  placesMapper: { [id: number]: Place[] } = {};
  restsMapper: { [id: string]: RestaurantExtended } = {};

  constructor() {
    if (global.env === "test") {
      this.init().then();
    }
  }

  async initRests() {
    try {
      this.rests = (await RestaurantsService.getAll()).data.map(
        (r: Restaurant): RestaurantExtended => ({
          ...r,
          label: r.restaurant_name,
          value: r.restaurant_id,
          places: r.places.map((p) => ({ ...p, value: p.id, label: p.name })),
        }),
      );
      this.rests.forEach((rest) => {
        this.restsMapper[rest.restaurant_id] = rest;
        this.placesMapper[rest.restaurant_id] = rest.places;
      });
    } catch (e) {
      console.warn("Restaurants and places were not loaded");
    }
  }

  async initUser() {
    try {
      if (AuthService.getUserRole() !== "ADMINISTRATOR") {
        await this.updateUsers();
      }
    } catch (e) {
      console.warn("Users were not loaded");
    }
  }

  async init() {
    await Promise.all([this.initRests(), this.initUser()]);
    // add default client
    if (config.autoGuestId) {
      this.defaultClient = (
        await ClientsService.getById(config.autoGuestId)
      ).data;
    }
    this.loaded.sendValueToCB();
  }

  restById(id?: number): RestaurantExtended | undefined {
    return id ? this.restsMapper[id] : undefined;
  }

  placesByIdRest(id?: number): Place[] {
    return id ? this.placesMapper[id] : [];
  }

  userById(id: number | undefined): GrantedUser | undefined {
    if (id) {
      return this.usersMapper[id];
    }
    return undefined;
  }

  async updateRestList() {
    this.rests = (await RestaurantsService.getAll()).data.map(
      (r: Restaurant): RestaurantExtended => ({
        ...r,
        label: r.restaurant_name,
        value: r.restaurant_id,
        places: r.places.map((p) => ({ ...p, value: p.id, label: p.name })),
      }),
    );
    this.rests.forEach((rest) => {
      this.restsMapper[rest.restaurant_id] = rest;
      this.placesMapper[rest.restaurant_id] = rest.places;
    });
  }

  async updateUsers(idRest?: number) {
    this.users = (await UsersService.getHostess(idRest)).data.map((u) => ({
      ...u,
      value: u.user_serial,
      label: u.username,
    }));
    this.users.forEach((user) => (this.usersMapper[user.user_serial] = user));

    this.users = _.sortBy(this.users, "name");
    const foundElem = this.users.find(
      ({ username }) => username === "В комментариях",
    );

    if (foundElem) {
      const foundIndexElem = this.users.findIndex(
        ({ username }) => username === "В комментариях",
      );
      this.users.push(foundElem);
      this.users.splice(foundIndexElem, 1);
    }
  }

  getRealUsers() {
    return this.users.filter(({ username }) => username !== "В комментариях");
  }
}
