import { useDialogsSocketConnected } from "features/Dialogs/SDK";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";

export function DialogsSocketNotification() {
  const isConnected = useDialogsSocketConnected();
  const wasConnected = useRef(isConnected);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!wasConnected.current) {
      if (isConnected) wasConnected.current = true;
      return;
    }

    if (isConnected) {
      Notification.success(
        formatMessage({
          id: ETranslations.SUCCESS_DIALOGS_SOCKET_RECONNECTED,
        }),
      );
    } else {
      Notification.error(
        formatMessage({
          id: ETranslations.ERROR_DIALOGS_SOCKET_DISCONNECTED,
        }),
      );
    }
  }, [isConnected]);

  return null;
}
