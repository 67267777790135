import { RESTAURANTS_TITLES } from "constants/pluralsTitles";
import { Button, SelectBasic, SelectCheckbox } from "ui-kit";

import type { Filters, Option } from "../utils";
import styles from "./DialogFilters.module.scss";

export const DialogFilters = ({
  restsOptions,
  chatActivityOptions,
  selectedFilters,
  labels,
  changeFilters,
  resetFilters,
}: {
  restsOptions: Option[];
  chatActivityOptions: Option[];
  selectedFilters: Filters;
  labels: Record<keyof Filters | 'resetButton', string>;

  changeFilters: <K extends keyof Filters>(
    filterKey: K,
    currentFilters: Filters,
    selectedOptions: Option[] | Option,
  ) => void;
  resetFilters: () => void;
}) => (
  <form className={styles.filterForm}>
    <label className={styles.labeledSelect}>
      {labels.restaurant}
      <SelectCheckbox
        isClearable={false}
        options={restsOptions}
        titles={RESTAURANTS_TITLES}
        value={selectedFilters.restaurant}
        //@ts-ignore
        onChange={(value: Option[]) =>
          changeFilters("restaurant", selectedFilters, value)
        }
      />
    </label>
    <label className={styles.labeledSelect}>
      {labels.activity}
      <SelectBasic
        options={chatActivityOptions}
        value={selectedFilters.activity}
        //@ts-ignore
        onChange={(value: Option[]) =>
          changeFilters("activity", selectedFilters, value)
        }
      />
    </label>
    <Button
      className={styles.resetButton}
      variant="secondary"
      onClick={resetFilters}
    >
      {labels.resetButton}
    </Button>
  </form>
);
