import { ICONS } from "../../common/helpers";
import { Button } from "../../ui-kit";
import styles from "./shifts.module.scss";
import cn from "classnames";
import dayjs from "dayjs";
import React, { VFC, useCallback, useMemo } from "react";

export interface IWeekPickerOnClickHandler {
  (direction: 1 | -1): void;
}

export type TWeekPickerProps = {
  startDate: Date;
  endDate: Date;
  onDatesChange: IWeekPickerOnClickHandler;
};

export const WeekPicker: VFC<TWeekPickerProps> = ({
  startDate,
  endDate,
  onDatesChange,
}) => {
  const transformDate = useMemo(() => {
    return `${dayjs(startDate).format("DD.MM.YYYY")} - ${dayjs(endDate).format("DD.MM.YYYY")}`;
  }, [startDate, endDate]);

  const handleOnNextWeekClick = useCallback(() => onDatesChange(1), []);
  const handleOnPrevWeekClick = useCallback(() => onDatesChange(-1), []);

  return (
    <div className={styles.weekInputContainer}>
      <Button
        className={cn(styles.iconContainer, styles.prev)}
        variant="phantom"
        onClick={handleOnPrevWeekClick}
      >
        <ICONS.PrevIcon className={styles.inputIcon} />
      </Button>
      <div className={styles.input}>{transformDate}</div>
      <Button
        className={cn(styles.iconContainer, styles.next)}
        variant="phantom"
        onClick={handleOnNextWeekClick}
      >
        <ICONS.NextIcon className={styles.inputIcon} />
      </Button>
    </div>
  );
};
