import { EDraggableEntity } from "constants/dragAndDrop";
import type { BookingSlot } from "models/booking.model";
import { usePreview } from "react-dnd-preview";

import { BookingCardDndLayout } from "./layouts/BookingCardDndLayout";

export const DndPreviewContainer = () => {
  const preview = usePreview<BookingSlot, HTMLDivElement>();
  if (!preview.display) {
    return null;
  }
  const { item, style, ref, itemType } = preview;
  if (itemType === EDraggableEntity.TABLE_TEMPLATE) return null;

  return <BookingCardDndLayout booking={item} ref={ref} style={style} />;
};
