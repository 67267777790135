import styles from "./Modal.module.scss";
import cn from "classnames";
import React, { ReactNode } from "react";

interface ModalContentProps {
  children: ReactNode;
  className?: string;
  noPadding?: boolean;
}

export function ModalContent({
  children,
  className,
  noPadding,
}: ModalContentProps) {
  return (
    <div
      className={cn(styles.modalContent, className, {
        [styles.noPadding]: noPadding,
      })}
    >
      {children}
    </div>
  );
}
