import { Modal, ModalProps } from "../../modal";
import styles from "./style.module.scss";
import cn from "classnames";
import React, { FC } from "react";

type Props = Omit<ModalProps, "children">;

export const BaseShiftsModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  children,
  className,
}) => {
  const parentSelector = document.querySelector("#settings");
  if (!parentSelector) return null;

  return (
    <Modal
      className={cn(styles.modal, className)}
      isOpen={isOpen}
      overlayClassName={styles.overlay}
      parentSelector={() => parentSelector as HTMLElement}
      portalClassName={styles.modalPortal}
      title={title}
      onClose={onClose}
    >
      {children}
    </Modal>
  );
};
