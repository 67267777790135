import cn from "classnames";
import { OrderActions } from "components/BookingActions/OrderActions";
import { BookingCardViewProps } from "components/BookingCard";
import { BookingCardNew } from "components/BookingCard/BookingCardNew";
import { memo } from "react";
import type { BookingOrder } from "types/booking";
import { Spinner } from "ui-kit";

import { ETranslations } from "../../types/translates";
import styles from "./BookingList.module.scss";
import { useOrderList } from "./useOrderList";

export interface BookingsListProps<T extends BookingOrder>
  extends BookingCardViewProps {
  bookings: T[];
  className?: string;
  emptyMessage?: string;
  loading?: boolean;
  withActions?: boolean;
  allowMove?: boolean;
  canDrag?: boolean;
  onClick?: (book: BookingOrder) => void;
  onEdit?: (order: BookingOrder) => void;
}

const List = <T extends BookingOrder>({
  bookings,
  className,
  emptyMessage,
  loading,
  withActions,
  allowMove,
  canDrag = true,
  onClick,
  onEdit,
  ...props
}: BookingsListProps<T>) => {
  const { intl, onSelectBook, onEditBook, selectedBooking, isDashboard } =
    useOrderList();

  if (!bookings.length) {
    return (
      <div className={cn(styles.list, className)}>
        <div className={styles.blank}>
          {emptyMessage ||
            intl.formatMessage({ id: ETranslations.BOOKING_LIST_EMPTY })}
          {loading && <Spinner className={styles.spinner} />}
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.list, className)}>
      {bookings.map((b) => (
        <BookingCardNew
          booking={b}
          canDrag={canDrag}
          key={b.orderId}
          onClick={onClick || onSelectBook}
          {...props}
          actions={
            withActions ? (
              <OrderActions order={b} onEdit={onEdit as any} />
            ) : undefined
          }
          className={styles.card}
          isDashboard={isDashboard}
        />
      ))}
      {loading && <Spinner className={styles.spinner} />}
    </div>
  );
};
export const OrderList = memo(List) as typeof List;
