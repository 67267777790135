import cn from "classnames";
import { BookingHistory } from "components/BookingHistory";
import { useBookingsList } from "features/api/bookings-api";
import { useAllStatuses } from "features/api/dictionaries-api";
import _ from "lodash";
import type { BookingSlot, ManagerSlot } from "models/booking.model";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { TUseBookingList } from "types/booking";
import { Spinner } from "ui-kit";

import styles from "./BookingsHistory.module.scss";

interface OwnProps {
  dateFrom?: Date | null;
  dateTo?: Date | null;
  searchWords: string;
  userid?: number | null;
}

type Props = OwnProps;

export const BookingsHistory: FunctionComponent<Props> = ({
  dateFrom,
  dateTo,
  searchWords,
  userid,
}) => {
  const [search, setSearch] = useState(searchWords);
  const { statuses: allStatuses } = useAllStatuses();
  const allStatusesSystemName = allStatuses.map((status) => status.system_name);
  const bookingPayload: TUseBookingList = {
    search,
    includeStatuses: allStatusesSystemName,
    fromDate: dateFrom,
    toDate: dateTo,
    userid,
    isPooling: false,
  };

  const { data, isLoading } = useBookingsList(bookingPayload);

  const debounceSearchWords = useCallback(
    _.debounce((val: string) => {
      setSearch(val);
    }, 500),
    [],
  );

  useEffect(() => {
    debounceSearchWords(searchWords);
  }, [searchWords]);

  const [activeBook, setActiveBook] = useState<
    BookingSlot | ManagerSlot | null
  >(null);
  const bookingClickHandler = useCallback(
    (book: BookingSlot | ManagerSlot) => {
      if (activeBook?.slot_id === book.slot_id) {
        setActiveBook(null);
        return;
      }
      setActiveBook(book);
    },
    [setActiveBook, activeBook],
  );

  if (isLoading) return <Spinner />;

  return (
    <div className={cn("bookingHistory", styles.root)}>
      {data &&
        data.slots.map((slot) => (
          <BookingHistory
            booking={slot as BookingSlot | ManagerSlot}
            isExpanded={activeBook?.slot_id === slot.slot_id}
            key={slot.slot_id}
            onClick={bookingClickHandler}
          />
        ))}
    </div>
  );
};
