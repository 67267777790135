import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { IDialogsApi } from "./api";
import {
  Dialog,
  DialogChannels,
  DialogsFilter,
  DialogsMessage,
  SendMessagePayload,
} from "./entities";
import { useDialogsContext } from "./provider";
import { DialogsStore, Registry } from "./store";

const DEFAULT_OPTIONS = {
  ttl: undefined,
};

/*
    ____,-------------------------------,____
    \   |            Диалоги            |   /
    /___|-------------------------------|___\
*/

export function useDialogs(
  filter: DialogsFilter,
  options: { ttl?: number } = DEFAULT_OPTIONS,
): UseDialogsValue {
  const { ttl } = options;
  const { store } = useDialogsContext();

  const search = useMemo(() => store.searches.get(filter, { ttl }), [filter]);
  // Не используем forceRetry в useMemo т.к. фильтр — сложный объект
  useImmediateEffect(() => {
    if (search.mayForce()) {
      search.load();
    }
  }, [search]);

  const [rev, setRev] = useState(0);

  const dialogResources = useMemo(
    () => search.data?.map((id) => store.dialogs.get(id)),
    // FIXME: переосмыслить магию
    [rev, search, search.state === "loaded"],
  );

  const dialogs = useMemo(
    () => dialogResources?.map((r) => r.data!),
    [dialogResources],
  );

  // Подписка на поиск

  useEffect(() => {
    const unsubscribe = search.subscribe(() => {
      setRev((r) => r + 1);
    });
    return unsubscribe;
  }, [search]);

  // Подписка на диалоги

  useEffect(() => {
    if (dialogResources) {
      const unsubs: Array<() => void> = [];

      dialogResources.forEach((d) => {
        const prev = d.data;

        unsubs.push(
          d.subscribe(() => {
            const shouldTrigger =
              search.state !== "loading" &&
              d.state === "loaded" &&
              prev !== d.data;

            if (shouldTrigger) setRev((r) => r + 1);
          }),
        );
      });

      return () => {
        unsubs.forEach((u) => u());
      };
    }

    return () => {};
  }, [dialogResources, search]);

  //

  return {
    dialogs,
    loading: search.state === "loading" && !search.data,
    updating: search.state === "loading" && !!search.data,
    error: search.error,
    reload: useCallback(() => search.load(), [search]),
  };
}

// ------------------------------------------

type UseDialogsValue = {
  dialogs?: Dialog[];
  loading: boolean;
  updating: boolean;
  error?: string;
  reload: () => void;
};

/*
    ____,-------------------------------,____
    \   |       Конкретный диалог       |   /
    /___|-------------------------------|___\
*/

export function useDialog(
  dialogId: string,
  options: { ttl?: number } = DEFAULT_OPTIONS,
): UseDialogValue {
  const { data, loading, updating, error, reload } = useResource(
    dialogId,
    (store) => store.dialogs,
    options.ttl,
  );
  return { dialog: data, loading, updating, error, reload };
}

// ------------------------------------------

type UseDialogValue = {
  dialog?: Dialog;
  loading: boolean;
  updating: boolean;
  error?: string;
  reload: () => void;
};

/*
    ____,-------------------------------,____
    \   |       Сообщения диалога       |   /
    /___|-------------------------------|___\
*/

export function useDialogMessages(
  dialogId: string,
  options: { ttl?: number } = DEFAULT_OPTIONS,
): UseDialogMessagesValue {
  const { data, loading, updating, error, reload } = useResource(
    dialogId,
    (store) => store.dialogMessages,
    options.ttl,
  );
  return { messages: data, loading, updating, error, reload };
}

// ------------------------------------------

type UseDialogMessagesValue = {
  messages?: DialogsMessage[];
  loading: boolean;
  updating: boolean;
  error?: string;
  reload: () => void;
};

/*
    ____,-------------------------------,____
    \   |        Сообщения задачи       |   /
    /___|-------------------------------|___\
*/

export function useTaskMessages(
  taskId: string,
  options: { ttl?: number } = DEFAULT_OPTIONS,
): UseTaskMessagesValue {
  const { data, loading, updating, error, reload } = useResource(
    taskId,
    (store) => store.taskMessages,
    options.ttl,
  );
  return { messages: data, loading, updating, error, reload };
}

// ------------------------------------------

type UseTaskMessagesValue = {
  messages?: DialogsMessage[];
  loading: boolean;
  updating: boolean;
  error?: string;
  reload: () => void;
};

/*
    ____,-------------------------------,____
    \   |      Мессенджеры диалога      |   /
    /___|-------------------------------|___\
*/

export function useDialogAllowedMessengers(
  dialogId: string,
  options: { ttl?: number } = DEFAULT_OPTIONS,
): UseDialogAllowedMessengersValue {
  const { data, loading, updating, error, reload } = useResource(
    dialogId,
    (store) => store.dialogAllowedMessengers,
    options.ttl,
  );
  return { allowedMessengers: data, loading, updating, error, reload };
}

// ------------------------------------------

type UseDialogAllowedMessengersValue = {
  allowedMessengers?: DialogChannels[];
  loading: boolean;
  updating: boolean;
  error?: string;
  reload: () => void;
};

/*
    ____,-------------------------------,____
    \   |      Мессенджеры клиента      |   /
    /___|-------------------------------|___\
*/

export function useCustomerAllowedMessengers(
  customerId: string,
  options: { ttl?: number } = DEFAULT_OPTIONS,
): UseCustomerAllowedMessengersValue {
  const { data, loading, updating, error, reload } = useResource(
    customerId,
    (store) => store.customerAllowedMessengers,
    options.ttl,
  );
  return { allowedMessengers: data, loading, updating, error, reload };
}

// ------------------------------------------

type UseCustomerAllowedMessengersValue = {
  allowedMessengers?: DialogChannels[];
  loading: boolean;
  updating: boolean;
  error?: string;
  reload: () => void;
};

/*
    ____,-------------------------------,____
    \   |        Линковка задач         |   /
    /___|-------------------------------|___\
*/

export function useLinkDialogTask(
  dialogId: string,
  taskId: string,
): UseLinkDialogTaskValue {
  const { load, done, loading, error } = useDialogsApi(
    useCallback((api) => api.linkTask(dialogId, taskId), [dialogId, taskId]),
  );

  return { link: load, done, loading, error };
}

// ------------------------------------------

type UseLinkDialogTaskValue = {
  link: () => void;
  loading: boolean;
  done: boolean;
  error?: string;
};

/*
    ____,-------------------------------,____
    \   |       Разлинковка задач       |   /
    /___|-------------------------------|___\
*/

export function useUnlinkDialogTask(
  dialogId: string,
  taskId: string,
): UseUnlinkDialogTaskValue {
  const { load, done, loading, error } = useDialogsApi(
    useCallback((api) => api.unlinkTask(dialogId, taskId), [dialogId, taskId]),
  );

  return { unlink: load, done, loading, error };
}

// ------------------------------------------

type UseUnlinkDialogTaskValue = {
  unlink: () => void;
  loading: boolean;
  done: boolean;
  error?: string;
};

/*
    ____,-------------------------------,____
    \   |       Закрытие диалогов       |   /
    /___|-------------------------------|___\
*/

export function useCloseDialog(dialogId: string): UseCloseDialogValue {
  const { load, done, loading, error } = useDialogsApi(
    useCallback((api) => api.close(dialogId), [dialogId]),
  );

  return { close: load, loading, done, error };
}

// ------------------------------------------

type UseCloseDialogValue = {
  close: () => void;
  loading: boolean;
  done: boolean;
  error?: string;
};

/*
    ____,-------------------------------,____
    \   |     Прочитанное в диалогах    |   /
    /___|-------------------------------|___\
*/

export function useMarkMessagesAsRead(
  dialogId: string,
): UseMarkMessagesAsReadValue {
  const { store } = useDialogsContext();

  const { load, done, loading, error } = useDialogsApi(
    useCallback(
      async (api, ids: string[]) => {
        const res = await api.markMessagesSeen(dialogId, ids);
        // т.к. нет обновлений по сокету, пометим сразу как dirty
        store.dialogMessages.get(dialogId).markDirty();
        return res;
      },
      [dialogId],
    ),
  );

  return { markAsRead: load, loading, done, error };
}

// ------------------------------------------

type UseMarkMessagesAsReadValue = {
  markAsRead: (ids: string[]) => Promise<void>;
  loading: boolean;
  done: boolean;
  error?: string;
};

/*
    ____,-------------------------------,____
    \   |     Прочитанное в задачах     |   /
    /___|-------------------------------|___\
*/

export function useMarkTaskMessagesAsRead(
  taskId: string,
): UseMarkTaskMessagesAsReadValue {
  const { store } = useDialogsContext();

  const { load, done, loading, error } = useDialogsApi(
    useCallback(
      async (api, ids: string[]) => {
        const res = await api.markTaskMessagesSeen(taskId, ids);
        // т.к. нет обновлений по сокету, пометим сразу как dirty
        store.taskMessages.get(taskId).markDirty();
        return res;
      },
      [taskId],
    ),
  );

  return { markAsRead: load, loading, done, error };
}

// ------------------------------------------

type UseMarkTaskMessagesAsReadValue = {
  markAsRead: (ids: string[]) => Promise<void>;
  loading: boolean;
  done: boolean;
  error?: string;
};

/*
    ____,-------------------------------,____
    \   |      Отправить сообщение      |   /
    /___|-------------------------------|___\
*/

export function useSendMessage(dialogId: string): UseSendMessageValue {
  const { load, done, loading, error } = useDialogsApi(
    useCallback(
      (api, payload: SendMessagePayload) => api.sendMessage(dialogId, payload),
      [dialogId],
    ),
  );

  return { send: load, loading, done, error };
}

// ------------------------------------------

type UseSendMessageValue = {
  send: (payload: SendMessagePayload) => Promise<void>;
  loading: boolean;
  done: boolean;
  error?: string;
};

/*
    ____,-------------------------------,____
    \   |  Отправить сообщение в задачу |   /
    /___|-------------------------------|___\
*/

export function useSendTaskMessage(taskId: string): UseSendTaskMessageValue {
  const { load, done, loading, error } = useDialogsApi(
    useCallback(
      (api, payload: SendMessagePayload) =>
        api.sendTaskMessage(taskId, payload),
      [taskId],
    ),
  );

  return { send: load, loading, done, error };
}

// ------------------------------------------

type UseSendTaskMessageValue = {
  send: (payload: SendMessagePayload) => Promise<void>;
  loading: boolean;
  done: boolean;
  error?: string;
};

/*
    ____,-------------------------------,____
    \   |       Состояние сокета        |   /
    /___|-------------------------------|___\
*/

export function useDialogsSocketConnected(): boolean {
  const { socket } = useDialogsContext();
  const [state, setState] = useState(socket.state === "connected");

  useImmediateEffect(() => {
    const uc = socket.onConnect(() => {
      setState(true);
    });
    const ud = socket.onDisconnect(() => {
      setState(false);
    });

    return () => {
      uc();
      ud();
    };
  }, [socket]);

  return state;
}

/*
    ____,-------------------------------,____
    \   |      Низкоуровневое API       |   /
    /___|-------------------------------|___\
*/

export function useDialogsApi<T, U = undefined>(
  cb: (api: IDialogsApi, arg: U) => Promise<T>,
): UseDialogsApiValue<T, U> {
  const { api } = useDialogsContext();

  const [done, setDone] = useState(false);
  const [data, setData] = useState<T>();
  const [promise, setPromise] = useState<Promise<T>>();
  const [error, setError] = useState<string>();

  const unmounted = useRef(false);
  useEffect(
    () => () => {
      unmounted.current = true;
    },
    [],
  );

  const load = useCallback(
    (arg: U) => {
      if (!promise) {
        const p = cb(api, arg);
        setPromise(p);
        setDone(false);
        p.then(
          (d) => {
            if (unmounted.current) {
              return;
            }
            setData(d);
            setDone(true);
            setPromise(undefined);
            setError(undefined);
          },
          (e) => {
            if (unmounted.current) {
              return;
            }
            setPromise(undefined);
            setError(e);
          },
        );
        return p;
      }
      return promise;
    },
    [cb],
  ) as UseDialogsApiValue<T, U>["load"];

  return {
    load,
    loading: Boolean(promise),
    data,
    done,
    error,
  };
}

// ------------------------------------------

type UseDialogsApiValue<T, U> = {
  load: U extends undefined ? () => Promise<T> : (arg: U) => Promise<T>;
  loading: boolean;
  data?: T;
  done: boolean;
  error?: string;
};

/*
    ____,-------------------------------,____
    \   |            Утилиты            |   /
    /___|-------------------------------|___\
*/

// Как useEffect, но выполняется синзронно (а не после рендера)
function useImmediateEffect(fn: () => void, deps: unknown[]) {
  const depsRef = useRef<unknown[]>(deps);
  if (!dependencyEqual(deps, depsRef.current)) fn();
  depsRef.current = deps;
}

function dependencyEqual(arrA: unknown[], arrB: unknown[]): boolean {
  if (arrA === arrB) return true;
  if (arrA.length !== arrB.length) return false;
  for (let i = 0; i < arrA.length; i++) {
    if (arrA[i] !== arrB[i]) return false;
  }
  return true;
}

// ------------------------------------------

// Хелпер для работы с простыми ресурсами стора
function useResource<T>(
  id: string,
  cb: (store: DialogsStore) => Registry<T>,
  ttl?: number,
) {
  const { store } = useDialogsContext();

  const resource = useMemo(
    () => cb(store).get(id, { forceRetry: true, ttl }),
    [store, id],
  );

  const [, setRev] = useState(0);

  // Подписка на изменения
  useEffect(() => {
    const unsubscribe = resource.subscribe(() => setRev((r) => r + 1));
    return unsubscribe;
  }, [resource]);

  return {
    data: resource.data,
    loading: resource.state === "loading" && !resource.data,
    updating: resource.state === "loading" && !!resource.data,
    error: resource.error,
    reload: useCallback(() => resource.load(), [resource]),
  };
}
