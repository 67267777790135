import { EventSourceInput } from "@fullcalendar/core";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayJsDuration from "dayjs/plugin/duration";
import isoWeek from "dayjs/plugin/isoWeek";
import { restaurantSelector } from "features/AppContext";
import { useSelector } from "react-redux";
import { EDaysOfWeek, TNullable } from "types/commons";
import { Durations, WeekShift } from "types/shift";
import { OptionSelectType } from "ui-kit";
import { v4 as uuid } from "uuid";

import { Restaurant } from "../../models/restaurant.model";

dayjs.extend(isoWeek);
dayjs.extend(dayJsDuration);
dayjs.extend(customParseFormat);

export type TDuration = { duration: number; count: number };
export type TDefaultDuration = { [key: number]: number };

export function prepareShiftsForCalendar(
  shiftsData: TNullable<WeekShift[]>,
): EventSourceInput {
  if (!shiftsData) return [];
  const shifts: EventSourceInput = [];

  const now = dayjs.tz().valueOf();
  shiftsData?.forEach((weekShift) => {
    //@ts-ignore
    const end = dayjs(weekShift.end_date).valueOf();
    shifts.push({
      // Добавляем уникальный идентификатор к shiftId.
      // Так как один shift может распространяться на несколько дней
      id: weekShift.id.toString() + ":" + uuid(),
      title: weekShift.title,
      //@ts-ignore
      start: dayjs(weekShift.start_date).valueOf(),
      //@ts-ignore
      end: dayjs(weekShift.end_date).valueOf(),
      backgroundColor:
        now > end
          ? "var(--shiftsSlot_old_default_bg)"
          : "var(--shiftsSlot_actualy_default_bg)",
      borderColor: "transparent",
      extendedProps: weekShift,
    });
  });
  return shifts;
}

export const getShortedDaysTitle = (days: EDaysOfWeek[]): string => {
  return days
    .map((el) => {
      const dayNumber = Number(EDaysOfWeek[el]);
      return dayjs().isoWeekday(dayNumber).format("dd");
    })
    .join(", ");
};

export const getPlacesTitles = (places: number[]): string => {
  const restaurant = useSelector(restaurantSelector);
  const titles = [];
  for (const place of restaurant.places) {
    if (places.some((id) => place.id === id)) {
      titles.push(place.name);
    }
  }
  return titles.join(",");
};

export const getPlaceTitle = (id: number, restaurant: Restaurant): string => {
  const place = restaurant.places.find((p) => p.id === id);
  return place?.name || "";
};

export const getDurationTime = (durations: Durations): TDuration[] => {
  const durationData = Object.entries(durations).map(([count, dur]) => {
    return { count: Number(count), duration: dur };
  });
  // Последний элемент приходит -1 вместо 10 персон. Исправляем это.
  durationData[durationData.length - 1].count = 10;

  return durationData;
};

export const getDurationTimeFormat = (minutes: number): string => {
  const MINUTES_IN_HOUR = 60;
  const isWholeHour = !(minutes % MINUTES_IN_HOUR);
  const isRussianLocale = dayjs.locale() === "ru";
  const hourFormat = isRussianLocale ? "[ч]" : "[hr]";
  const minuteFormat = isRussianLocale ? "[м]" : "[mins]";
  const format = `H ${hourFormat} ${isWholeHour ? "" : "m " + minuteFormat}`;

  return `${dayjs.duration(minutes, "minutes").format(format)}`;
};

export const getFormatTime = (time: string): string => {
  const isRussianLocale = dayjs.locale() === "ru";
  const format = isRussianLocale ? "HH:mm" : "h:mm A";
  return dayjs(time, "HH:mm:ss").format(format);
};

export const getHourOptions = () => {
  const format = dayjs.locale() === "ru" ? "HH:mm" : "h:mm A";
  const result = [];
  let temp = dayjs().set("hour", 0).set("minutes", 0).set("seconds", 0);
  result.push({ label: temp.format(format), value: 0 });
  while (temp.format("HH.mm") !== "23.45") {
    temp = temp.add(15, "minutes");
    const hour = temp.get("hours");
    const minutes = temp.get("minutes");
    result.push({ label: temp.format(format), value: hour * 60 + minutes });
  }
  return result;
};

export const getPartyOptions = (): OptionSelectType[] => {
  const result = [];
  let temp = dayjs().set("hour", 0).set("minutes", 0).set("seconds", 0);
  while (temp.format("HH.mm") !== "23.30") {
    temp = temp.add(30, "minutes");
    const minutes = temp.get("hours") * 60 + temp.get("minutes");
    result.push({ label: getDurationTimeFormat(minutes), value: minutes });
  }
  result.push({ label: getDurationTimeFormat(24 * 60), value: 24 * 60 });
  return result;
};

export const getDurationFormat = (minutes: number): OptionSelectType => {
  return { label: getDurationTimeFormat(minutes), value: minutes };
};

export const rebuildDurations = (durations: TDuration[]): TDefaultDuration => {
  const result = {} as TDefaultDuration;
  durations.forEach((el) => {
    result[el.count] = el.duration;
  });
  return result;
};
export const buildDurations = (): Durations => {
  const durations: Durations = {};
  for (let i = 1; i < 11; i++) {
    durations[i] = 30;
  }
  return durations;
};
export const getWeekDayById = (date: string): EDaysOfWeek[] => {
  const weekDayId = +dayjs(date).format("d");
  return [
    EDaysOfWeek[weekDayId === 0 ? 7 : weekDayId],
  ] as unknown as EDaysOfWeek[];
};

export const sortDaysWeek = (days: EDaysOfWeek[]): EDaysOfWeek[] => {
  return days.sort((a, b) => Number(EDaysOfWeek[a]) - Number(EDaysOfWeek[b]));
};

export const addPlusToDuration = (count: number, lastNumber: number) => {
  return count >= lastNumber ? `${count}+` : count;
};

export const required = (value: any) => {
  if (Array.isArray(value)) {
    return value.length ? undefined : "Required";
  }
  return value ? undefined : "Required";
};

export const getBookingInterval = () => {
  return [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 30, label: "30" },
  ];
};
