import { api, api2 } from "../api";

export const coreApi = api.enhanceEndpoints({
  endpoints: () => ({}),
  addTagTypes: [
    "ManagerialTables",
    "Bookings",
    "Booking",
    "BookingStatus",
    "TableOptions",
    "GlobalSearchBookings",
    "Timeline",
    "BookingHistory",
  ],
});

export const coreApi2 = api2.enhanceEndpoints({
  endpoints: () => ({}),
  addTagTypes: ["Rules", "AccessRules"],
});
