import cn from "classnames";
import { config } from "config";
import dayjs from "dayjs";
import { useIntlUtils } from "hooks/useIntlUtils";
import React, { FC, type ReactNode } from "react";
import type { TNullable } from "types/commons";
import { ETranslations } from "types/translates";

import styles from "./ShortUserInfo.module.scss";

interface Props {
  birthday: TNullable<string>;
  email: TNullable<string>;
  comment: TNullable<string>;
  gradeName: TNullable<string>;
  children?: ReactNode;
}

export const ShortInfo: FC<Props> = ({
  birthday,
  email,
  comment,
  gradeName,
  children,
}) => {
  const { intl, getIntlDateOfStrict, getIntlLevelOf } = useIntlUtils();

  return (
    <div className={styles.shortInfo}>
      <div className={styles.item}>
        <span className={styles.title}>
          {getIntlDateOfStrict(ETranslations.OF_BIRTH)}
        </span>
        <span className={styles.description}>
          {birthday && dayjs(birthday).format("DD.MM.YYYY")}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>
          {intl.formatMessage({ id: ETranslations.EMAIL })}
        </span>
        <span className={styles.description}>
          {email ||
            intl.formatMessage({ id: ETranslations.BASE_NOT_INDICATED })}
        </span>
      </div>
      {config.clientsHasLoyalty && (
        <div className={styles.item}>
          <span className={styles.title}>
            {getIntlLevelOf(ETranslations.OF_LOYALTY)}
          </span>
          <span className={styles.description}>{gradeName}</span>
        </div>
      )}
      <div className={cn(styles.item, styles.fullRow)}>
        <span className={styles.title}>
          {intl.formatMessage({ id: ETranslations.COMMENT })}
        </span>
        <span className={styles.description}>
          {comment ||
            intl.formatMessage({ id: ETranslations.BASE_NOT_INDICATED })}
        </span>
      </div>
      {children}
    </div>
  );
};
