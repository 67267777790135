import React, { FC, useRef } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { ETranslations } from "types/translates";
import { Button, ICONS } from "ui-kit";

import styles from "../Widget.module.scss";

type Props = {
  title: string;
  code: string;
};

const CodeBlock: FC<Props> = ({ title, code }) => {
  const intl = useIntl();
  const codeRef = useRef<HTMLDivElement | null>(null);

  const copyToClipboard = async () => {
    if (!codeRef.current) return;

    try {
      const textToCopy = codeRef.current.innerText;
      await navigator.clipboard.writeText(textToCopy);

      toast.success(intl.formatMessage({ id: ETranslations.COPIED }));
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        <span>{title}</span>
        <Button variant="phantom" onClick={copyToClipboard}>
          <ICONS.CopyIcon />
          Копировать
        </Button>
      </div>

      <div className={styles.info}>
        <code ref={codeRef}>{code}</code>
      </div>
    </div>
  );
};

export default CodeBlock;
