import { HallMode } from "../../../../features/HallSchema";

export const isTableHallView = (hallMode: HallMode): boolean =>
  [
    HallMode.TABLES,
    HallMode.BOOKING_HALL,
    HallMode.REGISTRATION_HALL,
    HallMode.MOVE_BOOKINGS_CAPTURED,
  ].includes(hallMode);

export const isRegView = (hallMode: HallMode): boolean =>
  [
    HallMode.REGISTRATION_HALL,
    HallMode.REGISTRATION_GUESTS,
    HallMode.REGISTRATION_TABLET,
  ].includes(hallMode);
export const isBookingView = (hallMode: HallMode): boolean =>
  [
    HallMode.BOOKING_GUEST,
    HallMode.BOOKING_HALL,
    HallMode.BOOKING_TABLET,
  ].includes(hallMode);
export const isHallView = (hallMode: HallMode): boolean =>
  [
    HallMode.REGISTRATION_HALL,
    HallMode.BOOKING_HALL,
    HallMode.TABLE_BOOKINGS_LIST,
    HallMode.MANAGERIAL_BOOKING,
    HallMode.TABLE_BOOKINGS_EDIT,
    HallMode.TABLE_BOOKINGS_MOVE,
  ].includes(hallMode);
export const isGuestView = (hallMode: HallMode): boolean =>
  [
    HallMode.REGISTRATION_GUESTS,
    HallMode.BOOKING_GUEST,
    HallMode.TABLE_BOOKINGS_EDIT_GUEST,
  ].includes(hallMode);
export const isManagerialTableView = (hallMode: HallMode): boolean =>
  [
    HallMode.MANAGERIAL_BOOKING,
    HallMode.MANAGERIAL_HALL_BOOKING,
    HallMode.MANAGERIAL_TABLET,
    HallMode.MANAGERIAL_CREATE_BOOKING,
    HallMode.MANAGERIAL_HALL,
  ].includes(hallMode);
export const isTableBookingView = (hallMode: HallMode): boolean =>
  [
    HallMode.TABLE_BOOKINGS_LIST,
    HallMode.TABLE_BOOKINGS_EDIT,
    HallMode.TABLE_BOOKINGS_MOVE,
    HallMode.TABLE_BOOKINGS_EDIT_GUEST,
  ].includes(hallMode);

export const isViewHalf = (hallMode: HallMode): boolean =>
  [
    HallMode.REGISTRATION_HALL,
    HallMode.BOOKING_HALL,
    HallMode.TABLE_BOOKINGS_LIST,
  ].includes(hallMode);
