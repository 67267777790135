import { useFromProxyActions } from "features/BookingFormProxy";
import * as select from "features/BookingFormProxy/selectors";
import { defer } from "lodash";
import { useCallback, useEffect } from "react";
import { useForm, useFormState } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, ICONS } from "ui-kit";

import {
  HallMode,
  useHallSchemaActions,
} from "../../../../features/HallSchema";
import { ETranslations } from "../../../../types/translates";

type props = {
  showSave?: boolean;
  isFromManagement?: boolean;
  changeTables?: boolean;
  placeId?: number;
  changePlaceId?: any;
};

export function Edit({ showSave, isFromManagement = false }: props) {
  const intl = useIntl();
  const isEdit = useSelector(select.isEdit);
  const { setEditMode, resetClient } = useFromProxyActions();
  const { reset, submit } = useForm();
  const { invalid } = useFormState();
  const { switchMode } = useHallSchemaActions();
  const { search } = useLocation();
  const edit = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const cancel = useCallback(() => {
    setEditMode(false);
    resetClient();
    defer(reset);
  }, []);

  const save = useCallback(async () => {
    await submit();
    !isFromManagement && setEditMode(false);
    switchMode(HallMode.TABLE_BOOKINGS_LIST);
  }, []);

  useEffect(() => {
    search.includes("edit") && setEditMode(true);
    return () => void setEditMode(false);
  }, [setEditMode, search]);

  if (!isEdit) {
    return (
      <Button variant="primary" onClick={edit}>
        <ICONS.Edit />
      </Button>
    );
  }

  return (
    <>
      {showSave && (
        <Button disabled={invalid} variant="primary" onClick={save}>
          {intl.formatMessage({ id: ETranslations.BASE_SAVE })}
        </Button>
      )}
      <Button variant="primary" onClick={cancel}>
        {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
      </Button>
    </>
  );
}
