import { z } from "zod";

import { ROLE_INTENT } from "../constants";

const Roles = z.enum([
  "ADMINISTRATOR",
  "HOSTESS",
  "RESTAURANT",
  "ADMINISTRATOR_RESTAURANT",
  "CALL_CENTER",
]);

const CommonFields = z.object({
  fullName: z.string().trim().min(1),
  phone: z.string().optional(),
  restaurant: z.string().array().nonempty(),
  login: z.string().trim().min(1),
});

const IntentDiscriminatedUnion = z.discriminatedUnion("intent", [
  z.object({ intent: z.literal(ROLE_INTENT.CREATE), role: Roles }),
  z.object({ intent: z.literal(ROLE_INTENT.EDIT), role: Roles.optional() }),
]);

export const UserRoleFormSchema = z.intersection(
  IntentDiscriminatedUnion,
  CommonFields,
);
