import { Modal } from "components/modal";
import AccessRuleForm from "components/shifts/Form/AccessRuleForm/AccessRuleForm";
import React from "react";
import { AccessRule } from "types/accessRules";

import { BaseShiftsModal } from "../BaseShiftsModal";
import styles from "../style.module.scss";

export type AccessRuleForModal = {
  name: string;
  access_rules: AccessRule[];
  id: number | undefined;
};

interface AccessRulesModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  access_rule?: AccessRuleForModal;
  isCreating?: boolean;
  isEditMode?: boolean;
  setIsEditMode?: any;
}

export const AccessRulesModal = ({
  isOpen,
  onClose,
  title,
  access_rule,
  isCreating = false,
  isEditMode,
  setIsEditMode,
}: AccessRulesModalProps) => {
  return (
    <BaseShiftsModal isOpen={isOpen} title={title} onClose={onClose}>
      <Modal.Content className={styles.modalContentAccess}>
        <AccessRuleForm
          access_rule={access_rule}
          isCreating={isCreating}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          onClose={onClose}
        />
      </Modal.Content>
    </BaseShiftsModal>
  );
};
