import cn from "classnames";
import { getFullName, getMaskPhone } from "common/helpers";
import { InfiniteList } from "components/InfiniteList";
import {
  useFetchSourcesPageQuery,
  useLazyFetchSourcesPageQuery,
} from "features/api/source-api";
import type {
  SourceId,
  SourceSearchDTO,
  SourceSearchParams,
} from "models/source.model";
import { forwardRef, memo, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { ETranslations } from "types/translates";
import {
  Button,
  Card,
  Collapses,
  ICONS,
  Input,
  SelectCheckbox,
  Spinner,
} from "ui-kit";
import { DetailsCollapse } from "ui-kit/Collapse/Collapse";

import { TAGS_TITLES } from "../../../../../constants";
import { SourceInfoPopup } from "./SourceInfoPopup";
import styles from "./SourceList.module.scss";

const SourceItem = memo(
  forwardRef(
    (
      {
        sourceId,
        phone,
        fullName,
      }: {
        sourceId: SourceId;
        phone: string;
        fullName: string;
      },
      ref: React.Ref<HTMLElement>,
    ) => {
      return (
        <article ref={ref} className={styles.source}>
          <NavLink
            to={String(sourceId)}
            className={({ isActive, isPending }) =>
              cn(styles.clientInfo, {
                [styles.active]: isActive,
                [styles.pending]: isPending,
              })
            }
          >
            <h3 className={styles.fullName}>{fullName}</h3>
            <p className={styles.phone}>
              <ICONS.USER_PHONE />
              <span>{phone}</span>
            </p>
          </NavLink>
          <SourceInfoPopup sourceId={sourceId} placement="auto">
            <Button
              className={styles.clientDetail}
              type="button"
              variant="phantom"
            >
              <ICONS.Question />
            </Button>
          </SourceInfoPopup>
        </article>
      );
    },
  ),
);

const SourceInfiniteList = ({
  filter,
  initialSources,
}: {
  filter: SourceSearchParams;
  initialSources: SourceSearchDTO["content"];
}) => {
  const { formatMessage } = useIntl();
  const [fetch] = useLazyFetchSourcesPageQuery();
  return (
    <Card.Content as="ul" className={styles.sourceList}>
      <InfiniteList
        initialItems={initialSources}
        fetchNextPage={(page) =>
          fetch({
            searchString: filter.searchString,
            contactLabels: filter.contactLabels,
            page,
          })
        }
      >
        {(organization, ref) =>
          Boolean(organization.sources.length) && (
            <li key={organization.organization_name || ""}>
              <DetailsCollapse
                title={
                  <strong
                    className={styles.organizationTitle}
                    data-count={organization.sources.length}
                  >
                    {organization.organization_name ??
                      formatMessage({ id: ETranslations.WITHOUT_ORGANIZATION })}
                  </strong>
                }
                forceOpen={false}
                className={styles.sourceCollapse}
                arrowClassName={styles.arrow}
              >
                <Collapses.List>
                  {organization.sources.map((source) => (
                    <Collapses.Item
                      key={source.source_id}
                      className={styles.collapseContent}
                    >
                      <SourceItem
                        sourceId={source.source_id}
                        phone={
                          source.source_contact?.phone
                            ? getMaskPhone(source.source_contact?.phone)
                            : "N/A"
                        }
                        fullName={
                          getFullName(
                            source.source_contact?.name,
                            source.source_contact?.middle_name,
                            source.source_contact?.surname,
                          ) || "N/A"
                        }
                        ref={ref}
                      />
                    </Collapses.Item>
                  ))}
                </Collapses.List>
              </DetailsCollapse>
            </li>
          )
        }
      </InfiniteList>
    </Card.Content>
  );
};

export const SourceList = () => {
  const { formatMessage } = useIntl();
  const [filter, setFilter] = useState<SourceSearchParams>({
    searchString: "",
  });
  const { data, fulfilledTimeStamp } = useFetchSourcesPageQuery(filter);
  return !data ? (
    <Spinner />
  ) : (
    <Card className={styles.list} as="section">
      <section className={styles.subHeader}>
        <Input.Search
          onSearch={(value) =>
            setFilter((prev) => ({ ...prev, searchString: value }))
          }
        />
        <SelectCheckbox
          options={[
            {
              value: "CONCIERGE",
              label: "CONCIERGE",
            },
            { value: "PERSONAL_ASSISTANT", label: "PERSONAL_ASSISTANT" },
            { value: "LIFESTYLE_MANAGER", label: "LIFESTYLE_MANAGER" },
            { value: "PRIME_CONCIERGE", label: "PRIME_CONCIERGE" },
            { value: "OPEN_SOURCE", label: "OPEN_SOURCE" },
          ]}
          placeholder={formatMessage(
            { id: ETranslations.BASE_SELECT_ENTITY },
            {
              entity: formatMessage({
                id: ETranslations.TYPE,
              }).toLowerCase(),
            },
          )}
          titles={TAGS_TITLES}
          onChange={(option) =>
            setFilter((prev) => ({
              ...prev,
              contactLabels: Array.isArray(option)
                ? option.map((o) => o.value)
                : option
                  ? [
                      (
                        option as {
                          label: string;
                          value: string | number;
                        } | null
                      )?.value,
                    ]
                  : undefined,
            }))
          }
        />
      </section>
      <SourceInfiniteList
        key={fulfilledTimeStamp}
        filter={filter}
        initialSources={data.content}
      />
    </Card>
  );
};
