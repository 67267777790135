import { DetailsBookingCard } from "components/BookingCard/DashboardBookingCard";
import { ClientList } from "containers/Guests/Clients/ClientList";
import dayjs from "dayjs";
import {
  applicationContextActions,
  dateSelector,
  useApplicationContextActions,
} from "features/AppContext";
import { useFromProxyActions } from "features/BookingFormProxy";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { useTimelineActions } from "features/Timeline";
import { bookingApi, useGetBookingQuery } from "features/api/bookings-api";
import { restaurantsApi } from "features/api/restaurants";
import { type BookingSlot, SlotId } from "models/booking.model";
import type { Client } from "models/client.model";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  type LoaderFunction,
  Outlet,
  useLoaderData,
  useOutletContext,
} from "react-router-dom";
import { loadStore } from "storage";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";
import { Card, Spinner } from "ui-kit";
import type { NavLinkTabsProps } from "ui-kit/Card/NavLinkTabs";
import { getBookingStartTime, invariant } from "utils";
import { z } from "zod";

import type { BookingDetailsContext } from "../context";
import styles from "./DetailsHeader.module.scss";

export const loader = (async ({ params }) => {
  const { slotId } = params as Params;
  const validatedSlotId = z.coerce.number().int().pipe(SlotId).parse(slotId);

  const store = await loadStore();

  const getBookingPromise = store.dispatch(
    bookingApi.endpoints.getBooking.initiate(validatedSlotId),
  );

  const getRestaurantsPromise = store.dispatch(
    restaurantsApi.endpoints.fetchAllRestaurants.initiate(),
  );

  await Promise.all([getBookingPromise, getRestaurantsPromise]).then((data) => {
    // Выбрасываем исключение, если не удалось загрузить бронь
    invariant(data[0].data, JSON.stringify(data[0]?.error));

    // Получаем id ресторана, к которому относится эта бронь
    const currentRestaurantId = data[0].data.restaurant_id;

    const restaurants = data[1].data;

    // Пытаемся найти этот ресторан среди ресторанов
    const currentRest = restaurants?.find(
      (r) => r.restaurant_id === currentRestaurantId,
    );
    // Если мы не смогли его найти, то скорее всего у нас нет доступа к этому ресторану, так что выбрасываем исключение
    invariant(currentRest, ETranslations.UNKNOWN_RESTAURANT);

    // Смотрим какой ресторан установлен в приложении сейчас
    const appRestaurant = store.getState().applicationContext.restaurant;

    //Если никакой ресторан не установлен, значит селектор ресторанов еще не заполнен, поэтому можем просто добавить id в localstorage
    !appRestaurant
      ? localStorage.setItem("restaurantId", String(currentRestaurantId))
      : // Иначе если id ресторана из брони не совпадает с id ресторана приложения, то устанавливаем в приложение этот ресторан
        appRestaurant.restaurant_id !== currentRestaurantId &&
        store.dispatch(applicationContextActions.setRestaurant(currentRest));
  });

  return validatedSlotId;
}) satisfies LoaderFunction;

export const BookingDetailsHeader = () => {
  const slotId = useLoaderData<typeof loader>();
  const { data } = useGetBookingQuery(slotId);
  // сохраняем дату, которая была в приложении до открытия брони
  const previousDate = useRef(useSelector(dateSelector));
  const { formatMessage } = useIntl();
  const links = useMemo(
    () =>
      [
        {
          title: formatMessage({ id: ETranslations.BOOKING_MANAGEMENT }),
          to: "info",
        },
        {
          title: formatMessage({ id: ETranslations.CHANGE_HISTORY }),
          to: "history",
        },
        {
          title: formatMessage({ id: ETranslations.CHAT }),
          to: "chat",
          disabled: !data?.client.client_id,
        },
      ] as const satisfies NavLinkTabsProps["links"],
    [slotId],
  );
  const { setOnlyBooking, setClient } = useFromProxyActions();
  const { setPlaceFromBooking, setDate } = useApplicationContextActions();
  const { switchMode } = useHallSchemaActions();

  const [view, setView] = useState<"booking" | "guestList">("booking");

  const selectGuestHandler = (guest: Client) => {
    setClient({ client: guest });
    setView("booking");
  };

  const { setTime } = useTimelineActions();
  useEffect(() => {
    data &&
      (setTime(getBookingStartTime(data.date, data.time)),
      setPlaceFromBooking(data),
      setOnlyBooking(data),
      switchMode(HallMode.TABLES),
      setDate(dayjs(data.date).utc(true).toISOString()));
    // Возвращаем дату, которая была в приложении до открытия брони
    return () => void setDate(previousDate.current.toISOString());
  }, [data?.slot_id]);

  return (
    <>
      <Card className={view === "guestList" ? styles.hide : undefined}>
        <Card.NavLinkTabs navigateOnClose=".." links={links} className={styles.detailsHeader}/>
        {!data ? (
          <Spinner />
        ) : (
          <>
            <DetailsBookingCard booking={data} className={styles.detailsCard} />
            <Card.Content noPadding>
              <Outlet<BookingDetailsContext>
                context={{
                  booking: data,
                  openGuestList: () => setView("guestList"),
                }}
              />
            </Card.Content>
          </>
        )}
      </Card>
      <ClientList
        className={view === "booking" ? styles.hide : undefined}
        withHeader
        onSelectClient={selectGuestHandler}
        onClose={() => setView("booking")}
      />
    </>
  );
};
