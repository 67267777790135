import { TemplatePlaceholder } from "@devexpress/dx-react-core";
import cn from "classnames";
import { clientShortName } from "common/helpers";
import { moveBookingSelectors } from "features/MoveBooking";
import { timelineSelectors } from "features/Timeline";
import { useSliderDatetime } from "hooks/useSliderDatetime";
import { constant } from "lodash";
import type { BookingSlot, ManagerSlot } from "models/booking.model";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import type { BookingTable } from "types/table";
import { ETranslations } from "types/translates";
import { ICONS } from "ui-kit";



import { SEAT_TYPE_ICONS } from "../../../constants";
import { activeTablesSelector, hallModeSelector } from "../../../features/HallSchema/selectors";
import { isManagerialTable } from "../../../utils";
import { getSlotExtraOptions, getType, selectSlotByShift } from "../../Table/utils";
import { TTableSlots } from "../../hall-scheme/redux/HallSchemaV2/NewScheme/utils";
import { useGetTableAction, useIsEndingSoon } from "../Table/hooks";
import { BottomBlock } from "./BottomBlock/BottomBlock";
import { TableNumber } from "./TableNumber/TableNumber";
import styles from "./style.module.scss";
import { useDragNDropTables } from "./useDragNDropTables";
import { useStatusColor } from "./useStatusColor";


export type TBookingTableTemplateProps = {
  onClick?: () => void;
  tableSlots: TTableSlots[number];
  table: BookingTable;
};

export const BookingTableTemplate = ({
  table: { tableId, tableNumber, tableCapacity, figure, position },
  tableSlots,
}: TBookingTableTemplateProps) => {
  if (!tableId) return null;
  const { formatMessage } = useIntl();
  const hallMode = useSelector(hallModeSelector);
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const activeTables = useSelector(activeTablesSelector);
  const moveSource = useSelector(moveBookingSelectors.sourceBookingId);
  const timeWithDate = useSliderDatetime();
  const slot = selectSlotByShift(tableSlots, timeWithDate());
  const { drag, dragPreview, drop, isOver, isDragging } = useDragNDropTables(
    slot,
    tableId,
    tableNumber,
  );
  const {
    tableColor,
    timeWord,
    timeString,
    tableStatus,
    slot: { slot: booking } = {},
  } = getSlotExtraOptions(slot, timeWithDate());

  const statusColor = useStatusColor(booking, isActualTime, tableColor);
  const fullName =
    booking?.slot_type === "BOOKING"
      ? clientShortName(booking?.client)
      : undefined;
  const modalType = getType(hallMode, booking?.slot_id);


  const { handleTableClick, selectedTableId } = useGetTableAction({
    tableId,
    tableNumber,
    booking: booking as BookingSlot | ManagerSlot,
  });
  const isEndingSoon = useIsEndingSoon({
    booking: booking as BookingSlot | ManagerSlot,
    tableColor,
  });

  const isTableSelected = useMemo(
    () => moveBookingSelectors.isTableSelectedFactory(tableId),
    [tableId],
  );

  const isBookingSelected = useMemo(
    () =>
      booking
        ? moveBookingSelectors.isBookingSelectedFactory(booking.slot_id)
        : constant(false),
    [booking],
  );

  const isTableMoveTarget = useSelector(isTableSelected);
  const isBookingMoveTarget = useSelector(isBookingSelected);
  const isMoveTarget = useMemo(
    () => isTableMoveTarget || isBookingMoveTarget,
    [isTableMoveTarget, isBookingMoveTarget],
  );

  const moveBookingClass = useMemo(() => {
    if (moveSource && moveSource === booking?.slot_id) return styles.moveSource;
    if (isMoveTarget) return styles.moveTarget;
    return undefined;
  }, [moveSource, isMoveTarget, booking]);

  const isShowBorder = booking && booking.slot_place.length > 1;

  const alerts = booking
    ? {
        isOverbooking: tableSlots?.some(
          ({ slot: b }) => b?.slot_type === "BOOKING" && b.overbooking,
        ),
        isVip: booking?.slot_type === "BOOKING" && Boolean(booking.client?.vip),
        isDeposit:
          booking?.slot_type === "BOOKING" &&
          Boolean(booking.slot_deposit.use_deposit),
        isEndingSoon: isEndingSoon,
        isManagerial: Boolean(booking && isManagerialTable(booking)),
      }
    : null;

  const hasAlerts = alerts && Object.values(alerts).some(Boolean);

  const isSelectedOrTargetTable =
    (modalType === "select-table" &&
      !!activeTables.find((it) => it === tableId)) ||
    selectedTableId === tableId ||
    isOver;

  const VisitorsIcon =
    (booking?.seat_type && SEAT_TYPE_ICONS[booking.seat_type]) || ICONS.Guests;

  return figure && position ? (
    <g className={`table-${tableNumber}`} ref={drop}>
      <foreignObject
        className={cn({
          [styles.selectedWrapper]: isSelectedOrTargetTable || isDragging,
        })}
        height={figure.height + 32 + (fullName ? 35 : 0) + (hasAlerts ? 5 : 0)}
        width={figure.width + 32}
        x={position.x}
        y={position.y}
      >
        {/* Дополнительная обертка, чтобы избежать багов визуализации в Safari на ios */}
        <div
          className={cn(
            styles.tableGroup,
            { [styles.selectedTable]: isSelectedOrTargetTable },
            moveBookingClass,
          )}
          ref={dragPreview}
        >
          {/* Обводка если столы объеденины */}
          <article
            className={cn(styles[figure.shape], styles.borderWrapper)}
            style={{
              height:
                figure.height + 17 + (fullName ? 35 : 0) + (hasAlerts ? 5 : 0),
              width: figure.width + 17,
              background: "transparent",
            }}
          >
            <div
              className={cn(styles.tableWrapper, styles[figure.shape], {
                [styles.border]:
                  isShowBorder &&
                  !(moveBookingClass || isSelectedOrTargetTable),
              })}
              ref={drag}
              style={{
                background:
                  booking?.slot_type === "BOOKING" && booking.extra_status
                    ? `linear-gradient(to right top, ${statusColor} 50%, ${booking.extra_status.color} 50%)`
                    : statusColor,
                outlineColor: statusColor,
                height: figure.height,
                width: figure.width,
              }}
            >
              <div className={styles.tableBody}>
                <div
                  className={cn(styles.tableContainer)}
                  data-no-d3-zoom
                  onClick={handleTableClick}
                >
                  <div className={styles.tableInfo} data-no-d3-zoom>
                    <VisitorsIcon height={20} width={20} data-no-d3-zoom />
                    <span data-no-d3-zoom>
                      {tableStatus && booking?.slot_type === "BOOKING"
                        ? booking?.visitors
                        : tableCapacity}
                    </span>
                  </div>
                  {isManagerialTable(booking) && (
                    <span className={styles.managementTitle}>
                      {formatMessage({
                        id: ETranslations.MANAGEMENT_RESERVATION,
                      })}
                    </span>
                  )}
                  <div className={styles.bookingInfo} data-no-d3-zoom>
                    {Boolean(timeString) && (
                      <span data-no-d3-zoom>{timeString}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <TableNumber
              isBookingSoon={timeWord === "BOOKING_SOON"}
              statusColor={statusColor}
              tableNumber={tableNumber}
            />
            {/* Уведомления */}
            {hasAlerts && (
              <BottomBlock
                alerts={alerts}
                style={{ marginTop: figure.height }}
              />
            )}

            {fullName && (
              <div
                className={cn(styles.fullName, {
                  [styles.selectedFullName]:
                    isSelectedOrTargetTable || isDragging,
                })}
              >
                <p>{fullName}</p>
              </div>
            )}
          </article>
        </div>
      </foreignObject>
    </g>
  ) : (
    <TemplatePlaceholder />
  );
};