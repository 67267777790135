import cn from "classnames";
import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { NavLink, type To } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, ICONS, LinkButton } from "ui-kit";

import { useCreateBookingFormContext } from "../context/CreateBookingContext";
import styles from "./FormComponents.module.scss";

export const FormStepper = ({
  stepsInfo,
  errorText,
}: {
  // valid имеет три состояния: true = валидный, false = инвалидный, undefined = не проверялся
  stepsInfo: {
    title: string;
    description?: ReactNode;
    valid?: boolean;
  }[];
  errorText: string;
}) => {
  return (
    <nav className={styles.stepList}>
      <ul>
        {stepsInfo.map((info, index) => (
          <li
            key={info.title}
            className={cn(
              styles.stepItem,
              info.valid && styles.valid,
              info.valid === false && styles.invalid,
            )}
          >
            <NavLink
              to={`step-${index + 1}`}
              className={({ isActive, isPending }) =>
                cn(styles.navLink, isActive && styles.active)
              }
            >
              <strong className={styles.stepTitle}>{info.title}</strong>
              {info.description && (
                <span className={styles.stepDescription}>
                  {info.valid !== false ? info.description : errorText}
                </span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export const FormHeader = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <header className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
      {children}
    </header>
  );
};

export const FormClose = ({
  to,
  onClose,
}: {
  to: To;
  onClose?: () => void;
}) => {
  return (
    <LinkButton
      className={styles.closeButton}
      variant="phantom"
      to={to}
      onClick={onClose}
    >
      <ICONS.Cross />
    </LinkButton>
  );
};

export const StepContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <main className={cn(styles.content, className)}>{children}</main>;
};

export const FormFooter = ({ children }: { children: ReactNode }) => {
  return <footer className={styles.footer}>{children}</footer>;
};

export const getValidationStatus = (
  validationValues: boolean[],
  currentStep: number,
) =>
  validationValues.reduce<{
    firstNotValidStep: number | undefined;
    nextStep: number | undefined;
    allValid: boolean;
  }>(
    (result, valid, index) => {
      if (!valid) {
        index >= currentStep && (result.nextStep ||= index + 1);
        result.allValid &&= false;
        result.firstNotValidStep ||= index + 1;
      }
      return result;
    },
    {
      firstNotValidStep: undefined,
      nextStep: undefined,
      allValid: true,
    },
  );
