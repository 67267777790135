import { ETranslations } from "../../types/translates";
import { ArrowUpFilled } from "../../ui-kit/ICONS/icons";
import styles from "./requestsGrid.module.scss";
import { CalendarOptions } from "@fullcalendar/core";
import { ResourceLabelContentArg } from "@fullcalendar/resource";
import cn from "classnames";
import React, { HTMLAttributes, VFC, useMemo } from "react";
import { useIntl } from "react-intl";

type TGridHeaderProps = {
  sortDirection: string;
} & Pick<HTMLAttributes<HTMLDivElement>, "onClick">;

export const GridHeader: VFC<TGridHeaderProps> = ({
  sortDirection,
  onClick,
}) => {
  const intl = useIntl();
  const isAscendingSortDirection = useMemo(
    () => sortDirection === "",
    [sortDirection],
  );

  return (
    <div className={styles.areaHeader} onClick={onClick}>
      <span className={styles.caption}>
        {intl.formatMessage({ id: ETranslations.GRID_RESOURCES_HEADER_TITLE })}
      </span>
      <ArrowUpFilled
        className={cn(styles.arrow, {
          [styles.active]: isAscendingSortDirection,
        })}
      />
      <ArrowUpFilled
        className={cn(styles.arrow, styles.downArrow, {
          [styles.active]: !isAscendingSortDirection,
        })}
      />
    </div>
  );
};

export const renderResourceLabelContent: CalendarOptions["resourceLabelContent"] =
  (resourceInfo: ResourceLabelContentArg) => {
    return (
      <div className={styles.tableResource}>
        <span className={styles.title}>{resourceInfo.resource.title}</span>
        <span className={styles.capacity}>
          {resourceInfo.resource.extendedProps.capacity}
        </span>
      </div>
    );
  };
