import { z } from "zod";

import { CRMTag, TagId } from "./tags.model";

export const ClientId = z.number().brand("ClientId");
export type ClientId = z.infer<typeof ClientId>;

const ContactId = z.number().brand("ContactId");
export type ContactId = z.infer<typeof ContactId>;

const CommonProperties = z.object({
  contact_id: ContactId,
  tags: TagId.array(),
  gradeName: z.string().optional(),
  vip: z.boolean().nullable(),
  middle_name: z.string().nullable().optional(),
  gradeColor: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().nullable().optional(),
  surname: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  display_name: z.string(),
  user_id: z.number().optional(),
  grade_name: z.string(),
  grade_color: z.string().nullable().optional(),
  loyalty_lvl: z.string().optional(),
  average_visits: z.number().optional(),
  time: z.string().optional(),
  birthday: z.string().optional(),
  comment: z.string().optional(),
  client_photo: z.string().optional(),
  sex: z
    .object({
      id: z.number(),
      name: z.string(),
      nameEng: z.string(),
    })
    .optional(),
  work_phone: z.string().optional(),
  alt_email: z.string().optional(),
  salutation_id: z.number().optional(),
  salutation: z
    .object({
      id: z.number(),
      name: z.string(),
      nameEng: z.string(),
    })
    .optional(),
  last_visit: z.string().optional(),
  last_booking_time: z.string().optional(),
  last_location: z.string().optional(),
  visit_count: z.number().optional(),
  cancel_count: z.number().optional(),
  not_come_count: z.number().optional(),
  avg_visit: z.number().nullable().optional(),
  avg_transaction: z.number().nullable().optional(),
  total_purchase: z.number().nullable().optional(),
});

export const Contact = CommonProperties.extend({
  contact_type: z.literal("CONTACT"),
  client_id: z.null().optional(),
});
export type Contact = z.infer<typeof Contact>;

export const Client = CommonProperties.extend({
  contact_type: z.literal("CLIENT"),
  client_id: ClientId,
});
export type Client = z.infer<typeof Client>;

export const ClientOrContact = z.discriminatedUnion("contact_type", [
  Client,
  Contact,
]);
export type ClientOrContact = z.infer<typeof ClientOrContact>;

export const ClientReviews = z
  .object({
    place: z.string(),
    review: z.string(),
    assessment: z.number().min(0).max(5),
    clientName: z.string(),
    clientSurname: z.string(),
    avatar: z.string(),
    publish: z.boolean(),
    improve: z.string().array(),
    user_id: ClientId,
    time_key: z.string().datetime({ offset: true }),
  })
  .array();
export type ClientReviews = z.infer<typeof ClientReviews>;

export const ClientsTags = z
  .object({
    clients_tags: z
      .object({
        tags: CRMTag.array(),
        client_id: ClientId,
      })
      .array(),
  })
  .transform((tagsObj) =>
    tagsObj.clients_tags.reduce<Record<`${ClientId}` | ClientId, CRMTag[]>>(
      (result, clientTags) => (
        (result[clientTags.client_id] = clientTags.tags), result
      ),
      {},
    ),
  );
export type ClientsTags = z.infer<typeof ClientsTags>;
export type ClientsTagsDTO = z.input<typeof ClientsTags>;
