import { ETranslations } from "../../../types/translates";
import useChoiceData from "./hooks/useChoiceData";
import useChoiceTimeInput from "./hooks/useChoiceTimeInput";
import styles from "./style.module.scss";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { DatePicker, SelectBasic } from "ui-kit";

type DateProps = FieldRenderProps<Date, any>;

export const ChoiceTimeInput = ({
  disabled = false,
  allowValueFromOutsideRange = false,
  isFromManagement = false,
}: {
  disabled?: boolean;
  allowValueFromOutsideRange?: boolean;
  isFromManagement?: boolean;
}) => {
  const { intl, handleChange, inputOptions, value } = useChoiceTimeInput(
    allowValueFromOutsideRange,
    isFromManagement,
    disabled,
  );

  return (
    <SelectBasic
      className={disabled ? "input" : ""}
      disabled={disabled}
      isValid={!!value?.value}
      options={inputOptions}
      placeholder={intl.formatMessage({ id: ETranslations.BASE_TIME })}
      value={value}
      changedMaxSize
      onChange={handleChange}
    />
  );
};

const ChoiceDate = ({
  value,
  onChange,
  disabled,
  minDate = new Date(),
}: any) => {
  const { datePickerHandler } = useChoiceData(disabled, value, onChange);

  return (
    <DatePicker
      className={styles.datePicker}
      customInputClassName={styles.boldText}
      disabled={disabled}
      minDate={minDate}
      selected={value}
      onChange={datePickerHandler}
    />
  );
};

export const ChoiceDateInput: React.FC<DateProps> = ({
  input,
  meta,
  ...rest
}) => <ChoiceDate {...input} {...meta} {...rest} />;
