import { TaskMessages } from "containers/Dialogs/Messages/Messages";
import { useGetChatMessagesQuery } from "features/api/bookings-api/chat";

import { useBookingDetailsContext } from "../context";
import styles from "./BookingChat.module.scss";

export const BookingChat = () => {
  const { booking } = useBookingDetailsContext();
  const data = useGetChatMessagesQuery({
    client_id: booking.client.client_id!,
    partner_id: booking.restaurant_id,
    limit: 400,
  });
  return (
    <section className={styles.bookingChatContainer}>
      <TaskMessages
        customerId={String(booking.client?.client_id)}
        taskId={booking.feed_id}
        className={styles.chat}
      />
    </section>
  );
};
