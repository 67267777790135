import React, { ReactElement, memo } from "react";

interface HideWhenProps {
  children: ReactElement | null | false;
  condition: boolean | undefined;
  noUnmount?: boolean;
}

const HIDE_STYLES = {
  display: "none",
};

export const HideWhen = memo(
  ({
    children,
    condition,
    noUnmount = false,
  }: HideWhenProps): JSX.Element | null => {
    if (!children) return null;
    if (condition && !noUnmount) return null;
    if (condition && noUnmount)
      return React.cloneElement(children, { style: HIDE_STYLES });
    return <>{children}</>;
  },
);
