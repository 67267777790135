import { ETranslations } from "../../types/translates";
import styles from "./Pagination.module.scss";
import cn from "classnames";
import { ICONS } from "common/helpers";
import RCPagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { VFC } from "react";
import { useIntl } from "react-intl";

interface Props {
  currentPage: number;
  pageSize: number;
  total: number;
  onChange: (page: number) => void;
  className?: string;
}

export const Pagination: VFC<Props> = ({
  currentPage,
  pageSize,
  total,
  className,
  onChange,
}) => {
  const intl = useIntl();

  return (
    <RCPagination
      className={cn(className, styles.pagination)}
      current={currentPage + 1}
      jumpNextIcon="...."
      jumpPrevIcon="...."
      locale={{ page: intl.formatMessage({ id: ETranslations.PAGE }) }}
      nextIcon={<ICONS.NextIcon />}
      pageSize={pageSize}
      prefixCls="pg"
      prevIcon={<ICONS.PrevIcon />}
      total={total}
      hideOnSinglePage
      onChange={onChange}
    />
  );
};
