import { BookingHistoryCard } from "components/BookingCard/BookingHistoryCard";
import { useFetchBookingHistoryQuery } from "features/api/bookings-api";
import { useAllStatuses } from "features/api/dictionaries-api";
import type { BookingSlot, ManagerSlot } from "models/booking.model";
import { FunctionComponent } from "react";
import type { BaseStatus, ExtraStatus } from "types/status";

import BookingHistoryLog from "./BookingHistoryLog";
import "./bookingHistory.scss";

type BookingHistoryProps = {
  isExpanded?: boolean;
  onClick?: (booking: BookingSlot | ManagerSlot) => void;
} & (
  | {
      bookingId: number | `${number}`;
      booking?: undefined;
    }
  | { bookingId?: undefined; booking: BookingSlot | ManagerSlot }
);

//FIXME: заменить booking на bookingId

export const BookingHistory: FunctionComponent<BookingHistoryProps> = ({
  booking,
  bookingId,
  isExpanded,
  onClick,
}) => {
  const { data } = useFetchBookingHistoryQuery(bookingId ?? booking.slot_id);
  const { statusById } = useAllStatuses();
  const status = data && statusById?.[data.booking.status_id];
  const extraStatus =
    data?.booking.extra_status_id && statusById?.[data.booking.extra_status_id];
  return (
    <div className="bookingHistory">
      <div className="bookingWrapper">
        {(data?.booking || booking) && status && (
          <BookingHistoryCard
            booking={data.booking}
            status={status as BaseStatus}
            extraStatus={extraStatus as ExtraStatus}
          />
        )}
      </div>
      {Boolean(data && isExpanded) && <BookingHistoryLog data={data!} />}
    </div>
  );
};
