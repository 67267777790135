import styles from "./Popup.module.scss";
import usePopup from "./usePopup";
import { Placement } from "@popperjs/core";
import cn from "classnames";
import { FC, ReactNode, Ref, cloneElement, forwardRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";

export const Trigger: FC = ({ children }) => <>{children}</>;

export interface ContentProps {
  noPadding?:
    | boolean
    | {
        top?: boolean;
        bottom?: boolean;
        left?: boolean;
        right?: boolean;
      };
}
export const Content: FC<ContentProps> = ({ children }) => <>{children}</>;

export interface PopupRef {
  close: () => void;
}

export interface PopupProps {
  children: ReactNode;
  placement?: Placement;
  onClose?: () => void;
  onOpen?: () => void;
}

const PopupBase = forwardRef(
  (
    { children, placement = "auto", onClose, onOpen }: PopupProps,
    ref: Ref<PopupRef>,
  ) => {
    const {
      trigger,
      isOpen,
      setPopperElement,
      popperStyles,
      attributes,
      paddingStyles,
      content,
      handleClick,
      setReferenceElement,
      close,
    } = usePopup({
      children,
      placement,
      onClose,
      onOpen,
      ref,
    });

    return (
      <OutsideClickHandler display="contents" useCapture onOutsideClick={close}>
        {trigger?.props?.children &&
          cloneElement(trigger.props.children, {
            onClick: handleClick,
            ref: setReferenceElement,
          })}
        {isOpen && (
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
            className={cn(
              styles.popup,
              attributes.popper?.className,
              paddingStyles,
            )}
          >
            {content}
          </div>
        )}
      </OutsideClickHandler>
    );
  },
);

export const Popup = Object.assign(PopupBase, {
  Trigger,
  Content,
});
