import { HideWhen } from "components/HideWhen";
import { DashboardBookings } from "components/booking/DashboardBookings";
import { CloseBookingContext } from "contexts/CloseBookingContext";
import React from "react";
import { Outlet } from "react-router-dom";
import { Spinner } from "ui-kit";

import useDashBoards from "./useDashBoards";

export function Dashboard() {
  const {
    hasBooking,
    isTablet,
    isCreatingBooking,
    mode,
    isGuestMode,
    close,
    isRootDashboard,
  } = useDashBoards();

  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <HideWhen
          condition={
            (isTablet && (hasBooking || !isRootDashboard)) ||
            (isTablet && isCreatingBooking)
          }
          noUnmount
        >
          <DashboardBookings />
        </HideWhen>
      </React.Suspense>
      <CloseBookingContext.Provider value={hasBooking ? close : null}>
        <HideWhen condition={mode.includes("HALL") || isGuestMode} noUnmount>
          <Outlet />
        </HideWhen>
      </CloseBookingContext.Provider>
    </>
  );
}
