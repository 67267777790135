import { ColorMode, useColorMode } from "hooks/useColorMode";
import { useUserLocale } from "hooks/useUserLocale";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { AuthService } from "services/auth.service";
import { ELocales } from "types/commons";

import { WebviewExternalAuth } from "./auth";
import { createMockHandler } from "./mock";
import { WebViewTransport } from "./transport";

/*
    ____,-------------------------------,____
    \   |           Контекст           |   /
    /___|-------------------------------|___\
*/

export type IWebViewContext = {
  isWebView: boolean;
  transport: WebViewTransport;
  settings: WebViewSettings;
};

export const WebViewContext = React.createContext<IWebViewContext | undefined>(
  undefined,
);

/*
    ____,-------------------------------,____
    \   |           Провайдер           |   /
    /___|-------------------------------|___\
*/

export function WebViewProvider({
  mock,
  debug = false,
  children,
}: {
  mock?: boolean;
  debug?: boolean;
  children: ReactNode;
}) {
  const refInitialized = useRef(false);

  // Подключение моков
  if (mock && !refInitialized.current) {
    createMockHandler(AuthService.new());
  }

  const { setUserLocale } = useUserLocale();
  const { setLight, setDark } = useColorMode();

  const transport = WebViewTransport.get();
  transport.debug = debug;
  const isWebView = transport.isReady;
  const [settings] = useState(getWebviewSettings);

  // Инициализация
  if (!refInitialized.current) {
    refInitialized.current = true;
    if (isWebView) {
      // Устанавливаем внешнюю авторизацию
      AuthService.useExternalAuth(new WebviewExternalAuth(transport));

      // Применяем настройки
      if (settings.locale) {
        setUserLocale(settings.locale);
      }
      switch (settings.theme) {
        case "light":
          setLight();
          break;
        case "dark":
          setDark();
          break;
      }
    }
  }

  useEffect(() => {
    // Сообщаем нативному приложению о готовности фронтенда
    transport.send({ type: "READY" });

    return () => {
      // Удаляем внешнюю авторизацию перед unmount
      if (isWebView) {
        AuthService.useExternalAuth(null);
      }
    };
  }, []);

  const value: IWebViewContext = {
    isWebView,
    transport,
    settings,
  };

  return (
    <WebViewContext.Provider value={value}>{children}</WebViewContext.Provider>
  );
}

type WebViewSettings = { locale: ELocales | null; theme: ColorMode | null };

function getWebviewSettings(): WebViewSettings {
  const params = new URLSearchParams(window.location.search);

  let locale = params.get("locale") as ELocales | null;
  if (!locale || !ELocales[locale]) locale = null;

  let theme = params.get("theme") as ColorMode | null;
  if (!theme || !["dark", "light"].includes(theme)) theme = null;

  return { locale, theme };
}
