import styles from "./style.module.scss";
import { FC } from "react";
import { ICONS } from "ui-kit";

interface Props {
  isBookingSoon: boolean;
  tableNumber: string;
  statusColor?: string;
}

export const TableNumber: FC<Props> = ({
  isBookingSoon,
  tableNumber,
  statusColor,
}) => {
  return (
    <div className={styles.tableNumberBody}>
      <p
        className={styles.tableNumber}
        style={{ border: `2px solid ${statusColor}` }}
        data-no-d3-zoom
      >
        {isBookingSoon && <ICONS.ClockAlarm height={12} width={12} />}
        {tableNumber}
      </p>
    </div>
  );
};
