import { useFetchSourceQuery } from "features/api/source-api";
import { useIntlUtils } from "hooks/useIntlUtils";
import { useIntl } from "react-intl";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";
import { Card, ICONS, LinkButton, Spinner } from "ui-kit";
import type { NavLinkTabsProps } from "ui-kit/Card/NavLinkTabs";
import { useIsTablet } from "ui-kit/hooks";

import { CreateSource } from "../SourceForm/SourceForm";
import styles from "./SourceCard.module.scss";

const SourceCard = () => {
  const { sourceId } = useParams<Params>();
  const { formatMessage } = useIntl();

  const { data: source, isFetching } = useFetchSourceQuery(sourceId ?? 0);

  const links = [
    {
      title: formatMessage(
        { id: ETranslations.CARD_OF },
        { value: formatMessage({ id: ETranslations.SOURCE_ALT_2 }) },
      ),
      to: "info",
    },
    {
      title: formatMessage({ id: ETranslations.SOURCE_CARD_UPDATING }),
      to: "edit",
      hideOtherLinksWhenActive: true,
      hideWhenInactive: true,
    },
  ] as const satisfies NavLinkTabsProps["links"];

  return (
    <Card className={styles.source}>
      <Card.NavLinkTabs
        links={links}
        className={styles.navLinks}
        navigateOnClose=".."
      />
      <Card.Content noPadding className={styles.content}>
        {isFetching || !source ? <Spinner /> : <Outlet context={source} />}
      </Card.Content>
    </Card>
  );
};

const SourceCreationCard = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <Card className={styles.source} onClose={() => navigate("..")}>
      <Card.Header
        title={formatMessage(
          { id: ETranslations.ENTITY_CREATING },
          { entity: formatMessage({ id: ETranslations.SOURCE_ALT_2 }) },
        )}
        className={styles.navLinks}
      />
      <Card.Content noPadding className={styles.content}>
        <CreateSource />
      </Card.Content>
    </Card>
  );
};

const EmptySourceDetails = () => {
  const {
    getIntlEntityViewingAction,
    intl: { formatMessage },
  } = useIntlUtils();
  const isTablet = useIsTablet();
  return !isTablet ? (
    <Card className={styles.emptySource} as="section">
      <ICONS.Contact width={220} height={220} />
      <p>
        {getIntlEntityViewingAction(
          ETranslations.SOURCE_ALT,
          ETranslations.SOURCE,
        )}
      </p>
      <LinkButton variant="primary" type="button" to={"create"}>
        {formatMessage(
          { id: ETranslations.BASE_ADD_ENTITY },
          {
            entity: formatMessage({
              id: ETranslations.SOURCE,
            }).toLowerCase(),
          },
        )}
      </LinkButton>
    </Card>
  ) : null;
};

SourceCard.displayName = "SourceCard";
EmptySourceDetails.displayName = "EmptySourceDetails";
SourceCreationCard.displayName = "SourceCreationCard";

export { SourceCard, EmptySourceDetails, SourceCreationCard };
