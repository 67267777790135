import { IPayloadClientHistory } from "components/BookingClientHistory/BookingClientHistory";
import { IResponse } from "models/common";
import {
  BookingHistoryDTO,
  BookingHistoryDetailsChronologicalChangesDTO,
  type HistoryId,
} from "models/history.model";
import {
  BookingHistoryClientResponse,
  BookingHistoryDetailsChronologicalPlacesResponse,
  BookingHistoryDetailsChronologicalStatusResponse,
  BookingHistoryDetailsParamResponse,
} from "types/booking";

import { invalidateHallSlots } from "../hallschema-api";
import { coreApi } from "./core";

const bookingHistoryApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    fetchBookingHistory: build.query<
      BookingHistoryDTO,
      number | `${number}` | null
    >({
      query: (bookingId) => ({
        url: `v2/booking/history/${bookingId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 10,
      transformResponse: (response: IResponse<BookingHistoryDTO>) => {
        const result = BookingHistoryDTO.safeParse(response.data);
        !result.success && console.log(result.error.format());
        return result.success ? result.data : response.data;
      },
      providesTags: (_res, _err, bookingId) => [
        { type: "BookingHistory", id: Number(bookingId) },
      ],
    }),
    fetchBookingHistoryDetailsParam: build.query<
      BookingHistoryDetailsParamResponse,
      number
    >({
      query: (id: number) => ({
        url: `v2/booking/history/details/params/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: IResponse<BookingHistoryDetailsParamResponse>,
      ) => response.data,
    }),
    fetchBookingHistoryDetailsChronologicalStatus: build.query<
      BookingHistoryDetailsChronologicalStatusResponse,
      number
    >({
      query: (id: number) => ({
        url: `v2/booking/history/details/chronological/status/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: IResponse<BookingHistoryDetailsChronologicalStatusResponse>,
      ) => response.data,
    }),
    fetchBookingHistoryDetailsChronologicalPlaces: build.query<
      BookingHistoryDetailsChronologicalPlacesResponse,
      number
    >({
      query: (id: number) => ({
        url: `v2/booking/history/details/chronological/places/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: IResponse<BookingHistoryDetailsChronologicalPlacesResponse>,
      ) => response.data,
    }),
    fetchBookingHistoryDetailsChronologicalChanges: build.query<
      BookingHistoryDetailsChronologicalChangesDTO[],
      number
    >({
      query: (id: number) => ({
        url: `v2/booking/history/details/chronological/changes/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: IResponse<BookingHistoryDetailsChronologicalChangesDTO[]>,
      ) => {
        const result = BookingHistoryDetailsChronologicalChangesDTO.array().safeParse(
          response.data,
        );
        !result.success && console.log(result.error.format());
        return result.success ? result.data : response.data;
      },
    }),
    recoverBookingStatus: build.mutation<
      String,
      { bookingId: number; historyId: HistoryId; force?: boolean }
    >({
      query: ({ bookingId, historyId, force }) => ({
        url: `v2/booking/recover/${bookingId}`,
        params: { force },
        body: { historyId },
        method: "post",
      }),
      invalidatesTags: (res, error, { bookingId }) => [
        "Bookings",
        { type: "Booking", id: bookingId },
        { type: "BookingStatus", id: bookingId },
        "GlobalSearchBookings",
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(invalidateHallSlots());
      },
    }),
    bookingHistoryClient: build.query<
      BookingHistoryClientResponse,
      { clientId: number | `${number}`; payload: IPayloadClientHistory }
    >({
      query: ({ clientId, payload }) => ({
        url: `v2/booking/history/client/${clientId}`,
        method: "POST",
        body: payload,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useFetchBookingHistoryQuery,
  useLazyFetchBookingHistoryQuery,
  useFetchBookingHistoryDetailsParamQuery,
  useFetchBookingHistoryDetailsChronologicalStatusQuery,
  useFetchBookingHistoryDetailsChronologicalPlacesQuery,
  useFetchBookingHistoryDetailsChronologicalChangesQuery,
  useBookingHistoryClientQuery,
  useRecoverBookingStatusMutation,
} = bookingHistoryApi;
