import styles from "./HallModal.module.scss";
import { Modal } from "components/modal";
import { requiredField } from "components/registration/validators/validators";
import { useUpdateHallMutation } from "features/api/restaurants";
import { Place } from "models/restaurant.model";
import { useCallback } from "react";
import { Field, Form } from "react-final-form";
import { useIntl } from "react-intl";
import { UpdatePlaceDTO } from "types/place";
import { ETranslations } from "types/translates";
import { Button, Input, Labeled } from "ui-kit";

interface HallModalProps {
  hall: Partial<UpdatePlaceDTO> | undefined;
  onClose: () => void;
  onAfterCreate?: (data: Place) => void;
}

export function HallModal({ hall, onClose, onAfterCreate }: HallModalProps) {
  const intl = useIntl();
  const [updateHall] = useUpdateHallMutation();

  const onSubmit = useCallback(
    async (data: Partial<UpdatePlaceDTO>) => {
      const updateResult = await updateHall(data).unwrap();
      onClose();
      if (!data.id) {
        onAfterCreate?.({
          ...updateResult,
          restaurant_id: updateResult.restaurant_id ?? data.restaurant_id,
        });
      }
    },
    [updateHall, onClose, onAfterCreate],
  );
  return (
    <Modal
      isOpen={Boolean(hall)}
      title={intl.formatMessage({
        id: hall?.id ? ETranslations.UPDATE_HALL : ETranslations.CREATE_HALL,
      })}
      onClose={onClose}
    >
      <Form initialValues={hall} onSubmit={onSubmit}>
        {({ handleSubmit, invalid, submitting }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Modal.Content className={styles.form}>
              <Labeled
                as="div"
                label={intl.formatMessage({ id: ETranslations.HALL_NAME })}
                required
              >
                <Field
                  name="name"
                  validate={(value) =>
                    requiredField(
                      value,
                      intl.formatMessage({
                        id: ETranslations.ERROR_DATE_NOT_SPECIFIED,
                      }),
                    )
                  }
                >
                  {({ input, meta }) => <Input {...input} {...meta} />}
                </Field>
              </Labeled>
              <Labeled
                as="div"
                label={intl.formatMessage({ id: ETranslations.HALL_WEIGHT })}
                required
              >
                <Field
                  name="weight"
                  parse={(v) => Number(v) || ""}
                  validate={(value) =>
                    requiredField(
                      value,
                      intl.formatMessage({
                        id: ETranslations.ERROR_DATE_NOT_SPECIFIED,
                      }),
                    )
                  }
                >
                  {({ input, meta }) => (
                    <Input min={1} type="number" {...input} {...meta} />
                  )}
                </Field>
              </Labeled>
            </Modal.Content>
            <Modal.Footer>
              <Button
                disabled={submitting || invalid}
                type="submit"
                variant="primary"
              >
                {hall?.id
                  ? intl.formatMessage({ id: ETranslations.BASE_SAVE })
                  : intl.formatMessage({ id: ETranslations.BASE_CREATE })}
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
}
