import { ETranslations } from "../../../types/translates";
import { HideWhen } from "../../HideWhen";
import { isRealClient } from "../validators/validators";
import styles from "./Client.module.scss";
import useClient from "./hooks/useClient";
import commonStyles from "./style.module.scss";
import cn from "classnames";
import { ICONS } from "common/helpers";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Input, Labeled } from "ui-kit";

const ClientInfoRedux = ({
  value,
  onChange,
  invalid,
  disabled,
  isCreateBooking,
  canEditNames = false,
  valid,
  isNewDesign = false,
  required,
  isWithPhone,
  props,
}: any) => {
  const {
    hide,
    fullRow,
    fullNameValue,
    handleClientName,
    editClient,
    isDisabled,
    clientFull,
    intl,
    goesEditClient,
    email,
    handleEmailChange,
  } = useClient({
    value,
    onChange,
    disabled,
    isCreateBooking,
    isWithPhone,
  });

  return (
    <>
      <HideWhen condition={hide && isWithPhone} noUnmount>
        <div
          className={cn("book titled-block required", { hasValue: !invalid })}
          style={{ gridColumn: "span 2" }}
        >
          <div className={commonStyles.guestInfo}>
            <Labeled
              className={styles.bookingLabel}
              label={intl.formatMessage({ id: ETranslations.NAME })}
              required={required}
            >
              <Input
                autoComplete="off"
                disabled={!editClient || isDisabled}
                inputClassName={commonStyles.greatBoldText}
                invalid={!(valid || fullNameValue.name?.trim())}
                maxLength={160}
                name="name"
                placeholder={
                  editClient || !isDisabled
                    ? intl.formatMessage({ id: ETranslations.NAME })
                    : undefined
                }
                required={required}
                size={1}
                suffix={
                  canEditNames &&
                  isRealClient(clientFull) &&
                  !editClient && (
                    <button
                      className={cn(styles.edit, isNewDesign && styles.editNew)}
                      type="button"
                      onClick={goesEditClient}
                    >
                      <ICONS.Edit />
                    </button>
                  )
                }
                value={fullNameValue.name}
                onChange={handleClientName}
                {...props}
              />
            </Labeled>
            <Labeled
              className={styles.bookingLabel}
              label={intl.formatMessage({ id: ETranslations.LAST_NAME })}
              required={required}
            >
              <Input
                autoComplete="off"
                disabled={!editClient || isDisabled}
                inputClassName={commonStyles.greatBoldText}
                invalid={!(valid || fullNameValue.surname?.trim())}
                maxLength={160}
                name="surname"
                placeholder={
                  editClient || !isDisabled
                    ? intl.formatMessage({
                        id: ETranslations.LAST_NAME,
                      })
                    : undefined
                }
                required={required}
                size={1}
                suffix={
                  canEditNames &&
                  isRealClient(clientFull) &&
                  !editClient && (
                    <button
                      className={cn(styles.edit, isNewDesign && styles.editNew)}
                      type="button"
                      onClick={goesEditClient}
                    >
                      <ICONS.Edit />
                    </button>
                  )
                }
                value={fullNameValue.surname}
                onChange={handleClientName}
                {...props}
              />
            </Labeled>
            <Labeled
              className={styles.bookingLabel}
              label={intl.formatMessage({ id: ETranslations.MIDDLE_NAME })}
            >
              <Input
                autoComplete="off"
                disabled={!editClient || isDisabled}
                inputClassName={commonStyles.greatBoldText}
                maxLength={160}
                name="middle_name"
                placeholder={
                  editClient || !isDisabled
                    ? intl.formatMessage({
                        id: ETranslations.MIDDLE_NAME,
                      })
                    : undefined
                }
                size={1}
                suffix={
                  canEditNames &&
                  isRealClient(clientFull) &&
                  !editClient && (
                    <button
                      className={cn(styles.edit, isNewDesign && styles.editNew)}
                      type="button"
                      onClick={goesEditClient}
                    >
                      <ICONS.Edit />
                    </button>
                  )
                }
                value={fullNameValue.middle_name}
                onChange={handleClientName}
                {...props}
              />
            </Labeled>
          </div>
        </div>
      </HideWhen>
      <HideWhen condition={hide || (fullRow && !isNewDesign)}>
        <div className="email titled-block add">
          <Labeled
            className={styles.bookingLabel}
            label={intl.formatMessage({ id: ETranslations.EMAIL })}
          >
            <Input
              disabled={isDisabled}
              inputClassName={commonStyles.greatBoldText}
              placeholder="@"
              size={1}
              type="email"
              value={email}
              onChange={handleEmailChange}
            />
          </Labeled>
        </div>
      </HideWhen>
    </>
  );
};

export const ChoiceClientInfoRedux: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => {
  return <ClientInfoRedux {...input} {...meta} {...rest} />;
};
