import { Subscription } from "features/Dialogs/SDK/utils";
import { IncomingMessages, OutgoingMessages, WebkitMessageHandler } from "./types";

// Транспорт для общения фронтенда Hostes и нативного приложения
//
// - Фронтенд реализует глобальный callback `__hostesAppCallback(msg: IncomingMessages): void`
//   для получения сообщений от нативного приложения
// - Приложение регистрирует в WebView messageHandler `hostesApp` для получения сообщений
//   от фронтенда
//

export class WebViewTransport {
    private handler?: WebkitMessageHandler;
    private subscription = new Subscription<IncomingMessages>();
    private static instance?: WebViewTransport;

    public debug = false;

    public isReady: boolean;

    private constructor() {
        // этот handler будем использовать мы
        this.handler = (<any>window).webkit?.messageHandlers?.hostesApp as WebkitMessageHandler | undefined;
        
        // этой функциией воспользуется нативное приложение
        if (this.handler) {
            (<any>window).__hostesAppCallback = this.onMessage.bind(this);
            this.isReady = true;
        } else {
            this.isReady = false;
        }
    }

    public static get(): WebViewTransport {
        if (!WebViewTransport.instance) {
            WebViewTransport.instance = new WebViewTransport()
        }
        return WebViewTransport.instance;
    }

    subscribe(cb: (msg: IncomingMessages) => void) {
        this.subscription.subscribe(cb);
        return () => this.subscription.unsubscribe(cb);
    }

    send(msg: OutgoingMessages): boolean {
        this.handler?.postMessage(msg)

        if (this.debug) {
            // eslint-disable-next-line no-console
            console.log(`<- WebView ${msg.type}`, msg);
        }

        return Boolean(this.handler);
    }

    private onMessage(msg: IncomingMessages) {
        if (this.debug) {
            // eslint-disable-next-line no-console
            console.log(`-> WebView ${msg.type}`, msg);
        }
        this.subscription.publish(msg)
    }
}