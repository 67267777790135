import cn from "classnames";
import type { SlotId } from "models/booking.model";
import type { ClientId } from "models/client.model";
import type { Tag } from "models/tags.model";
import type { ReactNode } from "react";
import type { BaseStatus, ExtraStatus } from "types/status";
import { ICONS } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";

import { StatusActions } from "./BaseStatusTag";
import styles from "./BookingCard.module.scss";
import { BookingTags } from "./BookingTags";
import { ExtraStatusTag } from "./ExtraStatusTag";
import { tagsToShortView } from "./utils";

export const BookingHeader = ({
  bookingId,
  clientId,
  useDeposit,
  compact,
  status,
  extraStatus,
  phone,
  tags,
  isDashboard,
  isEndingSoon,
  children,
}: {
  bookingId: SlotId | undefined;
  clientId: ClientId | undefined;
  useDeposit: boolean;
  children: ReactNode;
  phone: string | false;
  tags?: Tag[];
  compact?: boolean;
  status?: BaseStatus | undefined;
  extraStatus?: ExtraStatus | undefined;
  isDashboard?: boolean;
  isEndingSoon: boolean;
}) => {
  const isTablet = useIsTablet();
  const visibleTagsCount = isDashboard && !isTablet ? 3 : 2;
  const formattedTags = tags && tagsToShortView(tags, visibleTagsCount);

  const renderStatusSection = () => {
    if (isDashboard)
      return (
        <div className={cn(styles.section, styles.clientInfo)}>
          {children}
          <div className={styles.statusGroup}>
            {status && bookingId && (
              <StatusActions
                status={status}
                extraStatus={extraStatus}
                bookingId={bookingId}
                clientId={clientId}
                statusClassName={cn(styles.statusTag, styles.baseStatusTag)}
              />
            )}
            {extraStatus && (
              <ExtraStatusTag
                className={styles.statusTag}
                extraStatus={extraStatus}
              />
            )}
            {useDeposit && <ICONS.DepositLock />}
            {isEndingSoon && <ICONS.ClockCircle />}
          </div>
        </div>
      );

    if (compact && (!isTablet || !extraStatus))
      return (
        <div className={cn(styles.section, styles.clientInfo)}>
          {children}
          <div className={styles.statusGroup}>
            {status && bookingId && (
              <StatusActions
                status={status}
                extraStatus={extraStatus}
                bookingId={bookingId}
                clientId={clientId}
                statusClassName={cn(styles.statusTag, styles.baseStatusTag)}
              />
            )}
            {useDeposit && <ICONS.DepositLock />}
            {isEndingSoon && <ICONS.ClockCircle />}
          </div>
        </div>
      );

    return (
      <>
        <div
          className={cn(styles.section, styles.statusGroup, {
            [styles.dashboardTag]: isDashboard,
          })}
        >
          {status && bookingId && (
            <StatusActions
              status={status}
              extraStatus={extraStatus}
              bookingId={bookingId}
              clientId={clientId}
              statusClassName={cn(styles.statusTag, styles.baseStatusTag)}
            />
          )}
          {useDeposit && <ICONS.DepositLock />}
          {isEndingSoon && <ICONS.ClockCircle />}
          {extraStatus && (
            <ExtraStatusTag
              className={styles.statusTag}
              extraStatus={extraStatus}
            />
          )}
        </div>
        <div className={styles.section}>{children}</div>
      </>
    );
  };

  return (
    <>
      {renderStatusSection()}
      {(phone ||
        formattedTags?.visibleTags.length ||
        (extraStatus && compact && !isTablet && !isDashboard)) && (
        <div className={styles.section}>
          <div className={styles.subSection}>
            <span className={styles.phone}>{phone}</span>
            {Boolean(formattedTags?.visibleTags.length) && (
              <BookingTags tags={formattedTags!} />
            )}
          </div>
          {extraStatus && compact && !isTablet && !isDashboard && (
            <ExtraStatusTag
              className={styles.statusTag}
              extraStatus={extraStatus}
            />
          )}
        </div>
      )}
    </>
  );
};
