import cn from "classnames";
import { ICONS } from "ui-kit/ICONS";

import styles from "./StarRating.module.scss";

export const StarRating = ({
  value,
  className,
}: {
  value: number;
  className?: string;
}) => (
  <figure className={cn(styles.rating, className)}>
    <meter min={0} max={5} value={Math.min(Math.max(0, value), 5)} />
    <ICONS.Star />
    <ICONS.Star />
    <ICONS.Star />
    <ICONS.Star />
    <ICONS.Star />
  </figure>
);
