import { Outlet } from "react-router-dom";

import { SourceList } from "./SourceList/SourceList";

const Sources = () => (
  <>
    <SourceList />
    <Outlet />
  </>
);

Sources.displayName = "Sources";

export { Sources };
