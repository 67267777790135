import { AccessTokenInfo, IExternalAuthProvider } from "services/auth.service";

import { WebViewTransport } from "./transport";

interface ITokenData {
  token: string;
  expDateMs: number;
}

export class WebviewExternalAuth implements IExternalAuthProvider {
  constructor(private transport: WebViewTransport) {}

  private tokenData?: ITokenData;
  private promise?: Promise<ITokenData>;

  isAuthenticated(): boolean {
    // Считаем, что приложение закроет webview, если сессия истекла
    return true;
  }

  needRefresh(): boolean {
    return !this.tokenData || Date.now() >= this.tokenData.expDateMs;
  }

  async refresh(): Promise<unknown> {
    return await this.getAccessToken();
  }

  logout(): void {
    // Считаем, что запросы на разлогин можно игнорировать
    return;
  }

  async getAccessToken(): Promise<AccessTokenInfo> {
    // cached
    if (this.tokenData && Date.now() < this.tokenData.expDateMs) {
      return this.tokenData;
    }

    // dedupe
    if (this.promise) {
      return await this.promise;
    }

    // ask
    const requestId = `${Date.now()}-${Math.random().toString().substring(2)}`;
    this.promise = new Promise((resolve) => {
      this.transport.send({ type: "AUTH_REQUEST_TOKEN", requestId });
      const dispose = this.transport.subscribe((e) => {
        if (e.type === "AUTH_TOKEN" && e.requestId === requestId) {
          dispose();
          resolve(e);
        }
      });
    });

    try {
      this.tokenData = await this.promise;
      return this.tokenData;
    } finally {
      this.promise = undefined;
    }
  }

  reportForbidden(url: string): void {
    this.transport.send({ type: "AUTH_FORBIDDEN", apiUrl: url });
  }
}
