import { skipToken } from "@reduxjs/toolkit/dist/query";
import cn from "classnames";
import { useFetchFeedsQuery } from "features/api/communication";
import type { ReactNode } from "react";
import type { IntlShape } from "react-intl";
import type { FeedContent } from "types/communication";
import { ETranslations } from "types/translates";

import {
  useFeedsManagementContext,
  useOptimisticFeedIdsContext,
} from "../Dialog/FeedsManagementContext";
import commonStyles from "../Dialogs.module.scss";
import { EmptyFeedCard } from "./FeedCard";
import styles from "./FeedList.module.scss";
import { separateFeeds } from "./utils";

const LinkedFeedList = ({
  feeds,
  header,
  missingFeedIds,
  children,
}: {
  feeds: FeedContent[];
  header: string;
  missingFeedIds: string[];
  children: (
    feed: FeedContent,
    isBooking: boolean,
    isLinked?: boolean,
  ) => ReactNode;
}) => {
  const { restaurantId } = useFeedsManagementContext();
  const { data } = useFetchFeedsQuery(
    missingFeedIds.length && restaurantId
      ? {
          body: {
            feed_ids: missingFeedIds,
            restaurant_ids: [restaurantId],
          },
        }
      : skipToken,
  );
  const allLinkedFeeds = data ? feeds.concat(data.content) : feeds;

  return (
    <>
      <h2 className={commonStyles.divider}>{header}</h2>
      <div className={styles.feedCards}>
        {!data && missingFeedIds.map((id) => <EmptyFeedCard key={id} />)}
        {allLinkedFeeds.map((feed) =>
          children(feed, feed.id.object_type === "booking", true),
        )}
      </div>
    </>
  );
};

const ActiveFeedList = ({
  feeds,
  header,
  children,
}: {
  feeds: FeedContent[];
  header: string;
  children: (
    feed: FeedContent,
    isBooking: boolean,
    isLinked?: boolean,
  ) => ReactNode;
}) => {
  return (
    <>
      <h2 className={commonStyles.divider}>{header}</h2>
      <div className={styles.feedCards}>
        {feeds.map((feed) => children(feed, feed.id.object_type === "booking"))}
      </div>
    </>
  );
};

export const FeedListContent = ({
  feeds,
  formatMessage,
  children,
}: {
  feeds: FeedContent[];
  formatMessage: IntlShape["formatMessage"];
  children: (
    feed: FeedContent,
    isBooking: boolean,
    isLinked?: boolean,
  ) => ReactNode;
}) => {
  const linkedFeedIds = useOptimisticFeedIdsContext();
  const { linkedFeeds, activeFeeds, missingFeedsIds } = separateFeeds(
    feeds,
    linkedFeedIds,
  );

  return (
    <section className={commonStyles.list}>
      {Boolean(linkedFeeds.length || missingFeedsIds.size) && (
        <LinkedFeedList
          feeds={linkedFeeds}
          header={formatMessage({ id: ETranslations.LINKED_BOOKINGS })}
          missingFeedIds={Array.from(missingFeedsIds)}
        >
          {children}
        </LinkedFeedList>
      )}
      {Boolean(activeFeeds.length) && (
        <ActiveFeedList
          feeds={activeFeeds}
          header={formatMessage({ id: ETranslations.ACTIVE_BOOKINGS })}
        >
          {children}
        </ActiveFeedList>
      )}
    </section>
  );
};
