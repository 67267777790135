import { Client, ClientOrContact, Contact } from "models/client.model";
import { BaseStatus, ExtraStatus, type Status } from "types/status";
import { z } from "zod";

import { Table } from "./common";
import { Organization, Source, SourceId } from "./source.model";
import { Tag } from "./tags.model";

// todo: удалить модель целиком
export type Loyalty = "none" | "low" | "medium" | "max";

export class BookingResponse {
  constructor(
    public id: number,
    public date: string,
    public client: Client | Contact,
    public place_id: number,
    public user_id: number,
    public tags: number[],
    public visit_time: number,
    public persons: number,
    public deposit_status: boolean,
    public deposit_sum: number,
    public status: Status,
    public deposit_status_reserv: boolean,
    public tables: Table[],
    public comment: string,
    public time_key: string,
    public source_id: number,
    public restaurant_id: number,
  ) {}
}

/**
 * @deprecated старого типа объект
 */
export class Booking {
  constructor(
    public id: number,
    public date: string,
    public client: Client | Contact,
    public place_id: number,
    public user_id: number,
    public tags: number[],
    public visit_time: number,
    public persons: number,
    public deposit_status: boolean,
    public deposit_sum: number,
    public status: Status,
    public deposit_status_reserv: boolean,
    public tables: Table[],
    public comment: string,
    public time: string,
    public bookStatus:
      | "not-booked"
      | "booked"
      | "booked-paid"
      | "booked-not-paid"
      | "not-booked-not-paid"
      | "not-booked-paid"
      | string,
    public time_key: string,
    public source_id?: number,
  ) {}
}

export class BookingForTable {
  constructor(
    public hour: number,
    public min: number,
    public visit_time: number,
    public status: Status,
    public id?: number,
  ) {}
}

export class TagServer {
  constructor(
    public tag_id: number,
    public description: string,
    public color: string,
  ) {}
}

export enum TimeStatus {
  "farFarAway" = "farFarAway",
  "inHall" = "inHall",
  "upcoming" = "upcoming",
  "expired" = "expired",
  "notCome" = "notCome",
}
export interface BookingInfoFilter {
  place_id: number[];
  date: string;
  time?: string;
  visit_time: number;
  guests: number;
  table_type?: number;
}

export interface BookingTimeHostess {
  time_key: string;
  hostess_id: number;
}

export interface BookingInfoItems {
  items: BookingInfoItem;
}

export interface BookingInfoItem {
  [key: number]: BookingAvailableInfo;
}

export class BookingAvailableInfo {
  constructor(
    public date: string,
    public time: string[],
    public visit_time: number[],
    public guests: number[],
    public table_type: number[],
    public table_num: Table[],
    public visit_option_by_time: number[],
    public time_options_by_visit: string[],
  ) {}
}

export interface HistoryBooking {
  booking_id: number;
  date: string;
  user_name: string;
  action: string;
  params: { field: string; new: any; old: any }[];
}

export interface SwapTarget {
  booking_id: number;
}

const StatisticValue = z.object({
  bookings: z.number().min(0).int(),
  guests: z.number().min(0).int(),
});

const Statistic = z
  .object({
    inHall: StatisticValue,
    waitList: StatisticValue,
    notInHall: StatisticValue,
    all: StatisticValue,
  })
  .refine(
    ({ inHall, notInHall, all }) =>
      all.bookings === inHall.bookings + notInHall.bookings &&
      all.guests === inHall.guests + notInHall.guests,
    "incorrect calculations in the statistics field in the /filter request",
  );

export const SlotId = z.number().brand("SlotId");
export type SlotId = z.infer<typeof SlotId>;

const TableId = z.number().brand("TableId");
export type TableId = z.infer<typeof TableId>;
const HallId = z.number().brand("HallId");
export type HallId = z.infer<typeof HallId>;

export const SlotPlace = z.object({
  table_id: TableId,
  table_name: z.string(),
  minimum_capacity: z.number().int().min(1),
  maximum_capacity: z.number().int().min(1),
  hall_id: HallId,
  place_id: HallId,
  hall_name: z.string(),
  hall_weight: z.number(),
});
export type SlotPlace = z.infer<typeof SlotPlace>;

export const SlotPlaces = SlotPlace.array().refine(
  (places) =>
    places.every((place) => place.maximum_capacity >= place.minimum_capacity),
  {
    message: "The maximum table capacity is greater than the minimum",
  },
);

export type SlotPlaces = z.infer<typeof SlotPlaces>;

export const SlotDeposit = z.object({
  use_deposit: z.boolean(),
  deposit_status: z.enum([
    "NOT_MADE",
    "WAIT_PAYMENT",
    "PAYMENT_REJECT",
    "MADE",
    "CANCELED",
  ]),
  deposit_amount: z.number(),
});

export const SlotType = z.enum(["BOOKING", "ORDER", "MANAGER", "EVENT"]);
export type SlotType = z.infer<typeof SlotType>;

export const RestaurantId = z.number().brand("RestaurantId");
export type RestaurantId = z.infer<typeof RestaurantId>;

export const BookingId = z.number().brand("BookingId");
export type BookingId = z.infer<typeof BookingId>;
export const FeedId = z.string().brand("FeedId");

export const SeatType = z.enum([
  "BOOK",
  //Посадка в зал в обход бронирования
  "IN_WALK",
  // Лист ожидания
  "WAIT_LIST",
  // Менеджерская запись
  "MANAGEMENT",
  // Менеджерская бронь
  "MANAGER_BOOK",
]);
export type SeatType = z.infer<typeof SeatType>;

const CommonSlot = z.object({
  tags: Tag.array(),
  slot_id: SlotId,
  restaurant_id: RestaurantId,
  slot_type: SlotType,
  date: z.string().date(),
  time: z.string().refine((val) => /^\d\d:\d\d$/.test(val)),
  visit_duration: z.number(),
  slot_place: SlotPlaces,
  seat_type: SeatType,
  notes: z.record(z.string()),
});

const BookingSlot = CommonSlot.extend({
  slot_type: z.literal("BOOKING"),
  visitors: z.number(),
  locked: z.boolean(),
  overbooking: z.boolean(),
  slot_deposit: SlotDeposit,
  status: BaseStatus,
  extra_status: ExtraStatus.optional(),
  source: z
    .object({
      id: Source.shape.source_id,
      contact: Source.shape.source_contact,
      Organization: Source.shape.organization,
    })
    .optional(),
  client: ClientOrContact,
  comment: z.string(),
  feed_id: FeedId,
});
export type BookingSlot = z.infer<typeof BookingSlot>;
const OrderSlot = CommonSlot.extend({
  slot_type: z.literal("ORDER"),
  persons: z.number(),
  client: ClientOrContact,
});
export type OrderSlot = z.infer<typeof OrderSlot>;

const ManagerSlot = CommonSlot.extend({
  slot_type: z.literal("MANAGER"),
  notes: z.object({
    batch_id: z.string().uuid(),
  }),
});

export type ManagerSlot = z.infer<typeof ManagerSlot>;
const EventSlot = CommonSlot.extend({
  slot_type: z.literal("EVENT"),
});
export type EventSlot = z.infer<typeof EventSlot>;

export const Slot = z.discriminatedUnion("slot_type", [
  BookingSlot,
  OrderSlot,
  ManagerSlot,
  EventSlot,
]);

export type Slot = z.infer<typeof Slot>;

export const TempBooking = z.object({
  booking_id: BookingId,
  restaurant_id: RestaurantId,
  persons: z.number(),
  comment: z.string(),
  phone: z.string(),
  tags: Tag.array(),
  date: z.string().datetime({ offset: true }),
  time: z.string().time(),
  visit_duration: z.number(),
  slot_places: SlotPlaces,
  notes: z.record(z.string()),
  locked: z.boolean(),
  overbooking: z.boolean(),
  use_deposit: SlotDeposit.shape.use_deposit,
  deposit_status: SlotDeposit.shape.deposit_status,
  deposit_amount: SlotDeposit.shape.deposit_amount,
  status: BaseStatus,
  extra_status: ExtraStatus.optional(),
  source: z
    .object({
      id: Source.shape.source_id,
      contact: Source.shape.source_contact,
      Organization: Source.shape.organization,
    })
    .optional(),
  client: ClientOrContact,
  seat_type: SeatType,
  feed_id: FeedId,
});

// FIXME: убрать сортировку, как только сделают на бэке
export const SearchResponse = z
  .object({
    slots: z
      .discriminatedUnion("slot_type", [BookingSlot, ManagerSlot])
      .array(),
    statistics: Statistic,
    requestParamsDate: z.string(),
  })
  .transform(
    (response) => (
      response.slots.forEach((slot) => slot.tags.sort((a, b) => a.id - b.id)),
      response
    ),
  );
export type SearchResponse = z.infer<typeof SearchResponse>;

export type TempBooking = z.infer<typeof TempBooking>;
