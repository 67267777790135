import styles from "./DatePicker.module.scss";
import { datePickerHeader } from "./DatePickerHeader";
import { DatePickerInput } from "./DatePickerInput";
import "./customStyles.scss";
import cn from "classnames";
import en_EN from "date-fns/locale/en-GB";
import ru_RU from "date-fns/locale/ru";
import { useUserLocale } from "hooks/useUserLocale";
import { FC } from "react";
import DayPicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";

registerLocale("ru_RU", ru_RU);
registerLocale("en_EN", en_EN);

const DATE_PICKER_LOCALES = {
  en_EN,
  ru_RU,
};

interface Props extends ReactDatePickerProps {
  minYear?: number;
  customInputFormatDate?: string;
  customInputClassName?: string;
  invalid?: boolean;
  resetClick?: () => void;
}

export const DatePicker: FC<Props> = ({
  onChange,
  selected,
  minYear = 2020,
  customInputFormatDate = "DD.MM.YYYY",
  className,
  disabled,
  customInputClassName,
  invalid = false,
  ...rest
}) => {
  const { userLocale } = useUserLocale();
  return (
    <div className={className}>
      <DayPicker
        className={cn(styles.datePicker)}
        customInput={
          <DatePickerInput
            customInputClassName={cn(
              customInputClassName,
              cn({ [styles.invalid]: invalid }),
            )}
            disabled={disabled}
            format={customInputFormatDate}
            placeholderText={rest.placeholderText}
          />
        }
        dateFormat="dd.MM.yyyy"
        disabled={disabled}
        locale={DATE_PICKER_LOCALES[userLocale || "en_EN"]}
        renderCustomHeader={(headerProps) =>
          datePickerHeader({ ...headerProps, locale: userLocale }, minYear)
        }
        selected={selected}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};
