import cn from "classnames";
import type { ReactNode } from "react";

import styles from "./IconWithCaption.module.scss";

export const IconWithCaption = ({
  caption,
  children,
  title,
  className,
  captionClassName,
}: {
  caption: ReactNode;
  children: ReactNode;
  title?: string;
  className?: string;
  captionClassName?: string;
}) => {
  return (
    <figure className={cn(styles.figure, className)} title={title}>
      {children}
      <figcaption className={captionClassName}>{caption}</figcaption>
    </figure>
  );
};
