import { useDispatchActions } from "../../hooks/useDispatchActions";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GlobalSearchParams,
  SearchContext,
  SearchType,
} from "types/globalSearch";

const DEFAULT_PAGE_SIZE = 20;

export type Slice = Omit<GlobalSearchParams, "restaurantId">;

export const initialState: Slice = {
  context: SearchContext.CLIENT,
  keyword: "",
  page: 0,
  size: DEFAULT_PAGE_SIZE,
};

const slice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload - 1;
    },
    changeContext(state, { payload }: PayloadAction<SearchType>) {
      state.context = payload;
      state.page = initialState.page;
      state.keyword = "";
    },
    changeKeyword(state, { payload }: PayloadAction<string>) {
      state.page = initialState.page;
      state.keyword = payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  name: globalSearchSliceName,
  reducer: globalSearchReducer,
  actions: globalSearchActions,
} = slice;
export const useGlobalSearchActions = () => useDispatchActions(slice.actions);
