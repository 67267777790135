import { useApplicationContextActions } from "features/AppContext";
import { localeSelector } from "features/AppContext/selectors";
import { useSelector } from "react-redux";
import { RadioButton } from "ui-kit";

import { ELocales } from "../../../types/commons";

export const LocaleControls = () => (
  <RadioButton
    value={useSelector(localeSelector)}
    onChange={useApplicationContextActions().setLocale}
  >
    {(Object.keys(ELocales) as ELocales[]).map((locale) => (
      <RadioButton.Button key={locale} value={locale}>
        {locale.slice(3)}
      </RadioButton.Button>
    ))}
  </RadioButton>
);
