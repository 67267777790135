import { predata } from "common/helpers";
import { useApplicationContextActions } from "features/AppContext";
import { IResponse } from "models/common";
import { Place, Restaurant } from "models/restaurant.model";
import { Notification } from "services/notification";
import type { ErrorResponse } from "types/commons";
import { UpdatePlaceDTO } from "types/place";
import { ETranslations } from "types/translates";

import { api } from "./api";

export const restaurantsApi = api
  .enhanceEndpoints({
    addTagTypes: ["Restaurants"],
  })
  .injectEndpoints({
    endpoints: ($) => ({
      fetchAllRestaurants: $.query<Restaurant[], void>({
        query: () => ({
          url: "v2/restaurants",
        }),
        providesTags: ["Restaurants"],
        transformResponse: (response: IResponse<Restaurant[]>) =>
          response.data.map((rest) => ({
            ...rest,
            places: rest.places.sort((a, b) => a.weight - b.weight),
          })),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            data.forEach((rest) => {
              predata.restsMapper[rest.restaurant_id] = {
                label: rest.restaurant_name,
                value: rest.restaurant_id,
                ...rest,
              };
            });
          } catch (e) {
            (e as ErrorResponse)?.error?.data?.status === 500 &&
              global.channels.auth.cb?.(false); // временная мера, убрать как только бэк станет возвращать 401 при инвалидном токене
          }
        },
      }),
      updateHall: $.mutation<Place, Partial<UpdatePlaceDTO>>({
        query: (body) => ({
          url: "v2/places",
          method: "POST",
          body: {
            ...body,
            schedule: body.schedule ?? [
              {
                duration: 10,
                booking_interval: 10,
                time_start: "12:00:00",
                week_day: 1,
              },
            ],
          },
        }),
        invalidatesTags: (_res, err) => (!err ? ["Restaurants"] : []),
        transformResponse: (response: IResponse<Place>) => response.data,
      }),
      deleteHall: $.mutation({
        query: (id: number | string) => ({
          url: `v2/places/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Restaurants"],
      }),
    }),
  });

export const {
  useFetchAllRestaurantsQuery,
  useUpdateHallMutation,
  useDeleteHallMutation,
} = restaurantsApi;

export const useFetchAllRestaurants = () => {
  const { data, ...rest } = useFetchAllRestaurantsQuery();
  data?.length === 0 && Notification.error(ETranslations.UNKNOWN_RESTAURANT);
  const { setUpRestaurants, setSelectedPlaces, setPlace, setRestaurant } =
    useApplicationContextActions();
  const restId = localStorage.getItem("restaurantId");
  const restaurant =
    data && (data.find((r) => r.restaurant_id === Number(restId)) || data[0]);
  const selectedPlaces =
    restaurant?.places && restaurant.places.map((p) => p.id);
  data && setUpRestaurants(data);
  setSelectedPlaces(selectedPlaces || []);
  selectedPlaces?.length && setPlace(selectedPlaces[0]);
  restaurant && setRestaurant(restaurant);
  return { data, restaurant, ...rest };
};
