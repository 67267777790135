import styles from "../CreateGroupTagsModal.module.scss";
import classNames from "classnames";
import React, { useState } from "react";
import { FieldRenderProps } from "react-final-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { preventDefault, stopPropagation } from "utils";

const COLORS = [
  "#F86767",
  "#FF8A65",
  "#FDBBA1",
  "#FFA726",
  "#FEC535",
  "#FFCC80",
  "#FFE0B2",
  "#DBA786",
  "#BCAAA4",
  "#D7CCC8",
  "#FCE806",
  "#FFFA4A",
  "#FFF59D",
  "#DEDAAD",
  "#66BB6A",
  "#60C689",
  "#9EE189",
  "#A5D6A7",
  "#C8E6C9",
  "#C4D0C6",
  "#AFB42B",
  "#CDDC39",
  "#E5F07E",
  "#4DB6AC",
  "#72E6F7",
  "#9AFFFF",
  "#B2DFDB",
  "#ABAEC7",
  "#AFC4DF",
  "#94CBF7",
  "#CDE3FF",
  "#B39DDB",
  "#97A5F8",
  "#B5C4FA",
  "#D9DEF8",
  "#CE93D8",
  "#D1C4E9",
  "#E1BEE7",
  "#F8CCFF",
  "#F3E5F5",
  "#FA97FC",
  "#F18C9B",
  "#FAA5A5",
  "#F8BBD0",
  "#FFCDD2",
  "#FFEBEE",
  "#BABABA",
  "#D3D3D3",
  "#E8E6E7",
];

const ChooseColorComponent = ({ value, onChange }: any) => {
  const [showAllColors, setShowAllColors] = useState<boolean>(false);
  const intl = useIntl();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    stopPropagation(e);
    preventDefault(e);

    if (e.target instanceof HTMLButtonElement) {
      onChange(e.target.value);
    }
  };

  const handleToggleMore = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAllColors((state) => {
      return !state;
    });
  };
  return (
    <>
      <button onClick={handleToggleMore}>
        {intl.formatMessage({ id: ETranslations.SHOW_ALL })}
      </button>
      <div className={styles.colors}>
        {showAllColors
          ? COLORS.map((background, index) => (
              <div
                className={classNames(
                  styles.eachColor,
                  value === background && styles.currentColor,
                )}
                key={`${index}-${background}`}
              >
                <button
                  style={{ background }}
                  value={background}
                  onClick={handleClick}
                />
              </div>
            ))
          : COLORS.slice(0, 28).map((background, index) => (
              <div
                className={classNames(
                  styles.eachColor,
                  value === background && styles.currentColor,
                )}
                key={`${index}-${background}`}
              >
                <button
                  style={{ background }}
                  value={background}
                  onClick={handleClick}
                />
              </div>
            ))}
      </div>
    </>
  );
};

export const ChooseColorRedux: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => <ChooseColorComponent {...input} {...meta} {...rest} />;

export default ChooseColorComponent;
