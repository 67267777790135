import { useIntlUtils } from "../../../../hooks/useIntlUtils";
import { ETranslations } from "../../../../types/translates";
import styles from "./index.module.scss";
import { useFromProxyActions } from "features/BookingFormProxy";
import * as select from "features/BookingFormProxy/selectors";
import { useCloseOrderMutation } from "features/api/booking-order-api";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { BookingOrder } from "types/booking";
import { Button } from "ui-kit";

type props = {
  order: BookingOrder;
  submit: any;
  valid: any;
};

export function OrderControls({ order, submit, valid }: props) {
  const { intl, getIntlJoinedParts } = useIntlUtils();
  const isEdit = useSelector(select.isEdit);
  const { reset } = useFromProxyActions();
  const [closeOrder] = useCloseOrderMutation();
  const closeHandler = useCallback(async () => {
    await closeOrder(order.orderId).unwrap();
    reset();
  }, [closeOrder]);

  const intlTitles = useMemo(
    () => ({
      saveAndCreate: getIntlJoinedParts([
        ETranslations.BASE_SAVE,
        ETranslations.AND,
        ETranslations.BASE_CREATE,
      ]),
      create: intl.formatMessage({ id: ETranslations.BASE_CREATE }),
      close: intl.formatMessage({ id: ETranslations.BASE_CLOSE }),
    }),
    [],
  );

  return (
    <div className={styles.visitControls}>
      <Button disabled={!valid} variant="primary" onClick={submit}>
        {isEdit ? intlTitles.saveAndCreate : intlTitles.create}
      </Button>

      <Button variant="secondary" onClick={closeHandler}>
        {intlTitles.close}
      </Button>
    </div>
  );
}
