/*
    ____,-------------------------------,____
    \   |              Hooks            |   /
    /___|-------------------------------|___\
*/
import { useContext, useMemo } from "react";

import { WebViewContext } from "./provider";
import { OutgoingMessages } from "./types";

type Return = {
  isWebView: boolean;
  notifyApp: (msg: OutgoingMessages) => void;
  createBackUrl: () => string;
};

export function useWebView(): Return {
  const ctx = useContext(WebViewContext);
  return useMemo(
    () => ({
      isWebView: Boolean(ctx),
      notifyApp: (msg: OutgoingMessages) => ctx?.transport.send(msg),
      createBackUrl() {
        const url = new URL(window.location.href);
        if (ctx?.settings.locale)
          url.searchParams.set("locale", ctx.settings.locale);
        if (ctx?.settings.theme)
          url.searchParams.set("theme", ctx.settings.theme);
        return url.toString();
      },
    }),
    [ctx],
  );
}
