import { Modal } from "components/modal";
import { useRestsOptions } from "features/AppContext/selectors";
import type { RestaurantId } from "models/booking.model";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Form, useActionData } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, FieldError, ICONS, Input, Labeled, SelectBasic } from "ui-kit";

import type { action } from "../api/action";
import styles from "./Roles.module.scss";

export const EditUserForm = ({
  fullName,
  phone,
  restaurants,
  login,
}: {
  fullName: string;
  phone: string;
  restaurants: RestaurantId[];
  login: string;
}) => {
  const actionData = useActionData<typeof action>();
  const { formatMessage } = useIntl();
  const restsOptions = useRestsOptions();
  const [isEditRole, setIsEditRole] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  return (
    <>
      <Form className={styles.userForm} id="edit-user" method="POST">
        {isEditRole && (
          <>
            <Labeled required label={formatMessage({ id: ETranslations.ROLE })}>
              <SelectBasic
                //defaultValue={role}
                name="role"
                options={restsOptions}
                required
              />
              {actionData?.fieldErrors.role && (
                <FieldError error={actionData.fieldErrors.role} />
              )}
            </Labeled>
            <hr className={styles.divider} />
          </>
        )}
        <div className={styles.twoFields}>
          <Input
            autoComplete="off"
            name="fullName"
            defaultValue={fullName}
            label={formatMessage({ id: ETranslations.FULL_NAME })}
            required
            error={actionData?.fieldErrors.fullName}
          />
          <Input
            autoComplete="off"
            name="phone"
            defaultValue={phone}
            label={formatMessage({ id: ETranslations.ADDITIONAL_PHONE })}
            error={actionData?.fieldErrors.phone}
          />
        </div>
        <Labeled
          required
          label={formatMessage({ id: ETranslations.PLURAL_RESTAURANT })}
        >
          <SelectBasic
            isMulti
            styleObject={{
              multiValue: {
                borderRadius: "20px",
                font: "var(--font-12-b)",
                color: "var(--gl_text_constant_primary2)",
                backgroundColor: "var(--tags_constant_background_5)",
              },
              multiValueLabel: {
                color: "var(--gl_text_constant_primary2)",
              },
            }}
            defaultValue={restsOptions.filter((option) =>
              restaurants?.includes(option.value),
            )}
            name="restaurant"
            options={restsOptions}
            required
          />
          {actionData?.fieldErrors.restaurant && (
            <FieldError
              error={actionData.fieldErrors.restaurant}
              fieldName={formatMessage({ id: ETranslations.PLURAL_RESTAURANT })}
            />
          )}
        </Labeled>
        {!isEditRole && (
          <Button
            variant="phantom"
            className={styles.textButton}
            onClick={() => setIsEditRole(true)}
          >
            {formatMessage(
              { id: ETranslations.BASE_ENTITY_CHANGING },
              {
                entity: formatMessage({ id: ETranslations.ROLE }).toLowerCase(),
              },
            )}
          </Button>
        )}
        <hr className={styles.divider} />
        <Input
          required
          autoComplete="off"
          name="login"
          value={login}
          readOnly
          label={formatMessage({ id: ETranslations.AUTH_LOGIN })}
          error={actionData?.fieldErrors.login}
        />
        <Button
          variant="phantom"
          className={styles.textButton}
          onClick={() => setIsResettingPassword(true)}
        >
          {formatMessage(
            { id: ETranslations.BASE_RESET },
            {
              entity: formatMessage({
                id: ETranslations.AUTH_PASSWORD,
              }).toLowerCase(),
            },
          )}
        </Button>
        {actionData?.formErrors && <FieldError error={actionData.formErrors} />}
      </Form>
      <Modal
        className={styles.conformationModal}
        isOpen={isResettingPassword}
        onClose={() => setIsResettingPassword(false)}
      >
        <Modal.Content className={styles.body}>
          <ICONS.WARNING className={styles.icon} />
          <h2>
            {formatMessage(
              { id: ETranslations.BASE_RESET },
              {
                entity: formatMessage({
                  id: ETranslations.AUTH_PASSWORD,
                }).toLowerCase(),
              },
            )}
          </h2>
          <p>{formatMessage({ id: ETranslations.RESET_PASSWORD_MESSAGE })}</p>
          <div className={styles.actions}>
            <Button
              type="button"
              variant="primary"
              onClick={() => console.log("сбросить пароль")}
            >
              {formatMessage({ id: ETranslations.BASE_CONFIRM })}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setIsResettingPassword(false)}
            >
              {formatMessage({ id: ETranslations.BASE_CANCEL })}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
