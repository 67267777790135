import cn from "classnames";
import { formatMinutesDuration, getFullName } from "common/helpers";
import { ClientInfoPopup } from "components/BookingInfoPopup";
import type { BookingHistoryDTO } from "models/history.model";
import moment from "moment";
import { useIntl } from "react-intl";
import type { BaseStatus, ExtraStatus } from "types/status";
import { ETranslations } from "types/translates";
import { Button, ICONS, Tag } from "ui-kit";
import { formatPhone } from "utils";

import { BaseStatusTag } from "./BaseStatusTag";
import { ExtraStatusTag } from "./ExtraStatusTag";
import { SlotCard } from "./SlotCard";
import styles from "./SlotCard.module.scss";
import { useExtendedStatuses } from "./hooks/useExtendedStatus";

export const BookingHistoryCard = ({
  booking,
  status,
  extraStatus,
}: {
  booking: BookingHistoryDTO["booking"];
  status: BaseStatus;
  extraStatus: ExtraStatus;
}) => {
  const { formatMessage } = useIntl();
  const { newStatus, visitProgress, visitTime } = useExtendedStatuses({
    status,
    date: booking.date,
    time: booking.time.slice(0, 5),
    visitDuration: booking.visit_duration,
  });
  const isEndingSoon =
    moment(`${booking.date} ${booking.time}`, "YYYY-MM-DD HH:mm")
      .add(booking.visit_duration, "minutes")
      .diff(moment(), "minutes") <= 20;

  return (
    <SlotCard.Root
      as="div"
      statusClassName={newStatus.system_name.toLowerCase()}
      actions={
        booking.contact.contact_type === "CLIENT" && (
          <ClientInfoPopup
            clientId={booking.contact.client_id}
            placement="auto"
          >
            <Button variant="phantom" type="button" className={styles.userInfo}>
              <ICONS.Question />
            </Button>
          </ClientInfoPopup>
        )
      }
    >
      <SlotCard.Content
        vip={booking.contact.vip}
        name={getFullName(
          booking.contact.name,
          booking.contact.middle_name,
          booking.contact.surname,
        )}
        nameAddon={booking.contact.gradeName}
        phone={formatPhone(booking.contact.phone)}
        tags={booking.tags}
        visibleTagsCount={booking.contact.phone ? 3 : 4}
      >
        <div className={styles.statusGroup}>
          {
            <BaseStatusTag
              className={cn(styles.statusTag, styles.baseStatusTag)}
              statusName={newStatus?.name}
              statusColor={newStatus?.color}
            />
          }
          {extraStatus && (
            <ExtraStatusTag
              className={styles.statusTag}
              extraStatus={extraStatus}
            />
          )}
          {booking.seat_type === "MANAGER_BOOK" && (
            <Tag className={cn(styles.statusTag, styles.fromManagementTag)}>
              Manager
            </Tag>
          )}
          {booking.use_deposit && <ICONS.DepositLock />}
          {isEndingSoon && <ICONS.ClockCircle />}
        </div>
      </SlotCard.Content>
      <SlotCard.AdditionalInfo
        seatType={booking.seat_type}
        visitProgress={visitProgress}
        time={booking.time.slice(0, 5)}
        visitDuration={formatMinutesDuration(visitTime)}
        visitors={booking.visitors}
        tables={booking.slot_places.reduce(
          (result, place) => (result && `${result},\u00A0`) + place.table_name,
          "",
        )}
        hallName={booking.slot_places[0]?.hall_name}
        comment={booking.comment}
        warning={
          booking.overbooking
            ? formatMessage({ id: ETranslations.BOOKING_OVERLAP })
            : !booking.slot_places.length && status?.system_name !== "WAIT_LIST"
              ? formatMessage({ id: ETranslations.BOOKING_NO_TABLE_SELECTED })
              : undefined
        }
      />
    </SlotCard.Root>
  );
};
