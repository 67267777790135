import { useIntl } from "react-intl";
import { useRouteError } from "react-router-dom";
import { ETranslations } from "types/translates";
import { ICONS } from "ui-kit";

import styles from "./ErrorBoundary.module.scss";

export const DefaultErrorBoundary = ({
  message,
  children,
  entity,
}: {
  message?: unknown;
  children: ETranslations;
  entity?: ETranslations;
}) => {
  const routeError = useRouteError();

  const errorMessage =
    routeError instanceof Error
      ? routeError.message
      : JSON.stringify(message || routeError);
  const { formatMessage } = useIntl();
  return (
    <section className={styles.noDetails}>
      <ICONS.ErrorWarning width={80} height={80} />
      <p>
        {formatMessage(
          { id: children },
          {
            entity: formatMessage({ id: entity }).toLowerCase(),
            error:
              errorMessage in ETranslations
                ? formatMessage({ id: errorMessage })
                : errorMessage,
          },
        )}
      </p>
    </section>
  );
};
