import { ActionCreatorsMapObject, bindActionCreators } from "@reduxjs/toolkit";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

export function useDispatchActions<A, M extends ActionCreatorsMapObject<A>>(
  actions: M,
): M {
  const [staticActions] = useState(actions);
  const dispatch = useDispatch();
  return useMemo(() => {
    const boundActions = bindActionCreators(staticActions, dispatch);
    Object.keys(boundActions).map((action) =>
      Object.assign(boundActions[action], staticActions[action]),
    );
    return boundActions;
  }, [dispatch, staticActions]);
}
