import {
  Getter,
  Getters,
  Plugin,
  TemplateConnector,
} from "@devexpress/dx-react-core";
import { Plugins } from "@technolab/hse";
import { type HallSlotsDTO, TTable } from "features/api/hallschema-api";
import React, { FC } from "react";
import { TNullable } from "types/commons";

import { TablePathTemplate } from "./TablePathTemplate";
import { GETTERS } from "./utils";

const { Core } = Plugins;

const PLUGIN_NAME = "HOS_TABLE_PATH";

const getBookings = (getters: Getters, hallInfo: TNullable<HallSlotsDTO[]>) => {
  if (!hallInfo) return [];
  return hallInfo
    .reduce<HallSlotsDTO["slots"]>((acc, value) => {
      if (value.slots.length) acc.push(...value.slots);

      return acc;
    }, [])
    .reduce<HallSlotsDTO["slots"]>((acc, value) => {
      const findBooking = acc.find(
        (slot) =>
          slot.slot.slot_id === value.slot.slot_id ||
          value.slot.slot_place.length < 2,
      );
      if (!findBooking) acc.push(value);

      return acc;
    }, []);
};

const getPlaces = (getters: Getters) => {
  const places = getters[Core.GETTERS.place];

  if (!places) return {};

  return Object.keys(places).reduce(
    (acc, value) => {
      const tableId = places[value]?.hostesInfo?.id;
      if (!tableId) return acc;
      acc[tableId] = { ...places[value] };
      return acc;
    },
    {} as Record<number, TTable>,
  );
};

interface Props {
  hallInfo?: HallSlotsDTO[];
}

export const TablePath: FC<Props> = ({ hallInfo }) => {
  return (
    <Plugin name={PLUGIN_NAME}>
      <Getter
        computed={(getters) => getBookings(getters, hallInfo)}
        name={GETTERS.getBookings}
      />
      <Getter
        computed={(getters) => getPlaces(getters)}
        name={GETTERS.getPlaces}
      />
      <TemplateConnector>
        {(getters) => (
          <TablePathTemplate
            places={getters[GETTERS.getPlaces]}
            slots={getters[GETTERS.getBookings]}
          />
        )}
      </TemplateConnector>
    </Plugin>
  );
};
