import { DialogsSocketNotification } from "containers/Dialogs";
import { DialogsProvider } from "features/Dialogs/SDK";
import { ITokenData } from "features/Dialogs/SDK/auth";
import { PropsWithChildren } from "react";
import { useCallback } from "react";
import { AuthService } from "services/auth.service";

export function AppDialogsProvider(props: PropsWithChildren<{}>) {
  const baseUrl = `${new URL(window.location.href).origin}/frontline/api`;

  const onAuth = useCallback(
    async (isRetry: boolean = false): Promise<ITokenData> => {
      if (isRetry) {
        await AuthService.refresh();
      }

      const access = await AuthService.getAccessToken();
      if (!access || !AuthService.isAuthenticated()) {
        throw new Error("Dialogs Auth Error: no token or token expired");
      }

      return {
        token: access.token,
        expDateMs: access.expDateMs,
      };
    },
    [],
  );
  const onAuthError = useCallback(() => AuthService.logout(), []);

  const authenticated = AuthService.isAuthenticated();

  return (
    <DialogsProvider
      baseUrl={baseUrl}
      authenticated={authenticated}
      onAuth={onAuth}
      onAuthError={onAuthError}
      allowAuthRetry
      // Как создать mockApi:
      // const mockApi = useMemo(
      //   () => new DialogsApiMock({ successRate: 1.0 /* 0.0 — 1.0 */ }),
      //   [],
      // );
      mockApi={undefined}
    >
      {props.children}
      {authenticated && <DialogsSocketNotification />}
    </DialogsProvider>
  );
}
