import { useRestsOptions } from "features/AppContext/selectors";
import { useIntl } from "react-intl";
import { Form, useActionData } from "react-router-dom";
import { ETranslations } from "types/translates";
import { FieldError, Input, Labeled, SelectBasic } from "ui-kit";

import type { action } from "../api/action";
import styles from "./Roles.module.scss";

export const AddUserForm = ({ fullName }: { fullName?: string }) => {
  const actionData = useActionData<typeof action>();
  const { formatMessage } = useIntl();
  const restsOptions = useRestsOptions();
  return (
    <Form className={styles.userForm} id="create-user" method="POST">
      <Labeled required label={formatMessage({ id: ETranslations.ROLE })}>
        <SelectBasic
          //defaultValue={role}
          name="role"
          options={restsOptions}
          required
        />
        {actionData?.fieldErrors.role && (
          <FieldError
            error={actionData.fieldErrors.role}
            fieldName={formatMessage({ id: ETranslations.ROLE })}
          />
        )}
      </Labeled>
      <hr className={styles.divider} />
      <div className={styles.twoFields}>
        <Input
          autoComplete="off"
          name="fullName"
          defaultValue={fullName}
          label={formatMessage({ id: ETranslations.FULL_NAME })}
          required
          error={actionData?.fieldErrors.fullName}
        />
        <Input
          autoComplete="off"
          name="phone"
          label={formatMessage({ id: ETranslations.ADDITIONAL_PHONE })}
          error={actionData?.fieldErrors.phone}
        />
      </div>
      <Labeled
        required
        label={formatMessage({ id: ETranslations.PLURAL_RESTAURANT })}
      >
        <SelectBasic
          isMulti
          styleObject={{
            multiValue: {
              borderRadius: "20px",
              font: "var(--font-12-b)",
              color: "var(--gl_text_constant_primary2)",
              backgroundColor: "var(--tags_constant_background_5)",
            },
            multiValueLabel: {
              color: "var(--gl_text_constant_primary2)",
            },
          }}
          name="restaurant"
          options={restsOptions}
          required
        />
        {actionData?.fieldErrors.restaurant && (
          <FieldError
            error={actionData.fieldErrors.restaurant}
            fieldName={formatMessage({ id: ETranslations.PLURAL_RESTAURANT })}
          />
        )}
      </Labeled>
      <hr className={styles.divider} />
      <Input
        required
        autoComplete="off"
        name="login"
        label={formatMessage({ id: ETranslations.AUTH_LOGIN })}
        error={actionData?.fieldErrors.login}
      />
      {actionData?.formErrors && <FieldError error={actionData.formErrors} />}
    </Form>
  );
};
