import { sortDaysWeek } from "../../utils";
import styles from "./WeekDays.module.scss";
import cn from "classnames";
import dayjs from "dayjs";
import React, { FC, MouseEvent } from "react";
import { EDaysOfWeek } from "types/commons";
import { preventDefault, stopPropagation } from "utils";

const WEEK_DAYS = Object.keys(EDaysOfWeek).filter((v) =>
  isNaN(Number(v)),
) as unknown as EDaysOfWeek[];

type TProps = {
  weekDays: EDaysOfWeek[];
  disabled?: boolean;
  onChange: (value: any) => void;
};

const WeekDays: FC<TProps> = ({ weekDays, onChange, disabled }) => {
  const onItemClickHandler = (e: MouseEvent, day: EDaysOfWeek) => {
    preventDefault(e);
    stopPropagation(e);

    const result = weekDays.includes(day)
      ? weekDays.filter((d) => d !== day)
      : [...weekDays, day];
    const sorted = sortDaysWeek(result);
    onChange(sorted);
  };

  return (
    <div className={weekDays.length ? styles.wrapper : styles.wrapperError}>
      {WEEK_DAYS?.map((day) => (
        <button
          className={cn(styles.item, weekDays.includes(day) && styles.active)}
          disabled={disabled}
          key={day}
          onClick={(e) => onItemClickHandler(e, day)}
        >
          {dayjs().isoWeekday(Number(EDaysOfWeek[day])).format("dd")}
        </button>
      ))}
    </div>
  );
};

export default WeekDays;
