import { Tag } from "components/Tag";
import { useTags } from "features/api/tags";
import { Tag as ITags } from "models/tags.model";

interface TagsProps {
  tagsIDs: number[] | `${number}`[];
}

/* const EmptyTags = ({ ids }: { ids: number[] | `${number}`[] }) => (
  <div className="tags">
    {tags ? tags.map((t) => <Tag key={t.id} tag={t} />) : <Tag />}
  </div>
);
 */
export function Tags({ tags }: { tags: ITags[] | number[] | `${number}`[] }) {
  return (
    <div className="tags">
      {tags.map((t) => (
        <Tag
          key={typeof t === "object" ? t.id : t}
          tag={typeof t === "object" ? t : undefined}
        />
      ))}
    </div>
  );
}

export function TagsById({ tagsIDs }: TagsProps) {
  const tags = useTags(tagsIDs);
  return <Tags tags={tags || tagsIDs} />;
}
