import cn from "classnames";
import { ICONS } from "common/helpers";
import React, { ReactElement, ReactNode, useCallback, useMemo } from "react";
import ReactModal, { Props as ReactModalProps } from "react-modal";

import styles from "./Modal.module.scss";
import { ModalContent } from "./ModalContent";
import { ModalFooter } from "./ModalFooter";

export interface ModalProps
  extends Omit<ReactModalProps, "onAfterClose" | "onRequestClose"> {
  onClose(): void;
  title?: string;
  children: ReactNode;
  addon?: ReactNode;
}

const parentSelector = () => document.body;

ReactModal.setAppElement("#root");

function ModalComponent({
  onClose,
  title,
  className,
  children,
  addon,
  ...props
}: ModalProps) {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const [content, footer] = useMemo(
    () =>
      React.Children.toArray(children).reduce<Array<ReactElement | null>>(
        (acc, el) => {
          if (!React.isValidElement(el)) return acc;
          if (el.type === ModalContent) {
            acc[0] = el;
          } else if (el.type === ModalFooter) {
            acc[1] = el;
          } else {
            acc[0] = el;
          }
          return acc;
        },
        [null, null],
      ),
    [children],
  );

  return (
    <ReactModal
      className={cn(styles.modal, className)}
      closeTimeoutMS={0}
      overlayClassName={styles.modalOverlay}
      parentSelector={parentSelector}
      portalClassName={styles.modalPortal}
      role="dialog"
      shouldFocusAfterRender={false}
      ariaHideApp
      preventScroll
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldReturnFocusAfterClose
      onRequestClose={handleClose}
      {...props}
    >
     {title &&  <div className={styles.title}>
        <h3>{title}</h3>
        {addon}
        <button className="close-button" onClick={handleClose}>
          <ICONS.Cross fill="var(--gl_icon_primary_1)" />
        </button>
      </div>}
      {content}
      {footer}
    </ReactModal>
  );
}

export const Modal = Object.assign(ModalComponent, {
  Content: ModalContent,
  Footer: ModalFooter,
});
