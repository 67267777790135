import { RestaurantSelect } from "components/RestaurantSelect";
import { useApplicationContextActions } from "features/AppContext";
import { useRestsOptions } from "features/AppContext/selectors";
import {
  globalSearchSelectors,
  useGlobalSearchActions,
} from "features/GlobalSearch";
import { bookingApi } from "features/api/bookings-api";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { useUnmount, useUpdateEffect } from "react-use";
import { SearchContext } from "types/globalSearch";
import { Button, Input, SelectBasic } from "ui-kit";

import { ETranslations } from "../../../../types/translates";
import styles from "./SearchHeader.module.scss";
import { UserTags } from "./UserTags";
import { SearchTypeOption, options } from "./utils";

export function SearchHeader() {
  const intl = useIntl();
  const [searchType, setSearchType] = useState<SearchTypeOption>({
    value: options[0].value,
    label: intl.formatMessage({ id: options[0].label }),
  });
  const params = useSelector(globalSearchSelectors.params);
  const selectGlobalSearch = bookingApi.endpoints.globalSearch.select(params);
  const { isLoading } = useSelector(selectGlobalSearch);
  const navigate = useNavigate();
  const close = useCallback(() => navigate(-1), [history]);
  const { changeContext, changeKeyword, reset } = useGlobalSearchActions();
  const { setRestaurant } = useApplicationContextActions();
  const restOpts = useRestsOptions();
  const { state } = useLocation() as Location<{
    searchQuery?: string;
    from?: string;
  } | null>;

  useEffect(() => {
    const { searchQuery } = state || {};
    searchQuery && changeKeyword(searchQuery);
  }, [state]);

  useUnmount(reset);

  useUpdateEffect(() => {
    changeContext(searchType.value);
  }, [searchType]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {intl.formatMessage({ id: ETranslations.BOOKING_GLOBAL_SEARCH })}
      </h1>
      <div className={styles.search}>
        {Boolean((restOpts?.length ?? 0) > 1) && (
          <RestaurantSelect
            className={styles.rest}
            value={params.restaurantId}
            onRestaurantChange={setRestaurant}
          />
        )}
        <div className={styles.searchType}>
          <SelectBasic
            options={options}
            value={searchType}
            onChange={setSearchType as any}
          />
        </div>
        {searchType.value === SearchContext.CLIENT_TAG ? (
          <UserTags value={params.keyword} onChange={changeKeyword} />
        ) : (
          <Input.Search
            className={styles.searchValue}
            isLoading={isLoading}
            placeholder={intl.formatMessage({
              id: ETranslations.GLOBAL_SEARCH_PLACEHOLDER,
            })}
            value={params.keyword}
            onSearch={changeKeyword}
          />
        )}
      </div>
      <Button variant="primary" onClick={close}>
        {intl.formatMessage({ id: ETranslations.BASE_CLOSE })}
      </Button>
    </div>
  );
}
