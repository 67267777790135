import { useDispatchActions } from "../../hooks/useDispatchActions";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AccessRuleData } from "types/accessRules";

export interface State {
  selectedAccessRule: AccessRuleData | null;
}

export const initialState: State = {
  selectedAccessRule: null,
};

const slice = createSlice({
  name: "accessRuleReducer",
  initialState,
  reducers: {
    setSelectedAccessRule(state, action: PayloadAction<AccessRuleData>) {
      state.selectedAccessRule = action.payload;
    },
    reset(state) {
      state.selectedAccessRule = null;
    },
  },
});

export const { name: accessRulesSliceName, reducer: accessRuleReducer } = slice;
export const usAccessRulesActions = () => useDispatchActions(slice.actions);
