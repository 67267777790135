/*
    ____,-------------------------------,____
    \   |              Auth             |   /
    /___|-------------------------------|___\
*/

export interface ITokenData {
  token: string;
  expDateMs?: number;
}

export class DialogsAuth {
  constructor(
    private url: string,
    private onToken: (isRetry?: boolean) => Promise<ITokenData>,
    private onAuthError: () => void,
    public allowAuthRetry: boolean,
  ) {}

  private tokenData?: ITokenData;
  private promise?: Promise<ITokenData>;

  get baseUrl() {
    return this.url;
  }

  async askToken(isRetry?: boolean): Promise<string> {
    // cached
    if (
      this.tokenData &&
      this.tokenData.expDateMs &&
      Date.now() < this.tokenData.expDateMs
    ) {
      return this.tokenData.token;
    }

    // dedupe
    if (this.promise) {
      return (await this.promise).token;
    }

    // ask
    this.promise = this.onToken(isRetry);
    try {
      this.tokenData = await this.promise;
      return this.tokenData.token;
    } finally {
      this.promise = undefined;
    }
  }

  publishError() {
    this.onAuthError();
  }

  resetCache() {
    this.tokenData = undefined;
  }
}
