import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import { capitalizeString } from "../../utils";
import { useFetchBookingHistoryDetailsChronologicalPlacesQuery } from "features/api/bookings-api";
import moment from "moment";
import React, { FunctionComponent } from "react";
import { Spinner } from "ui-kit";

interface Props {
  bookingId: number;
}

const BookingHistoryModalMoving: FunctionComponent<Props> = ({ bookingId }) => {
  const { intl } = useIntlUtils();
  const { data } =
    useFetchBookingHistoryDetailsChronologicalPlacesQuery(bookingId);

  if (!data) return <Spinner />;

  return (
    <div className="bookingHistoryMovingBody">
      <div className="bookingHistorySectionHeader">
        <div className="bookingHistoryValue bookingHistoryTitle">
          {intl.formatMessage({ id: ETranslations.BASE_DATE })}
        </div>
        <div className="bookingHistoryValue">
          №{" "}
          {capitalizeString(
            intl.formatMessage({ id: ETranslations.PLURAL_TABLES_ALT }),
          )}
        </div>
      </div>
      {data.map(({ time_key, places }) => (
        <div className="bookingHistoryMovingSection" key={time_key}>
          <div className="bookingHistoryValue bookingHistoryTitle">
            {moment(time_key).format("DD.MM.YYYY HH:mm")}
          </div>
          <div className="bookingHistoryValue">{places}</div>
        </div>
      ))}
    </div>
  );
};

export default BookingHistoryModalMoving;
