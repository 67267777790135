import { Card as C } from "./Card";
import { Content } from "./Content";
import { Header } from "./Header";
import { HeaderTabs } from "./HeaderTabs";
import { NavLinkTabs } from "./NavLinkTabs";
import { useCloseCard } from "./useCloseCard";

export const Card = Object.assign(C, {
  Header,
  HeaderTabs,
  Content,
  NavLinkTabs,
  useCloseCard,
});
