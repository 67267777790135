import {
  restaurantSelector,
  restaurantsSelector,
  useApplicationContextActions,
} from "features/AppContext";
import {
  type Dialog,
  type DialogTask,
  useDialogsApi,
} from "features/Dialogs/SDK";
import type { Restaurant } from "models/restaurant.model";
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";
import { invariant } from "utils";

type FeedsManagementContext = {
  dialogId: string | undefined;
  restaurantId: string | number | undefined;
  clientId: string;
  addFeedToLinked: (id: string) => void;
  removeFeedFromLinked: (id: string) => void;
  optimisticReservationLink: (id: string) => void;
  cachedRestaurant: Restaurant;
  changeRestaurant: () => void;
};

type OptimisticFeedIdsContext = DialogTask["id"][] | undefined;

const feedsManagementContext = createContext<FeedsManagementContext | null>(
  null,
);

const optimisticFeedIdsContext = createContext<OptimisticFeedIdsContext | null>(
  null,
);

export const useFeedsManagementContext = () => {
  const value = useContext(feedsManagementContext);
  return (value ||
    invariant(
      value !== null,
      "FeedsManagement context not passed, please wrap your components with <FeedsManagementProvider />",
    )) as FeedsManagementContext;
};

export const useOptimisticFeedIdsContext = () => {
  const value = useContext(optimisticFeedIdsContext);
  return (value ||
    invariant(
      value !== null,
      "FeedsManagement context not passed, please wrap your components with <FeedsManagementProvider />",
    )) as OptimisticFeedIdsContext;
};

type FeedsManagementContextProps = {
  dialogId: string | undefined;
  dialog?: Dialog;
  dialogUpdating: boolean;
  children: ReactNode;
};

const FeedsManagementContextProvider = ({
  dialogId,
  dialog,
  dialogUpdating,
  children,
}: Required<FeedsManagementContextProps>) => {
  const { formatMessage } = useIntl();
  const {
    load: linkReservation,
    done: doneLinking,
    error: errorLinking,
  } = useDialogsApi<any, string>(
    useCallback(
      (api, taskId) => api.linkTask(dialogId || "", taskId),
      [dialogId],
    ),
  );

  const getExistingLinkedFeedIds = () => dialog.tasks?.map((task) => task.id);
  const [optimisticLinkedFeedIds, setOptimisticLinkedFeedIds] = useState<
    DialogTask["id"][] | undefined
  >(getExistingLinkedFeedIds);

  useEffect(() => {
    !dialogUpdating && setOptimisticLinkedFeedIds(getExistingLinkedFeedIds);
  }, [dialogUpdating, dialog]);

  // FIXME: сделать адекватный апдейт в случае ошибки

  useEffect(() => {
    doneLinking &&
      Notification.success(
        formatMessage({
          id: ETranslations.BOOKING_CREATE_SUCCESSFULLY,
        }),
      );
    errorLinking &&
      (Notification.error(
        formatMessage({
          id: ETranslations.ERROR_LINKING,
        }),
      ),
      setOptimisticLinkedFeedIds((prev) =>
        prev ? (prev.pop(), [...prev]) : [],
      ));
  }, [doneLinking, errorLinking]);

  // FIXME: убрать этот недокостыль после редактирования формы и отвязки в ней от селекта ресторанов
  const { setRestaurant } = useApplicationContextActions();
  const appRestaurant = useSelector(restaurantSelector);
  const restaurants = useSelector(restaurantsSelector);
  const [cachedRestaurant] = useState(appRestaurant);
  const dialogRestaurant = restaurants.find(
    (r) => r.restaurant_id === Number(dialog.partner?.id),
  );
  dialogRestaurant &&
    appRestaurant.restaurant_id !== dialogRestaurant.restaurant_id &&
    setRestaurant(dialogRestaurant);
  const shouldResetRestaurant = useRef(true);
  useEffect(() => {
    return () => {
      shouldResetRestaurant.current && setRestaurant(cachedRestaurant);
    };
  }, []);

  const addFeedToLinked = (id: string) =>
    setOptimisticLinkedFeedIds((prev) => (prev ? [...prev, id] : [id]));
  const removeFeedFromLinked = (id: string) =>
    setOptimisticLinkedFeedIds(
      (prev) => prev && prev.filter((feedId) => feedId !== id),
    );

  const optimisticReservationLink = (feedId: string) => (
    linkReservation(feedId), addFeedToLinked(feedId)
  );

  const feedsManagementContextValue = useMemo(
    () => ({
      dialogId,
      restaurantId: dialog.partner?.id || dialogRestaurant?.restaurant_id,
      clientId: dialog.customer.id,
      addFeedToLinked,
      removeFeedFromLinked,
      optimisticReservationLink,
      cachedRestaurant,
      changeRestaurant: () => (shouldResetRestaurant.current = false),
    }),
    [
      cachedRestaurant.restaurant_id,
      dialog.partner?.id,
      dialogRestaurant?.restaurant_id,
      dialog.customer.id,
      shouldResetRestaurant?.current,
    ],
  );

  return (
    <optimisticFeedIdsContext.Provider value={optimisticLinkedFeedIds}>
      <feedsManagementContext.Provider value={feedsManagementContextValue}>
        {children}
      </feedsManagementContext.Provider>
    </optimisticFeedIdsContext.Provider>
  );
};

export const FeedsManagementProvider = ({
  children,
  dialog,
  ...props
}: FeedsManagementContextProps) =>
  dialog ? (
    <FeedsManagementContextProvider dialog={dialog} {...props}>
      {children}
    </FeedsManagementContextProvider>
  ) : (
    <>{children}</>
  );
