import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { ETranslations } from "../../../types/translates";
import styles from "./HallLegendModal.module.scss";
import cn from "classnames";
import { OverbookedLabel } from "components/Table/OverbookedLabel";
import { Modal, ModalProps } from "components/modal";
import { config } from "config";
import { ICONS } from "ui-kit";

type HallLegendModal = Omit<ModalProps, "title" | "children">;

export default function HallLegendModal(props: HallLegendModal) {
  const { intl } = useIntlUtils();

  return (
    <Modal
      {...props}
      className={styles.legendContainer}
      title={intl.formatMessage({ id: ETranslations.HALL_SCHEME })}
    >
      <Modal.Content className={styles.legend}>
        <div className={cn(styles.table, styles.free)} />
        <span>{intl.formatMessage({ id: ETranslations.FREE })}</span>

        <div className={cn(styles.table, styles.confirmed)} />
        <span>{intl.formatMessage({ id: ETranslations.CONFIRMED })}</span>

        <div className={cn(styles.table, styles.notConfirmed)} />
        <span>{intl.formatMessage({ id: ETranslations.NOT_CONFIRMED })}</span>

        <div className={cn(styles.table, styles.inHall)} />
        <span>{intl.formatMessage({ id: ETranslations.IN_THE_HALL })}</span>

        <div className={cn(styles.table, styles.icon, styles.clock)}>
          <ICONS.ClockCircleScheme height={26} width={26} />
        </div>
        <span>{intl.formatMessage({ id: ETranslations.MIN_TO_LEFT })}</span>

        <div className={cn(styles.table, styles.late)} />
        <span>{intl.formatMessage({ id: ETranslations.LATE })}</span>

        <div className={cn(styles.table, styles.outstayed)} />
        <span>{intl.formatMessage({ id: ETranslations.OUTSTAY })}</span>

        {config.vipSign && (
          <>
            <div className={cn(styles.table, styles.icon)}>
              <ICONS.VipSign height={28} width={28} />
            </div>
            <span>VIP</span>
          </>
        )}

        <div className={cn(styles.table, styles.number)}>12</div>
        <span>{intl.formatMessage({ id: "HSE:TABLE_NUMBER" })}</span>

        <OverbookedLabel
          style={{ width: "26px", height: "26px", fontSize: "100%" }}
        />
        <span>
          {intl.formatMessage({ id: ETranslations.BOOKING_CROSSING })}
        </span>

        {/* <div className={cn(styles.table, styles.deposit)}> */}
        {/*   <ICONS.LockIcon width={20} height={20}/> */}
        {/* </div> */}
        {/* <span>{intl.formatMessage({id: ETranslations.LOCK_TABLE})}</span> */}

        <div className={cn(styles.table, styles.deposit)}>
          <ICONS.LockIcon height={20} width={20} />
        </div>
        <span>{intl.formatMessage({ id: ETranslations.DEPOSIT })}</span>
      </Modal.Content>
    </Modal>
  );
}
