import type { IResponse } from "models/common";
import type { FeedDTO, FeedStatus } from "types/communication";



import { apiFrontOffice } from "./api";


type FeedRequest = {
  body:
    | {
        feed_ids?: string[];
        restaurant_ids: [string | number];
        client_id: number;
        task_close_state?: FeedStatus;
      }
    | {
        feed_ids: string[];
        restaurant_ids?: [string | number];
        client_id?: number;
        task_close_state?: FeedStatus;
      };
  params?: {
    page: number;
    size: number;
    sort: string[];
  };
};

export const communicationApi = apiFrontOffice.injectEndpoints({
  endpoints: (build) => ({
    fetchFeeds: build.query<FeedDTO, FeedRequest>({
      query: ({ body, params }) => ({
        url: "communication/feed",
        method: "POST",
        body,
        params,
      }),
      providesTags: (result, err, args) => {
        return [
          {
            type: "Feeds",
            id:
              args?.body?.feed_ids?.toString() ||
              String(args?.body?.client_id ||
              result?.content?.[0]?.client_id),
          },
        ];
      },
      transformResponse: (response: IResponse<FeedDTO>) => response.data,
    }),
    /*  async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          data.forEach((rest) => {
            predata.restsMapper[rest.restaurant_id] = {
              label: rest.restaurant_name,
              value: rest.restaurant_id,
              ...rest,
            };
          });
        } catch (e) {
          (e as ErrorResponse)?.error?.data?.status === 500 &&
            global.channels.auth.cb?.(false); // временная мера, убрать как только бэк станет возвращать 401 при инвалидном токене
        }
      }, */
  }),
});

export const { useFetchFeedsQuery } = communicationApi;