import type { Tag } from "models/tags.model";

export type TagsShortView = ReturnType<typeof tagsToShortView>;

export const tagsToShortView = (tags: Tag[], count: number) => ({
  visibleTags: tags.slice(0, count),
  invisibleTagsNames: tags
    .slice(count)
    .reduce((result, tag) => (result && `${result}, `) + tag.name, ""),
  invisibleTagsCount: tags.length - count,
});
