import { skipToken } from "@reduxjs/toolkit/dist/query";
import type { PopupRef } from "components/Popup";
import {
  useChangeBookingStatus,
  useGetBookingStatusQuery,
} from "features/api/bookings-api";
import { useAllStatuses } from "features/api/dictionaries-api";
import { useBooleanState } from "hooks/useBooleanState";
import { useIntlUtils } from "hooks/useIntlUtils";
import { useRecoveryBooking } from "hooks/useRecoveryBooking";
import type { SlotId } from "models/booking.model";
import type { ClientId } from "models/client.model";
import { useCallback, useMemo, useRef, useState } from "react";
import type { BaseStatus, ExtraStatus } from "types/status";

export const useStatusActions = (
  bookingId: SlotId,
  status?: BaseStatus,
  clientId?: ClientId | null,
) => {
  const { intl } = useIntlUtils();
  const { recovery } = useRecoveryBooking(bookingId);

  const [isOpen, setIsOpen, setIsClose] = useBooleanState(false);

  const popup = useRef<PopupRef>(null);

  const { data: bookingStatus, isLoading } = useGetBookingStatusQuery(
    (isOpen && bookingId) || skipToken,
  );

  // Реализация убрана пока модель экстра статусов еще простая. При потребности - вернуть, но избавиться от слишком частых запросов
  //const { data: extraStatuses } = useGetBookingExtraStatusesQuery(booking.bookingId ?? NaN)
  const { statuses } = useAllStatuses();
  const extraStatuses = statuses.filter(
    (s) => s.is_extra && s.is_active && s.category === status?.category,
  ) as ExtraStatus[];

  const {
    force,
    nextStatus: goNextStatus,
    terminalStatus,
    resetForce,
  } = useChangeBookingStatus(bookingId, clientId || NaN);

  const nextStatus = useMemo(
    () => bookingStatus?.next || bookingStatus?.terminal,
    [bookingStatus],
  );

  const handleStatusChange = useCallback(async () => {
    try {
      popup.current?.close();
      // eslint-disable-next-line no-unused-expressions
      bookingStatus?.next ? goNextStatus() : terminalStatus();
    } catch {
      //
    }
  }, [bookingStatus]);

  const [popupShowCancelStatus, setPopupShowCancelStatus] = useState(false);

  const showCancel = () => setPopupShowCancelStatus(true);
  const hide = () => (setPopupShowCancelStatus(false), setIsClose());

  return {
    isLoading,
    intl,
    nextStatus,
    handleStatusChange,
    recovery,
    showCancel,
    hide,
    popup,
    isOpen,
    setIsOpen,
    force,
    goNextStatus,
    resetForce,
    extraStatuses,
  };
};
