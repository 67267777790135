import "./OpenScheme.scss";
import { ICONS } from "common/helpers";

export function OpenScheme({
  onClick,
}: {
  onClick?: (e: any) => void;
}): JSX.Element | null {
  return (
    <ICONS.HallIcon
      className="openScheme"
      height="30px"
      style={{ cursor: "pointer" }}
      width="30px"
      onClick={onClick}
    />
  );
}
