import { BookingHistory } from "components/BookingHistory";
import { EditBooking } from "components/hall-scheme/redux/TableBookingList/table-booking-list";
import { EditOrder } from "containers/BookingOrders";
import type { SlotId } from "models/booking.model";
import { useIntl } from "react-intl";
import {
  type LoaderFunction,
  redirect,
  useLoaderData,
  useSearchParams,
} from "react-router-dom";
import { Notification } from "services/notification";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";
import { Card } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";
import { getRecordParamInfo } from "utils";

import {
  DIALOG_TABS,
  type ReservationTab,
  TAB_KEY,
  getActiveReservationTab,
} from "../constans";

export const loader = (({ params, request }) => {
  const { record, dialogId } = params as Params;
  const recordInfo = getRecordParamInfo(record);
  const activeTab = getActiveReservationTab(
    new URL(request.url).searchParams.get(TAB_KEY),
  );
  return recordInfo
    ? {
        recordType: recordInfo.recordType,
        recordId: Number(recordInfo.id) as SlotId,
        activeTab,
      }
    : (Notification.error(ETranslations.ERROR_FORM_RESERVATION),
      redirect(`/dialogs/${dialogId}?${TAB_KEY}=${DIALOG_TABS.FEEDS}`));
}) satisfies LoaderFunction;

const TabletView = () => {
  const { activeTab, recordId, recordType } = useLoaderData<typeof loader>();
  return activeTab === "HISTORY" ? (
    <Card.Content noPadding>
      <BookingHistory bookingId={recordId} isExpanded />
    </Card.Content>
  ) : (
    <Card.Content noPadding>
      {recordType === "order" ? (
        <EditOrder orderId={recordId} />
      ) : (
        <EditBooking bookingId={recordId} />
      )}
    </Card.Content>
  );
};

const DesktopView = () => {
  const { activeTab, recordId, recordType } = useLoaderData<typeof loader>();
  const { formatMessage } = useIntl();
  const [, setSearchParams] = useSearchParams();
  const setTab = (tab: ReservationTab) =>
    setSearchParams((prev) => (prev.set(TAB_KEY, tab), prev));

  return (
    <Card>
      <Card.HeaderTabs<ReservationTab> activeTab={activeTab} onChange={setTab}>
        <Card.HeaderTabs.Item<ReservationTab>
          tabName="EDIT"
          title={formatMessage({
            id: ETranslations.BOOKING_MANAGEMENT,
          })}
        >
          <Card.Content noPadding>
            {recordType === "order" ? (
              <EditOrder orderId={recordId} />
            ) : (
              <EditBooking bookingId={recordId} />
            )}
          </Card.Content>
        </Card.HeaderTabs.Item>
        {recordType === "booking" && (
          <Card.HeaderTabs.Item<ReservationTab>
            tabName="HISTORY"
            title={formatMessage(
              { id: ETranslations.HISTORY_OF },
              {
                value: formatMessage({
                  id: ETranslations.PLURAL_CHANGES,
                }).toLowerCase(),
              },
            )}
          >
            <Card.Content noPadding>
              <BookingHistory bookingId={recordId} isExpanded />
            </Card.Content>
          </Card.HeaderTabs.Item>
        )}
      </Card.HeaderTabs>
    </Card>
  );
};
export const EditRecordFromDialog = () => {
  const isTablet = useIsTablet();

  return isTablet ? <TabletView /> : <DesktopView />;
};
