import { emptyStringIfNull, getMaskPhone } from "../../../common/helpers";
import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { TelephonyAction } from "../../../models/telephony.model";
import { ETranslations } from "../../../types/translates";
import "./lines.scss";
import cn from "classnames";
import { isArray } from "lodash";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Collapse } from "ui-kit";

interface IPhoneLines {
  onClose: () => void;
  data: TelephonyAction[];
}

export const PhoneLines = (props: IPhoneLines) => {
  const { getIntlCreatingOf, isRussianLocale, intl } = useIntlUtils();
  const { data, onClose } = props;
  const navigate = useNavigate();
  const isActiveLines = isArray(data) && data.length > 0;

  const guestRedirect = useCallback(
    (clientId: number | undefined): any => {
      const path = `/guests?clientId=${clientId}`;
      if (clientId) {
        navigate(path);
      }
      onClose();
    },
    [history],
  );

  const createBooking = useCallback(
    (phone: string | undefined): any => {
      const path = `/create-booking?phone=${phone}`;
      if (phone) {
        navigate(path);
      }
      onClose();
    },
    [history],
  );

  const createGuest = useCallback(
    (phone: string | undefined): any => {
      const path = `/guests?phone=${phone}`;
      if (phone) {
        navigate(path);
      }
      onClose();
    },
    [history],
  );

  return (
    <>
      {isActiveLines ? (
        <div className={cn("lines")}>
          {data.map((item) => {
            const {
              surname = "",
              name = "",
              phone,
              client_id,
              entry_id,
            } = item;
            return (
              <Collapse
                header={
                  <div>
                    <p className={cn("title")}>{getMaskPhone(phone)}</p>
                    <p className={cn("description")}>
                      {client_id
                        ? emptyStringIfNull([surname, name])
                        : intl.formatMessage({
                            id: ETranslations.UNKNOWN_GUEST,
                          })}
                    </p>
                  </div>
                }
                key={entry_id}
              >
                <div className={cn("line-buttons")}>
                  {client_id ? (
                    <button
                      className={cn("button")}
                      type="button"
                      onClick={() => guestRedirect(client_id)}
                    >
                      {intl.formatMessage({
                        id: ETranslations.GO_TO_USER_PROFILE,
                      })}
                    </button>
                  ) : (
                    <button
                      className={cn("button")}
                      type="button"
                      onClick={() => createGuest(phone)}
                    >
                      {getIntlCreatingOf(
                        isRussianLocale
                          ? ETranslations.PLURAL_GUESTS_ALT
                          : ETranslations.PLURAL_GUEST,
                      )}
                    </button>
                  )}
                  <button
                    className={cn("button")}
                    type="button"
                    onClick={() => createBooking(phone)}
                  >
                    {getIntlCreatingOf(ETranslations.PLURAL_BOOKING)}
                  </button>
                </div>
              </Collapse>
            );
          })}
        </div>
      ) : (
        <div className={cn("no-content")}>
          <h3>{intl.formatMessage({ id: ETranslations.NO_ACTIVE_CALLS })}</h3>
        </div>
      )}
    </>
  );
};
