import {
  dateSelector,
  placeSelector,
  restaurantSelector,
} from "features/AppContext";
import { tableBookingsTableSelector } from "features/TableBooking/selectors";
import useClosestInterval from "hooks/useClosestInterval";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getTimeOption } from "../../../registration/forms/utils";

export type TManagerialForm = {
  start_date: Date;
  end_date: Date;
  duration: number;
  week_days: any[];
  tables: any[];
  restaurant_id: number;
  place_id: number;
  time: any;
};

export function prepareData(): TManagerialForm {
  const restaurant = useSelector(restaurantSelector);
  const place = useSelector(placeSelector);
  const table = useSelector(tableBookingsTableSelector);
  const startDate = useSelector(dateSelector);

  const closestInterval = useClosestInterval();

  const data = {
    start_date: new Date(startDate.format("YYYY-MM-DD")),
    end_date: new Date(startDate.format("YYYY-MM-DD")),
    start_time: getTimeOption(closestInterval) || "",
    duration: 120,
    persons: 1,
    time: getTimeOption(closestInterval),
    week_days: [],
    tables: [table].filter(Boolean),
    restaurant_id: restaurant.restaurant_id,
    place_id: place,
  };

  return useMemo(() => data, [closestInterval]);
}
