import { State, initialState, moveBookingSliceName } from "./slice";
import { createSelector } from "@reduxjs/toolkit";
import { MoveRequest } from "features/api/hallschema-api";

const selectDomain = (state: any): State =>
  state[moveBookingSliceName] || initialState;

export const sourceBookingId = createSelector(
  selectDomain,
  ({ sourceBookingId: source }) => source,
);
export const sourceTableNumber = createSelector(
  selectDomain,
  ({ sourceTableNumbers }) => sourceTableNumbers || [],
);

export const isSourceSelected = (state: any) => !!sourceBookingId(state);

export const targetTables = createSelector(
  selectDomain,
  ({ targetTableIds }) => targetTableIds || [],
);
export const targetTablesNumbers = createSelector(
  selectDomain,
  ({ targetTableNumbers }) => targetTableNumbers || [],
);
export const isTableSelectedFactory = (tableId: number) =>
  createSelector(targetTables, (tables) => tables.includes(tableId));

export const targetBooking = createSelector(
  selectDomain,
  ({ targetBookingId }) => targetBookingId,
);
export const isBookingSelectedFactory = (bookingId: number) =>
  createSelector(
    [targetBooking, targetTables],
    // Если выбрны столы, то бронирование используется для определения овербукинга, а не как источник для пермещения
    (booking, tables) => (tables.length ? false : booking === bookingId),
  );

export const moveData = createSelector(
  selectDomain,
  ({
    sourceBookingId: source,
    targetBookingId,
    targetTableIds,
  }): MoveRequest | null => {
    if (!source) return null;
    if (targetTableIds?.length) {
      return {
        origin: {
          booking_id: source,
        },
        destination: {
          booking_id: targetBookingId,
          tables_id: targetTableIds,
        },
      } as MoveRequest;
    }
    return null;
  },
);
