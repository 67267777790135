import { HOURS, MINUTES, pad2, roundFloor } from "./helpers";
import _ from "lodash";
import { SelectOption, SingleOption } from "models/common";
import { Moment } from "moment";

export default class SnapTime {
  static constructorM(mins: number) {
    const snapTime = new SnapTime();
    snapTime.hours = Math.floor(mins / 60);
    snapTime.mins = roundFloor(mins % 60, 15);
    return snapTime;
  }

  static constructorHM(hours: number, mins: number) {
    const snapTime = new SnapTime();
    snapTime.hours = hours;
    snapTime.mins = mins;
    return snapTime;
  }

  static constructorHMSEL(hours: SelectOption, mins: SelectOption) {
    const snapTime = new SnapTime();
    snapTime.hours = _.get(hours, "value", 0);
    snapTime.mins = roundFloor(_.get(mins, "value", 0), 15);
    return snapTime;
  }

  static constructorSO(so: SingleOption) {
    const snapTime = new SnapTime();
    const r = so.label.match(/^(\d+):(\d+)$/);
    // @ts-ignore
    const [, h, m] = r;
    snapTime.hours = Number.parseInt(h, 10);
    snapTime.mins = Number.parseInt(m, 10);
    return snapTime;
  }

  static constructorMoment(m: Moment) {
    const snapTime = new SnapTime();
    snapTime.hours = m.hours();
    snapTime.mins = roundFloor(m.minutes(), 15);
    return snapTime;
  }

  public hours = 0;
  public mins = 0;

  hhmm(): string {
    return `${pad2(this.hours)}:${pad2(this.mins)}`;
  }

  get humanize(): string {
    return `${this.hours} ч. ${this.mins} мин.`;
  }

  get hoursInstanse(): SelectOption {
    return HOURS[this.hours];
  }

  get minsInstance(): SelectOption {
    // @ts-ignore
    return MINUTES.find((m) => m.value === this.mins);
  }

  get minsTotal(): number {
    return this.hours * 60 + this.mins;
  }

  get singleOntionSpec(): SingleOption {
    return { value: 0, label: this.hhmm() };
  }
}
