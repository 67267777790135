import { ICONS } from "..";
import styles from "./Spinner.module.scss";
import cn from "classnames";

export function Spinner({ className }: { className?: string }) {
  return (
    <div className={cn(styles["spin-loading"], className)}>
      <ICONS.Loading />
    </div>
  );
}
