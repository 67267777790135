import { BookingClientHistory } from "components/BookingClientHistory";
import { DefaultErrorBoundary } from "components/ErrorBoundary";
import { useParams } from "react-router-dom";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";

import styles from "./GuestHistory.module.scss";

export const GuestHistory = () => {
  const { clientId } = useParams<Params>();
  return (
    <div className={styles.main}>
      <BookingClientHistory clientId={clientId!} />
    </div>
  );
};

export const GuestHistoryError = () => (
  <DefaultErrorBoundary entity={ETranslations.PLURAL_BOOKINGS_NOM}>
    {ETranslations.ERROR_ENTITY}
  </DefaultErrorBoundary>
);
