import { ETranslations } from "../../../types/translates";
import { capitalizeString } from "../../../utils";
import styles from "./style.module.scss";
import { Modal } from "components/modal";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Button, ICONS } from "ui-kit";

interface ConfirmOverbookingModalProps {
  onConfirm(): void;
  onDecline(): void;
  isOpen?: boolean;
  disabled?: boolean;
}

export function ConfirmOverbookingModal({
  isOpen = false,
  onConfirm,
  onDecline,
  disabled,
}: ConfirmOverbookingModalProps) {
  const intl = useIntl();

  const bookingCrossingTitle = useMemo(
    () => intl.formatMessage({ id: ETranslations.BOOKING_CROSSING }),
    [],
  );

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      title={bookingCrossingTitle}
      onClose={onDecline}
    >
      <Modal.Content className={styles.content}>
        <ICONS.Info className={styles.icon} height={21} width={21} />
        <div className={styles.infoBlock}>
          <h5>{bookingCrossingTitle}</h5>
          <span>
            {intl.formatMessage({ id: ETranslations.BOOKING_SUPERIMPOSING })}
          </span>
          <div className={styles.buttonBlock}>
            <Button
              disabled={disabled}
              type="button"
              variant="dangerous"
              onClick={onConfirm}
            >
              {intl.formatMessage({ id: ETranslations.BASE_CONFIRM })}
            </Button>
            <Button
              className={styles.secondary}
              disabled={disabled}
              type="button"
              variant="secondary"
              onClick={onDecline}
            >
              {capitalizeString(
                intl.formatMessage({ id: ETranslations.BASE_CANCEL }),
              )}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
