import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _, { constant, isNull } from "lodash";
import type { Client } from "models/client.model";

import { useDispatchActions } from "../../hooks/useDispatchActions";
import { ClientsFilter } from "../../services/clients.service";

export interface State {
  loadedClients: Client[];
  filter: ClientsFilter;
  attachMode: "none" | "attach";
  selectedClient?: Client;
}

const emptyFilter = {
  offset: 0,
  count: 20,
};

export const initialState: State = {
  loadedClients: [],
  filter: emptyFilter,
  attachMode: "none",
};

const slice = createSlice({
  name: "clients-list",
  initialState,
  reducers: {
    loadClients(state, action: PayloadAction<Client[]>) {
      state.loadedClients = action.payload.filter((it) => !isNull(it.phone));
      state.attachMode = "none";
    },
    // concat list with onScroll action
    onScrollConcatLists(state, action: PayloadAction<Client[]>) {
      const { loadedClients } = state;
      state.loadedClients = _.uniqBy(
        loadedClients.concat(action.payload.filter((it) => !isNull(it.phone))),
        (item) => item.client_id,
      );
      state.attachMode = "none";
    },
    // apply filter
    applyFilter(state, action: PayloadAction<ClientsFilter>) {
      state.attachMode = "none";
      state.filter = { ...action.payload, offset: 0, count: 20 };
    },
    applyFilterConcat(state, action: PayloadAction<ClientsFilter>) {
      state.attachMode = "attach";
      state.filter = { ...state.filter, ...action.payload };
    },
    updateFilter(state, action: PayloadAction<Partial<ClientsFilter>>) {
      state.filter = {
        ...Object.assign(state.filter, action.payload),
        offset: 0,
      };
    },
    // reset the state
    reset(state) {
      state.loadedClients = [];
      state.filter = emptyFilter;
    },
    setSelectedClient(state, { payload }: PayloadAction<Client | undefined>) {
      state.selectedClient = payload;
    },
    clearSelectedClient: {
      prepare: constant({ payload: undefined }),
      reducer(state) {
        state.selectedClient = undefined;
      },
    },
  },
});

export const {
  name: clientListSliceName,
  reducer: clientListReducer,
  actions: clientListActions,
} = slice;
export const useClientListActions = () => useDispatchActions(slice.actions);
