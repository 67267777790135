import { M, predata } from "common/helpers";
import { BookingsHistory } from "components/BookingsHistory";
import { RestaurantManagement } from "components/RestaurantManagement";
import { Shifts } from "components/shifts";
import { Booking } from "models/booking.model";
import { SelectModel } from "models/common";
import {
  OptionAppointRole,
  OptionSettingsMode,
  SettingsModes,
} from "models/settings.model";
import { AppointUser } from "models/user.model";
import { Outlet } from "react-router-dom";
import { Role } from "services/auth.service";

import { ETranslations } from "../../types/translates";
import type { Settings } from "./settings";
import AccessRules from "./sub-renders/access_rules";
import AppointHostess from "./sub-renders/appoint-hostess";
import { StatusManagement } from "./sub-renders/status-management/status-management";
import TagsPage from "./sub-renders/tags";

/* eslint-disable */
export const getOptionsSettingsMode = (): OptionSettingsMode[] =>
  [
    {
      value: 6,
      label: ETranslations.SETTINGS_TITLE_SHIFTS,
      mode: SettingsModes.shifts,
      permissions: ["HOSTESS", "ADMINISTRATOR_RESTAURANT"],
    },
    {
      value: 1,
      label: ETranslations.SETTINGS_TITLE_ROLES,
      mode: SettingsModes.appointRoles,
      permissions: ["HOSTESS"],
    },
    {
      value: 5,
      label: ETranslations.SETTINGS_TITLE_BOOKING_HISTORY,
      mode: SettingsModes.changeBook,
      permissions: [
        "ADMINISTRATOR_RESTAURANT",
        "ADMINISTRATOR",
        "HOSTESS",
        "RESTAURANT",
      ],
    },
    {
      value: 6,
      label: ETranslations.SETTINGS_TITLE_RESTAURANT_MANAGEMENT,
      mode: SettingsModes.restManagement,
      permissions: ["ADMINISTRATOR_RESTAURANT", "HOSTESS"],
    },
    {
      value: 7,
      label: ETranslations.ACCESS_RULES,
      mode: SettingsModes.access_rules,
      permissions: ["HOSTESS"],
    },
    {
      value: 8,
      label: ETranslations.STATUSES,
      mode: SettingsModes.statuses,
      permissions: ["HOSTESS"],
    },
    {
      value: 9,
      label: ETranslations.TAGS,
      mode: SettingsModes.tags,
      permissions: [
        "ADMINISTRATOR_RESTAURANT",
        "ADMINISTRATOR",
        "HOSTESS",
        "RESTAURANT",
      ],
    },
  ].filter(Boolean) as OptionSettingsMode[];

export const appointRolesList: OptionAppointRole[] = [
  {
    id: 1,
    label: ETranslations.SETTINGS_ROLE_SYSTEM_ADMIN,
    role: "ADMINISTRATOR",
    declension: ETranslations.SETTINGS_ROLE_SYSTEM_ADMIN_DECL,
    data: [],
  },
  {
    id: 2,
    label: ETranslations.SETTINGS_ROLE_RESTAURANT_ADMIN,
    role: "ADMINISTRATOR_RESTAURANT",
    declension: ETranslations.SETTINGS_ROLE_RESTAURANT_ADMIN_DECL,
    data: [],
  },
  {
    id: 3,
    label: ETranslations.SETTINGS_ROLE_HOSTESS,
    role: "HOSTESS",
    declension: ETranslations.SETTINGS_ROLE_HOSTESS_DECL,
    data: [],
  },
];

export const optionsAppointRoles: SelectModel[] = [
  { value: "ADMINISTRATOR", label: ETranslations.SETTINGS_ROLE_SYSTEM_ADMIN },
  {
    value: "ADMINISTRATOR_RESTAURANT",
    label: ETranslations.SETTINGS_ROLE_RESTAURANT_ADMIN,
  },
  { value: "HOSTESS", label: ETranslations.SETTINGS_ROLE_HOSTESS },
];
/* eslint-enable */

export const isBookingsDateEqual = (
  prevBook: Booking | undefined,
  { date }: Booking,
) => {
  if (!prevBook) {
    return false;
  }
  return M(prevBook.date).isSame(date, "d");
};

export const getOptionAppointRole = (
  desiredRole?: Role,
): SelectModel | undefined => {
  const foundOption = optionsAppointRoles.find(
    ({ value }) => value === desiredRole,
  );
  return foundOption;
};

export const getRestName = ({ restaurant_id: id, role, name }: AppointUser) => {
  if (typeof id !== "number" && role === "ADMINISTRATOR") {
    return ETranslations.ALL_RESTAURANTS;
  }
  if (typeof id !== "number") {
    return ETranslations.UNKNOWN_RESTAURANT;
  }
  if (role === "RESTAURANT") {
    return name;
  }
  return predata.restsMapper[id]?.label;
};

export const renderCardContent = (settings: Settings) => {
  const {
    props: {
      onCallModalHostess,
      onCallModalConfirm,
      onCallModalUser,
      onCallModalStatus,
      intlUtils,
    },
    state: {
      selectedMode,
      restaurantList,
      isLoading,
      selectedSectionId,
      endDate,
      clientName,
      userName,
      hostess,
    },
  } = settings;
  switch (selectedMode.mode) {
    case SettingsModes.sources:
      return (
        <div>
          {intlUtils.intl.formatMessage({
            id: ETranslations.SOURCES,
          })}
        </div>
      );
    case SettingsModes.newRest:
      return (
        <div>
          {intlUtils.intl.formatMessage({
            id: ETranslations.STARTING_NEW_RESTAURANT,
          })}
        </div>
      );
    case SettingsModes.appointHostess:
      return (
        <AppointHostess
          isLoading={isLoading}
          restaurantList={restaurantList}
          selectedSectionId={selectedSectionId}
          setRestaurant={(restId) => settings.setRestaurant(restId)}
          onCallModalConfirm={(id, mode) => onCallModalConfirm(id, mode)}
          onCallModalHostess={(hostessUser, restId) =>
            onCallModalHostess(hostessUser, restId)
          }
        />
      );
    case SettingsModes.appointRoles:
      return <Outlet />;
    case SettingsModes.access_rules:
      return <AccessRules isLoading={isLoading} />;
    case SettingsModes.tags:
      return <TagsPage />;
    case SettingsModes.changeBook:
      return (
        <BookingsHistory
          dateFrom={endDate}
          dateTo={endDate}
          searchWords={clientName}
          userid={hostess?.value}
        />
      );
    case SettingsModes.shifts:
      return <Shifts />;
    case SettingsModes.restManagement:
      return <RestaurantManagement />;
    case SettingsModes.statuses:
      return (
        <StatusManagement
          onCallModalStatus={(allStatuses, statusId, editStatus, isEditing) =>
            onCallModalStatus(allStatuses, statusId, editStatus, isEditing)
          }
        />
      );
    default:
      return null;
  }
};

export const isWithoutSearchBlock = (selectedMode: SettingsModes) =>
  [
    SettingsModes.shifts,
    SettingsModes.restManagement,
    SettingsModes.access_rules,
    SettingsModes.statuses,
    SettingsModes.tags,
    SettingsModes.appointRoles,
  ].includes(selectedMode);
