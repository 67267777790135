import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, LinkButton } from "ui-kit";

import { useCreateBookingFormContext } from "../context/CreateBookingContext";
import { formatClient } from "../utils";
import {
  FormClose,
  FormFooter,
  FormHeader,
  FormStepper,
  getValidationStatus,
} from "./FormComponents";

export const CreateBookingFormLayout = () => {
  const { formatMessage } = useIntl();
  const { formValues, validationState } = useCreateBookingFormContext();
  return (
    <>
      <FormHeader
        title={formatMessage({ id: ETranslations.CREATION_RESERVATION })}
      >
        <FormClose to={"/dashboard"} />
      </FormHeader>
      <FormStepper
        stepsInfo={[
          {
            title: formatMessage({ id: ETranslations.RESERVATION_DATE_SELECT }),
            description: dayjs(formValues.bookingDate).format("DD MMMM, dddd"),
            valid: validationState.step1,
          },
          {
            title: formatMessage({ id: ETranslations.NUMBER_OF_GUESTS_TITLE }),
            description: formatMessage({
              id: ETranslations.NUMBER_OF_GUESTS_DESC,
            }),
            valid: validationState.step2,
          },
          {
            title: formatMessage({ id: ETranslations.PLURAL_GUEST }),
            description: formValues.client
              ? formatClient(formValues.client)
              : formatMessage({ id: ETranslations.GUEST_INFORMATION }),
            valid: validationState.step3,
          },
          {
            title: formatMessage({ id: ETranslations.PLURAL_TABLE }),
            description: formatMessage({ id: ETranslations.SELECT_TABLE }),
            valid: validationState.step4,
          },
          {
            title: formatMessage({ id: ETranslations.OTHER }),
            description: formatMessage({ id: ETranslations.ADDITIONAL_INFO }),
            valid: validationState.step5,
          },
        ]}
        errorText={formatMessage({ id: ETranslations.ERROR_FORM_PARAMETERS })}
      />
      <Outlet />
    </>
  );
};

export const CreateBookingFormFooter = ({
  currentStep,
}: {
  currentStep: 1 | 2 | 3 | 4 | 5;
}) => {
  const { formatMessage } = useIntl();
  const { validationState, createBooking } = useCreateBookingFormContext();
  const validationStatus = getValidationStatus(
    Object.values(validationState),
    currentStep,
  );
  return (
    <FormFooter>
      {validationStatus.allValid ? (
        <Button variant="primary" onClick={createBooking}>
          {formatMessage({ id: ETranslations.BASE_CREATE })}
        </Button>
      ) : (
        <LinkButton
          variant="primary"
          to={`/create-booking-new/step-${validationStatus.nextStep || validationStatus.firstNotValidStep}`}
        >
          {formatMessage({ id: ETranslations.CONTINUE })}
        </LinkButton>
      )}
    </FormFooter>
  );
};
