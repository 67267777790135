/*
    ____,-------------------------------,____
    \   |            Подписки           |   /
    /___|-------------------------------|___\
*/

export class Subscription<T> {
  private subscribers: Set<(cb: T) => void> = new Set();

  get size(): number {
    return this.subscribers.size;
  }

  subscribe(cb: (arg: T) => void) {
    this.subscribers.add(cb);
  }

  unsubscribe(cb: (arg: T) => void) {
    this.subscribers.delete(cb);
  }

  publish(message: T) {
    this.subscribers.forEach((cb) => cb(message));
  }

  clear() {
    this.subscribers.clear();
  }
}

/*
    ____,-------------------------------,____
    \   |              Delay            |   /
    /___|-------------------------------|___\
*/

export function delay(ms: number): Promise<void> {
  return new Promise((r) => setTimeout(r, ms));
}
