import styles from "./Labeled.module.scss";
import cn from "classnames";
import { ReactNode, Ref, forwardRef } from "react";

export interface LabeledProps {
  label: ReactNode | undefined;
  required?: boolean | undefined;
  children: ReactNode;
  className?: string;
  as?: "label" | "div";
}

export const Labeled = forwardRef(
  (
    { label, className, required, children, as: Label = "label" }: LabeledProps,
    ref: Ref<any>,
  ) => {
    if (!label)
      return (
        <Label className={className} ref={ref}>
          {children}
        </Label>
      );
    return (
      <Label className={className} ref={ref}>
        <span className={cn(styles.label, { [styles.required]: required })}>
          {label}
        </span>
        {children}
      </Label>
    );
  },
);
