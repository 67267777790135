import { colorStyles } from "common/helpers";
import { useIntlUtils } from "hooks/useIntlUtils";
import { Tag } from "models/tags.model";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Labeled, SelectTag } from "ui-kit";

type Props = {
  tags: Tag[];
  onClick?: () => void;
  onChange?: (tags: Tag[]) => void;
};

export function FauxTagsInput({ tags, onClick, onChange }: Props) {
  const intl = useIntl();
  const { getIntlChooseEntity } = useIntlUtils();

  const options = useMemo(
    () =>
      tags.map((t) => ({
        color: t.color,
        label: t.name,
        value: t.id,
      })),
    [tags],
  );

  return (
    <Labeled
      label={intl.formatMessage({
        id: ETranslations.RESERVATION_TAGS,
      })}
    >
      <SelectTag
        closeMenuOnSelect={false}
        menuPlacement="top"
        openTo="bottom"
        options={options}
        placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
        styles={colorStyles}
        value={options}
        changedMaxSize
        isMulti
        onMenuOpen={onClick}
        menuIsOpen={false}
        onChange={(_, action) => {
          if (action.action === "clear") {
            onChange?.([]);
          }
          if (action.action === "remove-value") {
            onChange?.(tags.filter((t) => t.id !== action.removedValue.value));
          }
        }}
      />
    </Labeled>
  );
}
