import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";

import styles from "./FieldError.module.scss";

export const FieldError = ({
  error,
  fieldName,
  value,
}: {
  error: string | string[];
  fieldName?: string;
  value?: string | number;
}) => {
  const { formatMessage } = useIntl();
  return Array.isArray(error) ? (
    <ul className={styles.errorList}>
      {Array.from(new Set(error)).map((err) => (
        <li key={String(err)}>
          <strong className={styles.error}>
            {err in ETranslations
              ? formatMessage(
                  { id: ETranslations[err as ETranslations] },
                  { field: fieldName || "", value: value || "" },
                )
              : err}
          </strong>
        </li>
      ))}
    </ul>
  ) : (
    <strong className={styles.error}>
      {error in ETranslations
        ? formatMessage(
            { id: ETranslations[error as ETranslations] },
            { value: fieldName || "" },
          )
        : error}
    </strong>
  );
};
