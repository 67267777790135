import {
  fromProxySelectors,
  useFromProxyActions,
} from "features/BookingFormProxy";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { useTimelineActions } from "features/Timeline";
import type { BookingSlot } from "models/booking.model";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import type { BookingOrder } from "types/booking";
import { getBookingStartTime } from "utils";

export const useOrderList = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const isDashboard = /^\/dashboard(\/.*)?$/.test(pathname);

  const { setEditMode } = useFromProxyActions();
  const selectedBooking = useSelector(
    fromProxySelectors.selectBooking,
  ) as BookingSlot;
  const { switchMode } = useHallSchemaActions();

  const { setTime } = useTimelineActions();
  const onSelectBook = useCallback(
    (book: BookingOrder) => {
      setTime(getBookingStartTime(book.bookingDate, book.bookingTime));
      switchMode(HallMode.TABLES);
    },
    [selectedBooking],
  );

  const onEditBook = useCallback(
    (book: BookingOrder) => {
      switchMode(HallMode.TABLES);
      setEditMode(true);
    },
    [selectedBooking],
  );

  return {
    intl,
    onSelectBook,
    onEditBook,
    selectedBooking,
    isDashboard,
  };
};
