import { Button, InternalButtonProps } from "./Button";
import styles from "./RadioButton.module.scss";
import cn from "classnames";
import {
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
  useMemo,
} from "react";

export interface RadioButtonProps<T> {
  children: ReactNode;
  value: T;
  onChange: (next: T) => void;
  disabled?: boolean;
  className?: string;
}

function filterButton<T>(
  v: ReactNode,
): v is ReactElement<InternalButtonProps<T>> {
  return isValidElement(v) && v.type === Button;
}

export function RadioButton<T>({
  children,
  value,
  onChange,
  disabled,
  className,
}: RadioButtonProps<T>) {
  const options = useMemo(
    () =>
      Children.toArray(children)
        .filter(filterButton)
        .map((button) =>
          cloneElement(button, {
            onClick: () => onChange(button.props.value as T),
            className: cn(button.props.className, {
              [styles.active]: value === button.props.value,
            }),
            disabled: button.props.disabled ?? disabled,
          }),
        ),
    [children, value, onChange, disabled],
  );
  return <div className={cn(styles.radio, className)}>{options}</div>;
}
