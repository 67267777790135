import "./phone-indicator.scss";
import cn from "classnames";
import React from "react";

export const PhoneIndicator = (props: any) => {
  const { isActive } = props;
  return (
    <>
      <div className={cn("phone-indicator", isActive ? "active" : "")}>
        <div className={cn("phone-icon", isActive ? "active" : "")} />
      </div>
    </>
  );
};
