import styles from "./ConfirmBusyTable.module.scss";
import { Modal } from "components/modal";
import { useIntlUtils } from "hooks/useIntlUtils";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";

interface ConfirmBusyTableProps {
  confirm: () => void;
  decline: () => void;
  isOpen?: boolean;
}

export function ConfirmBusyTable({
  confirm,
  decline,
  isOpen,
}: ConfirmBusyTableProps) {
  const { intl } = useIntlUtils();
  return (
    <Modal
      isOpen={!!isOpen}
      title={intl.formatMessage({ id: ETranslations.TABLE_ALREADY_BUSY })}
      onClose={decline}
    >
      <Modal.Content className={styles.body}>
        {intl.formatMessage({ id: ETranslations.TABLE_ALREADY_BUSY_HINT })}
      </Modal.Content>
      <Modal.Footer className={styles.footer}>
        <Button type="button" variant="primary" onClick={confirm}>
          {intl.formatMessage({ id: ETranslations.LOCATE })}
        </Button>
        <Button type="button" variant="secondary" onClick={decline}>
          {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
