import { Modal } from "components/modal";
import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Button, ICONS } from "ui-kit";

import styles from "./ConfirmModal.module.scss";

export const ConfirmModal = ({
  isOpen,
  header,
  children,
  confirm,
  close,
}: {
  isOpen: boolean;
  header?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  confirm: () => Promise<void>;
  close: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal className={styles.conformationModal} isOpen={isOpen} onClose={close}>
      <Modal.Content className={styles.body}>
        <ICONS.WARNING className={styles.icon} />
        <h2>{header}</h2>
        <p>{children}</p>
        <div className={styles.actions}>
          <Button
            type="button"
            variant="dangerous"
            onClick={() => confirm().then(close)}
          >
            {formatMessage({ id: ETranslations.BASE_CONFIRM })}
          </Button>
          <Button type="button" variant="secondary" onClick={close}>
            {formatMessage({ id: ETranslations.BASE_CANCEL })}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
