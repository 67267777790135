import cn from "classnames";
import { type FunctionComponent, type ReactNode, useCallback } from "react";

import styles from "./TabTitle.module.scss";

export interface TabTitleProps<T> {
  title: ReactNode;
  onClick(key: T): void;
  tabName: T;
  isActive?: boolean;
  disabled?: boolean;
  icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export function TabTitle<T>({
  isActive,
  title,
  onClick,
  tabName,
  disabled,
  icon: Icon,
}: TabTitleProps<T>) {
  const handleClick = useCallback(() => {
    if (disabled) return;
    onClick(tabName);
  }, [title, tabName, disabled]);

  return (
    <li
      className={cn(styles.tabTitle, {
        [styles.active]: isActive,
        [styles.disabled]: disabled,
      })}
      data-active={isActive}
      onClick={handleClick}
    >
      {Icon && <Icon width={20} height={20} />}
      {title}
    </li>
  );
}
