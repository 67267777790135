import {
  OrganizationSearchDTO,
  Source,
  type SourceAggregateRequest,
  type SourceId,
  SourceSearchDTO,
  type SourceSearchParams,
} from "models/source.model";
import { Notification } from "services/notification";

import { IResponse } from "../../models/common";
import { ErrorResponse } from "../../types/commons";
import { ETranslations } from "../../types/translates";
import { api } from "./api";

export const sourceApi = api
  .enhanceEndpoints({
    addTagTypes: ["Sources", "Source", "Organizations", "Organization"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchSourcesPage: build.query<
        SourceSearchDTO,
        SourceSearchParams & { page?: number; size?: number }
      >({
        query: (filter) => ({
          url: "v2/source-aggregate/search",
          method: "GET",
          params: filter,
        }),
        transformResponse: (response: SourceSearchDTO) => {
          const result = SourceSearchDTO.safeParse(response);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response;
        },
        providesTags: ["Sources"],
      }),
      fetchSource: build.query<Source, number | string>({
        query: (id) => ({
          url: `v2/source-aggregate/${id}`,
        }),
        providesTags: (r, e, id) => ["Sources", { type: "Source", id }],
        transformResponse: (response: IResponse<Source>) => {
          const result = Source.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
      }),
      fetchOrganizationPage: build.query<
        OrganizationSearchDTO,
        { searchName?: string; page?: number; size?: number }
      >({
        query: (params) => ({
          url: "v2/organization/search",
          method: "GET",
          params,
        }),
        transformResponse: (response: OrganizationSearchDTO) => {
          const result = OrganizationSearchDTO.safeParse(response);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response;
        },
        providesTags: ["Organizations"],
      }),
      createSource: build.mutation<Source, SourceAggregateRequest>({
        query: (body) => ({
          url: "v2/source-aggregate",
          method: "POST",
          body,
        }),
        invalidatesTags: (r, e, args) => [
          "Sources",
          "Organizations",
          { type: "Organization", id: args.organization?.name },
        ],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            //@ts-ignore
            if (data?.status === "ERROR") {
              throw data;
            }
          } catch (e) {
            const errorMessage = (e as ErrorResponse)?.error?.data
              ?.errorMessage;
            errorMessage &&
              Notification.error({
                title: ETranslations.ERROR_GUEST_UPDATING,
                message: errorMessage,
              });
            throw e;
          }
        },
      }),
      updateSource: build.mutation<
        Source,
        SourceAggregateRequest & { id: SourceId }
      >({
        query: (data) => ({
          url: `v2/source-aggregate/${data?.id}`,
          method: "PUT",
          body: {
            source_contact: data.source_contact,
            organization: data.organization,
          },
        }),
        invalidatesTags: (r, e, args) => [
          "Sources",
          { type: "Source", id: args.id },
          "Organizations",
          { type: "Organization", id: args.organization?.name },
        ],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            //@ts-ignore
            if (data?.status === "ERROR") {
              throw data;
            }
          } catch (e) {
            const errorMessage = (e as ErrorResponse)?.error?.data
              ?.errorMessage;
            errorMessage &&
              Notification.error({
                title: ETranslations.ERROR_GUEST_UPDATING,
                message: errorMessage,
              });
            throw e;
          }
        },
      }),
    }),
  });

export const {
  useFetchSourcesPageQuery,
  useLazyFetchSourcesPageQuery,
  useFetchSourceQuery,
  useLazyFetchOrganizationPageQuery,
  useFetchOrganizationPageQuery,
  useCreateSourceMutation,
  useUpdateSourceMutation,
} = sourceApi;
