import ManagerialModalError from "components/ManagerialTables/form/ManagerialModalError";
import { ConfirmOverbookingModal } from "components/modals/ConfirmOverbookingModal";
import BookingFormReduxProxy from "components/registration/forms/BookingFormReduxProxy";
import React from "react";
import { Card } from "ui-kit";

import useRegisterVisit from "./hooks/useRegisterVisit";

export const RegisterVisit = ({
  reset,
  isFromManagement = false,
}: {
  reset: any;
  isFromManagement?: boolean;
}): React.ReactElement => {
  const {
    registerVisit,
    registrationTitle,
    isConfirmationRequired,
    clearData,
    registerOverbooking,
    isCreating,
    hasManagerialError,
    closeModal,
    close,
  } = useRegisterVisit({ reset, isFromManagement });

  return (
    <Card onClose={close}>
      <Card.Header title={registrationTitle} needToReset />
      <Card.Content noPadding>
        <BookingFormReduxProxy
          isFromManagement={isFromManagement}
          submitFn={registerVisit}
          isRegisterVisit
        />
        <ConfirmOverbookingModal
          disabled={isCreating}
          isOpen={isConfirmationRequired}
          onConfirm={registerOverbooking}
          onDecline={clearData}
        />
      </Card.Content>
      {hasManagerialError && (
        <ManagerialModalError
          isOpen={hasManagerialError}
          onClose={closeModal}
        />
      )}
    </Card>
  );
};
