import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import dayjs, { type Dayjs } from "dayjs";
import { useDispatchActions } from "hooks/useDispatchActions";

import { store } from "../../storage";

export interface State {
  sliderMinutesShift: number | null;
  timelineLengthHours: number;
  timeStart: number;
  timestamp: null | number;
  isActualTime: boolean;
  visit_time: any;
}

// todo: придумать контрол для слотов
export const initialState: State = {
  // Смещение относительно текущего времени
  sliderMinutesShift: null,
  // продолжительность работы ресторана
  timelineLengthHours: 8,
  // Время начала работы ресторана
  timeStart: 6,
  // Время на таймлайне в миллисекундах
  timestamp: dayjs().valueOf(),
  // Флаг определяет будет-ли тикать время
  isActualTime: true,
  visit_time: null,
};

const slice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    setTime(state, { payload: dateTime }: PayloadAction<Dayjs>) {
      const minutes = (state.timeStart % 1) * 60;
      const shift = dateTime.diff(
        dateTime.clone().hour(state.timeStart).startOf("h").minute(minutes),
        "minute",
      );
      const timestamp = dateTime.valueOf();
      state.sliderMinutesShift = shift;
      state.timestamp = timestamp;
      state.isActualTime = false;
    },
    setShift(state, { payload }: PayloadAction<number>) {
      state.sliderMinutesShift = payload;
    },
    resetTimeShift: {
      // prepare: constant({payload: undefined}),
      prepare() {
        const currentDate: string = store.getState().applicationContext.date;
        return { payload: currentDate };
      },
      reducer(state, { payload }: PayloadAction<string>) {
        const currentDay = dayjs();
        const timestampDay = dayjs(payload);
        if (currentDay.isSame(timestampDay, "day")) {
          state.timestamp = currentDay.valueOf();
          state.isActualTime = true;
        } else {
          state.timestamp = timestampDay
            .set("hour", 0)
            .set("minute", 0)
            .valueOf();
          state.isActualTime = false;
        }
        state.sliderMinutesShift = null;
      },
    },
    setTimelineConfig(
      state,
      {
        payload,
      }: PayloadAction<
        Pick<Partial<State>, "timeStart" | "timelineLengthHours">
      >,
    ) {
      if (payload.timeStart) {
        state.timeStart = payload.timeStart - 1;
      }
      if (payload.timelineLengthHours) {
        const delta = payload.timelineLengthHours % 2 === 0 ? 2 : 1;
        state.timelineLengthHours = payload.timelineLengthHours + delta + 1;
      }
    },
    setTimestamp(state, { payload }: PayloadAction<number>) {
      state.timestamp = payload;
    },
    setIsActualTime(state, { payload }: PayloadAction<boolean>) {
      state.isActualTime = payload;
    },

    setVisistTime(state, { payload }: PayloadAction<boolean>) {
      state.visit_time = payload;
    },
  },
});

export const { name: timelineSliceName, reducer: timelineReducer } = slice;
export const useTimelineActions = () => useDispatchActions(slice.actions);
