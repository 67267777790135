import { ICONS } from "common/helpers";
import { config } from "config";
import { useIntl } from "react-intl";
import { Form, useActionData, useLoaderData } from "react-router-dom";
import { Button, Card, FieldError, Input } from "ui-kit";

import { ETranslations } from "../../../types/translates";
import type { action } from "../api/action";
import { loader } from "../api/loader";
import { AUTH_INTENT } from "../constants";
import styles from "./Auth.module.scss";
import { InputPassword } from "./InputPassword";
import { LocaleControls } from "./LocaleControls";

export const Auth = () => {
  const redirectTo = useLoaderData<typeof loader>();
  const { fieldErrors, formErrors, hasFormErrors } =
    useActionData<typeof action>() || {};
  const { formatMessage, locale } = useIntl();
  const shouldConfirmPassword = false;

  const BRAND_LOGO = ICONS[
    `${config.BRAND}_AUTH_LOGO` as keyof typeof ICONS
  ] as string;
  const BRAND_BG =
    (ICONS[`${config.BRAND}_BG` as keyof typeof ICONS] as string) ||
    ICONS.WRF_BG;

  return (
    <main className={styles.auth}>
      <header
        className={styles.header}
        style={{ "--brand-bg": `url(${BRAND_BG})` }}
      >
        {BRAND_LOGO && (
          <h1>
            <img alt={config.BRAND} src={BRAND_LOGO} />
          </h1>
        )}
        <p>{formatMessage({ id: ETranslations.AUTH_SYSTEM_NAME })}</p>
      </header>
      <Card>
        <Card.Header
          controls={config.hasMultiLanguage && <LocaleControls />}
          title={formatMessage({
            id: shouldConfirmPassword
              ? ETranslations.AUTH_SYSTEM_CONFIRM
              : ETranslations.AUTH_SYSTEM_ENTRY,
          })}
        />
        <Form className={styles.authForm} method="POST">
          {redirectTo && (
            <input type="hidden" name="redirectTo" value={redirectTo} />
          )}
          <input type="hidden" name="locale" value={locale} />
          <Input
            className={styles.labeled}
            inputClassName={styles.input}
            label={formatMessage({ id: ETranslations.AUTH_LOGIN })}
            name="login"
            type="text"
            autoFocus
            autoComplete="username"
            error={fieldErrors?.login}
            invalid={hasFormErrors}
          />
          <InputPassword
            className={styles.labeled}
            inputClassName={styles.input}
            label={formatMessage({
              id: shouldConfirmPassword
                ? ETranslations.AUTH_CREATE_PASSWORD
                : ETranslations.AUTH_PASSWORD,
            })}
            name="password"
            invalid={Boolean(fieldErrors?.confirmPassword) || hasFormErrors}
            autoComplete={
              shouldConfirmPassword ? "new-password" : "current-password"
            }
            error={fieldErrors?.password}
          />
          {shouldConfirmPassword && (
            <InputPassword
              className={styles.labeled}
              inputClassName={styles.input}
              label={formatMessage({ id: ETranslations.AUTH_CONFIRM_PASSWORD })}
              name="confirmPassword"
              invalid={hasFormErrors}
              autoComplete="new-password"
              error={fieldErrors?.confirmPassword}
            />
          )}

          <Button
            variant="primary"
            type="submit"
            name="intent"
            value={
              shouldConfirmPassword ? AUTH_INTENT.CONFIRM : AUTH_INTENT.ENTRY
            }
          >
            {formatMessage({ id: ETranslations.AUTH_SING_IN })}
          </Button>
          {formErrors && <FieldError error={formErrors} />}
        </Form>
      </Card>
    </main>
  );
};
