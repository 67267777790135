import { Option } from "../../../../models/common";
import { useHWLContext } from "common/components/HorizontalWindowLayout/hwl-context";
import dayjs from "dayjs";
import { timelineSelectors, useTimelineActions } from "features/Timeline";
import { useShiftTimesOptions } from "features/api/shifts";
import moment from "moment";
import { useEffect, useMemo, useRef } from "react";
import { useField } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

const useChoiceTimeInput = (
  allowValueFromOutsideRange: boolean,
  isFromManagement: boolean,
  disabled: boolean,
) => {
  const { input } = useField<Option<string> | null>("time", {
    allowNull: true,
  });
  const {
    input: { value: dateValue },
  } = useField<string | null>("date", { allowNull: true });
  const { value, onChange } = input;
  const intl = useIntl();
  const { options, isFetching } = useShiftTimesOptions(
    allowValueFromOutsideRange,
  );
  const startHour = useSelector(timelineSelectors.startHour);
  const timeStampValue = useSelector(timelineSelectors.getTimestamp);
  const { setIsActualTime, setTimestamp } = useTimelineActions();
  const initTimeRef = useRef(null);
  const { findByWindowName } = useHWLContext() || {};

  const {
    input: { value: initialVisitTime },
  } = useField<number>("initialVisitTime", { allowNull: true });

  const handleChange = (e: any) => {
    onChange(e);
    setTimestamp(e.value);
  };

  const inputOptions = useMemo(() => {
    if (isFromManagement) {
      //@ts-ignore
      const initTimeValue = dayjs(initTimeRef?.current?.label, "HH:mm");

      if (
        initialVisitTime &&
        typeof initialVisitTime === "number" &&
        initialVisitTime >= 0
      ) {
        // Вычисляем конечное время, добавляя initialVisitTime минут к initTimeValue
        const endTime = dayjs(initTimeValue, "HH:mm").add(
          initialVisitTime,
          "minutes",
        );
        return options?.filter(({ value: timeValue }) => {
          return (
            timeValue.valueOf() === initTimeValue.valueOf() ||
            (timeValue.valueOf() >= initTimeValue.valueOf() &&
              timeValue.valueOf() <= endTime.valueOf())
          );
        });
      } else {
        return options;
      }
    } else {
      return options;
    }
  }, [options, isFromManagement, initTimeRef, initialVisitTime]);

  // Хук для обратной совместимости
  // Изначально с бека приходит время в формате HH:mm строкой
  // В доступных опциях по шифту приходит абсолютное время.
  // При первом рендере меняем строку на таймстамп.
  useEffect(() => {
    if (typeof value?.value === "string" && dateValue) {
      const date = dayjs(dateValue);
      const time = dayjs(value.value, "HH:mm");
      const hour = time.hour();
      const minute = time.minute();
      const timestamp = date.set("hour", hour).set("minute", minute).valueOf();
      onChange({ ...value, value: timestamp });
    }
  }, [value]);

  useMount(() => {
    try {
      const window = findByWindowName("reg");
      if (!window.active) return;
    } catch {
      //
    }
  });

  useEffect(() => {
    if (value?.value) {
      const { value: timestampString } = value;
      const timestamp = Number(timestampString);
      setIsActualTime(false);
      if (!isFromManagement) {
        setTimestamp(timestamp);
      }
    }
  }, [value?.value, startHour]);

  useEffect(() => {
    if (!value && isFromManagement) {
      const label = moment(timeStampValue).format("HH:mm");
      //@ts-ignore
      initTimeRef.current = { label, value: timeStampValue };
      onChange({ label, value: timeStampValue });
      return;
    }
    if (disabled || allowValueFromOutsideRange || isFetching) return;
    const hasValue = options.some(
      (o) => Number(o.value) === Number(value?.value),
    );

    if (!value?.value || !hasValue) {
      const nextValue = options[0];
      options?.length && onChange(nextValue || null);
    }
  }, [options, disabled, allowValueFromOutsideRange, isFetching]);

  return {
    intl,
    handleChange,
    inputOptions,
    value,
  };
};

export default useChoiceTimeInput;
