import { restaurantSelector } from "features/AppContext";
import _ from "lodash";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { type GrantedUser, User } from "../models/user.model";
import { AuthService } from "../services/auth.service";

//FIXME: убрать полностью контекст, когда будут доделаны ручки с ролями/пользователями

interface AppUsersContext {
  authUser: User | undefined;
  userList: GrantedUser[];
  currentUser: GrantedUser | undefined;
}

const AppUsers = createContext<AppUsersContext>({
  authUser: undefined,
  userList: [],
  currentUser: undefined,
});

export function AppUsersProvider(props: PropsWithChildren<{}>) {
  const restaurant = useSelector(restaurantSelector);
  const [authUser, setAuthUser] = useState<User | undefined>(undefined);
  const [userList, setUserList] = useState<GrantedUser[]>([]);
  const [currentUser, setCurrentUser] = useState<GrantedUser | undefined>(
    undefined,
  );
  // load auth user
  /* useEffect(() => {
    if (authUserId) {
      const byId = UsersService.getById(authUserId);
      byId.then((resp) => {
        const user = resp.data;
        setAuthUser(user);
        setCurrentUser(mapToExtendedUser(user));
      });
    }
    return () => {
      setAuthUser(undefined);
      setCurrentUser(undefined);
    };
  }, [authUserId]);

  // load other users and auth user
  useEffect(() => {
    if (authUser && restaurant) {
      UsersService.getHostess(restaurant.restaurant_id).then((resp) => {
        const newArray = [authUser];
        setUserList(
          mapToList(_.uniqBy(newArray.concat(resp.data), (e) => e.id)),
        );
      });
    }
    return () => {
      setUserList([]);
    };
  }, [authUser, restaurant]); */

  return (
    <AppUsers.Provider {...props} value={{ authUser, userList, currentUser }} />
  );
}

export const useAppUsers = () => useContext(AppUsers);
