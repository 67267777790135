import styles from "../../Form.module.scss";
import DataField from "./DataField";
import InfinityField from "./InfinityField";
import { InputField } from "./InputField";
import SchemaField from "./SchemaField";
import ShiftsField from "./ShiftsField";
import TimeField from "./TimeField";
import { WeekDaysField } from "components/ManagerialTables/form/fields";
import { requiredField } from "components/registration/validators/validators";
import { required } from "components/shifts/utils";
import React from "react";
import { Field, useField } from "react-final-form";
import { useIntl } from "react-intl";
import { Shift } from "types/shift";
import { ETranslations } from "types/translates";
import { Collapse, Labeled } from "ui-kit";

export interface EachGroupFiledProps {
  eachRule: {
    nameOfRule: string;
    startDate: Date;
    endDate: Date;
    infininte: boolean;
    repeat_days: string[];
    startTime: { label: string; value: number };
    endTime: { label: string; value: number };
    shift: Shift[];
    schemas: unknown[];
    index: number;
  };

  isCreating: boolean;
}

const EachGroupFiled = ({ eachRule, isCreating }: EachGroupFiledProps) => {
  const currentFieldName = `settings.rules[${eachRule.index}].`;
  const {
    input: { value, onChange },
  } = useField<Array<unknown>>("settings.rules", { allowNull: true });

  const handleRemoveRule = () => {
    const newRules = value.filter((_, index) => index !== eachRule.index);
    onChange(newRules);
  };

  // @ts-ignore
  const isNotNew = !!eachRule?.access_group_id;

  const intl = useIntl();

  return (
    <div className={styles.eachRule}>
      <Collapse
        expendInHeader={false}
        header={
          <Labeled
            as="div"
            className={styles.bookingLabel}
            label={intl.formatMessage({ id: ETranslations.NAME_RULE })}
            required
          >
            <Field
              component={InputField}
              name={`${currentFieldName}rule_name`}
              placeholder={intl.formatMessage({ id: ETranslations.ENTER_NAME })}
              validate={(fieldValue) =>
                requiredField(
                  fieldValue,
                  intl.formatMessage({
                    id: ETranslations.ERROR_DATE_NOT_SPECIFIED,
                  }),
                )
              }
            />
          </Labeled>
        }
        headerClassName={styles.collapse}
        initialOpen={isCreating || !isNotNew ? true : false}
        autoHeight
      >
        <div className={styles.dateinfo}>
          <Labeled
            as="div"
            className={styles.bookingLabel}
            label={intl.formatMessage({ id: ETranslations.START_DATE })}
            required
          >
            <Field
              component={DataField}
              name={`${currentFieldName}start_date`}
              placeholderText={intl.formatMessage({
                id: ETranslations.START_DATE,
              })}
              validate={(fieldValue) =>
                requiredField(
                  fieldValue,
                  intl.formatMessage({
                    id: ETranslations.ERROR_DATE_NOT_SPECIFIED,
                  }),
                )
              }
            />
          </Labeled>
          <Labeled
            as="div"
            className={styles.bookingLabel}
            label={intl.formatMessage({ id: ETranslations.END_DATE })}
            required
          >
            <Field
              component={DataField}
              currentFieldName={currentFieldName}
              name={`${currentFieldName}end_date`}
              placeholderText={intl.formatMessage({
                id: ETranslations.END_DATE,
              })}
              validate={(fieldValue, formValues) => {
                //@ts-ignore
                if (formValues?.settings?.rules[eachRule?.index]?.infininte) {
                  return undefined;
                } else {
                  return required(fieldValue);
                }
              }}
            />
          </Labeled>
          <Field
            component={InfinityField}
            currentFieldName={currentFieldName}
            name={`${currentFieldName}infininte`}
          />
        </div>
        <div className={styles.schemaInfo}>
          <Labeled
            as="div"
            className={styles.bookingLabel}
            label={intl.formatMessage({
              id: ETranslations.DAYS_TO_CONTINUE_THIS_SHIFT,
            })}
            required
          >
            <Field
              component={WeekDaysField}
              name={`${currentFieldName}repeat_days`}
              validate={required}
            />
          </Labeled>
        </div>
        <div className={styles.timeInfo}>
          <Labeled
            as="div"
            className={styles.bookingLabel}
            label={intl.formatMessage({ id: ETranslations.START_TIME })}
            required
          >
            <Field
              component={TimeField}
              currentFieldName={currentFieldName}
              name={`${currentFieldName}start_active_time`}
              placeholder={intl.formatMessage({ id: ETranslations.START_TIME })}
              validate={(fieldValue) =>
                requiredField(
                  fieldValue,
                  intl.formatMessage({
                    id: ETranslations.ERROR_DATE_NOT_SPECIFIED,
                  }),
                )
              }
            />
          </Labeled>

          <Labeled
            as="div"
            className={styles.bookingLabel}
            label={intl.formatMessage({ id: ETranslations.END_TIME })}
            required
          >
            <Field
              component={TimeField}
              name={`${currentFieldName}end_active_time`}
              placeholder={intl.formatMessage({ id: ETranslations.END_TIME })}
              validate={(fieldValue) =>
                requiredField(
                  fieldValue,
                  intl.formatMessage({
                    id: ETranslations.ERROR_DATE_NOT_SPECIFIED,
                  }),
                )
              }
            />
          </Labeled>
        </div>
        <div className={styles.schemaInfo}>
          <Labeled
            as="div"
            className={styles.bookingLabel}
            label={intl.formatMessage({ id: ETranslations.SHIFT })}
            required
          >
            <Field
              component={ShiftsField}
              currentFieldName={currentFieldName}
              name={`${currentFieldName}shift_id`}
              validate={required}
            />
          </Labeled>
        </div>
        <div className={styles.schemaInfo}>
          <Labeled
            as="div"
            className={styles.bookingLabel}
            label={intl.formatMessage({ id: ETranslations.AVAILABLE_FOR_HALL })}
            required
          >
            <Field
              component={SchemaField}
              name={`${currentFieldName}places`}
              validate={required}
            />
          </Labeled>
        </div>
        <div className={styles.delete}>
          <p onClick={handleRemoveRule}>
            {intl.formatMessage({ id: ETranslations.BASE_DELETE })}
          </p>
        </div>
      </Collapse>
    </div>
  );
};

export default EachGroupFiled;
