import cn from "classnames";
import React, {
  type Key,
  type ReactNode,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";
import { useToggle } from "react-use";

import { Button, ICONS } from "..";
import styles from "./Collapse.module.scss";

interface CollapseProps {
  header: React.ReactNode;
  onClick?: () => void;
  headerClassName?: string;
  iconClassName?: string;
  initialOpen?: boolean;
  autoHeight?: boolean;
  expendInHeader?: boolean;
}

export const Collapse: React.FC<CollapseProps> = ({
  header,
  children,
  onClick,
  headerClassName,
  iconClassName,
  initialOpen,
  autoHeight,
  expendInHeader = true,
}) => {
  const [isActive, toggleIsActive] = useToggle(Boolean(initialOpen));

  const content = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    const { current: container } = content;
    if (!container) return;
    if (isActive) {
      container.style.height = "";
      const { scrollHeight } = container;
      container.style.height = autoHeight ? "auto" : `${scrollHeight}px`;
    } else {
      container.style.height = "0";
    }
  }, [children, isActive]);

  const toggle = useCallback(
    (e) => {
      e.stopPropagation();
      toggleIsActive();
    },
    [toggleIsActive],
  );

  return (
    <div className={styles.collapse}>
      <div
        className={cn(styles.header, headerClassName, {
          [styles.active]: isActive,
        })}
        onClick={expendInHeader ? onClick || toggle : () => {}}
      >
        {header}
        {children ? (
          <Button
            className={cn(styles.chevron__block, { [styles.rotate]: isActive })}
            variant="phantom"
            onClick={toggle}
          >
            <ICONS.ArrowDown className={cn(styles.icon, iconClassName)} />
          </Button>
        ) : null}
      </div>
      <div
        className={cn(styles["collapse-body"])}
        ref={content}
        style={{ height: "0" }}
      >
        {children}
      </div>
    </div>
  );
};

export const ControlledCollapse = ({
  title,
  className,
  addon,
  children,
  onChange,
  isOpen,
}: {
  title: string;
  className?: string;
  addon?: ReactNode;
  children: ReactNode;
  onChange: (value: boolean) => void;
  isOpen: boolean;
}) => {
  return (
    <>
      <label className={cn(styles.controlledCollapse, className)}>
        <input
          type="checkbox"
          hidden
          checked={isOpen}
          onChange={(e) => onChange(e.currentTarget.checked)}
        />
        <strong className={styles.title}>{title}</strong>
        <em>
          {addon}
          <ICONS.ArrowDown className={styles.arrow} />
        </em>
      </label>
      <section className={cn(styles.content, { [styles.open]: isOpen })}>
        {isOpen && children}
      </section>
    </>
  );
};

const DetailsCollapse = forwardRef<
  HTMLDetailsElement,
  {
    className?: string;
    titleClassName?: string;
    arrowClassName?: string;
    contentClassName?: string;
    title: ReactNode;
    addon?: ReactNode;
    children: ReactNode;
    forceOpen?: boolean;
    icon?: ReactNode;
  }
>(
  (
    {
      className,
      titleClassName,
      arrowClassName,
      contentClassName,
      title,
      addon,
      children,
      forceOpen,
      icon,
    },
    ref,
  ) => {
    return (
      <>
        <details ref={ref} open={forceOpen} className={styles.detailsCollapse}>
          <summary className={className}>
            <dfn role="term" className={cn(styles.title, titleClassName)}>
              {title}
            </dfn>
            {addon}
            {icon ?? (
              <ICONS.ArrowDown className={cn(styles.arrow, arrowClassName)} />
            )}
          </summary>
        </details>
        <section
          role="definition"
          className={cn(styles.content, contentClassName)}
        >
          {children}
        </section>
      </>
    );
  },
);
DetailsCollapse.displayName = "DetailsCollapse";
export { DetailsCollapse };

export type CollapsesOption<T extends {}> = {
  title: string;
  collapseData: T[];
  addon?: ReactNode;
};

type CollapsesRootProps<
  T extends {},
  CData extends string,
  COther extends string,
  COption = {
    [key in COther | CData]: key extends CData ? T[] : string | number | null;
  },
> = {
  className?: string;
  isExpandedAll?: boolean;
  children: (data: T) => ReactNode;
  collapseClassName?: string;
  arrowClassName?: string;
} & (
  | {
      withCustomOptions: true;
      collapseDataCustomProperty: CData;
      options: COption[];
      getKey: (option: COption) => Key | null | undefined;
      getTitle: (option: COption) => ReactNode;
      getAddon?: (option: COption) => ReactNode;
    }
  | {
      withCustomOptions?: false;
      collapseDataCustomProperty?: never;
      options: CollapsesOption<T>[];
      getKey?: never;
      getTitle?: never;
      getAddon?: never;
    }
);

const List = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => <ul className={cn(styles.list, className)}>{children}</ul>;

const Item = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => <li className={cn(styles.item, className)}>{children}</li>;

const CollapsesRoot = <
  T extends {},
  CData extends string,
  COther extends string,
>({
  className,
  withCustomOptions,
  collapseDataCustomProperty,
  options,
  isExpandedAll,
  collapseClassName,
  arrowClassName,
  children,
  getKey,
  getTitle,
  getAddon,
}: CollapsesRootProps<T, COther, CData>) => (
  <ul className={cn(styles.collapses, className)}>
    {/* Если в опции есть какие-то данные, то создаем коллапс */}
    {/* Если нужно использовать кастомные варианты свойств title, options и addon*/}
    {withCustomOptions
      ? options.reduce<JSX.Element[]>((result, option) => {
          (option[collapseDataCustomProperty] as T[])?.length &&
            result.push(
              <li key={getKey(option)}>
                <DetailsCollapse
                  className={collapseClassName}
                  arrowClassName={arrowClassName}
                  title={getTitle(option)}
                  addon={getAddon?.(option)}
                  forceOpen={isExpandedAll}
                >
                  <List>
                    {/* Чем конкретно наполнить каждый элемент коллапса решаем через children */}
                    {(option[collapseDataCustomProperty] as T[]).map(children)}
                  </List>
                </DetailsCollapse>
              </li>,
            );
          return result;
        }, [])
      : // Если используем стандартные свойства title, options и addon
        options.reduce<JSX.Element[]>((result, option) => {
          option.collapseData?.length &&
            result.push(
              <li key={option.title}>
                <DetailsCollapse
                  className={collapseClassName}
                  arrowClassName={arrowClassName}
                  title={option.title}
                  addon={option.addon}
                  forceOpen={isExpandedAll}
                >
                  <List>
                    {/* Чем конкретно наполнить каждый элемент коллапса решаем через children */}
                    {option.collapseData.map(children)}
                  </List>
                </DetailsCollapse>
              </li>,
            );
          return result;
        }, [])}
  </ul>
);

export const Collapses = {
  Root: CollapsesRoot,
  List,
  Item,
};
