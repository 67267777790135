import { MAX_STATUS_NAME_LENGTH, editStatusFormInputNames } from "../utils";
import { useEditStatusForm } from "./useEditStatusForm";
import cn from "classnames";
import type { ReactNode } from "react";
import type { IntlFormatters } from "react-intl";
import type { EditStatusRequest, Locale, Status } from "types/status";

export const EditStatusForm = ({
  formName,
  onValidSubmit,
  statuses,
  currentStatus,
  label,
  labelClassName,
  children,
  formatMessage,
  locale,
  className,
}: {
  formName: string;
  onValidSubmit: (payload: EditStatusRequest) => void;
  statuses: Status[];
  currentStatus: Status;
  label: string;
  className?: string;
  labelClassName?: string;
  formatMessage: IntlFormatters["formatMessage"];
  locale: Locale;
  children: ReactNode;
}) => {
  const { errors, handleStatusNameChange, handleEditStatusSubmit } =
    useEditStatusForm(
      statuses,
      currentStatus,
      onValidSubmit,
      formatMessage,
      locale,
    );
  return (
    <form
      className={cn("content custom-scroll", className)}
      id={formName}
      onSubmit={handleEditStatusSubmit}
    >
      <label className={cn("status-labeled", labelClassName)}>
        {label}
        <input
          autoComplete="off"
          className={cn({ invalid: errors?.["status-name"] })}
          defaultValue={currentStatus.name}
          maxLength={MAX_STATUS_NAME_LENGTH}
          name={editStatusFormInputNames.statusName}
          required
          onChange={handleStatusNameChange}
        />
        {errors?.["status-name"] && (
          <span className="error">{errors["status-name"]}</span>
        )}
      </label>
      {children}
      {errors?.color && <span className="error">{errors.color}</span>}
    </form>
  );
};
