import { capitalizeString, declOfNum } from "../../utils";
import type { OptionSelectType } from "./SelectBasic";
import type { CSSObject } from "@emotion/react";
import { ReactNode } from "react";
import type { StylesConfig } from "react-select";

export const getTitle = (
  values: Array<any>,
  optionsLength: number,
  placeholder: ReactNode,
  entities: Array<string>,
  allTitle: string,
  isFromManagement: boolean,
): ReactNode => {
  const selectedCount = values.length;
  const copyEntities = [...entities];
  const entityNom = copyEntities.pop();

  if (selectedCount === 0) {
    return placeholder;
  }

  if (selectedCount === 1) {
    return values[0]?.label || placeholder;
  }
  if (selectedCount === optionsLength && !isFromManagement) {
    return capitalizeString(`${allTitle} ${entityNom}`.toLowerCase());
  }
  return `${selectedCount} ${declOfNum(selectedCount, copyEntities)}`;
};

export type SelectStyles = Partial<
  Record<keyof StylesConfig<OptionSelectType, false>, CSSObject>
>;
