import ManagerialModalError from "components/ManagerialTables/form/ManagerialModalError";
import { ConfirmOverbookingModal } from "components/modals/ConfirmOverbookingModal";
import BookingFormReduxProxy from "components/registration/forms/BookingFormReduxProxy";
import { useApplicationContextActions } from "features/AppContext";
import { useFromProxyActions } from "features/BookingFormProxy";
import type { FormBooking } from "features/BookingFormProxy/types";

import { useTimelineActions } from "features/Timeline";
import {
  useSetStatusMutation,
  useUpdateBookingFromFormHandler,
} from "features/api/bookings-api";
import {
  useCreateClientMutation,
  useLazyFetchClientQuery,
  useUpdateClientMutation,
} from "features/api/client-api";
import { useBooleanState } from "hooks/useBooleanState";
import { useCallback, useEffect, useState } from "react";
import type { ErrorResponse } from "types/commons";
import { getBookingStartTime } from "utils";

import { useBookingDetailsContext } from "../context";

export const BookingInfo = () => {
  const { booking, openGuestList } = useBookingDetailsContext();
  const [hasManagerialError, setHasManagerialError] = useState<boolean>(false);
  const { updateBookingHandler } = useUpdateBookingFromFormHandler();
  const [setStatus] = useSetStatusMutation();
  const { setOnlyBooking } = useFromProxyActions();
  const [createGuest] = useCreateClientMutation();
  const [overbookingData, setOverbookingData] = useState<
    FormBooking | undefined
  >();
  const { setPlaceFromBooking } = useApplicationContextActions();

  const { setTime } = useTimelineActions();
  useEffect(() => {
    setTime(getBookingStartTime(booking.date, booking.time));
    setPlaceFromBooking(booking);
    setOnlyBooking(booking);
  }, [booking?.slot_id]);
  const [isUpdating, start, stop] = useBooleanState();
  const [updateGuest] = useUpdateClientMutation();
  const [getClient] = useLazyFetchClientQuery();
  const closeModal = () => setHasManagerialError(false);

  const update = useCallback(
    async (data) => {
      start();
      let { client } = data;
      try {
        const { client_id, phone, email } = data;

        if (client) {
          const {
            editClient = false,
            name,
            surname = "",
            middle_name = "",
          } = client;
          if (!client.client_id) {
            const {
              data: { client_id: newClientId },
            } = await createGuest({
              middle_name,
              name,
              phone,
              surname,
              email,
            }).unwrap();
            client = await getClient(newClientId).unwrap();
          }
          if (editClient && client_id) {
            getClient(client_id)
              .then(async (response) => {
                const newUser = {
                  ...response.data,
                  surname,
                  name,
                  middle_name,
                  type_authorization: "AUTH",
                };
                return newUser;
              })
              .then(async (newUser) => {
                //@ts-ignore
                await updateGuest(newUser);
              });
          }
        }
        await updateBookingHandler({
          ...data,
          contact: data.contact,
          client,
        }).unwrap();
      } catch (e) {
        const errorData = (e as ErrorResponse["error"])?.data;
        errorData?.errorCode === 10100 && setOverbookingData(data);
        errorData?.errorCode === 10400 && setHasManagerialError(true);
        throw e;
      } finally {
        stop();
      }
    },
    [updateBookingHandler, setOverbookingData],
  );

  const clearData = useCallback(
    () => setOverbookingData(undefined),
    [setOverbookingData],
  );

  const updateWithOverbooking = useCallback(async () => {
    if (!overbookingData) return;
    start();
    try {
      await updateBookingHandler({ ...overbookingData, force: true }).unwrap();
      clearData();
    } finally {
      stop();
    }
  }, [overbookingData, setStatus, updateBookingHandler]);
  return (
    <>
      <BookingFormReduxProxy
        booking={booking}
        isAnonym={booking?.client?.contact_type === "CONTACT"}
        isCreating={false}
        isUpdate={true}
        submitFn={update}
        openGuestList={openGuestList}
      />
      <ConfirmOverbookingModal
        disabled={isUpdating}
        isOpen={!!overbookingData}
        onConfirm={updateWithOverbooking}
        onDecline={clearData}
      />
      {hasManagerialError && (
        <ManagerialModalError
          isOpen={hasManagerialError}
          onClose={closeModal}
        />
      )}
    </>
  );
};
