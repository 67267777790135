import cn from "classnames";
import type { ReactNode } from "react";

import styles from "./Badge.module.scss";

const Badge = ({
  className,
  value,
}: {
  className?: string;
  value: string | number;
}) => <data className={cn(styles.badge, className)} value={value} />;

Badge.displayName = "Badge";

export { Badge };
