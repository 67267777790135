import { useFetchOrderClientQuery } from "../../features/api/booking-order-api";
import { IPayloadClientHistory, TModeHistory } from "./BookingClientHistory";
import { BookingHistoryItem } from "./BookingHistoryItem";
import styles from "./styles.module.scss";
import { Pagination } from "components/Pagination";
import { VFC } from "react";
import { Spinner } from "ui-kit";

interface Props {
  clientId: number | `${number}`;
  payload: IPayloadClientHistory;
  paginationHandler: (page: number) => void;
  mode: TModeHistory;
}

export const OrderClientHistoryList: VFC<Props> = ({
  clientId,
  payload,
  paginationHandler,
  mode,
}) => {
  const { data } = useFetchOrderClientQuery({ clientId, payload });

  if (!data) return <Spinner />;

  return (
    <>
      <section className={styles.list}>
        {data.content.map((el: any) => (
          <BookingHistoryItem booking={el} key={el.bookingId} mode={mode} />
        ))}
      </section>
      {!data.empty && (
        <Pagination
          className={styles.pagination}
          currentPage={data.pageable.pageNumber}
          pageSize={data.pageable.pageSize}
          total={data.totalElements}
          onChange={paginationHandler}
        />
      )}
    </>
  );
};
