import type { TableInfo } from "hooks/useTablesOptions";
import { SingleOption } from "models/common";
import moment, { Moment } from "moment";

export function buildTimeOptions({
  bookingIntervalInMinutes,
  minTime,
  workDurationInMinutes,
  globalCurrentDate,
  selectedDate,
  skipDateCheck = false,
}: {
  bookingIntervalInMinutes: number;
  minTime: string;
  globalCurrentDate: Moment;
  selectedDate: Moment;
  workDurationInMinutes: number;
  skipDateCheck?: boolean;
}) {
  // Время начала работы ресторана
  const hallStartTime = moment(minTime, "HH:mm:ss");
  let startScheduleOfDay = globalCurrentDate.clone().set({
    hour: hallStartTime.hour(),
    minute: hallStartTime.minute(),
    second: hallStartTime.second(),
  });
  // вычисляем время окончания работы ресторана
  const end = startScheduleOfDay
    .clone()
    .add(workDurationInMinutes, "minutes")
    .subtract(bookingIntervalInMinutes, "minutes");
  if (
    !skipDateCheck &&
    moment().isSame(selectedDate, "day") &&
    moment().isAfter(startScheduleOfDay, "minute")
  ) {
    // Если дата сегодняшняя и время уже после того как открыт ресторан находим следущий слот в 15 минут
    // и берем его за точку отсчета
    // В противном случае используем время начала
    startScheduleOfDay = moment().isAfter(startScheduleOfDay)
      ? moment()
      : startScheduleOfDay;
    const minutes = startScheduleOfDay.minutes();
    const after15Min = minutes % 15;
    startScheduleOfDay
      .subtract({ minutes: after15Min })
      .add({ minutes: 15 })
      .startOf("minute");
  }

  const options: SingleOption[] = [];

  while (startScheduleOfDay.isSameOrBefore(end)) {
    const label = startScheduleOfDay.format("HH:mm");
    options.push({ label, value: label as unknown as number });
    startScheduleOfDay.add({ minutes: 15 });
  }
  return options;
}

export const getAutoTable = (tablesInfo: TableInfo[], visitors: number) =>
  tablesInfo.reduce<{
    biggestTable: TableInfo | undefined;
    currentTable: TableInfo | undefined;
  }>(
    (result, table) => {
      // Если в списке столов находим свободный стол, максимальная капасити которого больше, чем у нас записано в результате,
      !table.busy &&
        table.maxCapacity > (result.biggestTable?.maxCapacity || 0) &&
        // то записываем этот стол в результат
        (result.biggestTable = table);

      // Если находим свободный стол который удовлетворяет всем условиям:
      // Его максимальная капасити позволяет посадить нужное количество персон
      !table.busy &&
        table.maxCapacity >= visitors &&
        // минимальная капасити позволяет посадить нужное количество персон
        table.minCapacity <= visitors &&
        // и в результате пока не записан стол
        (!result.currentTable ||
          //или записан стол менее идеальным пределом капасити
          table.maxCapacity < result.currentTable.maxCapacity) &&
        // то записываем этот стол в результат
        (result.currentTable = table);
      // возвращаем результат
      return result;
    },
    {
      biggestTable: undefined,
      currentTable: undefined,
    },
  );
