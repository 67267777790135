export enum SearchContext {
  TAGS = "TAGS",
  COMMENT = "COMMENT",
  SOURCE = "SOURCE",
  USER = "USER",
  CLIENT = "CLIENT",
  CLIENT_TAG = "CLIENT_TAG",
  CLIENT_EMAIL = "CLIENT_EMAIL",
}

export type SearchType = SearchContext;

export interface GlobalSearchParams {
  keyword: string;
  context: SearchType;
  page: number;
  size: number;
  restaurantId: number;
}
