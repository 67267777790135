import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { ETranslations } from "../../../types/translates";
import styles from "./style.module.scss";
import React from "react";
import { Button, Card, ICONS } from "ui-kit";

export interface ConfirmDialogModalProps {
  mode: any;
  data: any;
  handleClick: () => void;
  closeModal: () => void;
}

export default function ConfirmDialogModal(props: ConfirmDialogModalProps) {
  const { closeModal, handleClick } = props;
  const { intl, getIntlDeletingOf } = useIntlUtils();

  return (
    <div
      className={styles.container}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Card.Header
        controls={
          <ICONS.Cross
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        }
        title={getIntlDeletingOf(ETranslations.OF_USER)}
      />
      <Card.Content className={styles.content}>
        {intl.formatMessage({ id: ETranslations.CONFIRM_USER_DELETING })}
      </Card.Content>

      <div className={styles.buttonBlock}>
        <Button variant="dangerous" onClick={handleClick}>
          {intl.formatMessage({ id: ETranslations.BASE_DELETE })}
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
      </div>
    </div>
  );
}
