import dayjs from "dayjs";
import {
  dateSelector,
  useApplicationContextActions,
} from "features/AppContext";
import { timelineSelectors, useTimelineActions } from "features/Timeline";
import { useCurrentHallShiftsSchedule } from "features/api/hallschema-api";
import React, { FC, memo, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";
import { BottomBar } from "ui-kit/BottomBar/BottomBar";

import { TimelineInput } from "./TimelineInput";
import { TimelineOptions } from "./TimelineOptions";
import styles from "./timeline.module.scss";

const ShiftsTimelineComponent: FC = () => {
  const intl = useIntl();
  const { data, isFetching } = useCurrentHallShiftsSchedule();
  const { setTimestamp, setShift, setIsActualTime } = useTimelineActions();
  const { setDate } = useApplicationContextActions();
  const startHour = useSelector(timelineSelectors.startHour);
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const globalDate = useSelector(dateSelector);

  const getNow = () => {
    const now = dayjs.tz();
    const hours = Math.trunc(startHour);
    const minutes = (startHour % 1) * 60;
    const startWorkingDay = now
      .clone()
      .set("hour", hours)
      .set("minute", minutes);
    const dif = now.diff(startWorkingDay, "m", true);
    if (!globalDate.isSame(now, "day")) setDate(now.toISOString());
    setTimestamp(now.valueOf());
    setShift(dif);
    if (!isActualTime) setIsActualTime(true);
  };

  // Хук для автоматического тика времени при условии, что сейчас актуальное время
  useEffect(() => {
    if (isFetching || !isActualTime) return;
    if (isActualTime) {
      getNow();
    }
    const intervalId = setInterval(getNow, 5e3);
    return () => clearInterval(intervalId);
  }, [isActualTime, startHour]);

  if (!data || data.length === 0) return null;

  return (
    <BottomBar>
      <div className={styles.container}>
        <Button
          className={styles.button}
          type="button"
          variant="primary"
          onClick={getNow}
        >
          {intl.formatMessage({ id: ETranslations.BASE_NOW })}
        </Button>
        <div>
          <TimelineOptions />
          <TimelineInput isFetching={isFetching} />
        </div>
      </div>
    </BottomBar>
  );
};

export const ShiftsTimeline = memo(ShiftsTimelineComponent);
