import cn from "classnames";
import { ICONS } from "common/helpers";
import { ClientInfoPopup } from "components/BookingInfoPopup";
import type { SlotId } from "models/booking.model";
import {
  Children,
  ReactElement,
  cloneElement,
  isValidElement,
  memo,
} from "react";
import type { Booking, BookingOrder } from "types/booking";
import { Button } from "ui-kit";
import { stopPropagation } from "utils";

import styles from "./BookingCard.module.scss";
import { BookingClientInfo } from "./BookingClientInfo";
import { BookingHeader } from "./BookingHeader";
import { BookingVisitInfo } from "./BookingVisitInfo";
import { VisitProvider } from "./VisitContext";
import useBookingCardNew from "./hooks/useBookingCardNew";

export interface BookingCardViewProps {
  compact?: boolean;
  withDate?: boolean;
  showPhone?: boolean;
  showStatus?: boolean;
}

export interface BookingCardProps<T extends Booking | BookingOrder>
  extends BookingCardViewProps {
  booking: T;
  active?: boolean;
  onClick?: (booking: T) => void;
  className?: string;
  actions?:
    | ReactElement<{ className?: string }>
    | Array<ReactElement<{ className?: string }>>;
  canDrag?: boolean;
  isDashboard?: boolean;
}

const Card = <T extends Booking | BookingOrder>({
  booking,
  active,
  onClick,
  actions,
  className,
  compact,
  withDate,
  showPhone = true,
  showStatus = true,
  canDrag = true,
  isDashboard,
}: BookingCardProps<T>) => {
  const {
    handleClick,
    isExpired,
    isLate,
    label,
    statusClassName,
    statusClassNameManagement,
    phone,
    isManagerialTableBoolean,
    handleTouchStart,
    handleTouchEnd,
    handleContextMenuCapture,
    drag,
    dragPreview,
    isAnonym,
    isEndingSoon,
    isDragging,
  } = useBookingCardNew({ booking, onClick, canDrag });
  const isStatus = showStatus && "bookingId" in booking;
  const isPhone = Boolean(
    (showPhone && phone) ||
      ("bookingId" in booking && booking.tags.length) ||
      booking.source,
  );

  return (
    <>
      <span ref={dragPreview}></span>
      <div
        onMouseDown={handleTouchStart}
        onMouseLeave={handleTouchEnd}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchEnd}
        onContextMenuCapture={handleContextMenuCapture}
        ref={drag}
        aria-label={label}
        onClick={handleClick}
        className={cn(
          styles.bookingCard,
          styles[statusClassName],
          styles[statusClassNameManagement],
          className,
          {
            [styles.active]: active,
            [styles.interactive]: !!onClick,
            [styles.expired]: isExpired,
            [styles.late]: isLate,
            [styles.noStatusBorder]: !showStatus,
            [styles.dragging]: isDragging,
          },
        )}
      >
        <div className={styles.content}>
          <BookingHeader
            compact={compact}
            status={isStatus ? (booking as Booking).status : undefined}
            extraStatus={
              isStatus ? (booking as Booking).extra_status : undefined
            }
            bookingId={
              "bookingId" in booking ? (booking.bookingId as SlotId) : undefined
            }
            clientId={booking.client.client_id || undefined}
            phone={isPhone && phone}
            useDeposit={booking.useDeposit}
            isDashboard={isDashboard}
            isEndingSoon={isEndingSoon}
            tags={"tags" in booking ? booking.tags : undefined}
          >
            <BookingClientInfo
              client={booking.client}
              isManagerialBooking={
                "seatType" in booking && booking.seatType === "MANAGER_BOOK"
              }
            />
          </BookingHeader>
          <BookingVisitInfo
            isManagerialTableBoolean={isManagerialTableBoolean}
            bookingTime={booking.bookingTime.replace(/(\d+:\d+).*/, "$1")}
            places={booking.places}
            persons={booking.persons}
            isOverbooking={"bookingId" in booking && booking.isOverbooking}
            comment={booking.comment}
            compact={compact}
            date={withDate ? booking.bookingDate : undefined}
          />
        </div>
        <div className={styles.actions} onClick={stopPropagation}>
          {Children.map(actions, (el) => {
            if (isValidElement(el)) {
              return cloneElement(el, {
                className: cn(el.props.className, styles.action),
              });
            }
            return null;
          })}
          {booking?.client?.client_id && (
            <ClientInfoPopup
              clientId={booking.client.client_id}
              placement="auto"
            >
              <Button
                variant="phantom"
                type="button"
                className={styles.userInfo}
              >
                <ICONS.Question />
              </Button>
            </ClientInfoPopup>
          )}
        </div>
      </div>
    </>
  );
};

export const BookingCardNew = memo(
  (props: BookingCardProps<Booking | BookingOrder>) => {
    const booking = props.booking;
    return (
      <VisitProvider
        date={booking.bookingDate}
        time={booking.bookingTime}
        statusCategory={booking.status?.category}
        statusSystemName={booking.status?.system_name}
        isManagerialBooking={
          "seatType" in booking && booking.seatType === "MANAGEMENT"
        }
        visitDuration={booking.visitTime}
      >
        <Card {...props} />
      </VisitProvider>
    );
  },
) as typeof Card;
