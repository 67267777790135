import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { useCreateManagerialTableMutation } from "features/api/managerialTables";
import { useIntlUtils } from "hooks/useIntlUtils";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";

import { prepareData } from "../helpers/prepareData";

const useCreateManagerialBooking = (reset: any) => {
  const initialValues = prepareData();
  const { getIntlActionFailed, getIntlJoinedParts } = useIntlUtils();
  const [managerialError, setManagerialErrors] = useState([]);
  const { switchMode } = useHallSchemaActions();
  const location = useLocation() as Location<{ from: string } | null>;
  const navigate = useNavigate();
  const intl = useIntl();
  const [createManagerialBooking] = useCreateManagerialTableMutation();

  const close = useCallback(() => {
    const { from } = location.state || {};
    from !== location.pathname
      ? navigate(from || "/dashboard")
      : (reset(), switchMode(HallMode.BOOKING_GUEST));
  }, [history]);

  const onClose = () => {
    setManagerialErrors([]);
  };

  const onSubmit = useCallback(async (values) => {
    const {
      tables,
      place_id,
      time: { label },
      ...rest
    } = values;
    const payload = {
      ...rest,
      tables: [
        {
          place_id,
          table_ids: tables,
        },
      ],
      start_time: `${label}:00`,
    };

    await createManagerialBooking({
      ...payload,
    })
      .then((res) => {
        // @ts-ignore
        if (res.data.fail_messages.length && !!res.data.fail_count) {
          // @ts-ignore
          setManagerialErrors(() => [...res.data.fail_messages]);
        } else {
          close();
        }
      })
      .catch(() => {
        Notification.error({
          title: getIntlActionFailed(
            getIntlJoinedParts([
              ETranslations.BASE_CREATE,
              ETranslations.PLURAL_BOOKING,
            ]).toLowerCase(),
          ),
        });
      });
  }, []);

  return {
    initialValues,
    intl,
    onSubmit,
    onClose,
    close,
    managerialError,
  };
};

export default useCreateManagerialBooking;
