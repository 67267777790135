import useChoiceDataChips from "./hooks/useChoiceDataChips";
import styles from "./style.module.scss";
import cn from "classnames";
import { DataTab } from "components/DataTab";
import dayjs from "dayjs";
import React from "react";
import { FieldRenderProps } from "react-final-form";

type DateProps = FieldRenderProps<Date, any>;

const nextThreeDays = [
  dayjs().add(1, "day").format("YYYY-MM-DD"),
  dayjs().add(2, "day").format("YYYY-MM-DD"),
  dayjs().add(3, "day").format("YYYY-MM-DD"),
];

const ChoiceDateChips = ({
  value,
  onChange,
  disabled,
  isCreateBooking,
  isFromManagement,
}: any) => {
  const { datePickerHandler, dateField } = useChoiceDataChips({
    value,
    onChange,
    disabled,
  });

  return (
    <div
      className={cn(
        isCreateBooking && !isFromManagement
          ? styles.chipsWrapper
          : styles.chipsWrapperEdit,
      )}
    >
      {nextThreeDays.map((item, index) => {
        const isSameDay = dayjs(item).isSame(
          dayjs(dateField).format("YYYY-MM-DD"),
        );
        return (
          <DataTab
            datePickerHandler={datePickerHandler}
            disabled={disabled}
            index={index}
            isSameDay={isSameDay}
            item={item}
            key={index}
            styles={styles}
          />
        );
      })}
    </div>
  );
};

export const DataChips: React.FC<DateProps> = ({ input, meta, ...rest }) => (
  <ChoiceDateChips {...input} {...meta} {...rest} />
);
