/*
    ____,-------------------------------,____
    \   |        Интерфейс Socket       |   /
    /___|-------------------------------|___\
*/
import { DialogsAuth } from "./auth";
import { Subscription, delay } from "./utils";

export interface IDialogsSocketApi {
  state: "disconnected" | "connecting" | "connected";
  onConnect: (cb: () => void) => UnsubscribeCb;
  onDisconnect: (cb: () => void) => UnsubscribeCb;
  onMessage: (cb: (msg: unknown) => void) => UnsubscribeCb;
}

// ------------------------------------------

type UnsubscribeCb = () => void;

/*
    ____,-------------------------------,____
    \   |            Socket             |   /
    /___|-------------------------------|___\
*/

// TODO: реализовать нарастающий retry timeout
// - при переходе в режим navigator.online — сбросить retryCount
// - не забыть отписаться от событий online при cleanup()
const CONNECT_TIMEOUT_MS = 5 /* sec */ * 1000;
const RETRY_TIMEOUT_MS = 5 /* sec */ * 1000;
const HEARTBEAT_INTERVAL_MS = 10 /* sec */ * 1000;

export class DialogsSocket<T = unknown> implements IDialogsSocketApi {
  constructor(public auth: DialogsAuth) {}

  // --- Реализация IDialogsSocketApi

  state: "disconnected" | "connecting" | "connected" = "disconnected";

  private subsCon: Subscription<void> = new Subscription();
  private subsDis: Subscription<void> = new Subscription();
  private subsMsg: Subscription<T> = new Subscription();

  onConnect(cb: () => void): UnsubscribeCb {
    this.subsCon.subscribe(cb);
    return () => this.subsCon.unsubscribe(cb);
  }

  onDisconnect(cb: () => void): UnsubscribeCb {
    this.subsDis.subscribe(cb);
    return () => this.subsDis.unsubscribe(cb);
  }

  onMessage(cb: (msg: unknown) => void): UnsubscribeCb {
    this.subsMsg.subscribe(cb);
    return () => this.subsMsg.unsubscribe(cb);
  }

  // --- Управление сокетом

  private socket?: WebSocket;

  connect(): void {
    this.start();
  }

  disconnect(): void {
    if (this.state === "disconnected") return;

    if (this.socket) {
      this.socket.onclose = this.socket.onerror = this.socket.onmessage = null;
      this.socket.close();
    }

    this.toDisconnected();
  }

  cleanup(): void {
    this.subsCon.clear();
    this.subsDis.clear();
    this.subsMsg.clear();
    this.disconnect();
  }

  // -- Переходы состояний и инварианты

  private toConnecting() {
    this.lock = undefined;
    this.socket = undefined;
    this.state = "connecting";
  }

  private toConnected(socket: WebSocket) {
    this.lock = undefined;
    this.socket = socket;
    this.state = "connected";

    this.subsCon.publish();
  }

  private toDisconnected() {
    const prev = this.state;

    this.lock = undefined;
    this.socket = undefined;
    this.state = "disconnected";

    if (prev === "connected") this.subsDis.publish();
  }

  // -- Подключение и иницализация

  private lock?: unknown;

  private async start(): Promise<void> {
    if (this.lock || this.state === "connected") return;

    this.toConnecting();
    const lock = {};
    this.lock = lock;

    // Попытка в цикле открыть сокет, единственный способ прервать — сбросить lock,
    // в таком случае socket = null
    const socket = await (async (): Promise<WebSocket | null> => {
      let s: WebSocket | null = null;
      let attempt = 0;

      while (!s || s.readyState !== s.OPEN) {
        if (attempt > 0) await delay(RETRY_TIMEOUT_MS);
        if (lock !== this.lock) return null;

        attempt++;

        // токен
        let token: string;
        try {
          token = await this.auth.askToken();
        } catch {
          continue;
        }
        if (lock !== this.lock) return null;

        // сокет
        try {
          s = await this.createSocket(this.auth.baseUrl, token);
        } catch {
          continue;
        }
        if (lock !== this.lock) {
          s.close();
          return null;
        }
      }

      return s;
    })();

    if (!socket) {
      // просто предосторожность
      if (this.lock === lock) this.lock = undefined;
      return;
    }

    this.attachHandlers(socket);
    this.addHeartbeat(socket);
    this.toConnected(socket);
  }

  private createSocket(baseUrl: string, token: string): Promise<WebSocket> {
    const base = baseUrl
      .replace("https://", "wss://")
      .replace("http://", "ws://");

    return new Promise<WebSocket>((r) => {
      const socket = new WebSocket(`${base}/ws?access_token=${token}`);

      const handler = () => {
        clearTimeout(deadlineTimeout);
        socket.onopen = socket.onclose = socket.onerror = null;
        r(socket);
      };

      socket.onopen = socket.onclose = socket.onerror = handler;

      const deadlineTimeout = setTimeout(() => {
        // N.B. socket.close() при readyState = 0 вызовет сразу два события:
        // error и close, поэтому сначала отпишемся от обработчков
        socket.onopen = socket.onclose = socket.onerror = null;

        socket.close(1000, "Connection deadline exceeded");

        r(socket);
      }, CONNECT_TIMEOUT_MS);
    });
  }

  private attachHandlers(socket: WebSocket): void {
    // входящее сообщение
    socket.onmessage = (e: MessageEvent<string>) => {
      if (socket !== this.socket) {
        socket.close();
        return;
      }

      const msg = JSON.parse(e.data);
      this.subsMsg.publish(msg);
    };

    // ошибка соединения
    socket.onerror = () => {
      socket.onmessage = socket.onclose = socket.onerror = null;

      if (socket !== this.socket) return;

      // N.B. WebSocket API не предоставляет доступ к HTTP статусам ответов,
      // по соображениям безопасности, поэтому отловить ошибки авторизации
      // не получится

      // P.S. За error обычно сразу последует close, если сокет был OPEN,
      // но расчитывать на это не стоит

      this.toDisconnected();
      this.start();
    };

    // разрыв соединения
    socket.onclose = (e) => {
      socket.onmessage = socket.onclose = socket.onerror = null;

      if (socket !== this.socket) return;

      this.toDisconnected();

      // переподключение
      const NORMAL_CLOSE_CODE = 1000;
      if (e.code !== NORMAL_CLOSE_CODE) {
        this.start();
      }
    };
  }

  // -- Heartbeat

  private addHeartbeat(socket: WebSocket): void {
    const heartbeat = () => {
      if (
        !socket ||
        socket !== this.socket ||
        socket.readyState !== socket.OPEN
      ) {
        return;
      }

      socket.send("PING");
      schedule();
    };

    const schedule = () => setTimeout(heartbeat, HEARTBEAT_INTERVAL_MS);

    schedule();
  }
}
