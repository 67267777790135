import cn from "classnames";
import { DetailsBookingCard } from "components/BookingCard/DashboardBookingCard";
import ManagerialModalError from "components/ManagerialTables/form/ManagerialModalError";
import { ConfirmOverbookingModal } from "components/modals/ConfirmOverbookingModal";
import BookingFormReduxProxy from "components/registration/forms/BookingFormReduxProxy";
import type { SlotId } from "models/booking.model";
import { Spinner } from "ui-kit";

import styles from "./TableBookingList.module.scss";
import useEditBooking from "./hooks/useEditBooking";

interface EditBookingProps {
  bookingId: SlotId;
  hideCard?: boolean;
}

export const EditBooking = ({ bookingId, hideCard }: EditBookingProps) => {
  const {
    booking,
    update,
    overbookingData,
    clearData,
    updateWithOverbooking,
    hasManagerialError,
    closeModal,
    isUpdating,
  } = useEditBooking(bookingId);

  return (
    <div className={styles.edit}>
      {!hideCard && (
        <div className={cn(styles.sectionContent, styles.editCard)}>
          {booking ? <DetailsBookingCard booking={booking} /> : <Spinner />}
        </div>
      )}
      {booking && (
        <BookingFormReduxProxy
          booking={booking}
          isAnonym={booking?.client?.contact_type === "CONTACT"}
          isCreating={false}
          isUpdate={true}
          submitFn={update}
        />
      )}
      <ConfirmOverbookingModal
        disabled={isUpdating}
        isOpen={!!overbookingData}
        onConfirm={updateWithOverbooking}
        onDecline={clearData}
      />
      {hasManagerialError && (
        <ManagerialModalError
          isOpen={hasManagerialError}
          onClose={closeModal}
        />
      )}
    </div>
  );
};
