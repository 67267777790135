import { useFormActiveShift } from "./useFormActiveShift";
import { Option } from "models/common";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";

export function useMinutesVisitOptions() {
  const { time_interval: interval = 15 } = useFormActiveShift() || {};
  const intl = useIntl();
  return useMemo(() => {
    let cursor = 0;
    const maxMinutes = 60;
    const options: Option<number>[] = [];
    while (cursor < maxMinutes) {
      options.push({
        value: cursor,
        label: `${String(cursor).padStart(2, "0")} ${intl.formatMessage({ id: ETranslations.PLURAL_MINUTES })}`,
      });
      cursor += interval;
    }
    return options;
  }, [intl, interval]);
}
