import { Plugin, TemplateConnector } from "@devexpress/dx-react-core";
import { Plugins } from "@technolab/hse";
import { Selection } from "d3";
import { memo, useCallback, useEffect } from "react";

const { Core } = Plugins;

interface PreserveScaleProps {
  padding: number;
  svg: Selection<SVGSVGElement, unknown, null, unknown>;
}

const PreserveScale = memo(({ padding, svg }: PreserveScaleProps) => {
  const setViewport = useCallback(
    (svgEl: SVGSVGElement) => {
      // Get the bounds of the SVG content
      const bbox = svgEl.getBBox();
      // Set the viewport with these bounds
      svgEl.setAttribute(
        "viewBox",
        `${bbox.x - padding} ${bbox.y - padding} ${bbox.width + padding * 2} ${bbox.height + padding * 2}`,
      );
    },
    [padding],
  );
  const node = svg.node();

  useEffect(() => {
    if (node) {
      setViewport(node);
    }
  }, [node]);

  return null;
});

export const PreserveScalePlugin = memo(
  ({ padding }: Pick<PreserveScaleProps, "padding">) => {
    return (
      <Plugin name="PresserveScalePlugin">
        <TemplateConnector>
          {(getters) => {
            const { [Core.GETTERS.svg]: svg } = getters;
            return <PreserveScale padding={padding} svg={svg} />;
          }}
        </TemplateConnector>
      </Plugin>
    );
  },
);
