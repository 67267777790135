import { IResponse } from "../../models/common";
import { ETranslations } from "../../types/translates";
import { api } from "./api";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query";
import type { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import type {
  ClientNoteDTO,
  ClientNoteRequest,
  ClientNotesParams,
} from "models/notes.model";
import { Notification } from "services/notification";
import type { ErrorResponse } from "types/commons";

export type UpdateNote<M extends "create" | "edit"> = MutationTrigger<
  MutationDefinition<
    M extends "create" ? Omit<ClientNoteRequest, "id"> : ClientNoteRequest,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    "Bookings" | "Booking" | "BookingStatus" | "Timeline" | "Note" | "Notes",
    ClientNoteDTO,
    "coreApi"
  >
>;

export const notesApi = api
  .enhanceEndpoints({
    addTagTypes: ["Note", "Notes"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchClientNote: build.query<ClientNoteDTO, number | `${number}`>({
        query: (noteId) => ({
          url: `v2/client/notes/${noteId}`,
        }),
        transformResponse: (response: IResponse<ClientNoteDTO>) =>
          response.data,
        providesTags: (result, error, noteId) => [{ type: "Note", id: noteId }],
      }),
      fetchClientNotes: build.query<ClientNoteDTO[], ClientNotesParams>({
        query: (params) => ({
          url: "v2/client/notes",
          params,
        }),
        transformResponse: (response: IResponse<ClientNoteDTO[]>) =>
          response.data,
        providesTags: (result, error, params) => [
          { type: "Notes", id: `${params.restaurant_id}-${params.client_id}` },
        ],
      }),
      createOrEditNote: build.mutation<
        ClientNoteDTO,
        Omit<ClientNoteRequest, "id"> | ClientNoteRequest
      >({
        query: (body) => ({
          url: "v2/client/notes",
          method: "POST",
          body,
        }),
        transformResponse: (response: IResponse<ClientNoteDTO>) =>
          response.data,
        invalidatesTags: (result, error, body) => [
          { type: "Notes", id: `${body.restaurant_id}-${body.client_id}` },
        ],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (err) {
            const e = err as ErrorResponse;
            if (e?.error?.data?.errorCode === 10000) {
              Notification.error({
                title: e.error.data.errorMessage,
              });
            }

            if (e.status === "ERROR") {
              Notification.error({
                title: ETranslations.ERROR_GUEST_CREATION,
                message: e?.error?.message,
              });
            }
            throw e;
          }
        },
      }),
    }),
  });

const {
  useFetchClientNoteQuery,
  useFetchClientNotesQuery,
  useCreateOrEditNoteMutation,
} = notesApi;

const useUpdateNoteMutation = () => {
  const data = useCreateOrEditNoteMutation();
  return {
    createNote: data[0] as UpdateNote<"create">,
    editNote: data[0] as UpdateNote<"edit">,
    ...data[1],
  };
};

export {
  useFetchClientNoteQuery,
  useFetchClientNotesQuery,
  useUpdateNoteMutation,
};
