import * as select from "features/BookingFormProxy/selectors";
import { FormBooking } from "features/BookingFormProxy/types";
import { useFormState } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";

export function CancelBooking({
  openModal,
  type = "dangerous",
}: {
  openModal: () => void;
  type?: any;
}) {
  const intl = useIntl();
  const { submitting } = useFormState<FormBooking>();
  const isEdit = useSelector(select.isEdit);

  if (isEdit) return null;

  const cancelBooking = () => {
    openModal();
  };

  return (
    <Button disabled={submitting} variant={type} onClick={cancelBooking}>
      {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
    </Button>
  );
}
