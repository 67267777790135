import { SourceInput } from "components/SourceInput";
import type { SourceId } from "models/source.model";
import React from "react";
import { FieldRenderProps } from "react-final-form";

export const ChoiceSourceInput: React.FC<
  FieldRenderProps<{ id: SourceId; name: string } | undefined, any>
> = ({ input, meta, ...rest }) => (
  <SourceInput
    label={rest.label}
    placeholder={rest.placeholder}
    sourceName={input.value?.name}
    sourceId={input.value?.id}
    disabled={rest.disabled}
    onChange={(id, name) => input.onChange({ id, name })}
  />
);
