import { Deposit } from "../../../features/BookingFormProxy/types";
import { ETranslations } from "../../../types/translates";
import styles from "./Deposit.module.scss";
import useChoiceDeposit from "./hooks/useChoiceDeposit";
import cn from "classnames";
import Checkbox from "common/components/checkbox";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Input } from "ui-kit";

const ChoiceDeposit = ({ value, onChange, disabled, isNewDesign }: any) => {
  const {
    deposit_made,
    deposit_amount,
    checkboxText,
    handleUseDeposit,
    handleAmountChange,
    handleDepositMade,
    widthControlRef,
    use_deposit,
    intl,
  } = useChoiceDeposit(value, onChange, isNewDesign);

  return (
    <div
      className={cn("control-group", { [styles.newDeposit]: isNewDesign })}
      ref={widthControlRef}
    >
      <div className="left">
        <button
          className={`${use_deposit ? "active" : ""}`}
          disabled={disabled}
          type="button"
          onClick={() => handleUseDeposit(!use_deposit)}
        >
          {use_deposit
            ? intl.formatMessage({ id: ETranslations.WITH_DEPOSIT })
            : intl.formatMessage({ id: ETranslations.NO_DEPOSIT })}
        </button>
      </div>
      <div className={styles.deposit}>
        <Input
          className={cn(isNewDesign ? styles.inputNew : styles.input)}
          disabled={disabled || !use_deposit}
          maxLength={6}
          pattern="[0-9*]"
          placeholder={intl.formatMessage({ id: ETranslations.BASE_SUM })}
          size={1}
          suffix={<span className="suffix">₽</span>}
          type="text"
          value={deposit_amount}
          onChange={handleAmountChange}
        />
        <Checkbox
          disabled={disabled || !use_deposit}
          indent="deposit"
          text={checkboxText}
          value={deposit_made}
          onChange={handleDepositMade}
        />
      </div>
    </div>
  );
};

export const ChoiceDepositInput: React.FC<FieldRenderProps<Deposit, any>> = ({
  input,
  meta,
  ...rest
}) => <ChoiceDeposit {...input} {...meta} {...rest} />;
