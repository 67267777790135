import cn from "classnames";
import { formatMinutesDuration } from "common/helpers";
import { BookingActions } from "components/BookingActions";
import type { ManagerSlot } from "models/booking.model";
import moment from "moment";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { ICONS, Tag } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";

import { SlotCard } from "./SlotCard";
import styles from "./SlotCard.module.scss";

export const DashboardManagementCard = ({
  management,
}: {
  management: ManagerSlot;
}) => {
  const isTablet = useIsTablet();
  const { formatMessage } = useIntl();
  const isEndingSoon =
    moment(`${management.date} ${management.time}`, "YYYY-MM-DD HH:mm")
      .add(management.visit_duration, "minutes")
      .diff(moment(), "minutes") <= 20;

  return (
    <SlotCard.Root
      as="link"
      statusClassName={"management"}
      to={`management/${management.slot_id}/info`}
      actions={
        <BookingActions
          booking={management}
          editUrl={`management/${management.slot_id}/info?edit`}
        />
      }
    >
      <SlotCard.Content
        name={formatMessage({ id: ETranslations.MANAGEMENT_BOOKING })}
        vip={true}
        tags={management.tags}
        visibleTagsCount={isTablet ? 2 : 3}
      >
        <div className={styles.statusGroup}>
          <Tag className={cn(styles.statusTag, styles.managementTag)}>
            Manager
          </Tag>
          {isEndingSoon && <ICONS.ClockCircle />}
        </div>
      </SlotCard.Content>
      <SlotCard.AdditionalInfo
        time={management.time}
        visitDuration={formatMinutesDuration(management.visit_duration)}
        tables={management.slot_place.reduce(
          (result, place) => (result && `${result},\u00A0`) + place.table_name,
          "",
        )}
        hallName={management.slot_place[0].hall_name}
      />
    </SlotCard.Root>
  );
};

export const DetailsManagementCard = ({
  management,
  className,
}: {
  management: ManagerSlot;
  className?: string;
}) => {
  const { formatMessage } = useIntl();
  const isEndingSoon =
    moment(`${management.date} ${management.time}`, "YYYY-MM-DD HH:mm")
      .add(management.visit_duration, "minutes")
      .diff(moment(), "minutes") <= 20;

  return (
    <SlotCard.Root
      className={className}
      as="div"
      statusClassName={"management"}
      actions={<BookingActions booking={management} />}
    >
      <SlotCard.Content
        name={formatMessage({ id: ETranslations.MANAGEMENT_BOOKING })}
        vip={true}
        tags={management.tags}
      >
        <div className={styles.statusGroup}>
          <Tag className={cn(styles.statusTag, styles.managementTag)}>
            Manager
          </Tag>
          {isEndingSoon && <ICONS.ClockCircle />}
        </div>
      </SlotCard.Content>
      <SlotCard.AdditionalInfo
        time={management.time}
        visitDuration={formatMinutesDuration(management.visit_duration)}
        tables={management.slot_place.reduce(
          (result, place) => (result && `${result},\u00A0`) + place.table_name,
          "",
        )}
        hallName={management.slot_place[0].hall_name}
      />
    </SlotCard.Root>
  );
};
