import type { SpecialStatusColors } from "constants/colors";
import dayjs from "dayjs";
import type { BookingSlot, Slot } from "models/booking.model";
import type { Status } from "types/status";
import type { BookingTable } from "types/table";
import { getBookingStartTime } from "utils";

export const getActualStatusColor = (
  booking: BookingSlot,
  statusColors: Record<Status["system_name"], string> &
    typeof SpecialStatusColors,
) => {
  const minutesPassedFromBookingStart = Math.max(
    dayjs()
      .startOf("minute")
      .diff(getBookingStartTime(booking.date, booking.time), "minute"),
    0,
  );
  return minutesPassedFromBookingStart > 0 &&
    booking.status?.category === "PRE_SERVICE" &&
    booking.status.system_name !== "ARRIVED"
    ? statusColors.LATE
    : minutesPassedFromBookingStart - booking.visit_duration > 0
      ? statusColors.OVERSTAYED
      : statusColors[booking.status.system_name] || booking.status.color;
};

const getTimeLineStatusColor = (
  booking: BookingSlot,
  statusColors: Record<Status["system_name"], string> &
    typeof SpecialStatusColors,
  tableColor: string,
) => {
  switch (tableColor) {
    case "red":
      return statusColors.OVERSTAYED;
    case "yellow":
      return statusColors.LATE;
    case "light_grey":
      return booking.status.system_name === "CONFIRMED"
        ? statusColors.CONFIRMED
        : statusColors.NEW;
    case "green":
      return statusColors.IN_HALL;
    default:
      return statusColors[booking.status.system_name] || booking.status.color;
  }
};

export const getStatusColor = (
  booking: BookingSlot,
  statusColors: Record<Status["system_name"], string> &
    typeof SpecialStatusColors,
  isActualTime: boolean,
  tableColor: string,
) =>
  isActualTime
    ? getActualStatusColor(booking, statusColors)
    : getTimeLineStatusColor(booking, statusColors, tableColor);

export const isCanDrag = (
  booking: Slot | undefined,
  tableId: BookingTable["tableId"],
): boolean =>
  Boolean(
    booking?.slot_id &&
      booking.slot_place.some((place) => place.table_id === tableId),
  );
