import { useCallback, useEffect } from "react";
import { useLocalStorage } from "react-use";

export type ColorMode = "light" | "dark";

export function useColorMode() {
  const [mode, setMode] = useLocalStorage<ColorMode | undefined>("ui:mode");

  const setLight = useCallback(() => setMode("light"), []);

  const setDark = useCallback(() => setMode("dark"), []);

  useEffect(() => {
    if (!mode) return;
    document.documentElement.dataset.mode = mode;
  }, [mode]);

  return { mode, setLight, setDark };
}
