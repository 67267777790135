import { Template, TemplatePlaceholder } from "@devexpress/dx-react-core";
import { Plugins } from "@technolab/hse";
import { type HallSlotsDTO, TTable } from "features/api/hallschema-api";
import React, { FC } from "react";

import { TablePathSvg } from "./TablePathSvg";

interface Props {
  slots: HallSlotsDTO["slots"];
  places: Record<number, TTable>;
}

export const TablePathTemplate: FC<Props> = ({ slots, places }) => {
  return (
    <Template name={Plugins.Sector.TEMPLATES.root}>
      {slots.map((slot) => (
        <TablePathSvg key={slot.slot.slot_id} places={places} slots={[slot]} />
      ))}
      <TemplatePlaceholder />
    </Template>
  );
};
