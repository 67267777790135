import { LoaderFunction, Params, useLoaderData } from "react-router-dom";

import { TaskMessages } from "../Messages/Messages";
import styles from "./BookingChatWebView.module.scss";

export const loaderBookingChatWebView = (({ params }) => {
  const { clientId, bookingId } = params as Params;
  return { clientId, bookingId };
}) satisfies LoaderFunction;

export function BookingChatWebView() {
  const { clientId, bookingId } =
    useLoaderData<typeof loaderBookingChatWebView>();

  return (
    <div className={styles.chat}>
      <TaskMessages
        customerId={clientId ?? ""}
        taskId={`booking-${bookingId}`}
      />
    </div>
  );
}
