import { useIntlUtils } from "hooks/useIntlUtils";
import { useIntl } from "react-intl";
import { useRouteError } from "react-router-dom";
import { ETranslations } from "types/translates";
import { ICONS } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";

import styles from "./SlotDetails.module.scss";

export const EmptyDetails = () => {
  const { getIntlEntityViewingAction } = useIntlUtils();
  const isTablet = useIsTablet();
  return !isTablet ? (
    <section className={styles.noDetails}>
      <ICONS.Book width={220} height={220} />
      <p>
        {getIntlEntityViewingAction(
          ETranslations.OF_BOOKING,
          ETranslations.PLURAL_ALT_BOOKING,
        )}
      </p>
    </section>
  ) : null;
};

export const BookingSlotDetailsError = ({ message }: { message?: unknown }) => {
  const routeError = useRouteError();

  const errorMessage =
    routeError instanceof Error
      ? routeError.message
      : JSON.stringify(message || routeError);
  const { formatMessage } = useIntl();
  return (
    <section className={styles.noDetails}>
      <ICONS.ErrorWarning width={80} height={80} />
      <p>
        {formatMessage(
          { id: ETranslations.ERROR_ENTITY },
          {
            entity: formatMessage({
              id: ETranslations.PLURAL_BOOKING,
            }).toLowerCase(),
            error:
              errorMessage in ETranslations
                ? formatMessage({ id: errorMessage })
                : errorMessage,
          },
        )}
      </p>
    </section>
  );
};

export const ManagementSlotDetailsError = ({
  message,
}: {
  message?: unknown;
}) => {
  const routeError = useRouteError();

  const errorMessage =
    routeError instanceof Error
      ? routeError.message
      : JSON.stringify(message || routeError);
  const { formatMessage } = useIntl();
  return (
    <section className={styles.noDetails}>
      <ICONS.ErrorWarning width={80} height={80} />
      <p>
        {formatMessage(
          { id: ETranslations.ERROR_ENTITY },
          {
            entity: formatMessage({
              id: ETranslations.MANAGEMENT_RESERVATION,
            }).toLowerCase(),
            error:
              errorMessage in ETranslations
                ? formatMessage({ id: errorMessage })
                : errorMessage,
          },
        )}
      </p>
    </section>
  );
};
