import styles from "./BookingTags.module.scss";
import type { TagsShortView } from "./utils";
import { Tag } from "components/Tag";

export const BookingTags = ({ tags }: { tags: TagsShortView }) => (
  <span className="tags">
    {tags.visibleTags.map((t) => (
      <Tag key={t.id} tag={t} />
    ))}
    {tags.invisibleTagsCount > 0 && (
      <Tag
        className={styles.tagCount}
        key={"tag-count"}
        tag={{
          name: `+${tags.invisibleTagsCount}`,
        }}
        title={tags.invisibleTagsNames}
      />
    )}
  </span>
);
