import React, { FC } from "react";

import styles from "./Widget.module.scss";
import CodeBlock from "./components/CodeBlock";

type Props = {
  widgetKey: string;
};

const Widget: FC<Props> = ({ widgetKey }) => {
  return (
    <div className={styles.widget}>
      <p className={styles.title}>API</p>
      <CodeBlock
        title="Подключение скрипта в секцию head"
        code='<script src="https://stage-webtask.prime.travel/restaurantWidget.js" defer>'
      />
      <CodeBlock
        title="Добавление блока для виджета в секцию body"
        code={`<div id="restaurantWidget" data-showcaseKey="${widgetKey}" data-target="${window.location.origin}"></div>`}
      />
    </div>
  );
};

export default Widget;
