import { z } from "zod";

export const notificationTypes = [
  "ORDER",
  "CREATE",
  "CANCEL",
  "CONFIRMED",
  "CHANGED",
] as const;

export const NotificationType = z.enum(notificationTypes);
export type NotificationType = z.infer<typeof NotificationType>;

const NotificationSetting = z.object({
  type: NotificationType,
  message: z.string(),
  subject: z.string(),
  enabled: z.boolean(),
});
export type NotificationSetting = z.infer<typeof NotificationSetting>;

const NotificationVariables = z.record(z.string(), z.string());

export const NotificationDTO = z.object({
  settings: NotificationSetting.array(),
  variables: NotificationVariables,
});
export type NotificationDTO = z.infer<typeof NotificationDTO>;
