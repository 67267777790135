import { StepContainer } from "containers/CreateBookingForm/ui/FormComponents";
import { Tag } from "models/tags.model";
import { useState } from "react";

import { FauxTagsInput } from "./FauxTagsInput/FauxTagsInput";
import { TagsModal } from "./TagsModal/TagsModal";

export const OtherStep = () => {
  const [tagsOpened, setTagsOpened] = useState(false);
  const [tags, setTags] = useState<Tag[]>([]);

  return (
    <StepContainer>
      <form>
        <FauxTagsInput
          tags={tags}
          onClick={() => setTagsOpened(true)}
          onChange={(value) => setTags(value)}
        />
      </form>

      {tagsOpened && (
        <TagsModal
          initialValue={tags}
          onClose={() => setTagsOpened(false)}
          onChange={(value) => {
            setTagsOpened(false);
            setTags(value);
          }}
        />
      )}
    </StepContainer>
  );
};
