import dayjs from "dayjs";
import { useEffect } from "react";

import { useCreateBookingFormContext } from "../../context/CreateBookingContext";
import { CreateBookingFormFooter } from "../CreateBookingFormLayout";
import { StepContainer } from "../FormComponents";
import styles from "./BookingDateStep.module.scss";
import { SmallCalendar } from "./SmallCalendar";

export const BookingDateStep = () => {
  const disabledDaysSet = new Set([
    "2024-10-11",
    "2024-10-14",
    "2024-10-15",
    "2024-10-16",
    "2024-10-22",
    "2024-10-25",
    "2024-11-05",
    "2024-11-11",
    "2024-11-18",
    "2024-12-01",
    "2024-12-10",
    "2024-12-25",
  ]);

  const { formValues, updateValues, updateValidationState } =
    useCreateBookingFormContext();

  // Если дефолтно выбранная дата входит в список запрещенных дат, то поменять статус валидации на false
  useEffect(() => {
    disabledDaysSet.has(formValues.bookingDate) &&
      updateValidationState({ step1: false });
  }, []);

  return (
    <>
      <StepContainer>
        <form
          className={styles.form}
          onChange={(e) => {
            const bookingDate = new FormData(e.currentTarget).get(
              "bookingDate",
            );
            if (bookingDate) {
              updateValues({ bookingDate: String(bookingDate) });
              updateValidationState({
                step1: !disabledDaysSet.has(String(bookingDate)),
              });
            }
          }}
        >
          <SmallCalendar
            name="bookingDate"
            defaultIsoDate={
              formValues.bookingDate || dayjs.tz().format("YYYY-MM-DD")
            }
            disabledDaysSet={disabledDaysSet}
          />
        </form>
      </StepContainer>
      {!disabledDaysSet.has(formValues.bookingDate) && (
        <CreateBookingFormFooter currentStep={1} />
      )}
    </>
  );
};
