import type { ExtraStatus } from "types/status";
import { Tag } from "ui-kit";

export const ExtraStatusTag = ({
  extraStatus,
  className,
}: {
  extraStatus: ExtraStatus;
  className: string;
}) => (
  <Tag className={className} color={extraStatus.color || "gray"}>
    {extraStatus.name}
  </Tag>
);
