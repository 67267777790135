import { useCallback, useState } from "react";

/**
 * Hook for easy manage boolean state.
 *
 * @export
 * @param {boolean} [defaultValue=false]
 * @returns {[boolean, () => void, () => void]}[currentState, setTrue, setFalse] - order is easy to remember (as for ternary operator)
 */
export function useBooleanState(
  defaultValue = false,
): [boolean, () => void, () => void] {
  const [value, setValue] = useState(defaultValue);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);

  return [value, setTrue, setFalse];
}
