import { ShortUserDetail } from "components/GuestInfo";
import { HTMLAttributes, forwardRef } from "react";

interface BookingClientDetailsProps extends HTMLAttributes<HTMLDivElement> {
  clientId: number | `${number}`;
}

export const ClientDetailsPopup = forwardRef(
  ({ clientId }: BookingClientDetailsProps) => {
    return <ShortUserDetail userId={clientId} />;
  },
);
