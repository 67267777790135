import { apiINT } from "./api-data";
import { AuthService } from "./auth.service";
import { RequestWrapper } from "./request-wrapper";
import { Response } from "models/common";
import {
  AppointRestaurant,
  Place,
  Restaurant,
  Schedule,
} from "models/restaurant.model";

export class RestaurantsService {
  static async getAll(): Promise<{ data: Restaurant[] }> {
    return RequestWrapper.fetch(`${apiINT}/v2/restaurants`).then(
      function (response) {
        return response.json();
      },
    );
  }

  static async change(restaurant: AppointRestaurant): Promise<Response<any>> {
    return RequestWrapper.fetch(`${apiINT}/restaurant`, {
      method: "PUT",
      body: JSON.stringify(restaurant),
    }).then((response) => response.json());
  }

  static async create(restaurant: AppointRestaurant): Promise<Response<any>> {
    return RequestWrapper.fetch(`${apiINT}/restaurant`, {
      method: "POST",
      body: JSON.stringify(restaurant),
    }).then((response) => response.json());
  }
}
