import cn from "classnames";
import type { ComponentType, ReactNode } from "react";
import { Link, NavLink } from "react-router-dom";



import { ICONS, Tabs } from "..";
import styles from "./NavLinkTabs.module.scss";
import { useCloseCard } from "./useCloseCard";


export type NavLinkTabsProps = {
  className?: string;
  links: {
    title: string;
    to: string;
    disabled?: boolean;
    Icon?: ComponentType;
    hideOtherLinksWhenActive?: boolean;
    hideWhenInactive?: boolean;
  }[];
  addons?: ReactNode;
  navigateOnClose?: string;
};

export const NavLinkTabs = ({
  className,
  links,
  addons,
  navigateOnClose,
}: NavLinkTabsProps) => {
  const close = useCloseCard();
  return (
    <header className={cn(styles.navTabs, className)}>
      <nav className={styles.tabsContainer}>
        {links.reduce(
          (result, link) => (
            !link.disabled &&
              result.push(
                <NavLink
                  key={link.to}
                  to={link.to}
                  className={({ isActive }) =>
                    cn(styles.tabTitle, {
                      [styles.active]: isActive,
                      [styles.disabled]: link.disabled,
                      [styles.hide]: link.hideWhenInactive && !isActive,
                      [styles.hideOtherLinks]: link.hideOtherLinksWhenActive && isActive,
                    })
                  }
                >
                  {link.Icon && <link.Icon />}
                  {link.title}
                </NavLink>,
              ),
            result
          ),
          new Array<JSX.Element>(),
        )}
      </nav>
      {addons}
      {navigateOnClose ? (
        <Link to={navigateOnClose} className={styles.addon}>
          <ICONS.Cross />
        </Link>
      ) : (
        close && (
          <Tabs.Addon>
            <ICONS.Cross className={styles.cross} onClick={close} />
          </Tabs.Addon>
        )
      )}
    </header>
  );
};