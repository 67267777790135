import { environmentConfig } from "./base";

/* eslint-disable no-underscore-dangle*/
declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    /**
     * Production config
     *
     * Значения определяются в `public/config.js`. Сам файл `public/config.js`
     * генерируется на этапе сборки на CI для конкретной среды.
     */
    __APP_CONFIG?: Partial<typeof environmentConfig>;
  }
}

export const config = new Proxy(environmentConfig, {
  get(target, prop: keyof typeof environmentConfig) {
    // Приоритеты:
    // 1) Production config
    if (window.__APP_CONFIG && prop in window.__APP_CONFIG) {
      return window.__APP_CONFIG[prop];
    }
    // 2) Environment config
    if (prop in target) {
      return target[prop];
    }
    // 3) Default value
    return undefined;
  },
});

export * from "./base";
