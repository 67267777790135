import React from "react";
import { FieldRenderProps, useField } from "react-final-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Button, Input } from "ui-kit";

const AddTagToGroup = ({ styles, onChange, value }: any) => {
  const intl = useIntl();
  const {
    input: { onChange: changeTags, value: tags },
  } = useField<string[]>("tags", { allowNull: true });

  const handleAddNewTag = () => {
    if (value) {
      changeTags([...tags, value]);
      onChange("");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 50) return;
    onChange(e.target.value);
  };

  return (
    <div className={styles.addNewTag}>
      <Input value={value} onChange={handleChange} />
      <Button type="button" variant="primary" onClick={handleAddNewTag}>
        {intl.formatMessage({ id: ETranslations.BASE_CREATE })}
      </Button>
    </div>
  );
};

export const AddTagToGroupRedux: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => <AddTagToGroup {...input} {...meta} {...rest} />;

export default AddTagToGroup;
