import { ETranslations } from "../../../types/translates";
import useChoiceNotesInput from "./hooks/useChoiceNotesInput";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Textarea } from "ui-kit";

export const ChoiceNotesInput: React.FC<FieldRenderProps<string, any>> = ({
  input,
  meta,
  needResize = false,
  ...rest
}) => {
  const { autoResize, textareaHeight, getIntlEnterEntity } =
    useChoiceNotesInput(needResize);

  return (
    <Textarea
      id="textarea-id"
      style={{ height: textareaHeight, minHeight: "50px" }}
      onInput={autoResize}
      {...input}
      {...rest}
      placeholder={getIntlEnterEntity(ETranslations.TEXT)}
    />
  );
};
