import "./horizontal-windows-layout.scss";
import {
  HorizontalWindowLayoutProvider,
  Window,
  useHWLContext,
} from "./hwl-context";
import React, { CSSProperties, useLayoutEffect, useState } from "react";

type Item = {
  name: string;
  render(window: Window, popup: () => void, close: () => void): React.ReactNode;
  columnName?: string;
  pinned?: boolean;
  active?: boolean;
  initialVisible?: boolean;
};

type Column = {
  name: string;
  style?: CSSProperties;
};

const HorizontalWindowsContainer: React.FC<any> = ({
  children,
  styles,
  name,
}) => (
  <div className="horizontal-windows-layout" data-html={name} style={styles}>
    {React.Children.map<React.ReactNode, React.ReactNode>(children, (child) => {
      if (React.isValidElement(child)) {
        // @ts-ignore
        return React.cloneElement(child, { style: { ...child.props.style } });
      }
      return null;
    })}
  </div>
);

export const HorizontalWindowsLayoutColumn: React.FC<Column> = ({
  children,
  name,
  style,
}) => {
  const { addColumn, getColumn } = useHWLContext();
  const column = getColumn(name) || addColumn(name);
  return (
    <div className="horizontal-windows-column" style={style}>
      {React.Children.map<React.ReactNode, React.ReactNode>(
        children,
        (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              // @ts-ignore
              style: { ...child.props.style },
              columnName: column.name,
            });
          }
          return null;
        },
      )}
    </div>
  );
};

export const HorizontalWindowsLayoutItem = ({
  render,
  name,
  pinned,
  active,
  columnName,
  initialVisible = false,
}: Item) => {
  const {
    isActiveWindow,
    addWindow,
    getWindow,
    popupWindow,
    closeWindow,
    removeWindow,
  } = useHWLContext();
  const window =
    getWindow(columnName!, name) ||
    addWindow(columnName!, name, active, pinned);
  const [visible, setVisible] = useState(initialVisible);
  // debugger;
  useLayoutEffect(() => {
    setVisible(isActiveWindow(window));
  });

  useLayoutEffect(
    () => () => {
      removeWindow(window);
    },
    [],
  );

  return (
    <div
      className="horizontal-windows-item"
      style={{ display: visible ? "block" : "none" }}
    >
      {render(
        window,
        () => popupWindow(window),
        () => closeWindow(window),
      )}
    </div>
  );
};

type HorizontalWindowsLayoutProps = {
  name?: string;
};

export const HorizontalWindowsLayout: React.FC<
  HorizontalWindowsLayoutProps & any
> = (props) => (
  <HorizontalWindowLayoutProvider>
    <HorizontalWindowsContainer {...props} />
  </HorizontalWindowLayoutProvider>
);
