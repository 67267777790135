import { DialogStatus, useCloseDialog, useDialog } from "features/Dialogs/SDK";
import { type ReactNode, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  type LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { useOutlet } from "react-router-dom";
import { Notification } from "services/notification";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";
import { Button, Card } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";

import { DialogsError } from "../DialogsError";
import { DialogMessages } from "../Messages/Messages";
import { TAB_KEY, type Tab, getActiveTab, isDialogTab } from "../constans";
import styles from "./Dialog.module.scss";
import { DialogTabs } from "./DialogTabs";
import { FeedsManagementProvider } from "./FeedsManagementContext";

export const loader = (({ params, request }) => {
  const { dialogId, record } = params as Params;
  const currentUrl = new URL(request.url);
  const isReservationDetails =
    currentUrl.pathname === `/dialogs/${dialogId}/edit/${record}`;
  const activeTab = getActiveTab(currentUrl.searchParams.get(TAB_KEY));

  return isDialogTab(activeTab) || isReservationDetails
    ? { dialogId, activeTab }
    : redirect(`/dialogs/${dialogId}`);
}) satisfies LoaderFunction;

const CloseDialogButton = ({ dialogId }: { dialogId: string | undefined }) => {
  const { close, done, loading, error } = useCloseDialog(dialogId || "");
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  useEffect(() => {
    done &&
      (Notification.success(
        formatMessage({
          id: ETranslations.SUCCESS_CHAT_COMPLETED,
        }),
      ),
      navigate("/dialogs"));
    error &&
      Notification.error(
        formatMessage({
          id: ETranslations.ERROR_CHAT_COMPLETED,
        }),
      );
  }, [done, error]);

  return !done ? (
    <Button variant="secondary" onClick={close} disabled={loading || !dialogId}>
      {formatMessage({ id: ETranslations.END_CHAT })}
    </Button>
  ) : null;
};

const DialogContent = ({
  dialogId,
  error,
  errorMessage,
}: {
  dialogId: string | undefined;
  error?: string;
  errorMessage: string;
}) => (
  <Card.Content className={styles.chatContent} noPadding>
    {error || !dialogId ? (
      <DialogsError message={errorMessage} />
    ) : (
      <DialogMessages dialogId={dialogId} />
    )}
  </Card.Content>
);

const DialogWrapper = ({
  children,
  closeDialog,
  activeTab,
  headerText,
  outlet,
}: {
  children: ReactNode;
  closeDialog: ReactNode;
  activeTab: Tab;
  headerText: string;
  outlet: JSX.Element;
}) => {
  const isTablet = useIsTablet();
  return isTablet ? (
    <DialogTabs activeTab={activeTab} closeDialog={closeDialog} outlet={outlet}>
      {children}
    </DialogTabs>
  ) : (
    <>
      <Card className={styles.chat}>
        <Card.Header title={headerText} controls={closeDialog} />
        {children}
      </Card>
      {outlet}
    </>
  );
};

export function Dialog() {
  const { activeTab, dialogId } = useLoaderData<typeof loader>();
  const { dialog, error, updating, loading } = useDialog(dialogId);
  const { formatMessage } = useIntl();
  const outletContext = useMemo(
    () => ({ isLoading: loading, dialogError: error }),
    [loading, error],
  );
  const outlet = useOutlet(outletContext);

  return (
    <DialogWrapper
      activeTab={activeTab}
      headerText={formatMessage({ id: ETranslations.CHAT })}
      closeDialog={
        dialog &&
        dialog.status !== DialogStatus.CLOSED && (
          <CloseDialogButton key={dialogId} dialogId={dialogId} />
        )
      }
      outlet={
        <FeedsManagementProvider
          key={dialogId}
          dialogId={dialogId}
          dialog={dialog}
          dialogUpdating={updating}
        >
          {outlet}
        </FeedsManagementProvider>
      }
    >
      <DialogContent
        dialogId={dialogId}
        error={error}
        errorMessage={formatMessage(
          { id: ETranslations.ERROR_ENTITY },
          {
            entity: formatMessage({ id: ETranslations.CHAT }).toLowerCase(),
            error: JSON.stringify(error || ""),
          },
        )}
      />
    </DialogWrapper>
  );
}
