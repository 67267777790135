import styles from "./EachAccessRule.module.scss";
import { CurrentRuleModal } from "components/shifts/Modal/AccessRulesModal/CurrentRuleModal";
import dayjs from "dayjs";
import { usAccessRulesActions } from "features/AccessRules/slice";
import { useUserLocale } from "hooks/useUserLocale";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { EDaysOfWeek } from "types/commons";
import { ETranslations } from "types/translates";
import { Calendar, Edit } from "ui-kit/ICONS/icons";

const EachRule = ({ access_rule, currentRule }: any) => {
  const {
    rule_name,
    start_active_time,
    end_active_time,
    start_date,
    end_date,
    repeat_days,
  } = currentRule;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { userLocale } = useUserLocale();
  const langauge = userLocale?.split("_")[0] || "";

  const intl = useIntl();
  const { setSelectedAccessRule, reset: resetSelectedAR } =
    usAccessRulesActions();
  const modalOnClose = useCallback(() => {
    setSelectedAccessRule(access_rule);
    setModalOpen((a) => {
      if (a) {
        resetSelectedAR();
      }
      return !a;
    });
  }, [access_rule]);

  const startDate = `${dayjs(start_date)
    .locale(langauge)
    .format("MMMM")
    .slice(0, 3)} ${dayjs(start_date).locale(langauge).format("D")}`;
  const endDate =
    dayjs(end_date).valueOf() >= 2208974400000
      ? intl.formatMessage({ id: ETranslations.INFINITY })
      : `${dayjs(end_date)
          .locale(langauge)
          .format("MMMM")
          .slice(0, 3)} ${dayjs(end_date).locale(langauge).format("D")}`;

  return (
    <tr className={styles.row}>
      <td className={styles.name}>
        {rule_name.length > 20 ? `${rule_name.slice(0, 20)}...` : rule_name}
      </td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Calendar />
          <span>
            {start_active_time} - {end_active_time}
          </span>
        </div>
      </td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Calendar />
          <span>
            {startDate} - {endDate}
          </span>
        </div>
      </td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Calendar />
          {/* @ts-ignore */}
          <span>
            {repeat_days
              .map((each: EDaysOfWeek) =>
                dayjs().isoWeekday(Number(EDaysOfWeek[each])).format("dd"),
              )
              .join(", ")}
          </span>
        </div>
      </td>
      <td className={styles.editIcon} onClick={modalOnClose}>
        <Edit />
      </td>

      <CurrentRuleModal
        currentRule={currentRule}
        isOpen={modalOpen}
        title={rule_name}
        onClose={modalOnClose}
      />
    </tr>
  );
};

export default EachRule;
