import cn from "classnames";
import type { ElementType } from "react";

import styles from "./Card.module.scss";

type ContentProps = JSX.IntrinsicElements["div"] & {
  noPadding?: boolean;
  as?: ElementType;
};

export const Content = ({
  children,
  className,
  noPadding,
  as,
  ...props
}: ContentProps) => {
  const Element = as || "div";
  return (
    <Element
      className={cn(styles.content, className, {
        [styles.noPadding]: noPadding,
      })}
      {...props}
    >
      {children}
    </Element>
  );
};
