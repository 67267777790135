import cn from "classnames";
import { restaurantSelector } from "features/AppContext";
import { FormApi } from "final-form";
import React, { FC } from "react";
import { Field } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Checkbox from "../../../../common/components/checkbox";
import { useIntlUtils } from "../../../../hooks/useIntlUtils";
import { TNullable } from "../../../../types/commons";
import { Shift } from "../../../../types/shift";
import { ETranslations } from "../../../../types/translates";
import { DatePicker, Input, Labeled, SelectBasic } from "../../../../ui-kit";
import { Modal } from "../../../modal";
import { ChoiceDateInput } from "../../../registration/sub-renders/date-time";
import { requiredField } from "../../../registration/validators/validators";
import {
  getBookingInterval,
  getHourOptions,
  getPlaceTitle,
  required,
} from "../../utils";
import styles from "../Form.module.scss";
import WeekDays from "../WeekDays/WeekDays";
import { indefiniteChangeHandler, placesChangeHandler } from "./utils";

type TProps = {
  form: FormApi<any, Partial<any>>;
  shift: TNullable<Partial<Shift>>;
};

const PropertiesFields: FC<TProps> = ({ form, shift }) => {
  const { getIntlSelectEntity } = useIntlUtils();
  const intl = useIntl();
  const restaurant = useSelector(restaurantSelector);

  return (
    <Modal.Content className={styles.modalContent}>
      <Labeled
        className={cn(styles.personsInput, styles.row, styles.halfRow)}
        label={intl.formatMessage({ id: ETranslations.SHIFT_NAME })}
        required
      >
        <Field
          autocomplete="off"
          name="shift_name"
          validate={required}
          required
        >
          {({ input, meta, ...rest }) => (
            <Input {...input} {...meta} {...rest} error={undefined}/>
          )}
        </Field>
      </Labeled>

      <div className={cn(styles.dateBlock, styles.row, styles.dateBlockFixed)}>
        <Labeled
          as={"div"}
          label={intl.formatMessage({ id: ETranslations.START_DATE })}
          required
        >
          <Field
            name="start_date"
            validate={(value) =>
              requiredField(
                value,
                getIntlSelectEntity(ETranslations.ERROR_DATE_NOT_SPECIFIED),
              )
            }
            required
          >
            {(props) => (
              <DatePicker
                minDate={null}
                selected={props.input.value}
                onChange={props.input.onChange}
              />
            )}
          </Field>
        </Labeled>

        <Labeled
          as={"div"}
          label={intl.formatMessage({ id: ETranslations.END_DATE })}
        >
          <Field component={ChoiceDateInput} name="end_date">
            {(props) => (
              <DatePicker
                minDate={form.getFieldState("start_date")?.value}
                selected={props.input.value}
                onChange={props.input.onChange}
              />
            )}
          </Field>
        </Labeled>

        <Field name="continue_indefinite" type="checkbox">
          {({ input }) => (
            <Checkbox
              className={styles.propertiesCheckbox}
              indent="confirmed"
              text={intl.formatMessage({
                id: ETranslations.CONTINUE_INDEFINITE,
              })}
              value={input.checked}
              useStopPropagation
              onChange={(value) =>
                indefiniteChangeHandler(value, input.onChange, form, shift)
              }
            />
          )}
        </Field>
      </div>

      <Labeled
        className={styles.row}
        label={intl.formatMessage({
          id: ETranslations.DAYS_TO_CONTINUE_THIS_SHIFT,
        })}
        as="div"
        required
      >
        <Field name="repeatDays" validate={required} required>
          {({ input }) => (
            <WeekDays weekDays={input.value} onChange={input.onChange} />
          )}
        </Field>
      </Labeled>

      <div className={cn(styles.dateBlock)}>
        <Field name="open_time">
          {({ input }) => (
            <Labeled
              className={styles.row}
              label={intl.formatMessage({ id: ETranslations.OPEN_TIME })}
            >
              <SelectBasic
                {...input}
                isDisabled={input.disable}
                options={getHourOptions()}
                changedMaxSize
                onChange={input.onChange}
              />
            </Labeled>
          )}
        </Field>

        <Field name="close_time">
          {({ input }) => (
            <Labeled
              className={styles.row}
              label={intl.formatMessage({ id: ETranslations.CLOSE_TIME })}
            >
              <SelectBasic
                {...input}
                isDisabled={input.disable}
                options={getHourOptions()}
                changedMaxSize
                onChange={input.onChange}
              />
            </Labeled>
          )}
        </Field>
      </div>

      <div className={cn(styles.dateBlock)}>
        <Field name="booking_interval">
          {({ input }) => {
            return (
              <Labeled
                className={styles.row}
                label={intl.formatMessage({ id: ETranslations.TIME_INTERVAL })}
              >
                <SelectBasic
                  {...input}
                  isDisabled={input.disable}
                  options={getBookingInterval()}
                  changedMaxSize
                  onChange={input.onChange}
                />
              </Labeled>
            );
          }}
        </Field>
      </div>

      <div className={cn(styles.dateBlock)}>
        <Labeled
          className={styles.row}
          label={intl.formatMessage({
            id: ETranslations.SEATING_AREAS_AVAILABLE,
          })}
        >
          <Field name={"places"} type="checkbox">
            {({ input }) => {
              const values = form?.getFieldState("places")?.value;
              return restaurant.places.map((place) => (
                <Checkbox
                  className={styles.seatCheckbox}
                  indent="confirmed"
                  key={place.id}
                  text={getPlaceTitle(place.id, restaurant)}
                  value={values?.includes(place.id)}
                  useStopPropagation
                  onChange={(value) =>
                    placesChangeHandler(value, values, place.id, input.onChange)
                  }
                />
              ));
            }}
          </Field>
        </Labeled>
      </div>
    </Modal.Content>
  );
};

export default PropertiesFields;
