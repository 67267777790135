import { ICONS } from "../../../../common/helpers";
import styles from "./Hall.module.scss";
import cn from "classnames";
import HallLegendModal from "components/modals/HallLegendModal";
import { useBooleanState } from "hooks/useBooleanState";
import { Button } from "ui-kit";

interface HallLegendProps {
  className?: string;
}

export const HallLegend = ({ className }: HallLegendProps) => {
  const [isModalOpen, open, close] = useBooleanState();
  return (
    <>
      <Button
        className={cn(styles.legend, className, {
          [styles.legend_active]: isModalOpen,
        })}
        variant="phantom"
        onClick={open}
      >
        <ICONS.LegendIcon />
      </Button>
      <HallLegendModal isOpen={isModalOpen} onClose={close} />
    </>
  );
};
