import cn from "classnames";
import { ReactNode } from "react";

import styles from "./TabAddon.module.scss";

export interface TabAddonProps {
  children: ReactNode;
  className?: string;
}

export function TabAddon({ children, className }: TabAddonProps) {
  return <div className={cn(styles.addon, className)}>{children}</div>;
}
