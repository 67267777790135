import { Outlet } from "react-router-dom";

import { ClientList } from "./ClientList";

const Clients = () => (
  <>
    <ClientList />
    <Outlet />
  </>
);

Clients.displayName = "Clients";

export { Clients };
