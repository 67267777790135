import cn from "classnames";
import { ICONS } from "common/helpers";
import { ClientInfoPopup } from "components/BookingInfoPopup";
import type { BookingSlot, Slot } from "models/booking.model";
import {
  Children,
  ReactElement,
  cloneElement,
  isValidElement,
  memo,
} from "react";
import { Button } from "ui-kit";
import { isBooking, isManagerialTable, stopPropagation } from "utils";

import styles from "./BookingCard.module.scss";
import { BookingClientInfo } from "./BookingClientInfo";
import { BookingHeader } from "./BookingHeader";
import { BookingVisitInfo } from "./BookingVisitInfo";
import { VisitProvider } from "./VisitContext";
import useBookingCard from "./hooks/useBookingCard";

export interface BookingCardViewProps {
  compact?: boolean;
  withDate?: boolean;
  showPhone?: boolean;
  showStatus?: boolean;
}

export interface BookingCardProps<T extends Slot> extends BookingCardViewProps {
  slot: T;
  active?: boolean;
  onClick?: (booking: T) => void;
  className?: string;
  actions?:
    | ReactElement<{ className?: string }>
    | Array<ReactElement<{ className?: string }>>;
  canDrag?: boolean;
  isDashboard?: boolean;
}

const Card = <T extends Slot>({
  slot,
  active,
  onClick,
  actions,
  className,
  compact,
  withDate,
  showPhone = true,
  showStatus = true,
  canDrag = true,
  isDashboard,
}: BookingCardProps<T>) => {
  const {
    handleClick,
    isExpired,
    isLate,
    label,
    statusClassName,
    statusClassNameManagement,
    phone,
    isManagerialTableBoolean,
    handleTouchStart,
    handleTouchEnd,
    handleContextMenuCapture,
    drag,
    dragPreview,
    isEndingSoon,
    isDragging,
  } = useBookingCard({ slot, onClick, canDrag });
  const isStatus = showStatus && isBooking(slot);
  const isPhone = Boolean(
    (showPhone && phone) ||
      (isBooking(slot) && slot.tags.length) ||
      (slot.slot_type === "BOOKING" && slot.source),
  );
  return (
    <>
      <span ref={dragPreview}></span>
      <div
        onMouseDown={handleTouchStart}
        onMouseLeave={handleTouchEnd}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchEnd}
        onContextMenuCapture={handleContextMenuCapture}
        ref={drag}
        aria-label={label}
        onClick={handleClick}
        className={cn(
          styles.bookingCard,
          styles[statusClassName],
          styles[statusClassNameManagement],
          className,
          {
            [styles.active]: active,
            [styles.interactive]: !!onClick,
            [styles.expired]: isExpired,
            [styles.late]: isLate,
            [styles.noStatusBorder]: !showStatus,
            [styles.dragging]: isDragging,
          },
        )}
      >
        <div className={styles.content}>
          <BookingHeader
            compact={compact}
            status={isStatus ? (slot as BookingSlot).status : undefined}
            extraStatus={
              isStatus ? (slot as BookingSlot).extra_status : undefined
            }
            phone={isPhone && phone}
            useDeposit={
              slot.slot_type === "BOOKING" && slot.slot_deposit.use_deposit
            }
            isDashboard={isDashboard}
            isEndingSoon={isEndingSoon}
            tags={isBooking(slot) ? slot.tags : undefined}
            bookingId={slot.slot_id}
            clientId={
              (slot as BookingSlot | undefined)?.client?.client_id || undefined
            }
          >
            <BookingClientInfo
              client={slot.slot_type === "BOOKING" && slot.client}
              isManagerialBooking={isManagerialTable(slot)}
            />
          </BookingHeader>
          <BookingVisitInfo
            isManagerialTableBoolean={isManagerialTableBoolean}
            bookingTime={slot.time.replace(/(\d+:\d+).*/, "$1")}
            places={slot.slot_place}
            persons={slot.slot_type === "BOOKING" && slot.visitors}
            isOverbooking={isBooking(slot) && slot.overbooking}
            comment={(slot as BookingSlot).comment}
            compact={compact}
            date={withDate ? slot.date : undefined}
          />
        </div>
        <div className={styles.actions} onClick={stopPropagation}>
          {Children.map(actions, (el) => {
            if (isValidElement(el)) {
              return cloneElement(el, {
                className: cn(el.props.className, styles.action),
              });
            }
            return null;
          })}
          {slot.slot_type === "BOOKING" &&
            slot.client.contact_type === "CLIENT" && (
              <ClientInfoPopup
                clientId={slot.client.client_id}
                placement="auto"
              >
                <Button
                  variant="phantom"
                  type="button"
                  className={styles.userInfo}
                >
                  <ICONS.Question />
                </Button>
              </ClientInfoPopup>
            )}
        </div>
      </div>
    </>
  );
};

export const BookingCard = memo((props: BookingCardProps<Slot>) => (
  <VisitProvider
    date={props.slot.date}
    time={props.slot.time}
    statusCategory={
      props.slot.slot_type === "BOOKING"
        ? props.slot.status?.category
        : undefined
    }
    statusSystemName={
      props.slot.slot_type === "BOOKING"
        ? props.slot.status?.system_name
        : undefined
    }
    isManagerialBooking={props.slot.slot_type === "MANAGER"}
    visitDuration={props.slot.visit_duration}
  >
    <Card {...props} />
  </VisitProvider>
)) as typeof Card;
