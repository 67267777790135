import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";
import { DataSection } from "ui-kit/DataSection/DataSection";

import styles from "./GuestColumn.module.scss";

const GuestInfoBigBlock = ({
  birthday,
  sex,
  //country,
  phone,
  workPhone,
  email,
  altEmail,
  //priorityContact,
  hasLoyaltyProgram,
  vip,
  loyaltyStatus,
  //loyaltyRegistrationDate,
  //source,
  //preferences,
  comment,
  placeholder,
}: {
  birthday: string | undefined | null;
  sex: string | undefined | null;
  //country: string | undefined | null;
  phone: string | undefined | null;
  workPhone: string | undefined | null;
  email: string | undefined | null;
  altEmail: string | undefined | null;
  //priorityContact: string | undefined | null;
  hasLoyaltyProgram: boolean;
  vip: string | undefined | null;
  loyaltyStatus: string | undefined | null;
  //loyaltyRegistrationDate: string | undefined | null;
  //source: string | undefined | null;
  //preferences: string | undefined | null;
  comment: string | undefined | null;
  placeholder: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.dataBlock}>
      <section className={styles.contentSection}>
        <h2>{formatMessage({ id: ETranslations.ABOUT_CLIENT })}</h2>
        <DataSection.Root>
          <DataSection.Item
            label={formatMessage(
              { id: ETranslations.DATE_OF_STRICT },
              {
                value: formatMessage({
                  id: ETranslations.OF_BIRTH,
                }).toLowerCase(),
              },
            )}
          >
            {birthday || placeholder}
          </DataSection.Item>
          <DataSection.Item label={formatMessage({ id: ETranslations.GENDER })}>
            {sex || placeholder}
          </DataSection.Item>
          {/* <DataSection.Item label={formatMessage({ id: ETranslations.COUNTRY })}>
          {country || placeholder}
        </DataSection.Item> */}
        </DataSection.Root>
      </section>
       <section className={styles.contentSection}>
        <h2>{formatMessage({ id: ETranslations.CONTACT })}</h2>
        <DataSection.Root>
          <DataSection.Item
            label={formatMessage({ id: ETranslations.PHONE })}
            onClick={(e) =>
              phone &&
              navigator.clipboard
                .writeText(e.currentTarget.textContent || "")
                .then(() =>
                  Notification.info({
                    title: formatMessage({ id: ETranslations.PHONE }),
                    message: formatMessage({ id: ETranslations.COPIED }),
                  }),
                )
            }
          >
            {phone || placeholder}
          </DataSection.Item>
          <DataSection.Item
            label={formatMessage({ id: ETranslations.WORK_PHONE })}
            onClick={(e) =>
              workPhone &&
              navigator.clipboard
                .writeText(e.currentTarget.textContent || "")
                .then(() =>
                  Notification.info({
                    title: formatMessage({ id: ETranslations.WORK_PHONE }),
                    message: formatMessage({ id: ETranslations.COPIED }),
                  }),
                )
            }
          >
            {workPhone || placeholder}
          </DataSection.Item>
          <DataSection.Item label={formatMessage({ id: ETranslations.EMAIL })}>
            {email || placeholder}
          </DataSection.Item>
          <DataSection.Item
            label={formatMessage({ id: ETranslations.ALT_EMAIL })}
          >
            {altEmail || placeholder}
          </DataSection.Item>
          {/* <DataSection.Item
          label={formatMessage({ id: ETranslations.PRIORITY_COMMUNICATION })}
        >
          {priorityContact || placeholder}
        </DataSection.Item> */}
        </DataSection.Root>
      </section>
       <section className={styles.contentSection}>
        <h2>Special</h2>
        <DataSection.Root>
          {hasLoyaltyProgram ? (
            <>
              <DataSection.Item
                label={formatMessage({ id: ETranslations.LOYALTY_STATUS })}
              >
                {loyaltyStatus || placeholder}
              </DataSection.Item>
              {/*  <DataSection.Item
              label={formatMessage({
                id: ETranslations.LOYALTY_REGISTRATION_DATE,
              })}
            >
              {loyaltyRegistrationDate || placeholder}
            </DataSection.Item> */}
            </>
          ) : (
            <DataSection.Item label={formatMessage({ id: ETranslations.VIP })}>
              {vip || placeholder}
            </DataSection.Item>
          )}

          {/* <DataSection.Item label={formatMessage({ id: ETranslations.SOURCE })}>
          {source || placeholder}
        </DataSection.Item> */}
        </DataSection.Root>
      </section>
       <section className={styles.contentSection}>
        <h2>{formatMessage({ id: ETranslations.ADDITIONAL_INFO })}</h2>
        <DataSection.Root>
          {/* <DataSection.Item
          label={formatMessage({ id: ETranslations.PREFERENCES })}
        >
          {preferences || placeholder}
        </DataSection.Item> */}
          <DataSection.Item
            label={formatMessage({ id: ETranslations.GUEST_COMMENT })}
          >
            {comment || placeholder}
          </DataSection.Item>
        </DataSection.Root>
      </section>
    </div>
  );
};

export default GuestInfoBigBlock;
