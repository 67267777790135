import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Card } from "ui-kit";

import GuestForm from "./GuestForm";
import styles from "./GuestFormContainer.module.scss";

export const GuestFormContainer = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <Card
      onClose={() => navigate("..")}
      as="section"
      className={styles.formContainer}
    >
      <Card.Header
        title={formatMessage(
          { id: ETranslations.CARD_OF },
          { value: formatMessage({ id: ETranslations.PLURAL_GUESTS_ALT }) },
        )}
        className={styles.header}
      />
      <Card.Content noPadding className={styles.content}>
        <GuestForm />
      </Card.Content>
    </Card>
  );
};
