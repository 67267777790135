import dayjs from "dayjs";
import { isEmpty } from "lodash";
import type { Client } from "models/client.model";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useUnmount } from "react-use";

import { useApplicationContextActions } from "../../../features/AppContext";
import { useFromProxyActions } from "../../../features/BookingFormProxy";
import { selectOrder } from "../../../features/BookingFormProxy/selectors";
import { HallMode, useHallSchemaActions } from "../../../features/HallSchema";
import { hallModeSelector } from "../../../features/HallSchema/selectors";
import { useTimelineActions } from "../../../features/Timeline";
import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { useIsTablet } from "../../../hooks/useIsTablet";
import { BookingOrder } from "../../../types/booking";
import { getBookingStartTime } from "../../../utils";

const useBookingOrders = () => {
  const { getIntlEntityViewingAction } = useIntlUtils();
  const mode = useSelector(hallModeSelector);
  const { setOnlyOrder, reset, setClient } = useFromProxyActions();
  const orderSelected = useSelector(selectOrder);
  const isTablet = useIsTablet();
  const { switchMode } = useHallSchemaActions();
  const intl = useIntl();
  const { setTime, resetTimeShift } = useTimelineActions();
  const { setPlaceFromOrder, setDate } = useApplicationContextActions();

  const hasOrder = useMemo(
    () => !isEmpty(orderSelected) && !!orderSelected.orderId,
    [orderSelected],
  );
  const close = useCallback(() => {
    setOnlyOrder(undefined);
    resetTimeShift();
    setDate(dayjs.tz().toISOString());
  }, [setOnlyOrder]);

  const onSelectOrder = (order: BookingOrder) => {
    reset();
    setPlaceFromOrder(order);
    setOnlyOrder(order);
    setTime(getBookingStartTime(order.bookingDate, order.bookingTime));
    setDate(dayjs(order.bookingDate).toISOString());
  };

  const handleClose = useMemo(() => {
    if (!hasOrder) return undefined;
    return close;
  }, [hasOrder]);

  const closeHall = useCallback(
    () => switchMode(HallMode.TABLES),
    [switchMode],
  );

  const closeGuests = useCallback(
    () => switchMode(HallMode.TABLE_BOOKINGS_EDIT),
    [switchMode],
  );

  const handleSetClient = useCallback(
    (client: Client) => {
      setClient({ client });
      closeGuests();
    },
    [setClient, closeGuests],
  );

  useUnmount(() => setDate(dayjs.tz().toISOString()));

  return {
    intl,
    isTablet,
    hasOrder,
    onSelectOrder,
    handleClose,
    mode,
    orderSelected,
    getIntlEntityViewingAction,
    closeGuests,
    handleSetClient,
    closeHall,
  };
};

export default useBookingOrders;
