import styles from "./FormField.module.scss";
import { ReactNode } from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { Input } from "ui-kit";

export interface FormFieldProps
  extends FieldProps<string, FieldRenderProps<string>> {
  label: ReactNode;
  required?: boolean;
}

export function FormField({ label, required, ...props }: FormFieldProps) {
  return (
    <Field {...props}>
      {({ input, meta }) => (
        <div className={styles.field}>
          <Input {...input} label={label} required={required} />
          {meta.touched && meta.error && (
            <span className={styles.error}>{meta.error}</span>
          )}
        </div>
      )}
    </Field>
  );
}
