import type { CSSObject } from "@emotion/react";
import React, { FC, useEffect, useRef, useState } from "react";
import { StylesConfig, components } from "react-select";

import { Input } from "..";
import { OptionSelectType, SelectBasic, SelectBasicProps } from "./SelectBasic";

const { MenuList, ValueContainer, SingleValue, Placeholder } = components;

const customStyles: StylesConfig<OptionSelectType, false> = {
  control: (base, state) => {
    const { menuIsOpen } = state;

    const borderColor = `var(--${menuIsOpen ? "gl_dividerBorder_accent" : "gl_dividerBorder_primary"})`;
    const boxShadow = `var(--${menuIsOpen ? "shadow-lightest" : "none"})`;

    return {
      ...base,
      border: `1px solid ${borderColor}`,
      boxShadow,
      borderRadius: 3,
      backgroundColor: "var(--select_default_background_1)",
      height: 31,
      minHeight: 31,
      boxSizing: "border-box",

      ":hover": {
        border: `1px solid ${borderColor}`,
        boxShadow,
      },
    } as CSSObject;
  },
  menu: (base) =>
    ({
      ...base,
      marginTop: "8px",
      backgroundColor: "var(--select_default_background_1)",
      boxShadow: "var(--Border_Lightest)",
      zIndex: 100,
      borderRadius: 3,
    }) as CSSObject,
};

const CustomMenuList = ({ selectProps, ...props }: any) => {
  const { onInputChange, inputValue, onMenuInputFocus } = selectProps;
  return (
    <div className="select__menu">
      <div style={{ margin: 10 }}>
        <Input.Search
          value={inputValue}
          onFocus={onMenuInputFocus}
          onMouseDown={(e) => {
            e.stopPropagation();
            // @ts-ignore
            e.target?.focus?.();
          }}
          onSearch={(value) =>
            onInputChange(value, {
              action: "input-change",
            })
          }
          onTouchEnd={(e) => {
            e.stopPropagation();
            // @ts-ignore
            e.target?.focus?.();
          }}
        />
      </div>
      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

const CustomValueContainer = ({ children, selectProps, ...props }: any) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme,
    ...props,
  };

  return (
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(
        children,
        (child) =>
          child ||
          (props.hasValue ? (
            <SingleValue {...commonProps} isDisabled={selectProps.isDisabled}>
              {selectProps.getOptionLabel(props.getValue()[0])}
            </SingleValue>
          ) : (
            <Placeholder {...commonProps} key="placeholder">
              {selectProps.placeholder}
            </Placeholder>
          )),
      )}
    </ValueContainer>
  );
};

export const SelectHeader: FC<SelectBasicProps<OptionSelectType>> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null!);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const onDomClick = (e: MouseEvent) => {
    const menu = containerRef.current.querySelector(".select__menu");

    if (
      !containerRef.current.contains(e.target as HTMLElement) ||
      !menu ||
      !menu.contains(e.target as HTMLElement)
    ) {
      setIsFocused(false);
      setInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  return (
    <div ref={containerRef}>
      <SelectBasic
        {...props}
        components={{
          MenuList: CustomMenuList,
          ValueContainer: CustomValueContainer,
        }}
        inputValue={inputValue}
        isSearchable={false}
        maxMenuHeight={220}
        styles={customStyles}
        onChange={(...args) => {
          props?.onChange && props.onChange(...args);
          setIsFocused(false);
        }}
        onInputChange={(val) => setInputValue(val)}
        onMenuInputFocus={() => setIsFocused(true)}
        {...{
          menuIsOpen: isFocused || props.menuIsOpen || undefined,
          isFocused: isFocused || undefined,
        }}
        menuPortalTarget={null}
      />
    </div>
  );
};
