import { useIntl } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";
import { ETranslations } from "types/translates";
import { LinkButton } from "ui-kit";
import { NavLinkTabs } from "ui-kit/Card/NavLinkTabs";



import styles from "./GuestsLayout.module.scss";


const AddGuestButton = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const guestsChildRouteName = pathname.replace(/\/guests\/(\w+).*/, "$1");
  return (
    <LinkButton
      variant="primary"
      className={styles.createGuestButton}
      to={`${guestsChildRouteName}/create`}
    >
      {formatMessage(
        { id: ETranslations.BASE_ADD_ENTITY },
        {
          entity: formatMessage({
            id:
              guestsChildRouteName === "sources"
                ? ETranslations.SOURCE
                : ETranslations.PLURAL_GUESTS_ALT,
          }).toLowerCase(),
        },
      )}
    </LinkButton>
  );
};

export const GuestsLayout = () => {
  // FIXME: div временный, пока не будет отделен main от layout
  const { formatMessage } = useIntl();
  return (
    <div className={styles.layout}>
      <NavLinkTabs
        className={styles.navLinks}
        links={[
          {
            title: formatMessage({ id: ETranslations.GUEST_LIST }),
            to: "clients",
          },
          {
            title: formatMessage({ id: ETranslations.SOURCES }),
            to: "sources",
          },
        ]}
        addons={<AddGuestButton />}
      />
      <Outlet />
    </div>
  );
};