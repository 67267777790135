import BookingHistoryLog from "components/BookingHistory/BookingHistoryLog";
import { useFetchBookingHistoryQuery } from "features/api/bookings-api";
import { Spinner } from "ui-kit";

import { BookingSlotDetailsError } from "../DetailsPlaceholders";
import { useBookingDetailsContext } from "../context";

export const BookingHistory = () => {
  const { booking } = useBookingDetailsContext();
  const { data, isError, error } = useFetchBookingHistoryQuery(booking.slot_id);
  return data ? (
    <BookingHistoryLog data={data} />
  ) : isError ? (
    <BookingSlotDetailsError message={error} />
  ) : (
    <Spinner />
  );
};
