import { Dialog, DialogStatus } from "features/Dialogs/SDK";
import type { NonEmptyArray, SimpleEntries } from "types/commons";

export type Option<T extends any & {} = string | number> = {
  value: T;
  label: string;
};

export type Filters = {
  restaurant: Option<number>[];
  activity: Option<DialogStatus>[];
};

export const createSortedDialogList = ({
  dialogs,
  renderItem,
  unansweredHeader,
  answeredHeader,
  headerClassName,
}: {
  dialogs: NonEmptyArray<Dialog>;
  renderItem: (dialog: Dialog) => JSX.Element;
  unansweredHeader: string;
  answeredHeader: string;
  headerClassName: string;
}) => {
  // Определяем количество неотвеченных сообщений, если диалог не закрыт, закрытые диалоги считаются отвеченными
  const getUnansweredCount = (dialog: Dialog) =>
    dialog.status === DialogStatus.CLOSED ? 0 : dialog.unanswered_count;

  const sortByUnansweredCount = (a: Dialog, b: Dialog) =>
    Boolean(getUnansweredCount(b)) !== Boolean(getUnansweredCount(a)) &&
    getUnansweredCount(b) - getUnansweredCount(a);

  const sortByDialogStatus = (a: Dialog, b: Dialog) =>
    //Number(true) = 1; Number(false) = 0
    Number(b.status !== DialogStatus.CLOSED) -
    Number(a.status !== DialogStatus.CLOSED);

  const sortByMessageDate = (a: Dialog, b: Dialog) =>
    Date.parse(b.last_customer_message_at ?? b.created_at) -
    Date.parse(a.last_customer_message_at ?? a.created_at);

  const sortByClosedDate = (a: Dialog, b: Dialog) =>
    Date.parse(b.closed_at!) - Date.parse(a.closed_at!);

  // Сортируем диалоги по трём параметрам: по наличию неотвеченных сообщений, по статусу диалога (закрыт/не закрыт) и по дате
  const sortedDialogs = [...dialogs].sort(
    (a, b) =>
      // если один из диалогов считается отвеченным, а второй нет, то сортируем в пользу неотвеченного
      sortByUnansweredCount(a, b) ||
      // если оба находятся в одной группе, то сортируем по статусу
      sortByDialogStatus(a, b) ||
      // а если у них одинаковый статус, то по дате: открытия для открытых, закрытия для закрытых
      (a.status !== DialogStatus.CLOSED
        ? sortByMessageDate(a, b)
        : sortByClosedDate(a, b)),
  );

  /// Находим индекс первого отвеченного диалога
  const firstAnsweredDialogIndex = sortedDialogs.findIndex(
    (dialog) => !dialog.unanswered_count,
  );

  // Проверяем, есть ли хоть один неотвеченный диалог, то есть firstAnsweredDialogIndex > 0 или === -1
  // если есть, то в начале массива будет unansweredHeader
  const initialJSXElements: JSX.Element[] = firstAnsweredDialogIndex
    ? [
        <h2 className={headerClassName} key="unAnswered">
          {unansweredHeader}
        </h2>,
      ]
    : [];

  // Создаем массив JSX-элементов для каждого диалога, добавляя заголовок отвеченных диалогов в нужное место
  return sortedDialogs.reduce(
    (result, dialog, index) => (
      index === firstAnsweredDialogIndex &&
        result.push(
          <h2 className={headerClassName} key="answered">
            {answeredHeader}
          </h2>,
        ),
      result.push(renderItem(dialog)),
      result
    ),
    initialJSXElements,
  );
};

// проверка валидности значений из localStorage
export const getValidFiltersValues = (
  localFilters: { [key: string]: unknown } | undefined,
  optionsObj: Filters,
  initialFilters: Filters,
) => {
  const pushToResultIfInOptions = <
    K extends keyof Filters,
    O extends Filters[K],
    L extends {}[],
  >(
    options: O,
    localOptions: L,
    filter: Filters[K],
  ) =>
    options.forEach(
      function (
        this: { filter: Filters[K]; localOptions: L },
        option: O[number],
      ) {
        this.localOptions.some(
          (localOption) =>
            "value" in localOption && localOption.value === option.value,
        ) &&
          this.filter.every(
            (filterOption) => filterOption.value !== option.value,
          ) &&
          this.filter.push(option as never);
      },
      { localOptions, filter },
    );

  //Если в localStorage есть localFilters
  return localFilters
    ? // Проходимся по доступным опциям для фильтра
      (
        Object.entries(optionsObj) as SimpleEntries<typeof optionsObj>
      ).reduce<Filters>(
        (result, [key, options]) =>
          (
            // Если и в localStorage фильтр под ключом это массив
            Array.isArray(localFilters[key]) &&
              // то добавляем все значения в результат, которые соответствуют доступным опциям
              pushToResultIfInOptions(
                options,
                localFilters[key] as {}[],
                result[key],
              ),
            result
          ),
        { restaurant: [], activity: [] },
      )
    : // Если в localStorage нет localFilters, то ставит дефолтные фильтры
      initialFilters;
};

export const getFiltersCounter = (
  filters: Filters,
  initialFilters: Filters,
) => {
  return (Object.entries(filters) as SimpleEntries<typeof filters>).reduce(
    (count, [key, options]) =>
      count +
      +Boolean(
        options.length &&
          (!initialFilters[key].length ||
            options.some((option) =>
              initialFilters[key].some(
                (initialOption) => initialOption.value !== option.value,
              ),
            )),
      ),
    0,
  );
};
