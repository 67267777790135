import { useFromProxyActions } from "../../../features/BookingFormProxy";
import { useCreateOrderMutation } from "../../../features/api/booking-order-api";
import { CloseBookingContext } from "contexts/CloseBookingContext";
import { useCallback, useContext, useEffect } from "react";

const useEditOrder = () => {
  const [createOrder] = useCreateOrderMutation();
  const { setEditMode } = useFromProxyActions();
  const closeCard = useContext(CloseBookingContext);
  const update = useCallback(
    async (data) => {
      await createOrder(data).unwrap();
      closeCard?.();
    },
    [createOrder],
  );

  // onUnmount
  useEffect(
    () => () => {
      setEditMode(false);
    },
    [setEditMode],
  );
  return {
    update,
  };
};

export default useEditOrder;
