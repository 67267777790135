import cn from "classnames";
import { ICONS } from "common/helpers";
import { Statistic } from "types/booking";

import styles from "./RestaurantBookingStats.module.scss";

interface StatsProps {
  stats?: Statistic;
  title?: string;
}

export function RestaurantBookingStats({ stats, title }: StatsProps) {
  if (!stats) return null;

  return (
    <>
      {title}
      <div className={styles.stats}>
        <span className={styles.section}>
          {stats.all.bookings}/{stats.all.guests}
        </span>
        <span className={cn(styles.section, styles.small)}>
          <ICONS.OverviewBook width={20} height={20} />
          {stats.notInHall.bookings}/{stats.notInHall.guests}
        </span>
        <span className={cn(styles.section, styles.small)}>
          <ICONS.VisitorBook width={20} height={20} />
          {stats.inHall.bookings}/{stats.inHall.guests}
        </span>
      </div>
    </>
  );
}
