import { Option } from "common/components/MultiSelect/multi-select";
import { restaurantSelector } from "features/AppContext";
import { useFetchShiftsListQuery } from "features/api/shifts";
import React, { useEffect, useMemo } from "react";
import { FieldRenderProps, useField } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { SelectBasic } from "ui-kit";

const ShiftsField: React.FC<FieldRenderProps<string, any>> = ({ input }) => {
  const intl = useIntl();

  const {
    input: { value, onChange },
  } = useField<Option<any> | null>(input.name, { allowNull: true });
  const restaurant = useSelector(restaurantSelector);
  const { data: shifts } = useFetchShiftsListQuery(restaurant.restaurant_id);
  const options = useMemo(() => {
    if (!shifts?.data.length) {
      return [{ label: "", value: "" }];
    }

    return shifts.data.map((each) => ({
      label: each.shift_name,
      value: each.id,
    }));
  }, [shifts?.data]);

  useEffect(() => {
    if (value && typeof value === "number") {
      //@ts-ignore
      const selected = options.filter((option: Option<any>) => {
        return option.value === value;
      })[0];
      if (selected?.value) {
        onChange(selected);
      }
    }
  }, [value, options]);

  return (
    <SelectBasic
      isValid={!!value}
      options={options}
      placeholder={intl.formatMessage({ id: ETranslations.CHOOSE_SHIFT })}
      value={value}
      changedMaxSize
      onChange={onChange}
    />
  );
};

export default ShiftsField;
