import type { RestaurantId } from "models/booking.model";
import type { ClientId } from "models/client.model";

import { coreApi } from "./core";

type ChatParams = {
  client_id: ClientId;
  partner_id: RestaurantId;
  limit: number;
};

const chatApi = coreApi
  .enhanceEndpoints({ addTagTypes: ["Chat"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getChatMessages: build.query<any, ChatParams>({
        query: (params) => ({
          url: `/v2/chat/messages`,
          params,
        }),
        providesTags: (_response, _error, args) => [
          {
            type: "Chat",
            id: `${args.client_id}-${args.partner_id}-${args.limit}`,
          },
        ],
      }),
    }),
  });

export const { useGetChatMessagesQuery } = chatApi;
