import { GUESTS_TITLE } from "../../../../constants";
import { Modal } from "../../../modal";
import {
  TDuration,
  addPlusToDuration,
  getDurationFormat,
  getPartyOptions,
  required,
} from "../../utils";
import styles from "../Form.module.scss";
import { changeGuestTime } from "./utils";
import cn from "classnames";
import Checkbox from "common/components/checkbox";
import { FormApi } from "final-form";
import React, { FC } from "react";
import { Field } from "react-final-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Input, Labeled, SelectBasic } from "ui-kit";
import { declOfNum } from "utils";

type TProps = {
  form: FormApi<any, Partial<any>>;
};

const CapacityFields: FC<TProps> = ({ form }) => {
  const intl = useIntl();
  return (
    <Modal.Content className={styles.modalContent}>
      <Field
        name="shift_name"
        style={{ display: "none" }}
        validate={required}
        required
      >
        {({ input, meta, ...rest }) => <Input {...input} {...meta} {...rest} />}
      </Field>
      <span className={cn(styles.description, styles.row)}>
        {intl.formatMessage({ id: ETranslations.DURATION_DESCRIPTION })}
      </span>
      <div className={styles.durationsBlock}>
        <Field name={"durations"} type="checkbox">
          {({ input }) => {
            const fieldValues: TDuration[] =
              form.getFieldState("durations")?.value;
            return fieldValues?.map((place) => (
              <Labeled
                className={styles.row}
                key={place.count}
                label={`${
                  addPlusToDuration(place.count, fieldValues.length) +
                  ` ${intl.formatMessage({ id: declOfNum(place.count, GUESTS_TITLE) })}`
                }`}
              >
                <SelectBasic
                  {...input}
                  defaultValue={getDurationFormat(place.duration)}
                  isDisabled={input.disable}
                  key={place.count}
                  options={getPartyOptions()}
                  changedMaxSize
                  onChange={(value: any) =>
                    changeGuestTime(
                      value,
                      fieldValues,
                      place.count,
                      input.onChange,
                    )
                  }
                />
              </Labeled>
            ));
          }}
        </Field>
      </div>
      <Field name="use_max_covered" type="checkbox">
        {({ input }) => (
          <Checkbox
            className={cn(styles.capacityRow, styles.checkbox)}
            indent="confirmed"
            text={intl.formatMessage({ id: ETranslations.SET_MAX_COVERED })}
            value={input.checked}
            useStopPropagation
            onChange={input.onChange}
          />
        )}
      </Field>
      <Field name="max_covered_size">
        {({ input, meta, ...rest }) => (
          <Input
            {...input}
            {...meta}
            {...rest}
            className={cn(styles.row, styles.capacityRow)}
          />
        )}
      </Field>
    </Modal.Content>
  );
};

export default CapacityFields;
