import {
  usePrepareShiftsSchedule,
  useSlots,
} from "features/api/hallschema-api";
import { identity } from "lodash";
import { Spinner } from "ui-kit";

import { NewScheme } from "./NewScheme";

export const SCHEME_FACTOR = 40;

// new schema component for redux
export const HallSchema = ({
  onBookingListClick,
}: {
  onBookingListClick?: () => void;
}) => {
  // request to build timeline

  usePrepareShiftsSchedule();
  const { data: tableSlotsData, isLoading: slotsLoading } = useSlots();
  const isAllLoaded = [!slotsLoading].every(identity);

  if (!isAllLoaded) return <Spinner />;

  return (
    <NewScheme
      hallInfo={tableSlotsData || []}
      onBookingListClick={onBookingListClick}
    />
  );
};
