import { extraStatusColors } from "constants/colors";
import type { Status } from "types/status";

export const MAX_STATUS_NAME_LENGTH = 40;

export const getColor = (status: Status) =>
  extraStatusColors.find(({ color }) => color === status.color);

export const editStatusFormInputNames = {
  statusName: "status-name",
  color: "color",
} as const;

export interface EditStatusFormData extends FormData {
  get(
    name: (typeof editStatusFormInputNames)[keyof typeof editStatusFormInputNames],
  ): FormDataEntryValue | null;
}

export type FormErrors = Partial<
  Record<
    (typeof editStatusFormInputNames)[keyof typeof editStatusFormInputNames],
    string
  >
> | null;
