import { PlaceSelect } from "components/PlaceSelect";
import { NewScheme } from "components/hall-scheme/redux/HallSchemaV2/NewScheme";
import {
  placeSelector,
  useApplicationContextActions,
} from "features/AppContext";
import {
  useSelectedTableId,
  useTableBookingListActions,
} from "features/TableBooking/slice";
import {
  HallSlotsDTO,
  usePrepareShiftsSchedule,
} from "features/api/hallschema-api";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";
import { Info } from "ui-kit/ICONS/icons";

import { FormClose, FormHeader } from "../../FormComponents";
import styles from "./TableModal.module.scss";

type Props = {
  slots?: HallSlotsDTO[];
  reservationInfo?: string;

  onClose: () => void;
};

export function TableModal({ slots, onClose, reservationInfo }: Props) {
  const { formatMessage } = useIntl();

  usePrepareShiftsSchedule();

  const placeId = useSelector(placeSelector);
  const tableId = useSelector(useSelectedTableId);
  const [initials] = useState({ tableId, placeId });
  const { setTable, reset } = useTableBookingListActions();
  const { setPlace } = useApplicationContextActions();

  const handleClose = useCallback(() => {
    // сброс зала на изначальный
    setPlace(initials.placeId);
    // сброс стола на изначальный
    if (initials.tableId) setTable(initials.tableId);
    else reset();
    // закрытие
    onClose();
  }, [onClose, initials]);

  return (
    <section className={styles.tableModal}>
      <FormHeader
        title={formatMessage({ id: ETranslations.SELECT_TABLE_MODAL_TITLE })}
      >
        <div className={styles.placeSelector}>
          <PlaceSelect />
        </div>
        <FormClose to="" onClose={handleClose} />
      </FormHeader>
      <div className={styles.content}>
        <NewScheme
          hallInfo={slots ?? []}
          customPlaceId={placeId}
          timelineToolbar={
            <div className={styles.timelineToolbar}>
              <div className={styles.toolbarInner}>
                <div className={styles.info}>
                  {reservationInfo && (
                    <>
                      <Info className={styles.icon} />
                      <p>{reservationInfo}</p>
                    </>
                  )}
                </div>
                <Button variant="primary" onClick={onClose} disabled={!tableId}>
                  {formatMessage({
                    id: ETranslations.BASE_CHOOSE,
                  })}
                </Button>
              </div>
            </div>
          }
        />
      </div>
    </section>
  );
}
