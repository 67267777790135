import { getMaskPhone, getShortName } from "common/helpers";

import type { CreateBookingFormClient } from "./context/CreateBookingContext";

export const formatClient = (client: CreateBookingFormClient) => {
  const shortName =
    getShortName(client.name, client.middleName, client.surname) || "N/A";
  const phone = client.phone ? getMaskPhone(client.phone) : "N/A";

  return `${shortName}\n${phone}`;
};
